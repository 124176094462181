import { useState, useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import UsersTable from '../blocks/usersTable';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Pagination,
} from '@mui/material';
import SelectControl from '../elements/SelectControl';
import { useForm, Controller } from "react-hook-form";
import { Order, OrderBy } from '../../types/types';
import { mockUsers } from '../../data/data';
import { InputText } from '../elements/InputText';
import { emailPattern } from '../../utils';
import EditAdminDialog from '../blocks/EditAdminDialog';
import { getUsers, inviteAdmin, removeUser, resendInviteUser, updateUserData } from '../../service/userService';
import { useResponseError } from '../../hooks/useResponseError';
import { useResponseSuccessMsg } from '../../hooks/useResponseSuccessMsg';
import SuccessMsgDialog from '../blocks/SuccessMsgDialog';
import ErrorMsgDialog from '../blocks/errorMsgDialog';
import { STORAGE_USER } from '../../constants/storage';

type RegisterFormFields = {
  email: string;
  firstName: string;
  lastName: string;
  roles: 'ADMIN' | 'SUPER ADMIN';
};

const Users = () => {
  const userProfile = JSON.parse(localStorage.getItem(STORAGE_USER) || '');

  const [sort, setSort] = useState('ALL');
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof OrderBy>('roles');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const theme = useTheme();
  const [users, setUsers] = useState<any>([]);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openEditAdmin, setOpenEditAdmin] = useState(false);
  const [userState, setUserState] = useState(null);
  const { responseError, setResponseError } = useResponseError(50000);
  const { responseStatus, setResponseStatus } = useResponseSuccessMsg(3000);
  const [successMsgTitle, setSuccessMsgTitle] = useState('');
  const [successMsgSubTitle, setSuccessMsgSubTitle] = useState('');
  const [errorsMsgTitle, setErrorMsgTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const isSuperAdmin = userProfile?.roles.includes('SUPER_ADMIN');

  const dicRoles = {
    'ADMIN': ['ADMIN'],
    'SUPER ADMIN': ['SUPER_ADMIN'],
  }

  useEffect(() => {
    if (pageNumber) {
      sortUsers(sort, order, orderBy, pageNumber);
    }
  }, [pageNumber]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<RegisterFormFields>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      roles: 'ADMIN',
    },
    mode: "onChange",
  });

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleNextPage = (pgNumber: number) => {
    if (hasMore) {
      setPageNumber(pgNumber + 1)
    } else {
      setPageNumber(pgNumber);
    }
  }

  const handlePrevPage = (pgNumber: number) => {
    if (pgNumber > 0) {
      setPageNumber(pgNumber - 1)
    } else {
      setPageNumber(0);
    }
  }

  const handleClickOpen = () => {
    setOpenAdmin(true);
  };

  const handleClose = () => {
    setOpenAdmin(false);
  };

  const handleEditOpen = () => {
    setOpenEditAdmin(true);
  };

  const handleEditClose = () => {
    setOpenEditAdmin(false);
  };

  const onSubmit = async (data: any) => {
    try {
      //@ts-ignore
      data.roles = dicRoles[data.roles];
      const res = await inviteAdmin(data);
      setResponseStatus(true);
      setSuccessMsgTitle('Admin Added');
      setSuccessMsgSubTitle('Admin has been added.');
      setOpenAdmin(false);
      setPageNumber(1);
      sortUsers(sort, order, orderBy, 1);
    } catch (error: any) {
      setErrorMsgTitle('Admin add error');
      setResponseError(error.message)
    }
  }

  const onEditSubmit = async (data: any) => {
    try {
      //@ts-ignore
      data.roles = dicRoles[data.roles];
      //@ts-ignore
      const userId = userState?.id || data.id;
      const res = await updateUserData({
        id: userId,
        data: data,
      });
      setResponseStatus(true);
      setSuccessMsgTitle('Admin Edited');
      setSuccessMsgSubTitle('Admin has been edited.');
      setOpenEditAdmin(false);
      setPageNumber(1);
      sortUsers(sort, order, orderBy, 1);
    } catch (error: any) {
      setErrorMsgTitle('Admin Edit error');
      setResponseError(error.message)
    }
  }

  const onDeleteSubmit = async (user: any) => {
    try {
      //@ts-ignore
      const userId = userState?.id;
      const res = await removeUser({ id: userId, })
      setOpenEditAdmin(false);
      setResponseStatus(true);
      setSuccessMsgTitle('Admin Deleted');
      setSuccessMsgSubTitle('Admin has been deleted.');
      setPageNumber(1);
      sortUsers(sort, order, orderBy, 1);
    } catch (error: any) {
      setErrorMsgTitle('Admin Delete error');
      setResponseError(error.message)
    }
  }

  const handleResendUser = async (user: any) => {
    try {
      console.log('user', user);
      //@ts-ignore
      const userId = user.id;
      const res = await resendInviteUser({ id: userId, })
      setOpenEditAdmin(false);
      setResponseStatus(true);
      setSuccessMsgTitle('Successfully sent.');
      setSuccessMsgSubTitle('');
      setPageNumber(1);
      sortUsers(sort, order, orderBy, 1);
    } catch (error: any) {
      setErrorMsgTitle('Invite resent error');
      setResponseError(error.message)
    }
  }

  const handleEditUser = (user: any) => {
    console.log(user);
    setUserState(user);
    setOpenEditAdmin(true);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBy,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    setPageNumber(1);
    sortUsers(sort, orderValue, property, 1);
  };


  const sortUsers = useCallback(async (sortField: string, orderField: string, orderByField: string, pgNumber: number
  ) => {
    if (!hasMore) return;

    setIsLoading(true);
    let data: {
      take: number,
      page: number,
      searchText?: string,
      roles?: string,
      order: string,
      orderBy: string
    } = {
      page: pgNumber,
      take: 8,
      order: orderField,
      orderBy: orderByField
    }

    if (sortField !== "ALL") {
      data.roles = sortField;
    }

    try {
      const res = await getUsers(data);
      const formatData = res.data;
      setUsers([...formatData]);
      setTotal(res?.meta?.total);
      if (res?.meta?.total) {
        const totalPages = Math.ceil(res?.meta?.total / data.take);
        setTotalPages(totalPages);
        setHasMore(totalPages > data.page ? true : false);
      } else {
        setTotalPages(0);
        setHasMore(false);
      }
    } catch (error: any) {
      setErrorMsgTitle("Sort Table error")
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);

  return <Box sx={[{
    px: '35px',
    py: '20px',
    width: '100%',
    maxWidth: '100%',
  }]}>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: '55px'
    }}>
      <Typography
        variant="h1"
        component="h3"
      >
        Users
      </Typography>
      {isSuperAdmin && <Button variant="contained" onClick={handleClickOpen}>
        Add Admin
      </Button>}
      <SuccessMsgDialog
        title={successMsgTitle}
        subTitle={successMsgSubTitle}
        isOpen={responseStatus}
      />
      <ErrorMsgDialog
        title={errorsMsgTitle}
        subTitle={responseError || ''}
        isOpen={responseError ? true : false}
      />
      <Dialog
        fullScreen
        open={openAdmin}
        onClose={handleClose}
        sx={{
          top: '2%',
          left: '2%',
          right: '2%',
          bottom: '2%',
          '.MuiDialog-paper': {
            padding: '40px',
            boxSizing: 'border-box',
          }
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            justifyContent: 'flex-end',
            maxWidth: '35px',
            width: '100%',
            ml: 'auto'
          }}
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
            <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
          </svg>
        </IconButton>
        <Box sx={{
          maxWidth: '335px',
          width: '100%',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
          <DialogTitle>Add Admin</DialogTitle>
          <DialogContent sx={{
            px: 0,
            pb: '0',
            width: '100%'
          }}>
            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                '& > :not(style)': { width: '100%' },
                'display': 'flex',
                'flexDirection': 'column',
              }}
              id="hook-form-admin"
            >
              <InputText
                autoFocus
                required
                name="email"
                label="Email"
                fullWidth
                placeholder="Email"
                inputProps={{ ...register("email", { required: true, pattern: emailPattern.value }) }}
                error={!!errors['email']}
                helperText={errors['email'] ? emailPattern.message : ''}
              />
              <InputText
                autoFocus
                required
                name="firstName"
                label="First name"
                type="text"
                fullWidth
                placeholder="First name"
                inputProps={{ ...register("firstName", { required: true }) }}
                error={!!errors['firstName']}
                helperText={errors['firstName'] ? 'This is required' : ''}
              />
              <InputText
                autoFocus
                required
                name="lastName"
                label="Last name"
                type="text"
                fullWidth
                variant="outlined"
                placeholder="Last name"
                inputProps={{ ...register("lastName", { required: true }) }}
                error={!!errors['lastName']}
                helperText={errors['lastName'] ? 'This is required' : ''}
              />
              <Controller
                control={control}
                name='roles'
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectControl
                    label='Role'
                    items={
                      [
                        { value: 'ADMIN', label: 'Admin' },
                        { value: 'SUPER ADMIN', label: 'Super Admin' },
                      ]
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
            <Box sx={{
              textAlign: 'left',
              color: theme.palette.error.main,
            }}>{responseError}</Box>
          </DialogContent>
          <DialogActions sx={{
            width: '100%',
            px: 0,
            py: 0,
            mt: '16px'
          }}>
            <Button form="hook-form-admin" fullWidth variant="contained" type="submit">Add admin and send invite</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <EditAdminDialog
        onSubmit={onEditSubmit}
        isOpen={openEditAdmin}
        onDeleteSubmit={onDeleteSubmit}
        handleOpen={handleEditOpen}
        handleClose={handleEditClose}
        user={userState}
        responseError={responseError}
      />
    </Box>
    <Box sx={{
      position: 'relative',
      width: '100%',
      minHeight: '575px',
      mb: '30px',
    }}>
      <Box sx={[{
        'display': 'flex',
        flexWrap: 'wrap',
        pb: '15px',
        pr: '10px',
        maxWidth: '100%',
        width: '100%',
        overflow: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-2px',
      }]}>
        <UsersTable
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
          handleEditUser={handleEditUser}
          handleResendUser={handleResendUser}
          data={users}
          page={pageNumber}
          isSuperAdmin={isSuperAdmin}
        />
      </Box>
    </Box>
    <Box sx={{
      mb: '20px',
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
      {pageNumber > 1 && <Button
        sx={{
          mr: '15px',
          color: '#000',
          borderColor: '#C9C9C9',
          '&:hover': {
            borderColor: '#C9C9C9',
            boxShadow: 'none',
            opacity: '0.7'
          },
          '&:active': {
            boxShadow: 'none',
            borderColor: '#C9C9C9',
          },
        }}
        onClick={() => handlePrevPage(pageNumber)}
        variant="outlined"
        disabled={pageNumber === 1}
      >
        Previous Page
      </Button>}
      <Pagination
        sx={{
          mr: '15px',
        }}
        hideNextButton={true}
        hidePrevButton={true}
        page={pageNumber}
        onChange={handleChangePage}
        count={totalPages}
        variant="outlined"
        color="primary"
      />
      <Button
        sx={{
          color: '#000',
          borderColor: '#C9C9C9',
          '&:hover': {
            borderColor: '#C9C9C9',
            boxShadow: 'none',
            opacity: '0.7'
          },
          '&:active': {
            boxShadow: 'none',
            borderColor: '#C9C9C9',
          },
        }}
        onClick={() => handleNextPage(pageNumber)}
        variant="outlined"
        disabled={!hasMore}
      >
        Next Page
      </Button>
    </Box>
  </Box>;
};

export default Users;