export const mockMapData = [
    [
        "Country",
        "Value",
        "Total Sessions",
        {
            "type": "string",
            "role": "tooltip"
        }
    ],
    [
        "Afghanistan",
        12,
        8,
        "Total Sessions: 8\n        Vibe: Anger"
    ],
    [
        "Armenia",
        3,
        8,
        "Total Sessions: 8\n        Vibe: Balance"
    ],
    [
        "Angola",
        5,
        8,
        "Total Sessions: 8\n        Vibe: Grounded"
    ],
    [
        "Argentina",
        5,
        12,
        "Total Sessions: 12\n        Vibe: Grounded"
    ],
    [
        "Austria",
        10,
        24,
        "Total Sessions: 24\n        Vibe: Joyful"
    ],
    [
        "Australia",
        5,
        162,
        "Total Sessions: 162\n        Vibe: Grounded"
    ],
    [
        "Azerbaijan",
        7,
        16,
        "Total Sessions: 16\n        Vibe: Compassion"
    ],
    [
        "Bangladesh",
        7,
        32,
        "Total Sessions: 32\n        Vibe: Compassion"
    ],
    [
        "Belgium",
        13,
        8,
        "Total Sessions: 8\n        Vibe: Competitive"
    ],
    [
        "Benin",
        11,
        2,
        "Total Sessions: 2\n        Vibe: Off"
    ],
    [
        "Bosnia and Herzegovina",
        13,
        8,
        "Total Sessions: 8\n        Vibe: Competitive"
    ],
    [
        "Bolivia",
        6,
        10,
        "Total Sessions: 10\n        Vibe: Love"
    ],
    [
        "Burkina Faso",
        7,
        6,
        "Total Sessions: 6\n        Vibe: Compassion"
    ],
    [
        "Burundi",
        16,
        2,
        "Total Sessions: 2\n        Vibe: Insecurity"
    ],
    [
        "Brazil",
        3,
        24,
        "Total Sessions: 24\n        Vibe: Balance"
    ],
    [
        "Bulgaria",
        6,
        16,
        "Total Sessions: 16\n        Vibe: Love"
    ],
    [
        "Belarus",
        9,
        24,
        "Total Sessions: 24\n        Vibe: Certain"
    ],
    [
        "Canada",
        8,
        196,
        "Total Sessions: 196\n        Vibe: Peaceful"
    ],
    [
        "Congo",
        10,
        48,
        "Total Sessions: 48\n        Vibe: Joyful"
    ],
    [
        "Cuba",
        5,
        42,
        "Total Sessions: 42\n        Vibe: Grounded"
    ],
    [
        "Djibouti",
        2,
        2,
        "Total Sessions: 2\n        Vibe: Gratitude"
    ],
    [
        "China",
        8,
        36,
        "Total Sessions: 36\n        Vibe: Peaceful"
    ],
    [
        "Colombia",
        16,
        30,
        "Total Sessions: 30\n        Vibe: Insecurity"
    ],
    [
        "Germany",
        15,
        112,
        "Total Sessions: 112\n        Vibe: Anxious"
    ],
    [
        "Denmark",
        14,
        16,
        "Total Sessions: 16\n        Vibe: Fear"
    ],
    [
        "India",
        14,
        28,
        "Total Sessions: 28\n        Vibe: Fear"
    ],
    [
        "Indonesia",
        1,
        40,
        "Total Sessions: 40\n        Vibe: Clarity"
    ],
    [
        "Iran",
        8,
        26,
        "Total Sessions: 26\n        Vibe: Peaceful"
    ],
    [
        "Iraq",
        13,
        34,
        "Total Sessions: 34\n        Vibe: Competitive"
    ],
    [
        "Iceland",
        7,
        18,
        "Total Sessions: 18\n        Vibe: Compassion"
    ],
    [
        "Mexico",
        4,
        210,
        "Total Sessions: 210\n        Vibe: Inspiration"
    ],
    [
        "Malaysia",
        0,
        14,
        "Total Sessions: 14\n        Vibe: Focus"
    ],
    [
        "Myanmar",
        14,
        12,
        "Total Sessions: 12\n        Vibe: Fear"
    ],
    [
        "Nepal",
        14,
        2,
        "Total Sessions: 2\n        Vibe: Fear"
    ],
    [
        "Nigeria",
        16,
        52,
        "Total Sessions: 52\n        Vibe: Insecurity"
    ],
    [
        "Philippines",
        7,
        18,
        "Total Sessions: 18\n        Vibe: Compassion"
    ],
    [
        "Russia",
        15,
        36,
        "Total Sessions: 36\n        Vibe: Anxious"
    ],
    [
        "Saudi Arabia",
        3,
        32,
        "Total Sessions: 32\n        Vibe: Balance"
    ],
    [
        "Senegal",
        4,
        8,
        "Total Sessions: 8\n        Vibe: Inspiration"
    ],
    [
        "Tunisia",
        2,
        2,
        "Total Sessions: 2\n        Vibe: Gratitude"
    ],
    [
        "Viet Nam",
        9,
        40,
        "Total Sessions: 40\n        Vibe: Certain"
    ],
    [
        "South Africa",
        12,
        36,
        "Total Sessions: 36\n        Vibe: Anger"
    ],
    [
        "Syrian Arab Republic",
        0,
        10,
        "Total Sessions: 10\n        Vibe: Focus"
    ],
    [
        "Chad",
        6,
        2,
        "Total Sessions: 2\n        Vibe: Love"
    ],
    [
        "Dominican Republic",
        16,
        84,
        "Total Sessions: 84\n        Vibe: Insecurity"
    ],
    [
        "Algeria",
        13,
        6,
        "Total Sessions: 6\n        Vibe: Competitive"
    ],
    [
        "Ecuador",
        13,
        10,
        "Total Sessions: 10\n        Vibe: Competitive"
    ],
    [
        "Egypt",
        17,
        48,
        "Total Sessions: 48\n        Vibe: Sad"
    ],
    [
        "Gabon",
        0,
        2,
        "Total Sessions: 2\n        Vibe: Focus"
    ],
    [
        "Ghana",
        1,
        8,
        "Total Sessions: 8\n        Vibe: Clarity"
    ],
    [
        "Greece",
        5,
        8,
        "Total Sessions: 8\n        Vibe: Grounded"
    ],
    [
        "Greenland",
        5,
        8,
        "Total Sessions: 8\n        Vibe: Grounded"
    ],
    [
        "Guatemala",
        17,
        14,
        "Total Sessions: 14\n        Vibe: Sad"
    ],
    [
        "Hong Kong",
        2,
        20,
        "Total Sessions: 20\n        Vibe: Gratitude"
    ],
    [
        "Honduras",
        14,
        14,
        "Total Sessions: 14\n        Vibe: Fear"
    ],
    [
        "Italy",
        9,
        80,
        "Total Sessions: 80\n        Vibe: Certain"
    ],
    [
        "Japan",
        13,
        26,
        "Total Sessions: 26\n        Vibe: Competitive"
    ],
    [
        "Kenya",
        10,
        14,
        "Total Sessions: 14\n        Vibe: Joyful"
    ],
    [
        "Cambodia",
        2,
        4,
        "Total Sessions: 4\n        Vibe: Gratitude"
    ],
    [
        "Central African Republic",
        7,
        2,
        "Total Sessions: 2\n        Vibe: Compassion"
    ],
    [
        "Côte d'Ivoire",
        7,
        16,
        "Total Sessions: 16\n        Vibe: Compassion"
    ],
    [
        "Congo - Kinshasa",
        13,
        6,
        "Total Sessions: 6\n        Vibe: Competitive"
    ],
    [
        "Namibia",
        10,
        6,
        "Total Sessions: 6\n        Vibe: Joyful"
    ],
    [
        "Czech Republic",
        15,
        16,
        "Total Sessions: 16\n        Vibe: Anxious"
    ],
    [
        "Eritrea",
        11,
        2,
        "Total Sessions: 2\n        Vibe: Off"
    ],
    [
        "Ethiopia",
        10,
        6,
        "Total Sessions: 6\n        Vibe: Joyful"
    ],
    [
        "Fiji",
        12,
        8,
        "Total Sessions: 8\n        Vibe: Anger"
    ],
    [
        "Finland",
        9,
        8,
        "Total Sessions: 8\n        Vibe: Certain"
    ],
    [
        "Georgia",
        13,
        8,
        "Total Sessions: 8\n        Vibe: Competitive"
    ],
    [
        "Croatia",
        12,
        8,
        "Total Sessions: 8\n        Vibe: Anger"
    ],
    [
        "France",
        14,
        32,
        "Total Sessions: 32\n        Vibe: Fear"
    ],
    [
        "Guinea",
        6,
        6,
        "Total Sessions: 6\n        Vibe: Love"
    ],
    [
        "Haiti",
        2,
        28,
        "Total Sessions: 28\n        Vibe: Gratitude"
    ],
    [
        "Hawaii",
        12,
        8,
        "Total Sessions: 8\n        Vibe: Anger"
    ],
    [
        "Hungary",
        14,
        24,
        "Total Sessions: 24\n        Vibe: Fear"
    ],
    [
        "Ireland",
        2,
        8,
        "Total Sessions: 8\n        Vibe: Gratitude"
    ],
    [
        "Jamaica",
        14,
        14,
        "Total Sessions: 14\n        Vibe: Fear"
    ],
    [
        "Jordan",
        16,
        4,
        "Total Sessions: 4\n        Vibe: Insecurity"
    ],
    [
        "Kazakhstan",
        4,
        32,
        "Total Sessions: 32\n        Vibe: Inspiration"
    ],
    [
        "Mauritania",
        12,
        2,
        "Total Sessions: 2\n        Vibe: Anger"
    ],
    [
        "Moldova",
        10,
        8,
        "Total Sessions: 8\n        Vibe: Joyful"
    ],
    [
        "Spain",
        2,
        80,
        "Total Sessions: 80\n        Vibe: Gratitude"
    ],
    [
        "Korea",
        0,
        24,
        "Total Sessions: 24\n        Vibe: Focus"
    ],
    [
        "Morocco",
        8,
        20,
        "Total Sessions: 20\n        Vibe: Peaceful"
    ],
    [
        "Nicaragua",
        17,
        14,
        "Total Sessions: 14\n        Vibe: Sad"
    ],
    [
        "Pakistan",
        0,
        42,
        "Total Sessions: 42\n        Vibe: Focus"
    ],
    [
        "Poland",
        7,
        40,
        "Total Sessions: 40\n        Vibe: Compassion"
    ],
    [
        "Romania",
        6,
        24,
        "Total Sessions: 24\n        Vibe: Love"
    ],
    [
        "Sweden",
        0,
        16,
        "Total Sessions: 16\n        Vibe: Focus"
    ],
    [
        "Serbia",
        5,
        16,
        "Total Sessions: 16\n        Vibe: Grounded"
    ],
    [
        "United Arab Emirates",
        10,
        14,
        "Total Sessions: 14\n        Vibe: Joyful"
    ],
    [
        "Chile",
        2,
        8,
        "Total Sessions: 8\n        Vibe: Gratitude"
    ],
    [
        "Cameroon",
        9,
        4,
        "Total Sessions: 4\n        Vibe: Certain"
    ],
    [
        "Israel",
        8,
        2,
        "Total Sessions: 2\n        Vibe: Peaceful"
    ],
    [
        "Korea",
        10,
        6,
        "Total Sessions: 6\n        Vibe: Joyful"
    ],
    [
        "Kyrgyzstan",
        3,
        2,
        "Total Sessions: 2\n        Vibe: Balance"
    ],
    [
        "Latvia",
        6,
        8,
        "Total Sessions: 8\n        Vibe: Love"
    ],
    [
        "Sri Lanka",
        17,
        2,
        "Total Sessions: 2\n        Vibe: Sad"
    ],
    [
        "Malawi",
        0,
        4,
        "Total Sessions: 4\n        Vibe: Focus"
    ],
    [
        "New Caledonia",
        17,
        2,
        "Total Sessions: 2\n        Vibe: Sad"
    ],
    [
        "New Zealand",
        0,
        2,
        "Total Sessions: 2\n        Vibe: Focus"
    ],
    [
        "Niger",
        11,
        2,
        "Total Sessions: 2\n        Vibe: Off"
    ],
    [
        "Norway",
        13,
        8,
        "Total Sessions: 8\n        Vibe: Competitive"
    ],
    [
        "Papua New Guinea",
        1,
        2,
        "Total Sessions: 2\n        Vibe: Clarity"
    ],
    [
        "Paraguay",
        1,
        2,
        "Total Sessions: 2\n        Vibe: Clarity"
    ],
    [
        "Rwanda",
        4,
        2,
        "Total Sessions: 2\n        Vibe: Inspiration"
    ],
    [
        "Singapore",
        4,
        10,
        "Total Sessions: 10\n        Vibe: Inspiration"
    ],
    [
        "Solomon Islands",
        4,
        10,
        "Total Sessions: 10\n        Vibe: Inspiration"
    ],
    [
        "Somalia",
        4,
        4,
        "Total Sessions: 4\n        Vibe: Inspiration"
    ],
    [
        "Sudan",
        5,
        6,
        "Total Sessions: 6\n        Vibe: Grounded"
    ],
    [
        "South Sudan",
        17,
        2,
        "Total Sessions: 6\n        Vibe: Sad"
    ],
    [
        "Botswana",
        16,
        2,
        "Total Sessions: 2\n        Vibe: Insecurity"
    ],
    [
        "Sierra Leone",
        12,
        2,
        "Total Sessions: 2\n        Vibe: Anger"
    ],
    [
        "Taiwan",
        7,
        20,
        "Total Sessions: 20\n        Vibe: Compassion"
    ],
    [
        "Tanzania",
        13,
        14,
        "Total Sessions: 14\n        Vibe: Competitive"
    ],
    [
        "Tajikistan",
        17,
        2,
        "Total Sessions: 2\n        Vibe: Sad"
    ],
    [
        "United Kingdom",
        10,
        144,
        "Total Sessions: 144\n        Vibe: Joyful"
    ],
    [
        "Kuwait",
        10,
        2,
        "Total Sessions: 2\n        Vibe: Joyful"
    ],
    [
        "Lebanon",
        8,
        6,
        "Total Sessions: 6\n        Vibe: Peaceful"
    ],
    [
        "Libyan Arab Jamahiriya",
        9,
        4,
        "Total Sessions: 4\n        Vibe: Certain"
    ],
    [
        "Liberia",
        5,
        2,
        "Total Sessions: 2\n        Vibe: Grounded"
    ],
    [
        "Macau",
        16,
        2,
        "Total Sessions: 2\n        Vibe: Insecurity"
    ],
    [
        "Mali",
        9,
        8,
        "Total Sessions: 8\n        Vibe: Certain"
    ],
    [
        "Madagascar",
        5,
        2,
        "Total Sessions: 2\n        Vibe: Grounded"
    ],
    [
        "Mongolia",
        1,
        2,
        "Total Sessions: 2\n        Vibe: Clarity"
    ],
    [
        "Mozambique",
        7,
        8,
        "Total Sessions: 8\n        Vibe: Compassion"
    ],
    [
        "Netherlands",
        6,
        8,
        "Total Sessions: 8\n        Vibe: Love"
    ],
    [
        "Oman",
        5,
        2,
        "Total Sessions: 2\n        Vibe: Grounded"
    ],
    [
        "Peru",
        2,
        20,
        "Total Sessions: 20\n        Vibe: Gratitude"
    ],
    [
        "Togo",
        11,
        2,
        "Total Sessions: 2\n        Vibe: Off"
    ],
    [
        "Thailand",
        12,
        10,
        "Total Sessions: 10\n        Vibe: Anger"
    ],
    [
        "Turkmenistan",
        4,
        2,
        "Total Sessions: 2\n        Vibe: Inspiration"
    ],
    [
        "Uganda",
        2,
        2,
        "Total Sessions: 2\n        Vibe: Gratitude"
    ],
    [
        "Turkey",
        17,
        28,
        "Total Sessions: 28\n        Vibe: Sad"
    ],
    [
        "Ukraine",
        13,
        96,
        "Total Sessions: 96\n        Vibe: Competitive"
    ],
    [
        "United States",
        3,
        224,
        "Total Sessions: 224\n        Vibe: Balance"
    ],
    [
        "Uruguay",
        4,
        2,
        "Total Sessions: 2\n        Vibe: Inspiration"
    ],
    [
        "Uzbekistan",
        2,
        6,
        "Total Sessions: 6\n        Vibe: Gratitude"
    ],
    [
        "Vanuatu",
        2,
        24,
        "Total Sessions: 24\n        Vibe: Gratitude"
    ],
    [
        "Venezuela",
        2,
        24,
        "Total Sessions: 24\n        Vibe: Gratitude"
    ],
    [
        "Yemen",
        16,
        10,
        "Total Sessions: 10\n        Vibe: Insecurity"
    ],
    [
        "Zimbabwe",
        13,
        4,
        "Total Sessions: 4\n        Vibe: Competitive"
    ],
    [
        "Zambia",
        13,
        2,
        "Total Sessions: 2\n        Vibe: Competitive"
    ]
];