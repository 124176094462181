import {
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  SvgIcon,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { intentionsColors } from '../../constants/storage';

type LegendDialogProps = {
  arrIntentions: any[]
  isOpen: boolean,
  setIsOpen: any,
};

const LegendDialog = ({ arrIntentions, isOpen, setIsOpen }: LegendDialogProps) => {
  const theme = useTheme();

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  return (<Dialog
    hideBackdrop
    disableEnforceFocus
    style={{ pointerEvents: 'none' }}
    PaperProps={{ style: { pointerEvents: 'auto' } }}
    open={isOpen}
    onClose={() => setIsOpen(false)}
    sx={{
      maxWidth: '375px',
      width: '100%',
      top: '15%',
      right: '2%',
      left: 'auto',
      '.MuiDialog-container': {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      '.MuiDialog-paper': {
        maxWidth: '225px',
        width: '100%',
        padding: '20px',
        paddingBottom: '10px',
        margin: 0,
        boxSizing: 'border-box',
        border: '1px solid #E2E2E2',
        boxShadow: 'none',
      }
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={() => setIsOpen(false)}
      aria-label="close"
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        justifyContent: 'flex-end',
        maxWidth: '25px',
        width: '100%',
        padding: '2px',
        ml: 'auto'
      }}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
        <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
      </svg>
    </IconButton>
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <DialogTitle sx={{
        padding: 0,
        mb: '20px',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#000',
      }}>Legend</DialogTitle>
      <DialogContentText sx={{
        fontSize: '13px',
        color: '#333'
      }}>
        {arrIntentions.map((item: any, index) => (<Box
          key={index}
          sx={{
            mb: '10px',
            display: 'flex',
            alignItems: 'center',
            '&::before': {
              content: '""',
              mr: '10px',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: intentionsColors[item.toLowerCase() as keyof typeof intentionsColors]
            }
          }}
        >
          {item}
        </Box>
        ))}
      </DialogContentText>
    </Box>
  </Dialog>);
}

export default LegendDialog;