import {
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  SvgIcon,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';

type SuccessMsgProps = {
  title: string,
  subTitle?: string,
  isOpen: boolean,
};

const SuccessMsgDialog = ({ title, subTitle, isOpen }: SuccessMsgProps) => {
  const theme = useTheme();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(isOpen);
  }, [isOpen]);

  return (<Dialog
    hideBackdrop
    disableEnforceFocus
    style={{ pointerEvents: 'none' }}
    PaperProps={{ style: { pointerEvents: 'auto' } }}
    open={isShow}
    onClose={() => setIsShow(false)}
    sx={{
      maxWidth: '375px',
      width: '100%',
      top: '15%',
      right: '2%',
      left: 'auto',
      '.MuiDialog-container': {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      '.MuiDialog-paper': {
        maxWidth: '375px',
        width: '100%',
        padding: '30px',
        margin: 0,
        boxSizing: 'border-box',
        border: '1px solid #E2E2E2',
        boxShadow: 'none',
      }
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={() => setIsShow(false)}
      aria-label="close"
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        justifyContent: 'flex-end',
        maxWidth: '25px',
        width: '100%',
        padding: '2px',
        ml: 'auto'
      }}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
        <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
      </svg>
    </IconButton>
    <Box sx={{
      width: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <SvgIcon sx={
        [{
          width: '24px',
          height: '24px',
          mb: '12px',
        }]
      }>
        <svg width="24" height="24" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 38L36 50L58 28" stroke="#A460F6" strokeWidth="8" />
        </svg>
      </SvgIcon>
      <DialogTitle sx={{
        padding: 0,
        mb: '10px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#000',
      }}>{title}</DialogTitle>
      <DialogContentText sx={{
        fontSize: '13px',
        color: '#333'
      }}>
        {subTitle}
      </DialogContentText>
    </Box>
  </Dialog>);
}

export default SuccessMsgDialog;