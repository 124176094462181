import { useState } from 'react';
import {
  SvgIcon,
  useTheme,
  Button,
  Box,
  Drawer,
  Typography,
  Divider,
  IconButton,
  FormControlLabel,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  RadioGroup,
  Radio,
} from '@mui/material';
import BasicCheckbox from '../elements/BasicCheckbox';

interface Filters {
  [key: string]: any;
}

export default function GeolocationFilterDrawer({
  filters = {},
  mainTitle = 'Locations',
  applyFilters,
  setMapSidebarFiltersOpened,
}: any) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [filtersState, setFiltersState] = useState<Filters>({});
  const [accordionOpen, setAccordionOpen] = useState<string[]>([]);

  const toggleAccordionOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>, continent: string) => {
    const target = e.target as HTMLElement;
    if (e.target instanceof HTMLInputElement || target.classList.contains('MuiFormControlLabel-label')) return;

    if (accordionOpen.includes(continent)) {
      setAccordionOpen(accordionOpen.filter((item) => item !== continent));
    } else {
      setAccordionOpen([...accordionOpen, continent]);
    }
  }

  const resetFiltersState = () => {
    setFiltersState({});
    applyFilters({});
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    setMapSidebarFiltersOpened(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMapSidebarFiltersOpened(false);
  };

  const handleCheckboxChange =
    (continent: string, country: string | null, city: string | null) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        let newFilters: Filters = { ...filtersState };

        if (event.target.checked) {
          if (!newFilters[continent]) {
            newFilters = { [continent]: '' };
          }
          if (country) {
            newFilters[continent] = country;
          }
        } else if (!event.target.checked) {
          if (country) {
            newFilters[continent] = '';
          } else {
            delete newFilters[continent];
          }
        }

        setFiltersState(newFilters);
        applyFilters(newFilters);
      };

  const iconElement = () => {
    return (
      <div className={'select-icon'}>
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5.29289 5.29289C5.68342 5.68342 6.31658 5.68342 6.70711 5.29289L11 1" stroke={theme.palette.primary.main} strokeWidth="2" />
        </svg>
      </div>
    );
  }

  const mapFiltersIcon = () => {
    if (open || Object.keys(filtersState).length > 0) {
      return (
        <svg width="40" height="40" viewBox="0 -14 10 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 0C3.67446 0.00184628 2.40371 0.555562 1.46641 1.53973C0.529107 2.52389 0.00175836 3.85818 0 5.24999C0 9.01905 4.65833 13.7397 4.85625 13.9387C4.89475 13.978 4.94632 14 5 14C5.05368 14 5.10525 13.978 5.14375 13.9387C5.34167 13.7397 10 9.01905 10 5.24999C9.99824 3.85818 9.47089 2.52389 8.53359 1.53973C7.59629 0.555562 6.32554 0.00184628 5 0ZM5 7.65624C4.54675 7.65624 4.10368 7.51512 3.72682 7.25072C3.34996 6.98631 3.05623 6.61051 2.88278 6.17083C2.70933 5.73114 2.66394 5.24732 2.75237 4.78056C2.84079 4.31379 3.05905 3.88504 3.37955 3.54852C3.70004 3.212 4.10838 2.98283 4.55292 2.88998C4.99746 2.79714 5.45824 2.84479 5.87698 3.02691C6.29573 3.20903 6.65364 3.51745 6.90545 3.91315C7.15726 4.30886 7.29167 4.77408 7.29167 5.24999C7.2913 5.88805 7.04974 6.49987 6.62005 6.95105C6.19036 7.40222 5.60768 7.65586 5 7.65624Z" fill="#A460F6" />
        </svg>
      );
    }

    return (
      <svg width="40" height="40" viewBox="0 -14 10 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0C3.67446 0.00184628 2.40371 0.555562 1.46641 1.53973C0.529107 2.52389 0.00175836 3.85818 0 5.24999C0 9.01905 4.65833 13.7397 4.85625 13.9387C4.89475 13.978 4.94632 14 5 14C5.05368 14 5.10525 13.978 5.14375 13.9387C5.34167 13.7397 10 9.01905 10 5.24999C9.99824 3.85818 9.47089 2.52389 8.53359 1.53973C7.59629 0.555562 6.32554 0.00184628 5 0ZM5 7.65624C4.54675 7.65624 4.10368 7.51512 3.72682 7.25072C3.34996 6.98631 3.05623 6.61051 2.88278 6.17083C2.70933 5.73114 2.66394 5.24732 2.75237 4.78056C2.84079 4.31379 3.05905 3.88504 3.37955 3.54852C3.70004 3.212 4.10838 2.98283 4.55292 2.88998C4.99746 2.79714 5.45824 2.84479 5.87698 3.02691C6.29573 3.20903 6.65364 3.51745 6.90545 3.91315C7.15726 4.30886 7.29167 4.77408 7.29167 5.24999C7.2913 5.88805 7.04974 6.49987 6.62005 6.95105C6.19036 7.40222 5.60768 7.65586 5 7.65624Z" fill="#C9C9C9" />
      </svg>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ p: 0 }}
      >
        <SvgIcon sx={{
          width: '40px',
          height: '40px',
        }}>
          {mapFiltersIcon()}
        </SvgIcon>
      </IconButton>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            maxWidth: '335px',
            width: '100%',
            borderLeftColor: '#D9D9D9',
            top: '61.6px',
            height: 'calc(100vh - 61.6px)',
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{
          width: '100%',
          flexGrow: '1',
          py: '20px',
          pl: '20px',
          boxSizing: 'border-box',
          backgroundColor: '#FBFBFB',
        }}>
          <Box sx={{
            mb: '40px',
            pl: '20px',
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography
              variant="h1"
              component="h3"
            >
              {mainTitle}
            </Typography>
            <IconButton
              sx={{
                p: 0,
                position: 'absolute',
                right: '20px',
                top: '20px'
              }}
              onClick={handleDrawerClose}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
                <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
              </svg>
            </IconButton>
          </Box>
          <Box
            sx={{
              mr: '10px',
              position: 'relative',
              maxHeight: 'calc(100% - 75px)',
              height: '100%',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '2px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px #E2E2E2',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '5px',
              right: 0,
              pl: '20px',
            }}>
              {Object.keys(filters).map((continent, index) => (
                <div key={index}>
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: '30px',
                    }}
                  >
                    <Accordion
                      key={index}
                      expanded={accordionOpen.includes(continent)}
                      sx={{
                        boxShadow: 'none',
                        borderRadius: '0',
                        border: 'none',
                        backgroundColor: 'transparent',
                        '&.MuiAccordion-root': {
                          '&.Mui-expanded': {
                            margin: '0',
                          }
                        },
                        '&:before': {
                          display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                          padding: '0',
                          fontWeight: '400',
                          minHeight: 'initial',
                          '&.Mui-expanded': {
                            minHeight: 'initial',
                          },
                          '& .MuiAccordionSummary-content': {
                            margin: '0',
                          }
                        },
                        '& .MuiAccordionDetails-root': {
                          padding: '0 0 0 20px',
                        }
                      }}
                    >
                      <AccordionSummary
                        id="panel-header-continent"
                        aria-controls="panel-content"
                        expandIcon={iconElement()}
                        onClick={(e) => toggleAccordionOpen(e, continent)}
                      >
                        <FormControlLabel
                          key={index}
                          sx={{
                            color: filtersState[continent] !== undefined ? '#000000' : '#666666',
                            '.MuiFormControlLabel-label': {
                              fontSize: '13px',
                            }
                          }}
                          control={
                            <Radio
                              key={index}
                              checked={filtersState[continent] !== undefined}
                              onChange={handleCheckboxChange(continent, null, null)}
                              value={continent}
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                          }
                          label={continent}
                        />
                      </AccordionSummary>
                      <AccordionDetails key={index}>
                        <RadioGroup
                          name={`radio-buttons-${continent}`}
                        >
                          {Object.keys(filters[continent]).map((country: string, countryIndex) => (
                            <Box key={countryIndex}>
                              <FormControlLabel
                                key={countryIndex}
                                sx={{
                                  color: filtersState[continent] && filtersState[continent][country] !== undefined ? '#000000' : '#666666',
                                  '.MuiFormControlLabel-label': {
                                    fontSize: '13px',
                                  }
                                }}
                                control={
                                  <Radio
                                    name="mapCountry[]"
                                    key={countryIndex}
                                    value={country}
                                    checked={filtersState[continent] !== undefined && filtersState[continent] === country}
                                    onChange={handleCheckboxChange(continent, country, null)}
                                  />
                                }
                                label={filters[continent][country].countryName}
                              />
                            </Box>
                          ))}
                        </RadioGroup>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </div>
              ))}
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            mt: 'auto'
          }}
        />
        <Box sx={{
          display: 'flex',
          p: '20px',
          backgroundColor: '#fff',
          borderTop: '1px solid #D9D9D9'
        }}>
          <Button
            fullWidth
            sx={{
              mr: '10px',
              color: '#000',
              borderColor: '#C9C9C9',
              '&:hover': {
                borderColor: '#C9C9C9',
                boxShadow: 'none',
                opacity: '0.7'
              },
              '&:active': {
                boxShadow: 'none',
                borderColor: '#C9C9C9',
              },
            }}
            onClick={resetFiltersState}
            variant="outlined"
          >
            Reset
          </Button>
          <Button
            onClick={handleDrawerClose}
            fullWidth
            variant="contained"
          >
            Confirm
          </Button>
        </Box>
      </Drawer >
    </Box >
  );
}
