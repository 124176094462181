import { handleApiError } from "./handleApiError";
import instance from './index';

type TGetUser = {
  token?: string,
};

type TGetUserById = {
  id: string,
};

type TUserData = {
  phoneNumber?: string,
  firstName: string,
  lastName: string,
  city: string,
  state: string,
  zip: string
}

type TUpdateData = {
  id: string,
  data: TUserData
}

type TRemoveData = {
  id: string,
}

type TResendData = {
  id: string,
}

type TGetUsers = {
  page?: number,
  take?: number,
  roles?: string,
  searchText?: string,
  orderBy?: string,
  order?: string,
}

type TInviteAdmin = {
  email: string;
  firstName: string;
  lastName: string;
  roles: 'ADMIN' | 'SUPER_ADMIN' | 'USER';
}

export const getUsers = async ({
  page = 1,
  take = 12,
  roles,
  searchText,
  orderBy = 'email',
  order = 'desc',
}: TGetUsers) => {
  try {
    const response = await instance.get(`/admin/users`, {
      params: {
        page,
        take,
        filters: {
          searchText,
          roles,
        },
        sorting: {
          orderBy,
          order,
        }
      }
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getUserData = async ({ token }: TGetUser) => {
  try {
    const response = await instance.get(`/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getuserById = async ({ id }: TGetUserById) => {
  try {
    const response = await instance.get(`/admin/users/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const updateUserData = async ({ id, data }: TUpdateData) => {
  try {
    const response = await instance.patch(`/admin/users/${id}`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};


export const removeUser = async ({ id }: TRemoveData) => {
  try {
    const response = await instance.delete(`/admin/users/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const resendInviteUser = async ({ id }: TResendData) => {
  try {
    const response = await instance.post(`/admin/users/${id}/resend-invite`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const inviteAdmin = async (data: TInviteAdmin) => {
  try {
    const response = await instance.post(`/admin/users/`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};
