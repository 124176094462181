export const vibeColors = [
  {
    color: '#fff369',
    colorRgba: 'rgba(255, 243, 105, 1)',
    vibe: 'Focus',
  },
  {
    color: '#2a81ec',
    colorRgba: 'rgba(42, 129, 236, 1)',
    vibe: 'Clarity',
  },
  {
    color: '#008963',
    colorRgba: 'rgba(0, 137, 99, 1)',
    vibe: 'Gratitude',
  },
  {
    color: '#ee95ce',
    colorRgba: 'rgba(238, 149, 206, 1)',
    vibe: 'Balance',
  },
  {
    color: '#ff7303',
    colorRgba: 'rgba(255, 115, 3, 1)',
    vibe: 'Inspiration',
  },
  {
    color: '#c37d5c',
    colorRgba: 'rgba(195, 125, 92, 1)',
    vibe: 'Grounded',
  },
  {
    color: '#c9b26f',
    colorRgba: 'rgba(201, 178, 111, 1)',
    vibe: 'Love',
  },
  {
    color: '#003ca0',
    colorRgba: 'rgba(0, 60, 160, 1)',
    vibe: 'Compassion',
  },
  {
    color: '#73c0ca',
    colorRgba: 'rgba(115, 192, 202, 1)',
    vibe: 'Peaceful',
  },
  {
    color: '#9f9bb5',
    colorRgba: 'rgba(159, 155, 181, 1)',
    vibe: 'Certain',
  },
  {
    color: '#d42d57',
    colorRgba: 'rgba(212, 45, 87, 1)',
    vibe: 'Joyful',
  },
  {
    color: '#dee3e7',
    colorRgba: 'rgba(222, 227, 231, 1)',
    vibe: 'Off',
  },
  {
    color: '#b83020',
    colorRgba: 'rgba(184, 48, 32, 1)',
    vibe: 'Anger',
  },
  {
    color: '#b1c963',
    colorRgba: 'rgba(173, 195, 96, 1)',
    vibe: 'Competitive',
  },
  {
    color: '#0f161e',
    colorRgba: 'rgba(136, 143, 152, 1)',
    vibe: 'Fear',
  },
  {
    color: '#a2a2a2',
    colorRgba: 'rgba(249, 251, 250, 1)',
    vibe: 'Anxious',
  },
  {
    color: '#7e7d81',
    colorRgba: 'rgba(126, 125, 129, 1)',
    vibe: 'Insecurity',
  },
  {
    color: '#879ab8',
    colorRgba: 'rgba(135, 154, 184, 1)',
    vibe: 'Sad',
  },
];

export const vibeColorsArr = [
  '#fff369',
  '#2a81ec',
  '#008963',
  '#ee95ce',
  '#ff7303',
  '#c37d5c',
  '#c9b26f',
  '#003ca0',
  '#73c0ca',
  '#9f9bb5',
  '#d42d57',
  '#dee3e7',
  '#b83020',
  '#b1c963',
  '#0f161e',
  '#a2a2a2',
  '#7e7d81',
  '#879ab8'
];

export const vibeColorsRgbaArr = [
  'rgba(255, 243, 105, 1)',
  'rgba(42, 129, 236, 1)',
  'rgba(0, 137, 99, 1)',
  'rgba(238, 149, 206, 1)',
  'rgba(255, 115, 3, 1)',
  'rgba(195, 125, 92, 1)',
  'rgba(201, 178, 111, 1)',
  'rgba(0, 60, 160, 1)',
  'rgba(115, 192, 202, 1)',
  'rgba(159, 155, 181, 1)',
  'rgba(212, 45, 87, 1)',
  'rgba(222, 227, 231, 1)',
  'rgba(184, 48, 32, 1)',
  'rgba(173, 195, 96, 1)',
  'rgba(136, 143, 152, 1)',
  'rgba(249, 251, 250, 1)',
  'rgba(126, 125, 129, 1)',
  'rgba(135, 154, 184, 1)'
];