export const mapCities = [
  {
    "country": "Afghanistan",
    "cities": [
      "Herat",
      "Kabul",
      "Kandahar",
      "Molah",
      "Rana",
      "Shar",
      "Sharif",
      "Wazir Akbar Khan"
    ]
  },
  {
    "country": "Albania",
    "cities": [
      "Elbasan",
      "Petran",
      "Pogradec",
      "Shkoder",
      "Tirana",
      "Ura Vajgurore"
    ]
  },
  {
    "country": "Algeria",
    "cities": [
      "Algiers",
      "Annaba",
      "Azazga",
      "Batna City",
      "Blida",
      "Bordj",
      "Bordj Bou Arreridj",
      "Bougara",
      "Cheraga",
      "Chlef",
      "Constantine",
      "Djelfa",
      "Draria",
      "El Tarf",
      "Hussein Dey",
      "Illizi",
      "Jijel",
      "Kouba",
      "Laghouat",
      "Oran",
      "Ouargla",
      "Oued Smar",
      "Relizane",
      "Rouiba",
      "Saida",
      "Souk Ahras",
      "Tamanghasset",
      "Tiaret",
      "Tissemsilt",
      "Tizi",
      "Tizi Ouzou",
      "Tlemcen"
    ]
  },
  {
    "country": "Andorra",
    "cities": [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Engordany",
      "Escaldes-Engordany",
      "La Massana",
      "Llorts",
      "Ordino",
      "Santa Coloma",
      "Sispony",
      "Soldeu"
    ]
  },
  {
    "country": "Angola",
    "cities": [
      "Ambriz",
      "Benguela",
      "Cabinda",
      "Cacole",
      "Camabatela",
      "Cazeta",
      "Huambo",
      "Kuito",
      "Lobito",
      "Luanda",
      "Lubango",
      "Lucapa",
      "Lumeje",
      "Malanje",
      "Menongue",
      "Muxaluando",
      "Namibe",
      "Ondjiva",
      "Piri",
      "Saurimo",
      "Talatona"
    ]
  },
  {
    "country": "Antigua and Barbuda",
    "cities": [
      "All Saints",
      "Cassada Gardens",
      "Codrington",
      "Old Road",
      "Parham",
      "Woods"
    ]
  },
  {
    "country": "Argentina",
    "cities": [
      "Buenos Aires",
      "Cordoba",
      "Rosario",
      "Mendoza",
      "San Miguel de Tucuman",
      "La Plata",
      "Mar del Plata",
      "Salta",
      "Santa Fe",
      "San Juan",
      "Resistencia",
      "Corrientes",
      "Posadas",
      "Neuquen",
      "Formosa",
      "San Salvador de Jujuy",
      "Bahia Blanca",
      "Parana",
      "Rio Cuarto",
      "Comodoro Rivadavia",
      "San Nicolas de los Arroyos",
      "Santa Rosa",
      "San Rafael",
      "Tandil",
      "San Carlos de Bariloche",
      "Trelew",
      "Concordia",
      "Junin",
      "Pergamino",
      "San Fernando del Valle de Catamarca",
      "Villa Maria",
      "Zarate",
      "Pilar",
      "San Martin",
      "San Luis",
      "Rafaela",
      "Tres Arroyos",
      "San Francisco",
      "Necochea",
      "Villa Mercedes",
      "Azul",
      "Goya",
      "Olavarria",
      "Rio Gallegos",
      "Chivilcoy",
      "Mercedes",
      "Campana",
      "Viedma",
      "General Roca",
      "Lujan",
      "Gualeguaychu",
      "San Ramon de la Nueva Oran",
      "San Lorenzo",
      "Concepcion del Uruguay",
      "Cutral-Co",
      "Gualeguay",
      "Pehuajo",
      "Venado Tuerto",
      "Lomas de Zamora",
      "Santiago del Estero",
      "San Luis",
      "La Rioja",
      "Rio Tercero",
      "Rio Grande",
      "Tigre",
      "San Nicolas de los Arroyos",
      "Balcarce",
      "Coronel Suarez",
      "Trenque Lauquen",
      "Roosevelt",
      "General Villegas",
      "Punta Alta",
      "Chacabuco",
      "Villa Constitucion",
      "Coronel Pringles",
      "Villaguay",
      "San Martin",
      "San Justo",
      "San Pedro",
      "Yerba Buena",
      "Villa Regina",
      "Rafael Castillo",
      "Retiro",
      "Pilar",
      "Merlo",
      "Victoria",
      "San Isidro",
      "San Fernando",
      "Monte Grande",
      "Burzaco",
      "Don Torcuato",
      "Campana",
      "Lomas de Zamora",
      "Santa Rosa",
      "Concordia",
      "Buenos Aires",
      "Rosario",
      "Mendoza"
    ]
  },
  {
    "country": "Armenia",
    "cities": [
      "Abovyan",
      "Agarak",
      "Apaga",
      "Aparan",
      "Arabkir",
      "Ashtarak",
      "Erebuni Fortress",
      "Hrazdan",
      "Ijevan",
      "Jermuk",
      "Kapan",
      "Tsaghkadzor",
      "Vanadzor",
      "Yerevan"
    ]
  },
  {
    "country": "Aruba",
    "cities": [
      "Noord",
      "Oranjestad",
      "Palm Beach",
      "Paradera",
      "Ponton",
      "Sabaneta",
      "San Barbola",
      "Santa Cruz",
      "Sero Blanco",
      "Sint Nicolaas",
      "Tanki Lender"
    ]
  },
  {
    "country": "Australia",
    "cities": [
      "Sydney",
      "Melbourne",
      "Brisbane",
      "Perth",
      "Adelaide",
      "Gold Coast",
      "Newcastle",
      "Canberra",
      "Sunshine Coast",
      "Wollongong",
      "Hobart",
      "Geelong",
      "Townsville",
      "Cairns",
      "Darwin",
      "Toowoomba",
      "Ballarat",
      "Bendigo",
      "Albury-Wodonga",
      "Launceston",
      "Mackay",
      "Rockhampton",
      "Bunbury",
      "Coffs Harbour",
      "Bundaberg",
      "Wagga Wagga",
      "Hervey Bay",
      "Mildura",
      "Shepparton",
      "Port Macquarie",
      "Gladstone",
      "Tamworth",
      "Traralgon",
      "Orange",
      "Bowral",
      "Geraldton",
      "Dubbo",
      "Nowra",
      "Bathurst",
      "Warrnambool",
      "Albany",
      "Warragul",
      "Devonport",
      "Mount Gambier",
      "Lismore",
      "Nelson Bay",
      "Burnie",
      "Victor Harbor",
      "Maryborough",
      "Whyalla",
      "Griffith",
      "Echuca",
      "Port Hedland",
      "Murray Bridge",
      "Streaky Bay",
      "Port Augusta",
      "Kingston SE",
      "Hamilton",
      "Ceduna",
      "Roebourne",
      "Peterborough",
      "Pinnaroo",
      "Yulara",
      "Tennant Creek",
      "Ouyen",
      "Moranbah",
      "Tumby Bay",
      "Renmark",
      "Winton",
      "Merredin",
      "Beaconsfield",
      "Kingaroy",
      "Wilcannia",
      "Bourke",
      "Richmond",
      "Longreach",
      "Cloncurry",
      "Mount Isa",
      "Camooweal",
      "Barcaldine",
      "Julia Creek",
      "Roma",
      "Carnarvon",
      "Wyndham",
      "Newman",
      "Meekatharra",
      "Leinster",
      "Wiluna",
      "Kalgoorlie-Boulder",
      "Karratha",
      "South Hedland",
      "Port Hedland",
      "Broome",
      "Derby",
      "Fitzroy Crossing",
      "Halls Creek",
      "Kununurra",
      "Lake Argyle",
      "Bidyadanga",
      "Yulara",
      "Coober Pedy"
    ]
  },
  {
    "country": "Austria",
    "cities": [
      "Vienna",
      "Graz",
      "Linz",
      "Salzburg",
      "Innsbruck",
      "Klagenfurt",
      "Villach",
      "Wels",
      "Sankt Polten",
      "Dornbirn",
      "Steyr",
      "Wiener Neustadt",
      "Feldkirch",
      "Bregenz",
      "Wolfsberg",
      "Baden bei Wien",
      "Leoben",
      "Krems an der Donau",
      "Traun",
      "Amstetten",
      "Lustenau",
      "Kapfenberg",
      "Modling",
      "Hallein",
      "Kufstein",
      "Traiskirchen",
      "Schwechat",
      "Braunau am Inn",
      "Stockerau",
      "Saalfelden am Steinernen Meer",
      "Ansfelden",
      "Tulln",
      "Hohenems",
      "Spittal an der Drau",
      "Ternitz",
      "Perchtoldsdorf",
      "Feldkirchen in Karnten",
      "Bludenz",
      "Bad Ischl",
      "Hall in Tirol",
      "Waidhofen",
      "Gmunden",
      "Marchtrenk",
      "Korneuburg",
      "Rankweil",
      "Vocklabruck",
      "Brunn am Gebirge",
      "Schwechat",
      "Ried im Innkreis",
      "Worgl",
      "Sankt Veit an der Glan",
      "Sankt Johann im Pongau",
      "Knittelfeld",
      "Zwettl Stift",
      "Koflach",
      "Gerasdorf bei Wien",
      "Neunkirchen",
      "Eisenstadt",
      "Gross-Enzersdorf",
      "Friedberg",
      "Sankt Andra",
      "Hard",
      "Mistelbach",
      "Murzzuschlag",
      "Zell am See",
      "Stockerau",
      "Sankt Valentin",
      "Hollabrunn",
      "Bruck an der Mur",
      "Enns",
      "Sankt Peter",
      "Gleisdorf",
      "Feldbach",
      "Klosterneuburg",
      "Voitsberg",
      "Wolfsberg",
      "Neu-Guntramsdorf",
      "Zwettl",
      "Ganserndorf",
      "Melk",
      "Bischofshofen",
      "Volkswagen",
      "Wolfern",
      "Altheim",
      "Ebreichsdorf",
      "Liezen",
      "Bad Voslau",
      "Schrems",
      "Perg",
      "Bischofshofen",
      "Weiz",
      "Ganserndorf",
      "Mank",
      "Schladming",
      "Trofaiach",
      "Stadtschlaining",
      "Perg",
      "Traismauer"
    ]
  },
  {
    "country": "Azerbaijan",
    "cities": [
      "Baku",
      "Nakhchivan",
      "Quba",
      "Qusar",
      "Sulutapa",
      "Sumqayit",
      "Xirdalan",
      "Zurges"
    ]
  },
  {
    "country": "Bahamas",
    "cities": [
      "Andros Town",
      "Dunmore Town",
      "Freeport",
      "Marsh Harbour",
      "Nassau",
      "Palmetto Point",
      "Spanish Wells"
    ]
  },
  {
    "country": "Bahrain",
    "cities": [
      "Al Budayyi`",
      "Al Hadd",
      "Al Hamalah",
      "Al Janabiyah",
      "Al Markh",
      "Al Muharraq",
      "Bani Jamrah",
      "Barbar",
      "Jurdab",
      "Madinat `Isa",
      "Madinat Hamad",
      "Manama",
      "Oil City",
      "Sanabis",
      "Sanad",
      "Sitrah",
      "Tubli"
    ]
  },
  {
    "country": "Bangladesh",
    "cities": [
      "Agrabad",
      "Bangla",
      "Barisal",
      "Bazar",
      "Chittagong",
      "Comilla",
      "Delta",
      "Dhaka",
      "Hossain",
      "Jessore",
      "Kabi",
      "Kazipur",
      "Mymensingh",
      "Narayanganj",
      "Rajshahi",
      "Tejgaon",
      "Uttara"
    ]
  },
  {
    "country": "Barbados",
    "cities": [
      "Atlantic Shores",
      "Bagatelle",
      "Bloomsbury",
      "Bridgetown",
      "Bruce Vale",
      "Cave Hill",
      "Clapham",
      "Hastings",
      "Holetown",
      "Husbands",
      "Jackmans",
      "Oistins",
      "Pine Housing Estate",
      "Porters",
      "Rendezvous",
      "Rockley",
      "The Garden",
      "Wanstead",
      "Warners",
      "Warrens",
      "White Hill",
      "Wildey",
      "Worthing"
    ]
  },
  {
    "country": "Belarus",
    "cities": [
      "Baranovichi",
      "Borisov",
      "Brest",
      "Dzyarzhynsk",
      "Horki",
      "Hrodna",
      "Lahoysk",
      "Lida",
      "Lyakhovichi",
      "Lyaskavichy",
      "Mazyr",
      "Minsk",
      "Mogilev",
      "Navapolatsk",
      "Orsha",
      "Pinsk",
      "Polatsk",
      "Radoshkovichi",
      "Shklow",
      "Vitebsk",
      "Zaslawye",
      "Zhodzina"
    ]
  },
  {
    "country": "Belgium",
    "cities": [
      "Brussels",
      "Antwerp",
      "Ghent",
      "Charleroi",
      "Liege",
      "Bruges",
      "Namur",
      "Leuven",
      "Mons",
      "Aalst",
      "Mechelen",
      "La Louviere",
      "Kortrijk",
      "Hasselt",
      "Ostend",
      "Sint-Niklaas",
      "Tournai",
      "Genk",
      "Seraing",
      "Roeselare",
      "Verviers",
      "Mouscron",
      "Beveren",
      "Dendermonde",
      "Beringen",
      "Turnhout",
      "Dilbeek",
      "Heist-op-den-Berg",
      "Sint-Truiden",
      "Lokeren",
      "Brasschaat",
      "Vilvoorde",
      "Herstal",
      "Maasmechelen",
      "Waregem",
      "Chatelet",
      "Ieper",
      "Ninove",
      "Geel",
      "Halle",
      "Hoboken",
      "Schoten",
      "Lier",
      "Ypres",
      "Grimbergen",
      "Knokke-Heist",
      "Wavre",
      "Tienen",
      "Bilzen",
      "Herentals",
      "Wetteren",
      "Evergem",
      "Heusden",
      "Louvain-la-Neuve",
      "Sint-Pieters-Leeuw",
      "Wavre",
      "Balen",
      "Rixensart",
      "Woluwe-Saint-Pierre",
      "Puurs",
      "Londerzeel",
      "Kontich",
      "Koksijde",
      "Bree",
      "Zwevegem",
      "Zottegem",
      "Ath",
      "Lessines",
      "Tongeren",
      "Soignies",
      "Ranst",
      "Kasterlee",
      "Herk-de-Stad",
      "Lanaken",
      "Pont-a-Celles",
      "Boussu",
      "Boussu",
      "Zonhoven",
      "Estaimpuis",
      "Tervuren",
      "Morlanwelz-Mariemont",
      "Harelbeke",
      "Hannut",
      "Oudenaarde",
      "Beersel",
      "Sint-Katelijne-Waver",
      "Tielt",
      "Beernem",
      "Duffel",
      "Edegem",
      "Zedelgem",
      "Buggenhout",
      "Kuurne",
      "Lede",
      "Wingene",
      "Lebbeke",
      "Riemst"
    ]
  },
  {
    "country": "Belize",
    "cities": [
      "Belize City",
      "Belmopan",
      "Benque Viejo del Carmen",
      "Freetown Sibun",
      "Ladyville",
      "San Ignacio",
      "San Pedro Town"
    ]
  },
  {
    "country": "Benin",
    "cities": [
      "Alibori",
      "Atakora",
      "Atlantique",
      "Borgou",
      "Collines",
      "Donga",
      "Kouffo",
      "Littoral",
      "Mono",
      "Oueme",
      "Plateau",
      "Zou"
    ]
  },
  {
    "country": "Bermuda",
    "cities": [
      "Devonshire",
      "Hamilton",
      "Hamilton",
      "Paget",
      "Pembroke",
      "Saint George",
      "Saint George's",
      "Sandys",
      "Smith's",
      "Southampton",
      "Warwick"
    ]
  },
  {
    "country": "Bhutan",
    "cities": [
      "Bumthang",
      "Chukha",
      "Dagana",
      "Gasa",
      "Haa",
      "Lhuntse",
      "Mongar",
      "Paro",
      "Pemagatshel",
      "Punakha",
      "Samdrup Jongkhar",
      "Samtse",
      "Sarpang",
      "Thimphu",
      "Trashigang",
      "Trashiyangste",
      "Trongsa",
      "Tsirang",
      "Wangdue Phodrang",
      "Zhemgang"
    ]
  },
  {
    "country": "Bolivia",
    "cities": [
      "Anillo",
      "Aroma",
      "Bermejo",
      "Caracasa",
      "Cobija",
      "Cochabamba",
      "Cotoca",
      "Cruz",
      "Guayaramerin",
      "La Paz",
      "Oruro",
      "Riberalta",
      "Santa Cruz",
      "Santa Cruz",
      "Sucre",
      "Tarija",
      "Trinidad"
    ]
  },
  {
    "country": "Bosnia and Herzegovina",
    "cities": [
      "Banja",
      "Banja Luka",
      "Bijeljina",
      "Bosanska Dubica",
      "Bosanska Krupa",
      "Brcko",
      "Breza",
      "Bugojno",
      "Cazin",
      "Core",
      "Doboj",
      "Donja Mahala",
      "Gracanica",
      "Ilidza",
      "Jajce",
      "Jelah",
      "Kiseljak",
      "Livno",
      "Ljubuski",
      "Lopare",
      "Lukavac",
      "Modrica",
      "Mostar",
      "Novi Travnik",
      "Odzak",
      "Prijedor",
      "Sanski Most",
      "Sarajevo",
      "Siroki Brijeg",
      "Srpska",
      "Stup",
      "Tesanj",
      "Travnik",
      "Tuzla",
      "Visoko",
      "Vitez",
      "Vogosca",
      "Zenica",
      "Zepce",
      "Zivinice"
    ]
  },
  {
    "country": "Botswana",
    "cities": [
      "Francistown",
      "Gaborone",
      "Orapa",
      "Serowe",
      "Village"
    ]
  },
  {
    "country": "Brazil",
    "cities": [
      "Sao Paulo",
      "Rio de Janeiro",
      "Brasilia",
      "Salvador",
      "Fortaleza",
      "Belo Horizonte",
      "Manaus",
      "Curitiba",
      "Recife",
      "Porto Alegre",
      "Goiânia",
      "Belém",
      "Guarulhos",
      "Campinas",
      "Nova Iguaçu",
      "Maceió",
      "São Luís",
      "Duque de Caxias",
      "São Gonçalo",
      "Natal",
      "Teresina",
      "Campo Grande",
      "Santo André",
      "Osasco",
      "João Pessoa",
      "Jaboatão dos Guararapes",
      "São José dos Campos",
      "Ribeirão Preto",
      "Contagem",
      "Uberlândia",
      "Sorocaba",
      "Aracaju",
      "Feira de Santana",
      "Cuiabá",
      "Joinville",
      "Juiz de Fora",
      "Londrina",
      "Aparecida de Goiânia",
      "Ananindeua",
      "Niterói",
      "São João de Meriti",
      "Belford Roxo",
      "Campos dos Goytacazes",
      "Carapicuíba",
      "Piracicaba",
      "Mauá",
      "Itaquaquecetuba",
      "São José do Rio Preto",
      "Caxias do Sul",
      "Cariacica",
      "Serra",
      "Diadema",
      "Mogi das Cruzes",
      "Betim",
      "Olinda",
      "Jundiaí",
      "Montes Claros",
      "São Vicente",
      "Pelotas",
      "Uberaba",
      "Rio Branco",
      "Cascavel",
      "Novo Hamburgo",
      "Vila Velha",
      "Anápolis",
      "Camaçari",
      "Marabá",
      "Cotia",
      "Macapá",
      "Ponta Grossa",
      "Vitória da Conquista",
      "Petrolina",
      "Rio Verde",
      "Limeira",
      "Paulista",
      "Marília",
      "Santa Maria",
      "Santarém",
      "Sumaré",
      "Volta Redonda",
      "Santa Luzia",
      "Gravataí",
      "Caruaru",
      "Luziânia",
      "Barueri",
      "Rondonópolis",
      "Taubaté",
      "Governador Valadares",
      "Crato",
      "Resende",
      "Igarassu",
      "Itabuna",
      "Jequié",
      "Araraquara",
      "Timon",
      "Cabo Frio"
    ]
  },
  {
    "country": "Brunei",
    "cities": [
      "Bandar Seri Begawan",
      "Bangar",
      "Kampong Jerudong",
      "Kuala Balai",
      "Kuala Belait",
      "Panaga",
      "Seria",
      "Tutong"
    ]
  },
  {
    "country": "Bulgaria",
    "cities": [
      "Sofia",
      "Plovdiv",
      "Varna",
      "Burgas",
      "Ruse",
      "Stara Zagora",
      "Pleven",
      "Sliven",
      "Dobrich",
      "Shumen",
      "Pernik",
      "Haskovo",
      "Yambol",
      "Pazardzhik",
      "Blagoevgrad",
      "Veliko Tarnovo",
      "Vratsa",
      "Gabrovo",
      "Asenovgrad",
      "Vidin",
      "Kazanluk",
      "Kyustendil",
      "Kardzhali",
      "Montana",
      "Dimitrovgrad",
      "Targovishte",
      "Lovech",
      "Silistra",
      "Petrich",
      "Samokov",
      "Lom",
      "Karlovo",
      "Dupnitsa",
      "Harmanli",
      "Rakovski",
      "Cherven Bryag",
      "Svishtov",
      "Gorna Oryahovitsa",
      "Nova Zagora",
      "Gotse Delchev",
      "Botevgrad",
      "Radomir",
      "Sevlievo",
      "Peshtera",
      "Svilengrad",
      "Berkovitsa",
      "Karnobat",
      "Panagyurishte",
      "Chirpan",
      "Radnevo",
      "Tryavna",
      "Provadiya",
      "Parvomay",
      "Knezha",
      "Nesebar",
      "Byala Slatina",
      "Razlog",
      "Pomorie",
      "Chepelare",
      "Sopot",
      "Kostinbrod",
      "Krumovgrad",
      "Simeonovgrad",
      "Lyaskovets",
      "Sredets",
      "Devnya",
      "Belene",
      "Elin Pelin",
      "Isperih",
      "Strazhitsa",
      "Rudozem",
      "Bratsigovo",
      "Zlatograd",
      "Balchik",
      "Kavarna",
      "Aitos",
      "Kotel",
      "Tsarevo",
      "Elena",
      "Kavarna",
      "Zlatitsa",
      "Batak",
      "Pavlikeni",
      "Kaspichan",
      "Topolovgrad",
      "Krichim",
      "Dryanovo",
      "Etropole",
      "Valchedram",
      "Kotel",
      "Septemvri",
      "Sveti Vlas"
    ]
  },
  {
    "country": "Burkina Faso",
    "cities": [
      "Bale",
      "Bam",
      "Banwa",
      "Bazega",
      "Bougouriba",
      "Boulgou",
      "Boulkiemde",
      "Comoe",
      "Ganzourgou",
      "Gnagna",
      "Gourma",
      "Houet",
      "Ioba",
      "Kadiogo",
      "Kenedougou",
      "Komondjari",
      "Kompienga",
      "Kossi",
      "Koulpelogo",
      "Kouritenga",
      "Kourweogo",
      "Leraba",
      "Loroum",
      "Mouhoun",
      "Namentenga",
      "Nahouri",
      "Nayala",
      "Noumbiel",
      "Oubritenga",
      "Oudalan",
      "Passore",
      "Poni",
      "Sanguie",
      "Sanmatenga",
      "Seno",
      "Sissili",
      "Soum",
      "Sourou",
      "Tapoa",
      "Tuy",
      "Yagha",
      "Yatenga",
      "Ziro",
      "Zondoma",
      "Zoundweogo"
    ]
  },
  {
    "country": "Burma",
    "cities": [
      "Ayeyarwady",
      "Bago",
      "Magway",
      "Mandalay",
      "Sagaing",
      "Tanintharyi",
      "Yangon",
      "Chin State",
      "Kachin State",
      "Kayin State",
      "Kayah State",
      "Mon State",
      "Rakhine State",
      "Shan State"
    ]
  },
  {
    "country": "Burundi",
    "cities": [
      "Bubanza",
      "Bujumbura Mairie",
      "Bujumbura Rural",
      "Bururi",
      "Cankuzo",
      "Cibitoke",
      "Gitega",
      "Karuzi",
      "Kayanza",
      "Kirundo",
      "Makamba",
      "Muramvya",
      "Muyinga",
      "Mwaro",
      "Ngozi",
      "Rutana",
      "Ruyigi"
    ]
  },
  {
    "country": "Cambodia",
    "cities": [
      "Moung Roessei",
      "Phnom Penh",
      "Phumi Boeng (1)",
      "Phumi Chhuk",
      "Phumi Preah Haoh",
      "Phumi Prei",
      "Phumi Prek Mrinh",
      "Phumi Siem Reab",
      "Phumi Thmei",
      "Phumi Thnal",
      "Phumi Vott Phnum",
      "Sihanoukville",
      "Tuol Kok",
      "Vott Kampong Svay"
    ]
  },
  {
    "country": "Cameroon",
    "cities": [
      "Bafia",
      "Bafoussam",
      "Bamenda",
      "Buea",
      "Douala",
      "Kribi",
      "Kumba",
      "Ringo",
      "Tibati"
    ]
  },
  {
    "country": "Canada",
    "cities": [
      "Toronto",
      "Montreal",
      "Vancouver",
      "Calgary",
      "Edmonton",
      "Ottawa",
      "Winnipeg",
      "Quebec City",
      "Hamilton",
      "Kitchener",
      "London",
      "Victoria",
      "Halifax",
      "Oshawa",
      "Windsor",
      "Saskatoon",
      "Regina",
      "St. John's",
      "Barrie",
      "Kelowna",
      "Sherbrooke",
      "Guelph",
      "Abbotsford",
      "Kingston",
      "Sudbury",
      "Saguenay",
      "Trois-Rivieres",
      "Thunder Bay",
      "Saint John",
      "Peterborough",
      "Red Deer",
      "Kamloops",
      "Moncton",
      "Nanaimo",
      "Belleville",
      "Sarnia",
      "Saint-Jean-sur-Richelieu",
      "Fredericton",
      "Prince George",
      "Drummondville",
      "New Westminster",
      "Saint-Hyacinthe",
      "North Bay",
      "Granby",
      "Moose Jaw",
      "Charlottetown",
      "Wood Buffalo",
      "Vernon",
      "Cornwall",
      "Courtenay",
      "Campbell River",
      "Fort McMurray",
      "North Vancouver",
      "Penticton",
      "Prince Albert",
      "Duncan",
      "Orillia",
      "Brooks",
      "New Glasgow",
      "Fort Saint John",
      "Miramichi",
      "Collingwood",
      "Quesnel",
      "Orangeville",
      "Yorkton",
      "Leamington",
      "Thompson",
      "Sylvan Lake",
      "Brandon",
      "Nelson",
      "Lloydminster",
      "Trail",
      "Huntsville",
      "Terrace",
      "Squamish",
      "Peace River",
      "Grande Prairie",
      "Pembroke",
      "Yellowknife",
      "Powell River",
      "Whitehorse",
      "Hinton",
      "Cobourg",
      "Wetaskiwin",
      "Camrose",
      "Williams Lake",
      "Chatham-Kent",
      "Cranbrook",
      "Port Alberni"
    ]
  },
  {
    "country": "Cayman Islands",
    "cities": [
      "Bodden Town",
      "Coral Gables",
      "George Town",
      "Newlands",
      "Savannah",
      "Spot Bay",
      "West Bay"
    ]
  },
  {
    "country": "Central African Republic",
    "cities": [
      "Bamingui-Bangoran",
      "Bangui",
      "Basse-Kotto",
      "Haute-Kotto",
      "Haut-Mbomou",
      "Kemo",
      "Lobaye",
      "Mambere-Kadei",
      "Mbomou",
      "Nana-Grebizi",
      "Nana-Mambere",
      "Ombella-Mpoko",
      "Ouaka",
      "Ouham",
      "Ouham-Pende",
      "Sangha-Mbaere",
      "Vakaga"
    ]
  },
  {
    "country": "Chad",
    "cities": [
      "Batha",
      "Biltine",
      "Borkou-Ennedi-Tibesti",
      "Chari-Baguirmi",
      "Guéra",
      "Kanem",
      "Lac",
      "Logone Occidental",
      "Logone Oriental",
      "Mayo-Kebbi",
      "Moyen-Chari",
      "Ouaddaï",
      "Salamat",
      "Tandjile"
    ]
  },
  {
    "country": "Chile",
    "cities": [
      "Algarrobo",
      "Angol",
      "Antofagasta",
      "Apoquindo",
      "Arauco",
      "Arica",
      "Buin",
      "Bulnes",
      "Calama",
      "Caldera",
      "Castro",
      "Catemu",
      "Centro",
      "Cerrillos de Tamaya",
      "Chicureo Abajo",
      "Chiguayante",
      "Chillan",
      "Chillan Viejo",
      "Chimbarongo",
      "Chonchi",
      "Chuquicamata",
      "Coihaique",
      "Colina",
      "Collipulli",
      "Concon",
      "Constitucion",
      "Coquimbo",
      "Coronel",
      "Cunco",
      "Curacavi",
      "Dalcahue",
      "El Arco",
      "El Bosque",
      "El Monte",
      "El Quisco",
      "El Salto",
      "El Salvador",
      "El Valle",
      "Fresia",
      "Galvarino",
      "Graneros",
      "Hanga Roa",
      "Iquique",
      "La Calera",
      "La Cisterna",
      "La Granja",
      "La Laja",
      "La Ligua",
      "La Reina",
      "La Serena",
      "La Union",
      "Lampa",
      "Las Condes",
      "Lautaro",
      "Llanquihue",
      "Lo Barnechea",
      "Lolol",
      "Los Andes",
      "Los Angeles",
      "Machali",
      "Macul",
      "Maipu",
      "Maquina Atacama",
      "Maule",
      "Mejillones",
      "Melipilla",
      "Miraflores",
      "Molina",
      "Monte Aguila",
      "Nacimiento",
      "Nogales",
      "Nunoa",
      "Olmue",
      "Osorno",
      "Ovalle",
      "Paillaco",
      "Penablanca",
      "Penaflor",
      "Port Montt",
      "Providencia",
      "Puchuncavi",
      "Pucon",
      "Puente Alto",
      "Puerto Aisen",
      "Puerto Natales",
      "Puerto Varas",
      "Punta Arenas",
      "Puren",
      "Purranque",
      "Quellon",
      "Quemchi",
      "Quilicura",
      "Quillota",
      "Rancagua",
      "Recoleta",
      "Renaca",
      "Renca",
      "Rengo",
      "Rinconada",
      "Rio Bueno",
      "San Antonio",
      "San Bernardo",
      "San Carlos",
      "San Felipe",
      "San Fernando",
      "San Javier",
      "San Martin",
      "San Pedro",
      "Santa Barbara",
      "Santiago",
      "Talagante",
      "Talca",
      "Talcahuano",
      "Tarapaca",
      "Temuco",
      "Tome",
      "Valdivia",
      "Vallenar",
      "Villa Alemana",
      "Villarrica",
      "Vitacura"
    ]
  },
  {
    "country": "China",
    "cities": [
      "Shanghai",
      "Beijing",
      "Guangzhou",
      "Shenzhen",
      "Tianjin",
      "Chongqing",
      "Wuhan",
      "Chengdu",
      "Nanjing",
      "Xi'an",
      "Hangzhou",
      "Shenyang",
      "Harbin",
      "Suzhou",
      "Wenzhou",
      "Dalian",
      "Xiamen",
      "Changsha",
      "Zhengzhou",
      "Qingdao",
      "Jinan",
      "Changchun",
      "Ningbo",
      "Taiyuan",
      "Nanning",
      "Fuzhou",
      "Hefei",
      "Kunming",
      "Shijiazhuang",
      "Wuxi",
      "Urumqi",
      "Guiyang",
      "Lanzhou",
      "Nanchang",
      "Haikou",
      "Changzhou",
      "Hohhot",
      "Shantou",
      "Nantong",
      "Zhongshan",
      "Jilin",
      "Tangshan",
      "Nanchong",
      "Zibo",
      "Luoyang",
      "Yangzhou",
      "Baotou",
      "Anshan",
      "Foshan",
      "Changzhou",
      "Nantong",
      "Zibo",
      "Luoyang",
      "Yangzhou",
      "Baotou",
      "Anshan",
      "Foshan",
      "Handan",
      "Linyi",
      "Huainan",
      "Huzhou",
      "Taizhou",
      "Nanning",
      "Liuzhou",
      "Xuzhou",
      "Guiyang",
      "Shaoxing",
      "Changzhi",
      "Jincheng",
      "Xiangyang",
      "Zhuhai",
      "Zhanjiang",
      "Taizhou",
      "Zhangjiagang",
      "Liaocheng",
      "Zhenjiang",
      "Anyang",
      "Bengbu",
      "Yantai",
      "Jining",
      "Weifang",
      "Zaozhuang",
      "Taian",
      "Lianyungang",
      "Huangshi",
      "Xinyang",
      "Yinchuan",
      "Changde",
      "Dengzhou",
      "Jingmen",
      "Huaibei",
      "Zhuzhou",
      "Lu'an",
      "Dandong",
      "Quanzhou",
      "Xining",
      "Xingtai",
      "Chifeng",
      "Tonghua",
      "Chuzhou",
      "Leshan",
      "Yinchuan",
      "Nangong",
      "Yuncheng",
      "Zaozhuang",
      "Yangzhou",
      "Xiangtan",
      "Wuhu",
      "Jinzhou",
      "Lijiang",
      "Shaoyang",
      "Tongliao",
      "Shiyan",
      "Panzhihua",
      "Tongling"
    ]
  },
  {
    "country": "Colombia",
    "cities": [
      "Acacias",
      "Acevedo",
      "Aguachica",
      "Antioquia",
      "Arauca",
      "Armenia",
      "Atlantico",
      "Barrancabermeja",
      "Barranquilla",
      "Bello",
      "Bermudez",
      "Boyaca",
      "Bucaramanga",
      "Buenaventura",
      "Buga",
      "Cajica",
      "Calamar",
      "Caldas",
      "Candelaria",
      "Cartagena",
      "Cartago",
      "Cauca",
      "Chia",
      "Chiquinquira",
      "Chocho",
      "Columbia",
      "Corozal",
      "Cota",
      "Cumaribo",
      "Cundinamarca",
      "Dosquebradas",
      "Duitama",
      "El Colegio",
      "Engativa",
      "Envigado",
      "Espinal",
      "Florencia",
      "Floridablanca",
      "Fonseca",
      "Fundacion",
      "Fusagasuga",
      "Galapa",
      "Ginebra",
      "Giron",
      "Guarne",
      "Ibague",
      "Inirida",
      "Ipiales",
      "Itagui",
      "La Ceja",
      "La Estrella",
      "La Mesa",
      "La Union",
      "Los Patios",
      "Madrid",
      "Magdalena",
      "Manizales",
      "Miami",
      "Mitu",
      "Montenegro",
      "Mosquera",
      "Municipio de Copacabana",
      "Neiva",
      "Obando",
      "Palmira",
      "Pamplona",
      "Pasto",
      "Pereira",
      "Piedecuesta",
      "Pitalito",
      "Planadas",
      "Popayan",
      "Puerta Roja",
      "Puerto Tejada",
      "Purificacion",
      "Riohacha",
      "Rionegro",
      "Risaralda",
      "Sabana de Torres",
      "Sabaneta",
      "Sachica",
      "San Clemente",
      "San Diego",
      "San Gil",
      "San Martin",
      "Santa Marta",
      "Santa Rosa de Cabal",
      "Santa Rosa del Sur",
      "Santiago de Cali",
      "Silvania",
      "Sincelejo",
      "Soacha",
      "Sogamoso",
      "Soledad",
      "Sopo",
      "Tarapaca",
      "Tauramena",
      "Tenjo",
      "Tocancipa",
      "Tunja",
      "Turbaco",
      "Ubaque",
      "Urumita",
      "Valledupar",
      "Velez",
      "Villa del Rosario",
      "Villamaria",
      "Villavicencio",
      "Yopal",
      "Yotoco",
      "Yumbo"
    ]
  },
  {
    "country": "Comoros",
    "cities": [
      "Grande Comore (Njazidja)",
      "Anjouan (Nzwani)",
      "Moheli (Mwali)"
    ]
  },
  {
    "country": "Congo",
    "cities": [
      "Banana",
      "Goma",
      "Kinshasa",
      "Likasi",
      "Lubumbashi"
    ]
  },
  {
    "country": "Costa Rica",
    "cities": [
      "Alajuela",
      "Alajuelita",
      "Alfaro",
      "Aserri",
      "Atenas",
      "Barva",
      "Cartago",
      "Colon",
      "Corazon de Jesus",
      "Coronado",
      "Coyol",
      "Curridabat",
      "Desamparados",
      "Escazu",
      "Esparza",
      "Filadelfia",
      "Grecia",
      "Guachipelin",
      "Guacima",
      "Guadalupe",
      "Guapiles",
      "Heredia",
      "Isla de Tibas",
      "Jaco",
      "La Garita",
      "Liberia",
      "Llorente",
      "Llorente",
      "Mercedes",
      "Mexico",
      "Miramar",
      "Moravia",
      "Palmares",
      "Paraiso",
      "Pavas",
      "Perez",
      "Poas",
      "Puerto Jimenez",
      "Puntarenas",
      "Quepos",
      "Quesada",
      "Sabanilla",
      "San Diego",
      "San Francisco",
      "San Francisco",
      "San Isidro",
      "San Joaquin",
      "San Jose de la Montana",
      "San Josecito",
      "San Juan",
      "San Marcos",
      "San Pablo",
      "San Pedro",
      "San Rafael",
      "San Rafael",
      "San Ramon",
      "Santa Ana",
      "Santa Cecilia",
      "Santa Cruz",
      "Santo Domingo",
      "Sarchi",
      "Siquirres",
      "Tres Rios",
      "Turrialba",
      "Turrucares",
      "Zapote",
      "Zarcero"
    ]
  },
  {
    "country": "Croatia",
    "cities": [
      "Antunovac",
      "Baska",
      "Baska Voda",
      "Bedekovcina",
      "Bestovje",
      "Betina",
      "Bibinje",
      "Bizovac",
      "Bjelovar",
      "Bracevci",
      "Brdovec",
      "Bregana",
      "Brela",
      "Brsadin",
      "Buje",
      "Cabuna",
      "Cavtat",
      "Cepin",
      "Cestica",
      "Core",
      "Crikvenica",
      "Dalj",
      "Darda",
      "Delnice",
      "Desinic",
      "Donja Pusca",
      "Donja Zelina",
      "Donji Kraljevec",
      "Dubrovnik",
      "Dugo Selo",
      "Fazana",
      "Fuzine",
      "Galgovo",
      "Glina",
      "Gornja Lomnica",
      "Gornji Kriz",
      "Gornji Stupnik",
      "Hreljin",
      "Icici",
      "Ivanec",
      "Ivanic-Grad",
      "Karlovac",
      "Karojba",
      "Kastel Gomilica",
      "Kastel Luksic",
      "Kastel Sucurac",
      "Koprivnica",
      "Kostrena",
      "Kraljevec na Sutli",
      "Krapina",
      "Kriz",
      "Krk",
      "Krsan",
      "Kutjevo",
      "Labin",
      "Lapad",
      "Lekenik",
      "Lepoglava",
      "Lovran",
      "Lukavec",
      "Lupoglav",
      "Lupoglav",
      "Makarska",
      "Matulji",
      "Medulin",
      "Mlini",
      "Mursko Sredisce",
      "Nedelisce",
      "Nova Gradiska",
      "Novi Marof",
      "Novi Vinodolski",
      "Novo Cice",
      "Nustar",
      "Okrug Gornji",
      "Opatija",
      "Orahovica",
      "Orebic",
      "Orle",
      "Oroslavje",
      "Osijek",
      "Pakrac",
      "Pazin",
      "Petrcane",
      "Petrinja",
      "Piskorevci",
      "Podastrana",
      "Podgajci Posavski",
      "Popovaca",
      "Postira",
      "Pregrada",
      "Prelog",
      "Primosten",
      "Privlaka",
      "Pula",
      "Rab",
      "Rakitje",
      "Rijeka",
      "Rijeka",
      "Rovinj",
      "Samobor",
      "Sapjane",
      "Senkovec",
      "Sesvete",
      "Sesvetski Kraljevec",
      "Sice",
      "Silas",
      "Simuni",
      "Sinj",
      "Sisak",
      "Slatina",
      "Slavonski Brod",
      "Sokolovac",
      "Solin",
      "Split",
      "Splitska",
      "Strmec",
      "Strmec Samoborski",
      "Sveti Ivan Zelina",
      "Tar",
      "Torcec",
      "Trogir",
      "Tuzno",
      "Umag",
      "Varazdinske Toplice",
      "Vardarac",
      "Velika Gorica",
      "Vetovo",
      "Vinica",
      "Vinkovci",
      "Virje",
      "Virovitica",
      "Viskovci",
      "Vojnic",
      "Vrata",
      "Vratisinec",
      "Vrbnik",
      "Vrbovec",
      "Vukovar",
      "Zabok",
      "Zadar",
      "Zagreb",
      "Zapresic",
      "Zlatar"
    ]
  },
  {
    "country": "Cuba",
    "cities": [
      "Bayamo",
      "Cienfuegos",
      "Habana",
      "Havana",
      "La Habana",
      "Las Tunas",
      "Matanzas",
      "Santiago de Cuba",
      "Varadero",
      "Villa"
    ]
  },
  {
    "country": "Cyprus",
    "cities": [
      "Agia Anna",
      "Aradippou",
      "Ayia Marina",
      "Chlorakas",
      "Deryneia",
      "Famagusta",
      "Geroskipou",
      "Kato Lakatamia",
      "Kato Polemidia",
      "Kiti",
      "Kyrenia",
      "Larnaca",
      "Laxia",
      "Limassol",
      "Livadia",
      "Morfou",
      "Mouttagiaka",
      "Nicosia",
      "Paphos",
      "Paralimni",
      "Parekklisha",
      "Prodromi",
      "Sotira",
      "Strovolos",
      "Tera"
    ]
  },
  {
    "country": "Czech Republic",
    "cities": [
      "Adamov",
      "Albrechtice",
      "Arnoltice",
      "As",
      "Babice",
      "Banov",
      "Baska",
      "Batelov",
      "Bavory",
      "Bechyne",
      "Bela pod Bezdezem",
      "Belotin",
      "Benatky nad Jizerou",
      "Benesov",
      "Benesovice",
      "Bernartice",
      "Bernartice nad Odrou",
      "Beroun",
      "Bezdekov",
      "Bilina",
      "Bilina",
      "Bilovice",
      "Bilovice nad Svitavou",
      "Bitovany",
      "Blansko",
      "Blatna",
      "Blizevedly",
      "Blovice",
      "Blucina",
      "Bludov",
      "Bludovice",
      "Bochov",
      "Bohumin",
      "Bohunovice",
      "Bojanov",
      "Bojkovice",
      "Bolatice",
      "Bolebor",
      "Bor",
      "Boretice",
      "Borohradek",
      "Borotin",
      "Borovany",
      "Borsice",
      "Bosin",
      "Boskovice",
      "Bradlec",
      "Brandys nad Labem-Stara Boleslav",
      "Branky",
      "Branov",
      "Bratrikov",
      "Brezi",
      "Brezno",
      "Prague",
      "Brno",
      "Ostrava",
      "Plzen",
      "Liberec",
      "Olomouc",
      "Usti nad Labem",
      "Hradec Kralove",
      "Ceske Budejovice",
      "Pardubice",
      "Havirov",
      "Zlin",
      "Kladno",
      "Most",
      "Opava",
      "Frydek-Mistek",
      "Karvina",
      "Jihlava",
      "Teplice",
      "Decin",
      "Karlovy Vary",
      "Chomutov",
      "Jablonec nad Nisou",
      "Mlada Boleslav",
      "Prostejov",
      "Prerov",
      "Jindrichuv Hradec",
      "Trebic",
      "Trinec",
      "Cheb",
      "Znojmo",
      "Pisek",
      "Orlova",
      "Sumperk",
      "Vsetin",
      "Kromeriz",
      "Valasske Mezirici",
      "Hodonin",
      "Ceska Lipa",
      "Uherske Hradiste",
      "Tabor",
      "Zatec",
      "Trutnov",
      "Pisek",
      "Koprivnice",
      "Litvinov",
      "Havirov",
      "Litomerice",
      "Uhersky Brod",
      "Cesky Tesin",
      "Hranice",
      "Sokolov",
      "Kadan",
      "Pribram",
      "Jirkov",
      "Benesov",
      "Chrudim",
      "Havlickuv Brod",
      "Klatovy",
      "Blansko",
      "Kolin",
      "Trhove Sviny",
      "Rakovnik",
      "Neratovice",
      "Melnik",
      "Bilina",
      "Kralupy nad Vltavou",
      "Zdar nad Sazavou",
      "Otrokovice",
      "Pardubicky",
      "Roznov pod Radhostem",
      "Letnany",
      "Kutna Hora",
      "Stochov",
      "Rychnov nad Kneznou",
      "Koprivnice",
      "Louny",
      "Tisnov",
      "Turnov",
      "Jindrichuv Hradec",
      "Branik",
      "Letohrad",
      "Steti",
      "Zliv",
      "Otrokovice",
      "Zamberk",
      "Roztoky",
      "Beroun",
      "Nymburk",
      "Cesky Krumlov",
      "Krnov",
      "Usti nad Orlici",
      "Mnisek pod Brdy",
      "Dobris",
      "Hodonin",
      "Cesky Brod",
      "Kadan",
      "Pisek",
      "Usti nad Labem",
      "Chodov",
      "Rakovnik",
      "Benesov",
      "Ostrov",
      "Svitavy",
      "Svitavy",
      "Mlada Boleslav",
      "Ceska Trebova",
      "Kadan",
      "Roztoky",
      "Vyskov",
      "Kadan",
      "Tachov",
      "Sokolov",
      "Otrokovice",
      "Mlada Boleslav",
      "Varnsdorf",
      "Kutna Hora",
      "Kraslice",
    ]
  },
  {
    "country": "Denmark",
    "cities": [
      "Aabenraa",
      "Aabybro",
      "Aalborg",
      "Aarhus",
      "Aars",
      "Abyhoj",
      "Agedrup",
      "Agerbaek",
      "Agerskov",
      "Akirkeby",
      "Albaek",
      "Albertslund",
      "Ale",
      "Alestrup",
      "Aller",
      "Allerod",
      "Allingabro",
      "Allinge",
      "Almind",
      "Alsgarde",
      "Alslev",
      "Alslev",
      "Anholt",
      "Ans",
      "Ansager",
      "Arden",
      "Arre",
      "Arslev",
      "Arslev",
      "Arup",
      "Asa",
      "Askeby",
      "Asnaes",
      "Asperup",
      "Assens",
      "Astrup",
      "Augustenborg",
      "Auning",
      "Avlum",
      "Baekke",
      "Baekmarksbro",
      "Baelum",
      "Bagsvaerd",
      "Balle",
      "Ballerup",
      "Bandholm",
      "Baring",
      "Barrit",
      "Beder",
      "Bedsted",
      "Bevtoft",
      "Billund",
      "Bjaert",
      "Bjaeverskov",
      "Bjerringbro",
      "Blavand",
      "Blokhus",
      "Blommenslyst",
      "Boeslunde",
      "Bogense",
      "Bogo By",
      "Bolderslev",
      "Bording Stationsby",
      "Borkop",
      "Borre",
      "Borum",
      "Borup",
      "Bovlingbjerg",
      "Bovrup",
      "Brabrand",
      "Braedstrup",
      "Bramming",
      "Brande",
      "Branderup",
      "Bredballe",
      "Bredebro",
      "Bredsten",
      "Brejning",
      "Brenderup",
      "Broager",
      "Brobyvaerk",
      "Brondby Strand",
      "Bronshoj",
      "Brorup",
      "Brovst",
      "Bruunshab",
      "Bryrup",
      "Bylderup-Bov",
      "Charlottenlund",
      "Christiansfeld",
      "Copenhagen",
      "Dalmose",
      "Dannemare",
      "Daugard",
      "Dianalund",
      "Dong",
      "Dronninglund",
      "Dronningmolle",
      "Dybvad",
      "Ebberup",
      "Ebeltoft",
      "Ega",
      "Egernsund",
      "Egtved",
      "Ejby",
      "Ejby",
      "Ejstrup",
      "Elsinore",
      "Engesvang",
      "Errindlev",
      "Erslev",
      "Esbjerg",
      "Eskebjerg",
      "Eskilstrup",
      "Espergaerde",
      "Faaborg",
      "Fakse",
      "Farevejle",
      "Farum",
      "Farup",
      "Farvang",
      "Fensmark",
      "Ferritslev",
      "Ferslev",
      "Fjenneslev",
      "Fjerritslev",
      "Flauenskjold",
      "Flemming",
      "Follenslev",
      "Fovling",
      "Fredensborg",
      "Fredericia",
      "Frederiksberg",
      "Frederiksberg",
      "Frederiksdal",
      "Frederikshavn",
      "Frederikssund",
      "Frorup",
      "Frostrup",
      "Fuglebjerg",
      "Gadbjerg",
      "Gadstrup",
      "Galten",
      "Gammel Svebolle",
      "Gandrup",
      "Ganlose",
      "Gedved",
      "Gelsted",
      "Gentofte",
      "Gesten",
      "Gilleleje",
      "Gislev",
      "Gislinge",
      "Gistrup",
      "Give",
      "Gjerlev",
      "Gjern",
      "Gjol",
      "Gladsaxe",
      "Glamsbjerg",
      "Glejbjerg",
      "Glesborg",
      "Glostrup",
      "Glumso",
      "Glyngore",
      "Gording",
      "Gorlose",
      "Graested",
      "Gram",
      "Gredstedbro",
      "Greve",
      "Greve Strand",
      "Grevinge",
      "Grindsted",
      "Gudbjerg",
      "Gudhjem",
      "Gudme",
      "Guldborg",
      "Haderslev",
      "Haderup",
      "Hadsten",
      "Hadsund",
      "Hals",
      "Hammel",
      "Hammershoj",
      "Hampen",
      "Hanstholm",
      "Harboore",
      "Harby",
      "Harlev",
      "Harlev",
      "Harndrup",
      "Harpelunde",
      "Hasle",
      "Haslev",
      "Hasselager",
      "Havdrup",
      "Havndal",
      "Hedehusene",
      "Hedensted",
      "Hejls",
      "Hejnsvig",
      "Hellebaek",
      "Hellerup",
      "Helsinge",
      "Hemmet",
      "Henne",
      "Henne Strand",
      "Herfolge",
      "Herlev",
      "Herlufmagle",
      "Herning",
      "Hesselager",
      "Himmelev",
      "Hinnerup",
      "Hirtshals",
      "Hjallerup",
      "Hjerm",
      "Hjortshoj",
      "Hobro",
      "Hodsager",
      "Hojbjerg",
      "Hojby",
      "Hoje Tastrup",
      "Hojslev",
      "Holbaek",
      "Holeby",
      "Holme",
      "Holme-Olstrup",
      "Holstebro",
      "Holsted",
      "Holte",
      "Hoptrup",
      "Horbelev",
      "Hornbaek",
      "Horning",
      "Hornslet",
      "Hornsyld",
      "Horsens",
      "Horslunde",
      "Horve",
      "Hosterkob",
      "Hou",
      "Hovedgard",
      "Humble",
      "Humlebaek",
      "Hundested",
      "Hundslund",
      "Hurup",
      "Hvide Sande",
      "Hvidovre",
      "Hyllinge",
      "Idestrup",
      "Ikast",
      "Janderup",
      "Jelling",
      "Jerslev",
      "Jerslev",
      "Jerup",
      "Jordrup",
      "Jorlunde",
      "Juelsminde",
      "Jyderup",
      "Jyllinge",
      "Jystrup",
      "Kaldred",
      "Kalundborg",
      "Kalvehave",
      "Karise",
      "Karlslunde",
      "Karlstrup",
      "Karrebaeksminde",
      "Karup",
      "Kastrup",
      "Kastrup",
      "Kejlstrup",
      "Kerteminde",
      "Kettinge",
      "Kibaek",
      "Kirke Eskilstrup",
      "Kirke Hvalso",
      "Kirke Saby",
      "Kirke-Hyllinge",
      "Kjargaard",
      "Kjellerup",
      "Klampenborg",
      "Klarup",
      "Klemensker",
      "Klippinge",
      "Klokkerholm",
      "Klovborg",
      "Knebel",
      "Kokkedal",
      "Koldby",
      "Kolding",
      "Kolind",
      "Kolsen",
      "Kongens Lyngby",
      "Kongerslev",
      "Krusa",
      "Kvaerndrup",
      "Kvistgard",
      "Kvols",
      "Laeso",
      "Lambjerg",
      "Langa",
      "Langeskov",
      "Lasby",
      "Laurbjerg",
      "Ledoje",
      "Lejre",
      "Lem",
      "Lemming",
      "Lemvig",
      "Lille Skensved",
      "Liseleje",
      "Logstrup",
      "Lokken",
      "Losning",
      "Lundby Stationsby",
      "Lunderskov",
      "Lyngby",
      "Lynge",
      "Lystrup",
      "Malling",
      "Malov",
      "Mariager",
      "Maribo",
      "Marslet",
      "Marslev",
      "Marstal",
      "Martofte",
      "Melby",
      "Mern",
      "Middelfart",
      "Millinge",
      "Moldrup",
      "Morke",
      "Morkov",
      "Morud",
      "Munke Bjergby",
      "Munkebo",
      "Naerum",
      "Naesby",
      "Naestved",
      "Nakskov",
      "Nexo",
      "Nibe",
      "Nim",
      "Nimtofte",
      "Niva",
      "No",
      "Nodebo",
      "Norager",
      "Nordborg",
      "Nordby",
      "Nordenskov",
      "Norre Aby",
      "Norre Asmindrup",
      "Norre Bindslev",
      "Norre Nebel",
      "Norre Snede",
      "Norreballe",
      "Norresundby",
      "Nyborg",
      "Nygard",
      "Nykobing Falster",
      "Nykobing Mors",
      "Nykobing Sjaelland",
      "Nysted",
      "Odder",
      "Oddesund Syd",
      "Odense",
      "Odsted",
      "Oksbol",
      "Olsted",
      "Olstykke",
      "Omme",
      "Orbaek",
      "Ornhoj",
      "Orsted",
      "Orum",
      "Osby",
      "Ostbirk",
      "Oster Assels",
      "Ostermarie",
      "Ostervra",
      "Otterup",
      "Oure",
      "Padborg",
      "Pandrup",
      "Ramlose",
      "Randbol",
      "Randers",
      "Ranum",
      "Rebaek",
      "Refsvindinge",
      "Regstrup",
      "Ribe",
      "Ringe",
      "Ringsted",
      "Risskov",
      "Rodding",
      "Rodkaersbro",
      "Rodvig",
      "Ronde",
      "Roskilde",
      "Roslev",
      "Rude",
      "Ruds-Vedby",
      "Rungsted",
      "Ry",
      "Rynkeby",
      "Ryomgard",
      "Ryslinge",
      "Sabro",
      "Saeby",
      "Salten",
      "Saltum",
      "Sandager",
      "Sanderum",
      "Sandved",
      "Sherrebek",
      "Silkeborg",
      "Sindal",
      "Sjolund",
      "Skaelskor",
      "Skaering",
      "Skagen",
      "Skals",
      "Skamby",
      "Skanderborg",
      "Skarup",
      "Skibby",
      "Skive",
      "Skjern",
      "Skodsborg",
      "Skodstrup",
      "Skovlunde",
      "Slagelse",
      "Slangerup",
      "Slet",
      "Snedsted",
      "Snekkersten",
      "Snertinge",
      "Soborg",
      "Soby",
      "Soesmarke",
      "Solbjerg",
      "Sollested",
      "Solrod",
      "Sommersted",
      "Sonder Omme",
      "Sonder Stenderup",
      "Sonderso",
      "Soro",
      "Sorring",
      "Sorvad",
      "Spentrup",
      "Spjald",
      "Spottrup",
      "Stakroge",
      "Stege",
      "Stenderup",
      "Stenlille",
      "Stenlose",
      "Stenstrup",
      "Stensved",
      "Stoholm",
      "Stokkemarke",
      "Store Fuglede",
      "Store Heddinge",
      "Store Merlose",
      "Storvorde",
      "Stouby",
      "Stovring",
      "Strandby",
      "Strib",
      "Stroby",
      "Struer",
      "Suldrup",
      "Sulsted",
      "Sundby",
      "Sunds",
      "Svaneke",
      "Svanninge",
      "Svendborg",
      "Svenstrup",
      "Svinninge",
      "Taastrup",
      "Tagerup",
      "Tappernoje",
      "Tarbaek",
      "Tarm",
      "Tars",
      "Tejn",
      "Terndrup",
      "Them",
      "Thisted",
      "Thorso",
      "Tikob",
      "Tilst",
      "Tim",
      "Tinglev",
      "Tistrup",
      "Tisvildeleje",
      "Tjaereborg",
      "Tjebberup",
      "Toftlund",
      "Tollose",
      "Tommerup",
      "Toreby",
      "Tornby",
      "Torrig",
      "Torring",
      "Torring",
      "Tranbjerg",
      "Tranebjerg",
      "Tranekaer",
      "Trelde",
      "Trige",
      "TRUE",
      "Trustrup",
      "Tullebolle",
      "Tune",
      "Tureby",
      "Tylstrup",
      "Ugerlose",
      "Uldum",
      "Ulfborg",
      "Ullerslev",
      "Ulstrup",
      "Vadum",
      "Vaeggerlose",
      "Vaerlose",
      "Vamdrup",
      "Vandel",
      "Vantinge",
      "Varde",
      "Vasby",
      "Vebbestrup",
      "Vedbaek",
      "Vederso",
      "Veflinge",
      "Vejby",
      "Vejen",
      "Vejle",
      "Vejstrup",
      "Vekso",
      "Vemb",
      "Vemmelev",
      "Verninge",
      "Vestbjerg",
      "Vester Hassing",
      "Vester-Skerninge",
      "Vesterborg",
      "Vestervig",
      "Viborg",
      "Viby",
      "Viby",
      "Vig",
      "Vildbjerg",
      "Vils",
      "Vinderup",
      "Vinkel",
      "Vipperod",
      "Virring",
      "Virum",
      "Vissenbjerg",
      "Viuf",
      "Vivild",
      "Vodskov",
      "Vojens",
      "Vorbasse",
      "Vordingborg",
      "Vra"
    ]
  },
  {
    "country": "Djibouti",
    "cities": [
      "Ali Sabih",
      "Dikhil",
      "Djibouti",
      "Obock",
      "Tadjoura"
    ]
  },
  {
    "country": "Dominica",
    "cities": [
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Joseph",
      "Saint Luke",
      "Saint Mark",
      "Saint Patrick",
      "Saint Paul",
      "Saint Peter"
    ]
  },
  {
    "country": "Dominican Republic",
    "cities": [
      "Arenazo",
      "Bavaro",
      "Boca Chica",
      "Cabarete",
      "Cotui",
      "Dominica",
      "Guaricano",
      "Hato Mayor del Rey",
      "Jimani",
      "La Romana",
      "Los Alcarrizos",
      "Los Prados",
      "Moca",
      "Pedernales",
      "Puerto Plata",
      "Punta Cana",
      "Sabaneta",
      "San Cristobal",
      "San Fernando de Monte Cristi",
      "San Jose de Ocoa",
      "Santa Cruz de Barahona",
      "Santiago de los Caballeros",
      "Santo Domingo"
    ]
  },
  {
    "country": "East Timor",
    "cities": [
      "Aileu",
      "Ainaro",
      "Baucau",
      "Bobonaro",
      "Cova-Lima",
      "Dili",
      "Ermera",
      "Lautem",
      "Liquica",
      "Manatuto",
      "Manufahi",
      "Oecussi",
      "Viqueque"
    ]
  },
  {
    "country": "Ecuador",
    "cities": [
      "Ambato",
      "Atacames",
      "Atuntaqui",
      "Bahia de Caraquez",
      "Banos",
      "Calderon",
      "Cayambe",
      "Cuenca",
      "Daule",
      "El Carmen",
      "El Naranjal",
      "Esmeraldas",
      "Florida",
      "General Leonidas Plaza Gutierrez",
      "Guabo",
      "Gualaceo",
      "Guamote",
      "Guayaquil",
      "Guayas",
      "Guayllabamba",
      "Hacienda Bolivia",
      "Hacienda Duran",
      "Hacienda Ibarra",
      "Hacienda Santa Catalina",
      "Huaquillas",
      "Ibarra",
      "Jipijapa",
      "La Esperanza",
      "La Libertad",
      "La Troncal",
      "Latacunga",
      "Loja",
      "Loreto",
      "Macara",
      "Macas",
      "Machachi",
      "Machala",
      "Manta",
      "Milagro",
      "Montecristi",
      "Nabon",
      "Olmedo",
      "Otavalo",
      "Paccha",
      "Pasaje",
      "Pelileo",
      "Pichincha",
      "Playas",
      "Portoviejo",
      "Puerto Francisco de Orellana",
      "Puyo",
      "Quevedo",
      "Quito",
      "Riobamba",
      "Salinas",
      "Samborondon",
      "San Miguel",
      "San Rafael",
      "Santa Cruz",
      "Santa Elena",
      "Santo Domingo de los Colorados",
      "Sucre",
      "Tababela",
      "Tarapoa",
      "Tena",
      "Vinces",
      "Zamora",
      "Zaruma",
      "Zumba"
    ]
  },
  {
    "country": "Egypt",
    "cities": [
      "Abu Hammad",
      "Al Mahallah al Kubra",
      "Al Mansurah",
      "Al Marj",
      "Alexandria",
      "Almazah",
      "Ar Rawdah",
      "Assiut",
      "Az Zamalik",
      "Badr",
      "Banha",
      "Bani Suwayf",
      "Cairo",
      "Damietta",
      "Faraskur",
      "Flaminj",
      "Giza",
      "Heliopolis",
      "Helwan",
      "Hurghada",
      "Ismailia",
      "Kafr ash Shaykh",
      "Luxor",
      "Madinat an Nasr",
      "Madinat as Sadis min Uktubar",
      "Minya",
      "Nasr",
      "New Cairo",
      "Port Said",
      "Rafah",
      "Ramsis",
      "Sadat",
      "Shirbin",
      "Shubra",
      "Sohag",
      "Suez",
      "Tanta",
      "Toukh",
      "Zagazig"
    ]
  },
  {
    "country": "El Salvador",
    "cities": [
      "Ahuachapan",
      "Antiguo Cuscatlan",
      "Apaneca",
      "Apopa",
      "Ayutuxtepeque",
      "Botoncillal El Botoncillo",
      "Coatepeque",
      "Colon",
      "Colonia Escalon",
      "Cuscatancingo",
      "Delgado",
      "Gigante",
      "Guazapa",
      "Ilopango",
      "La Libertad",
      "Lourdes",
      "Mejicanos",
      "Nuevo Cuscatlan",
      "Ozatlan",
      "San Jose Villanueva",
      "San Marcos",
      "San Miguel",
      "San Salvador",
      "Santa Ana",
      "Santa Tecla",
      "Santiago Texacuangos",
      "Sonsonate",
      "Soyapango",
      "Usulutan",
      "Zaragoza"
    ]
  },
  {
    "country": "Equatorial Guinea",
    "cities": [
      "Annobon",
      "Bioko Norte",
      "Bioko Sur",
      "Centro Sur",
      "Kie-Ntem",
      "Litoral",
      "Wele-Nzas"
    ]
  },
  {
    "country": "Eritrea",
    "cities": [
      "Anseba",
      "Debub",
      "Debubawi K'eyih Bahri",
      "Gash Barka",
      "Ma'akel",
      "Semenawi Keyih Bahri"
    ]
  },
  {
    "country": "Estonia",
    "cities": [
      "Aasmae",
      "Aaviku",
      "Aespa",
      "Ahtma",
      "Alliku",
      "Ambla",
      "Antsla",
      "Ardu",
      "Avinurme",
      "Elva",
      "Emmaste",
      "Haabneeme",
      "Haage",
      "Haapsalu",
      "Halinga",
      "Harju",
      "Harku",
      "Kaarma",
      "Kadrina",
      "Kehra",
      "Keila",
      "Kiili",
      "Kohila",
      "Kohtla",
      "Kose",
      "Kulna",
      "Kuressaare",
      "Kuusalu",
      "Laagri",
      "Lagedi",
      "Lahtse",
      "Lepna",
      "Loksa",
      "Loo",
      "Lubja",
      "Maardu",
      "Muraste",
      "Narva",
      "Otepaeae",
      "Paide",
      "Paikuse",
      "Paldiski",
      "Palivere",
      "Peetri",
      "Puhja",
      "Rakke",
      "Rakvere",
      "Rapla",
      "Saku",
      "Saue",
      "Sindi",
      "Somerpalu",
      "Syare",
      "Tabasalu",
      "Tabivere",
      "Tallinn",
      "Tamsalu",
      "Tapa",
      "Tartu",
      "Toila",
      "Vaida",
      "Valga",
      "Vara",
      "Venevere",
      "Viimsi",
      "Viljandi",
      "Voka"
    ]
  },
  {
    "country": "Ethiopia",
    "cities": [
      "Addis Ababa",
      "Afar",
      "Amhara",
      "Binshangul Gumuz",
      "Dire Dawa",
      "Gambela Hizboch",
      "Harari",
      "Oromia",
      "Somali",
      "Tigray",
      "Southern Nations, Nationalities, and Peoples Region"
    ]
  },
  {
    "country": "Faroe Islands",
    "cities": [
      "Argir",
      "Glyvrar",
      "Hvalba",
      "Innan Glyvur",
      "Leirvik",
      "Saltangara",
      "Signabour",
      "Strendur"
    ]
  },
  {
    "country": "Fiji",
    "cities": [
      "Central (Suva)",
      "Eastern (Levuka)",
      "Northern (Labasa)",
      "Rotuma",
      "Western (Lautoka)"
    ]
  },
  {
    "country": "Finland",
    "cities": [
      "Helsinki",
      "Espoo",
      "Tampere",
      "Vantaa",
      "Oulu",
      "Turku",
      "Jyväskylä",
      "Lahti",
      "Kuopio",
      "Kouvola",
      "Pori",
      "Joensuu",
      "Lappeenranta",
      "Hämeenlinna",
      "Vaasa",
      "Seinäjoki",
      "Rovaniemi",
      "Mikkeli",
      "Kotka",
      "Salo",
      "Porvoo",
      "Lohja",
      "Hyvinkää",
      "Järvenpää",
      "Rauma",
      "Kokkola",
      "Kerava",
      "Kajaani",
      "Nurmijärvi",
      "Tuusula",
      "Kirkkonummi",
      "Kauniainen",
      "Kangasala",
      "Riihimäki",
      "Savonlinna",
      "Iisalmi",
      "Jakobstad",
      "Valkeakoski",
      "Varkaus",
      "Kemi",
      "Raisio",
      "Raahe",
      "Ylöjärvi",
      "Hamina",
      "Lieto",
      "Forssa",
      "Kaarina",
      "Kuusamo",
      "Siilinjärvi",
      "Uusikaupunki",
      "Pietarsaari",
      "Sipoo",
      "Nokia",
      "Vihti",
      "Ylivieska",
      "Kontiolahti",
      "Kauhava",
      "Lempäälä",
      "Mäntsälä",
      "Keuruu",
      "Paimio",
      "Nurmo",
      "Ilmajoki",
      "Laukaa",
      "Kempele",
      "Lieksa",
      "Naantali",
      "Lapua",
      "Hollola",
      "Ikaalinen",
      "Janakkala",
      "Orivesi",
      "Kitee",
      "Karkkila",
      "Sastamala",
      "Nivala",
      "Kankaanpää",
      "Ulvila",
      "Haukipudas",
      "Tornio",
      "Hämeenkyrö",
      "Somero",
      "Kuhmo",
      "Pudasjärvi",
      "Ilomantsi",
      "Nastola",
      "Kangasniemi",
      "Alajärvi",
      "Pirkkala",
      "Pietarsaari",
      "Kankaanpää",
      "Mäntsälä",
      "Jämsä",
      "Kauniainen",
      "Pargas",
      "Pyhäjärvi",
      "Orimattila",
      "Kaustinen",
      "Pyhtää",
      "Raisio",
      "Sastamala",
      "Ylivieska",
      "Kuusamo",
      "Iisalmi",
      "Pori",
      "Loviisa",
      "Eura",
      "Laukaa",
      "Valkeakoski",
      "Lohja",
      "Kauhava",
      "Kitee",
      "Lempäälä",
      "Varkaus",
      "Hämeenlinna",
      "Seinäjoki",
      "Hollola",
      "Kempele",
      "Pieksämäki",
      "Rovaniemi",
      "Kerava",
      "Salo",
      "Kouvola",
      "Pietarsaari",
      "Iisalmi",
      "Kaarina",
      "Kontiolahti",
      "Kangasala",
      "Siilinjärvi",
      "Kemi",
      "Jyväskylä",
      "Joensuu",
      "Lappeenranta",
      "Porvoo",
      "Hamina",
      "Nokia",
      "Imatra",
      "Jakobstad",
      "Lieto",
      "Forssa",
      "Kirkkonummi",
      "Lahti",
      "Ylöjärvi",
      "Uusikaupunki",
      "Nurmijärvi",
      "Hämeenlinna",
      "Kokkola",
      "Riihimäki",
      "Järvenpää",
      "Raahe",
      "Ylöjärvi",
      "Paimio",
      "Ylivieska"
    ]
  },
  {
    "country": "France",
    "cities": [
      "Paris",
      "Marseille",
      "Lyon",
      "Toulouse",
      "Nice",
      "Nantes",
      "Strasbourg",
      "Montpellier",
      "Bordeaux",
      "Lille",
      "Rennes",
      "Reims",
      "Le Havre",
      "Saint-Étienne",
      "Toulon",
      "Grenoble",
      "Dijon",
      "Angers",
      "Nîmes",
      "Villeurbanne",
      "Saint-Denis",
      "Le Mans",
      "Aix-en-Provence",
      "Clermont-Ferrand",
      "Brest",
      "Tours",
      "Limoges",
      "Amiens",
      "Annecy",
      "Perpignan",
      "Boulogne-Billancourt",
      "Metz",
      "Besançon",
      "Orléans",
      "Saint-Denis",
      "Argenteuil",
      "Rouen",
      "Montreuil",
      "Mulhouse",
      "Caen",
      "Nancy",
      "Saint-Paul",
      "Tourcoing",
      "Roubaix",
      "Vitry-sur-Seine",
      "Avignon",
      "Créteil",
      "Dunkerque",
      "Poitiers",
      "Asnières-sur-Seine",
      "Courbevoie",
      "Versailles",
      "Colombes",
      "Fort-de-France",
      "Aulnay-sous-Bois",
      "Saint-Pierre",
      "Rueil-Malmaison",
      "Pau",
      "Aubervilliers",
      "Le Tampon",
      "Champigny-sur-Marne",
      "Antibes",
      "La Rochelle",
      "Calais",
      "Saint-Maur-des-Fossés",
      "Béziers",
      "Cannes",
      "Saint-Nazaire",
      "Colmar",
      "Drancy",
      "Bourges",
      "Mérignac",
      "Saint-Louis",
      "La Seyne-sur-Mer",
      "Quimper",
      "Noisy-le-Grand",
      "Villejuif",
      "Les Abymes",
      "Troyes",
      "Vénissieux",
      "Lorient",
      "Sarcelles",
      "Niort",
      "Montauban",
      "Cholet",
      "Chambéry",
      "Annecy",
      "Hyères",
      "Le Lamentin",
      "Pessac",
      "Évry",
      "Meaux",
      "Ivry-sur-Seine",
      "Saint-André",
      "Levallois-Perret",
      "La Roche-sur-Yon",
      "Issy-les-Moulineaux",
      "Neuilly-sur-Seine",
      "Narbonne",
      "Chelles",
      "Talence",
      "Villeneuve-d'Ascq",
      "Laval"
    ]
  },
  {
    "country": "French Polynesia",
    "cities": [
      "Arue",
      "Faaa",
      "Mahina",
      "Paeau",
      "Papeete",
      "Pirae",
      "Punaauia"
    ]
  },
  {
    "country": "Gabon",
    "cities": [
      "Gamba",
      "Libreville",
      "Mamagnia",
      "Moanda",
      "Port-Gentil"
    ]
  },
  {
    "country": "Gambia",
    "cities": [
      "Banjul",
      "Central River",
      "Lower River",
      "North Bank",
      "Upper River",
      "Western"
    ]
  },
  {
    "country": "Georgia",
    "cities": [
      "Gogolesubani",
      "Kutaisi",
      "Lentekhi",
      "Qazbegi",
      "Samtredia",
      "Sukhumi",
      "Tbilisi",
      "Zemo-Avchala",
      "Zugdidi"
    ]
  },
  {
    "country": "Germany",
    "cities": [
      "Berlin",
      "Hamburg",
      "Munich",
      "Cologne",
      "Frankfurt",
      "Stuttgart",
      "Düsseldorf",
      "Dortmund",
      "Essen",
      "Leipzig",
      "Bremen",
      "Dresden",
      "Hanover",
      "Nuremberg",
      "Duisburg",
      "Bochum",
      "Wuppertal",
      "Bielefeld",
      "Bonn",
      "Münster",
      "Karlsruhe",
      "Mannheim",
      "Augsburg",
      "Wiesbaden",
      "Gelsenkirchen",
      "Mönchengladbach",
      "Braunschweig",
      "Chemnitz",
      "Kiel",
      "Aachen",
      "Halle",
      "Magdeburg",
      "Freiburg",
      "Krefeld",
      "Lübeck",
      "Oberhausen",
      "Erfurt",
      "Mainz",
      "Rostock",
      "Kassel",
      "Hagen",
      "Hamm",
      "Saarbrücken",
      "Mülheim an der Ruhr",
      "Potsdam",
      "Leverkusen",
      "Oldenburg",
      "Osnabrück",
      "Heidelberg",
      "Solingen",
      "Herne",
      "Neuss",
      "Darmstadt",
      "Paderborn",
      "Regensburg",
      "Ingolstadt",
      "Würzburg",
      "Wolfsburg",
      "Fürth",
      "Ulm",
      "Offenbach",
      "Heilbronn",
      "Pforzheim",
      "Göttingen",
      "Bottrop",
      "Trier",
      "Recklinghausen",
      "Reutlingen",
      "Bremerhaven",
      "Koblenz",
      "Bergisch Gladbach",
      "Jena",
      "Remscheid",
      "Erlangen",
      "Moers",
      "Siegen",
      "Hildesheim",
      "Salzgitter",
      "Kaiserslautern",
      "Cottbus",
      "Gütersloh",
      "Schwerin",
      "Witten",
      "Gera",
      "Iserlohn",
      "Zwickau",
      "Düren",
      "Ratingen",
      "Esslingen",
      "Flensburg",
      "Ludwigsburg",
      "Marl",
      "Lünen",
      "Hanau",
      "Velbert",
      "Dessau",
      "Brandenburg an der Havel",
      "Lüneburg",
      "Villingen-Schwenningen",
      "Worms",
      "Konstanz",
      "Gladbeck",
      "Rheine",
      "Troisdorf",
      "Neumünster",
      "Castrop-Rauxel",
      "Hattingen",
      "Viersen",
      "Langenfeld",
      "Sankt Augustin"
    ]
  },
  {
    "country": "Ghana",
    "cities": [
      "Accra",
      "Bawku",
      "Berekum",
      "Bolgatanga",
      "Cape Coast",
      "Home",
      "Koforidua",
      "Kumasi",
      "Legon",
      "Mampong",
      "Navrongo",
      "Sunyani",
      "Takoradi",
      "Tema",
      "Wa",
      "Winneba"
    ]
  },
  {
    "country": "Greece",
    "cities": [
      "Aegina",
      "Agioi Anargyroi",
      "Agios Nikolaos",
      "Agrinio",
      "Aigaleo",
      "Aigio",
      "Alexandreia",
      "Alexandroupoli",
      "Aliartos",
      "Alimos",
      "Amaliada",
      "Anavyssos",
      "Andravida",
      "Ano Liosia",
      "Archaia Nemea",
      "Argos",
      "Argostoli",
      "Argyroupoli",
      "Arta",
      "Aspropyrgos",
      "Athens",
      "Attica",
      "Ayios Stefanos",
      "Chalcis",
      "Chania",
      "Chania",
      "Chios",
      "Cholargos",
      "Corfu",
      "Corinth",
      "Dafni",
      "Dionysos",
      "Drama",
      "Ekali",
      "Elassona",
      "Elefsina",
      "Elliniko",
      "Eretria",
      "Farsala",
      "Filippiada",
      "Filothei",
      "Florina",
      "Galatsi",
      "Giannitsa",
      "Glyfada",
      "Gonnoi",
      "Goumenissa",
      "Heraklion",
      "Hydra",
      "Ialysos",
      "Ierapetra",
      "Igoumenitsa",
      "Ioannina",
      "Kaisariani",
      "Kalamaria",
      "Kalamata",
      "Kalamos",
      "Kallithea",
      "Kalymnos",
      "Kamatero",
      "Karditsa",
      "Kassiopi",
      "Kastoria",
      "Katerini",
      "Kavala",
      "Keratea",
      "Keratsini",
      "Kifissia",
      "Kilkis",
      "Komotini",
      "Koropi",
      "Kos",
      "Kouvaras",
      "Kozani",
      "Kranidi",
      "Kryonerion",
      "Kymi",
      "Lamia",
      "Larisa",
      "Lefkada",
      "Lefkimmi",
      "Leontario",
      "Litochoro",
      "Lixouri",
      "Loutraki",
      "Lykovrysi",
      "Magoula",
      "Makrygialos",
      "Mandra",
      "Marathon",
      "Markopoulo Mesogaias",
      "Megalochari",
      "Megara",
      "Melissia",
      "Mesagros",
      "Messolonghi",
      "Metaxades",
      "Moires",
      "Mytilene",
      "Nafpaktos",
      "Nafplion",
      "Naousa",
      "Naxos",
      "Nea Alikarnassos",
      "Nea Filadelfeia",
      "Nea Ionia",
      "Nea Kios",
      "Nea Makri",
      "Nea Peramos",
      "Nea Smyrni",
      "Nikaia",
      "Orestiada",
      "Ormylia",
      "Oropos",
      "Paiania",
      "Pallini",
      "Paloukia",
      "Panorama",
      "Parga",
      "Patmos",
      "Pefki",
      "Pella",
      "Perama",
      "Peristeri",
      "Peristeri",
      "Pikermi",
      "Piraeus",
      "Platy",
      "Polichni",
      "Porto Rafti",
      "Preveza",
      "Psychiko",
      "Ptolemaida",
      "Pylaia",
      "Pyrgos",
      "Rafina",
      "Rethymno",
      "Rhodes",
      "Rio",
      "Salamina",
      "Samothraki",
      "Serres",
      "Servia",
      "Sindos",
      "Skala",
      "Skala Oropou",
      "Skiathos",
      "Sparta",
      "Spata",
      "Symi",
      "Tavros",
      "Thebes",
      "Thermi",
      "Thessaloniki",
      "Tinos",
      "Trikala",
      "Tripoli",
      "Vari",
      "Varkiza",
      "Vergina",
      "Veroia",
      "Volos",
      "Voula",
      "Vouliagmeni",
      "Xanthi",
      "Xylokastro",
      "Zakynthos"
    ]
  },
  {
    "country": "Greenland",
    "cities": [
      "Aasiaat",
      "Ilulissat",
      "Kapisillit",
      "Maniitsoq",
      "Narsaq",
      "Narsarsuaq",
      "Nuuk",
      "Nuussuaq",
      "Paamiut",
      "Qaqortoq",
      "Qasigiannguit",
      "Qeqertarsuaq",
      "Qeqertat",
      "Sisimiut",
      "Tasiilaq",
      "Upernavik",
      "Uummannaq Kommune"
    ]
  },
  {
    "country": "Guadeloupe",
    "cities": [
      "Anse-Bertrand",
      "Baie Mahault",
      "Baie-Mahault",
      "Baillif",
      "Basse-Terre",
      "Capesterre-Belle-Eau",
      "Capesterre-de-Marie-Galante",
      "Deshaies",
      "Gourbeyre",
      "Goyave",
      "Grand-Bourg",
      "Lamentin",
      "Le Gosier",
      "Le Moule",
      "Les Abymes",
      "Petit-Bourg",
      "Petit-Canal",
      "Pointe-Noire",
      "Port-Louis",
      "Saint-Claude",
      "Saint-Francois",
      "Saint-Louis",
      "Sainte-Anne",
      "Sainte-Rose",
      "Terre-de-Bas",
      "Terre-de-Haut",
      "Trois-Rivieres",
      "Village"
    ]
  },
  {
    "country": "Grenada",
    "cities": [
      "Carriacou and Petit Martinique",
      "Saint Andrew",
      "Saint David",
      "Saint George",
      "Saint John",
      "Saint Mark",
      "Saint Patrick"
    ]
  },
  {
    "country": "Guatemala",
    "cities": [
      "Antigua Guatemala",
      "Cambote",
      "Catarina",
      "Central",
      "Chimaltenango",
      "Chiquimula",
      "Ciudad Vieja",
      "Coban",
      "El Limon",
      "El Naranjo",
      "El Salvador",
      "Escuintla",
      "Esquipulas",
      "Flores",
      "Guatemala City",
      "Huehuetenango",
      "Jocotenango",
      "La Reforma",
      "La Reforma",
      "Mazatenango",
      "Melchor de Mencos",
      "Mixco",
      "Palin",
      "Panajachel",
      "Petapa",
      "Puerto Barrios",
      "Quesada",
      "Quetzaltenango",
      "Retalhuleu",
      "San Antonio Miramar",
      "San Jose Pinula",
      "San Juan",
      "San Marcos",
      "San Pedro Sacatepequez",
      "Santa Catarina Pinula",
      "Santa Cruz La Laguna",
      "Santa Cruz Naranjo",
      "Santa Lucia Cotzumalguapa",
      "Santa Rosa",
      "Solola",
      "Villa Nueva",
      "Vista Hermosa",
      "Zacapa"
    ]
  },
  {
    "country": "Guam",
    "cities": [
      "Barrigada Village",
      "Dededo Village",
      "Inarajan Village",
      "Santa Rita",
      "Tamuning-Tumon-Harmon Village",
      "Yigo Village"
    ]
  },
  {
    "country": "Guinea",
    "cities": [
      "Conakry",
      "Dabola",
      "Kalia",
      "Kankan",
      "Lola",
      "Mamou",
      "Port Kamsar",
      "Sangaredi"
    ]
  },
  {
    "country": "Guinea-Bissau",
    "cities": [
      "Bafata",
      "Biombo",
      "Bissau",
      "Bolama",
      "Cacheu",
      "Gabu",
      "Oio",
      "Quinara",
      "Tombali"
    ]
  },
  {
    "country": "Guyana",
    "cities": [
      "Barima-Waini",
      "Cuyuni-Mazaruni",
      "Demerara-Mahaica",
      "East Berbice-Corentyne",
      "Essequibo Islands-West Demerara",
      "Mahaica-Berbice",
      "Pomeroon-Supenaam",
      "Potaro-Siparuni",
      "Upper Demerara-Berbice",
      "Upper Takutu-Upper Essequibo"
    ]
  },
  {
    "country": "Haiti",
    "cities": [
      "Carrefour",
      "Delmar",
      "Duverger",
      "Jacmel",
      "Masseau",
      "Moise",
      "Petionville",
      "Port-au-Prince",
      "Prince",
      "Turgeau"
    ]
  },
  {
    "country": "Hashemite Kingdom of Jordan",
    "cities": [
      "Amman",
      "Ar Ramtha",
      "Farah",
      "Irbid",
      "Madaba",
      "Sahab",
      "Salt"
    ]
  },
  {
    "country": "Honduras",
    "cities": [
      "Honduras",
      "Choloma",
      "Comayagua",
      "Comayaguela",
      "Coxen Hole",
      "El Barro",
      "El Paraiso",
      "El Progreso",
      "La Ceiba",
      "La Hacienda",
      "Morazan",
      "Nacaome",
      "Pinalejo",
      "Piraera",
      "Puerto Lempira",
      "San Antonio de Flores",
      "San Pedro Sula",
      "Santa Barbara",
      "Sula",
      "Tegucigalpa"
    ]
  },
  {
    "country": "Hong Kong",
    "cities": [
      "Aberdeen",
      "Causeway Bay",
      "Central District",
      "Cha Kwo Ling",
      "Chai Wan Kok",
      "Chek Chue",
      "Cheung Kong",
      "Cheung Sha Wan",
      "Chuen Lung",
      "Chung Hau",
      "Fa Yuen",
      "Fanling",
      "Fo Tan",
      "Happy Valley",
      "Ho Man Tin",
      "Hong Kong",
      "Hung Hom",
      "Kat O Sheung Wai",
      "Kennedy Town",
      "Kowloon",
      "Kowloon Tong",
      "Kwai Chung",
      "Kwun Hang",
      "Lai Chi Wo",
      "Lam Tei",
      "Lam Tin",
      "Lin Fa Tei",
      "Lo So Shing",
      "Ma On Shan Tsuen",
      "Ma Wan",
      "Ma Yau Tong",
      "Mau Ping",
      "Mid Levels",
      "Mong Kok",
      "Nam A",
      "Ngau Chi Wan",
      "Ngau Tau Kok",
      "North Point",
      "Pak Ngan Heung",
      "Peng Chau",
      "Ping Yeung",
      "Quarry Bay",
      "Repulse Bay",
      "Sai Keng",
      "San Tsuen",
      "San Tung Chung Hang",
      "Sha Po Kong",
      "Sha Tau Kok",
      "Sha Tin Wai",
      "Sham Shui Po",
      "Sham Tseng",
      "Shatin",
      "Shau Kei Wan",
      "Shek Kip Mei",
      "Shek Tong Tsui",
      "Sheung Shui",
      "Sheung Tsuen",
      "Shuen Wan",
      "Tai Chau To",
      "Tai Hang",
      "Tai Kok Tsui",
      "Tai Lin Pai",
      "Tai Po",
      "Tai Tan",
      "Tai Wai",
      "Tai Wan To",
      "Tin Shui Wai",
      "Tin Wan Resettlement Estate",
      "Ting Kau",
      "To Kwa Wan",
      "Tseung Kwan O",
      "Tsimshatsui",
      "Tsing Lung Tau",
      "Tsz Wan Shan",
      "Tuen Mun San Hui",
      "Wan Tsai",
      "Wo Che",
      "Wong Chuk Hang",
      "Wong Tai Sin",
      "Yau Ma Tei",
      "Ying Pun",
      "Yuen Long San Hui"
    ]
  },
  {
    "country": "Hungary",
    "cities":       [
      "Budapest",
      "Debrecen",
      "Szeged",
      "Miskolc",
      "Pecs",
      "Gyor",
      "Nyiregyhaza",
      "Kecskemet",
      "Szekesfehervar",
      "Szombathely",
      "Szolnok",
      "Tatabanya",
      "Kaposvar",
      "Erd",
      "Veszprem",
      "Bekescsaba",
      "Zalaegerszeg",
      "Sopron",
      "Eger",
      "Nagykanizsa",
      "Dunaujvaros",
      "Hodmezovasarhely",
      "Szekszard",
      "Papa",
      "Vac",
      "Keszthely",
      "Mosonmagyarovar",
      "Salgotarjan",
      "Szekszard",
      "Karcag",
      "Bekes",
      "Gyongyos",
      "Szigetszentmiklos",
      "Ozd",
      "Paks",
      "Godollo",
      "Szentendre",
      "Szazhalombatta",
      "Kiskunfelegyhaza",
      "Varpalota",
      "Budaors",
      "Gyal",
      "Szarvas",
      "Kalocsa",
      "Oroshaza",
      "Mezotur",
      "Hatvan",
      "Balmazujvaros",
      "Satoraljaujhely",
      "Balassagyarmat",
      "Cegled",
      "Bekescsaba",
      "Ozd",
      "Esztergom",
      "Ajka",
      "Dombovar",
      "Torokszentmiklos",
      "Kisvarda",
      "Baja",
      "Jaszbereny",
      "Szentgotthard",
      "Varpalota",
      "Hajduboszormeny",
      "Kazincbarcika",
      "Tata",
      "Koszeg",
      "Kiskunhalas",
      "Berettyoujfalu",
      "Nagykoros",
      "Komlo",
      "Pecsvard",
      "Szigethalom",
      "Tiszaujvaros",
      "Gyongyos",
      "Monor",
    ]
  },
  {
    "country": "Iceland",
    "cities": [
      "Akranes",
      "Akureyri",
      "Borgarnes",
      "Dalvik",
      "Grindavik",
      "Hella",
      "Holmavik",
      "Husavik",
      "Hvammstangi",
      "Hveragerdi",
      "Hvolsvollur",
      "Kopavogur",
      "Reykjavik",
      "Selfoss",
      "Skagastrond",
      "Stokkseyri",
      "Vestmannaeyjar",
      "Vogar"
    ]
  },
  {
    "country": "India",
    "cities": [
      "Mumbai",
      "Delhi",
      "Bangalore",
      "Kolkata",
      "Chennai",
      "Hyderabad",
      "Ahmedabad",
      "Pune",
      "Surat",
      "Jaipur",
      "Lucknow",
      "Kanpur",
      "Nagpur",
      "Indore",
      "Thane",
      "Bhopal",
      "Visakhapatnam",
      "Pimpri-Chinchwad",
      "Patna",
      "Vadodara",
      "Ghaziabad",
      "Ludhiana",
      "Agra",
      "Nashik",
      "Faridabad",
      "Meerut",
      "Rajkot",
      "Kalyan-Dombivali",
      "Vasai-Virar",
      "Varanasi",
      "Srinagar",
      "Aurangabad",
      "Dhanbad",
      "Amritsar",
      "Navi Mumbai",
      "Allahabad",
      "Howrah",
      "Ranchi",
      "Gwalior",
      "Jabalpur",
      "Coimbatore",
      "Vijayawada",
      "Jodhpur",
      "Madurai",
      "Raipur",
      "Kota",
      "Guwahati",
      "Chandigarh",
      "Solapur",
      "Hubli-Dharwad",
      "Bareilly",
      "Moradabad",
      "Mysore",
      "Gurgaon",
      "Aligarh",
      "Jalandhar",
      "Tiruchirappalli",
      "Bhubaneswar",
      "Salem",
      "Mira-Bhayandar",
      "Warangal",
      "Guntur",
      "Bhiwandi",
      "Saharanpur",
      "Gorakhpur",
      "Bikaner",
      "Amravati",
      "Noida",
      "Jamshedpur",
      "Bhilai",
      "Cuttack",
      "Firozabad",
      "Kochi",
      "Nellore",
      "Bhavnagar",
      "Dehradun",
      "Durgapur",
      "Asansol",
      "Rourkela",
      "Nanded",
      "Kolhapur",
      "Ajmer",
      "Akola",
      "Gulbarga",
      "Jamnagar",
      "Ujjain",
      "Loni",
      "Siliguri",
      "Jhansi",
      "Ulhasnagar",
      "Jammu",
      "Sangli-Miraj & Kupwad",
      "Mangalore",
      "Erode",
      "Belgaum",
      "Ambattur",
      "Tirunelveli",
      "Malegaon",
      "Gaya",
      "Jalgaon",
      "Udaipur",
      "Maheshtala",
      "Tirupur",
      "Davanagere",
      "Kozhikode",
      "Akola"
    ]
  },
  {
    "country": "Indonesia",
    "cities": [
      "Jakarta",
      "Surabaya",
      "Bandung",
      "Medan",
      "Semarang",
      "Makassar",
      "Palembang",
      "Tangerang",
      "Depok",
      "South Tangerang",
      "Batam",
      "Bogor",
      "Padang",
      "Denpasar",
      "Bandar Lampung",
      "Pekanbaru",
      "Malang",
      "Surakarta",
      "Banjarmasin",
      "Yogyakarta",
      "Cimahi",
      "Balikpapan",
      "Jambi",
      "Serang",
      "Surakarta",
      "Manado",
      "Pontianak",
      "Kupang",
      "Palu",
      "Ambon",
      "Tarakan",
      "Cilegon",
      "Bengkulu",
      "Palangkaraya",
      "Pasuruan",
      "Tanjung Pinang",
      "Madiun",
      "Samarinda",
      "Mataram",
      "Pematangsiantar",
      "Probolinggo",
      "Cirebon",
      "Banjarbaru",
      "Sukabumi",
      "Bekasi",
      "Tasikmalaya",
      "Banda Aceh",
      "Jayapura",
      "Bekasi",
      "Palu",
      "Sorong",
      "Tegal",
      "Kediri",
      "Binjai",
      "Purwokerto",
      "Purwakarta",
      "Cileduk",
      "Pekalongan",
      "Padang Sidempuan",
      "Singkawang",
      "Cikupa",
      "Sumedang",
      "Tegal",
      "Lhokseumawe",
      "Baturaja",
      "Sukabumi",
      "Pematangsiantar",
      "Parepare",
      "Bitung",
      "Padang Sidempuan",
      "Lahat",
      "Tarakan",
      "Tomohon",
      "Prabumulih",
      "Tanjung Balai",
      "Pangkal Pinang",
      "Metro",
      "Langsa",
      "Tanah Grogot",
      "Jambi",
      "Singaraja",
      "Kedungwuni",
      "Tanjung Pandan",
      "Pangkalan Brandan",
      "Manokwari",
      "Solok",
      "Tanjung Redeb",
      "Kudus",
      "Kendari",
      "Sidoarjo",
      "Payakumbuh",
      "Pariaman",
      "Bukittinggi",
      "Pamekasan",
      "Kendal",
      "Praya",
      "Martapura",
      "Tanjung Pinang",
      "Pariaman",
      "Sijunjung",
      "Padangpanjang",
      "Meulaboh",
      "Ketapang",
      "Rantepao",
      "Samarinda",
      "Majene",
      "Pangkalanbuun",
      "Tarutung",
      "Banyuwangi"
    ]
  },
  {
    "country": "Iran",
    "cities": [
      "Tehran",
      "Mashhad",
      "Isfahan",
      "Karaj",
      "Tabriz",
      "Shiraz",
      "Ahvaz",
      "Qom",
      "Kermanshah",
      "Urmia",
      "Rasht",
      "Kerman",
      "Hamadan",
      "Yazd",
      "Ardabil",
      "Eslamshahr",
      "Zahedan",
      "Arak",
      "Ardabil",
      "Zanjan",
      "Sanandaj",
      "Bandar Abbas",
      "Khorramabad",
      "Gorgan",
      "Sari",
      "Khorramshahr",
      "Birjand",
      "Qazvin",
      "Karaj",
      "Khomeini Shahr",
      "Qarchak",
      "Qods",
      "Borujerd",
      "Varamin",
      "Maragheh",
      "Saveh",
      "Najafabad",
      "Shahreza",
      "Rezvanshahr",
      "Bukan",
      "Parsabad",
      "Fasa",
      "Khoy",
      "Shahr-e Babak",
      "Khalkhal",
      "Damghan",
      "Sahneh",
      "Ramhormoz",
      "Paveh",
      "Qaleh Ganj",
      "Khorramdarreh",
      "Javanrud",
      "Shahrud",
      "Neyshabur",
      "Chabahar",
      "Fereydunkenar",
      "Malayer",
      "Semnan",
      "Nurabad",
      "Fasa",
      "Bonab",
      "Sardasht",
      "Qasr-e Shirin",
      "Neyriz",
      "Takab",
      "Khorramabad",
      "Ilam",
      "Saqez",
      "Nahavand",
      "Gonbad-e Qabus",
      "Abhar",
      "Minab",
      "Rafsanjan",
      "Bam",
      "Golpayegan",
      "Gonbad-e Kavus",
      "Bojnurd",
      "Kazerun",
      "Farsan",
      "Bandar-e Anzali",
      "Astara",
      "Sirjan",
      "Ahar",
      "Dehloran",
      "Borazjan",
      "Dezful",
      "Kashmar",
      "Larestan",
      "Nurabad",
      "Kish",
      "Kahriz",
      "Jahrom",
      "Aligudarz",
      "Qaemshahr",
      "Sonqor",
      "Meybod",
      "Lar",
      "Kuhdasht",
      "Qorveh",
      "Gorgan",
      "Dorud",
      "Dehui",
      "Gonbad-e Qabus",
      "Khalkhal",
      "Zabol",
      "Iranshahr",
      "Semnan",
      "Torbat-e Jam"
    ]
  },
  {
    "country": "Iraq",
    "cities": [
      "Al `Amarah",
      "Al Hillah",
      "Baghdad",
      "Bahr",
      "Basere",
      "Basra",
      "Erbil",
      "Haji Hasan",
      "Hayat",
      "Karkh",
      "Kirkuk",
      "Manawi",
      "Mosul",
      "Najaf",
      "Sulaymaniyah",
      "Tikrit"
    ]
  },
  {
    "country": "Ireland",
    "cities": [
      "Dublin",
      "Cork",
      "Limerick",
      "Galway",
      "Waterford",
      "Drogheda",
      "Dundalk",
      "Swords",
      "Bray",
      "Navan",
      "Kilkenny",
      "Ennis",
      "Carlow",
      "Tralee",
      "Newbridge",
      "Portlaoise",
      "Balbriggan",
      "Naas",
      "Athlone",
      "Mullingar",
      "Wexford",
      "Celbridge",
      "Clonmel",
      "Greystones",
      "Malahide",
      "Leixlip",
      "Sligo",
      "Killarney",
      "Letterkenny",
      "Carrick-on-Shannon",
      "Tullamore",
      "Cobh",
      "Castlebar",
      "Midleton",
      "Mallow",
      "Ashbourne",
      "Ballina",
      "Laytown",
      "Lucan",
      "Monaghan",
      "Roscommon",
      "Dungarvan",
      "Arklow",
      "Carrigaline",
      "New Ross",
      "Thurles",
      "Youghal",
      "Skerries",
      "Dunboyne",
      "Lusk",
      "Clane",
      "Kinsale",
      "Rush",
      "Buncrana",
      "Donabate",
      "Shannon",
      "Sallins",
      "Portmarnock",
      "Bantry",
      "Swinford",
      "Portarlington",
      "Blessington",
      "Kilcock",
      "Edenderry",
      "Mitchelstown",
      "Bandon",
      "Cobh",
      "Skibbereen",
      "Longford",
      "Ratoath",
      "Blackrock",
      "Maynooth",
      "Baldoyle",
      "Mountmellick",
      "Cavan",
      "Westport",
      "Wicklow",
      "Ballyshannon",
      "Loughrea",
      "Bantry",
      "Dunshaughlin",
      "Loughrea",
      "Roscrea",
      "Bundoran",
      "Ballinasloe",
      "Templemore",
      "Bagenalstown",
      "Kildare",
      "Rosslare",
      "Ballybofey",
      "Carrickmacross",
      "Cahersiveen",
      "Kilcoole",
      "Castlepollard",
      "Kilrush",
      "Rosslare",
      "Mountmellick",
      "Ballyhaunis",
      "Clifden"
    ]
  },
  {
    "country": "Isle of Man",
    "cities": [
      "Ballasalla",
      "Castletown",
      "Crosby",
      "Dalby",
      "Douglas",
      "Foxdale",
      "Laxey",
      "Onchan",
      "Peel",
      "Port Erin",
      "Port Saint Mary",
      "Ramsey",
      "Saint Johns"
    ]
  },
  {
    "country": "Israel",
    "cities": [
      "`Alma",
      "`Amir",
      "`Arugot",
      "`Aseret",
      "`En Ayyala",
      "`En HaShelosha",
      "`Evron",
      "Acre",
      "Afiqim",
      "Ahituv",
      "Allonim",
      "Ashdod",
      "Ashqelon",
      "Azor",
      "Bahan",
      "Baraq",
      "Bareqet",
      "Bat Hadar",
      "Bat Hefer",
      "Bat Yam",
      "Beersheba",
      "Ben Shemen-Kefar Hano`ar",
      "Bene Ziyyon",
      "Bet Alfa",
      "Bet Dagan",
      "Bet Oren",
      "Bet Shemesh",
      "Binyamina",
      "Biriyya",
      "Dabburiya",
      "Dimona",
      "Eilat",
      "Elyakhin",
      "Elyaqim",
      "Emunim",
      "Et Taiyiba",
      "Even Yehuda",
      "Gan Hayyim",
      "Gan Yavne",
      "Ganne Tiqwa",
      "Gedera",
      "Gibbeton",
      "Gimzo",
      "Ginnosar",
      "Giv`at Hayyim",
      "Hadar `Am",
      "Hadar Ramatayim",
      "Hadera",
      "Hadid",
      "Haifa",
      "HaKarmel",
      "Haluz",
      "Hazav",
      "Hazor Ashdod",
      "Hazor HaGelilit",
      "Herut",
      "Herzliya",
      "Hever",
      "Hod HaSharon",
      "Holon",
      "Hurfeish",
      "Jaffa",
      "Jerusalem",
      "Kadima",
      "Karkur",
      "Kefar Daniyyel",
      "Kefar Netter",
      "Kefar Witqin",
      "Kefar Yona",
      "Kfar Saba",
      "Liman",
      "Lod",
      "maalot Tarshiha",
      "Magen",
      "Maghar",
      "Mazkeret Batya",
      "Mazliah",
      "Mazor",
      "Mesillat Ziyyon",
      "Migdal",
      "Mikhmoret",
      "Misgav Regional Council",
      "Mizpe Netofa",
      "Modiin",
      "Moran",
      "Naham",
      "Nahariya",
      "Nazareth",
      "Nazerat `Illit",
      "Nesher",
      "Ness Ziona",
      "Netanya",
      "Netivot",
      "Newe Efrayim",
      "Newe Yamin",
      "Nir Zevi",
      "Nirim",
      "Nordiyya",
      "Ofaqim",
      "Or `Aqiva",
      "Or Yehuda",
      "Pardes Hanna Karkur",
      "Pardesiyya",
      "Pasuta",
      "Petah Tikwah",
      "Qiryat Ata",
      "Qiryat Bialik",
      "Qiryat Gat",
      "Qiryat Hayyim",
      "Qiryat Motzkin",
      "Qiryat Ono",
      "Qiryat Tiv`on",
      "Qiryat Yam",
      "Ramat Aviv",
      "Ramat Dawid",
      "Ramat Ef`al",
      "Ramat Gan",
      "Ramat HaSharon",
      "Ramat Poleg",
      "Ramat Yishay",
      "Ramla",
      "Ramot Naftali",
      "Rehovot",
      "Rinnatya",
      "Rishon LeZion",
      "Rishpon",
      "Safed",
      "Sarid",
      "Savyon",
      "Sde Boker",
      "Sde Warburg",
      "Sderot",
      "Sedot Yam",
      "Shamir",
      "Shave Ziyyon",
      "Shefayim",
      "Shelomi",
      "Shetulim",
      "Shoval",
      "Talme Menashe",
      "Tel Aviv",
      "Tel Mond",
      "Tiberias",
      "Timrat",
      "Tirat Karmel",
      "Tirat Yehuda",
      "Urim",
      "Yaqum",
      "Yavne",
      "Yehud",
      "Zoran"
    ]
  },
  {
    "country": "Italy",
    "cities": [
      "Rome",
      "Milan",
      "Naples",
      "Turin",
      "Palermo",
      "Genoa",
      "Bologna",
      "Florence",
      "Bari",
      "Catania",
      "Venice",
      "Verona",
      "Messina",
      "Padua",
      "Trieste",
      "Taranto",
      "Brescia",
      "Parma",
      "Prato",
      "Modena",
      "Reggio Calabria",
      "Reggio Emilia",
      "Perugia",
      "Ravenna",
      "Livorno",
      "Cagliari",
      "Foggia",
      "Rimini",
      "Salerno",
      "Ferrara",
      "Sassari",
      "Latina",
      "Giugliano in Campania",
      "Monza",
      "Siracusa",
      "Pescara",
      "Bergamo",
      "Forlì",
      "Trento",
      "Vicenza",
      "Terni",
      "Bolzano",
      "Novara",
      "Piacenza",
      "Ancona",
      "Andria",
      "Udine",
      "Arezzo",
      "Cesena",
      "Lecce",
      "Pesaro",
      "Barletta",
      "Alessandria",
      "La Spezia",
      "Pistoia",
      "Pisa",
      "Catanzaro",
      "Guidonia Montecelio",
      "Lucca",
      "Brindisi",
      "Torre del Greco",
      "Como",
      "Treviso",
      "Busto Arsizio",
      "Marsala",
      "Grosseto",
      "Sesto San Giovanni",
      "Pozzuoli",
      "Varese",
      "Fiumicino",
      "Casoria",
      "Asti",
      "Caserta",
      "Cinisello Balsamo",
      "Gela",
      "Aprilia",
      "Ragusa",
      "Pavia",
      "Cremona",
      "Carpi",
      "Quartu Sant'Elena",
      "Lamezia Terme",
      "Altamura",
      "Imola",
      "Molfetta",
      "Matera",
      "Massa",
      "Viterbo",
      "Potenza",
      "Trapani",
      "Cosenza",
      "Afragola",
      "Velletri",
      "Crotone",
      "Avezzano",
      "Savona",
      "Matera"
    ]
  },
  {
    "country": "Jamaica",
    "cities": [
      "Black River",
      "Browns Town",
      "Gordon Town",
      "Gregory Park",
      "Kingston",
      "Mandeville",
      "May Pen",
      "Moneague",
      "Montego Bay",
      "Negril",
      "Ocho Rios",
      "Old Harbour",
      "Port Maria",
      "Portland Cottage",
      "Portmore",
      "Runaway Bay",
      "Spanish Town"
    ]
  },
  {
    "country": "Japan",
    "cities": [
      "Tokyo",
      "Yokohama",
      "Osaka",
      "Nagoya",
      "Sapporo",
      "Fukuoka",
      "Kobe",
      "Kyoto",
      "Kawasaki",
      "Saitama",
      "Hiroshima",
      "Sendai",
      "Kitakyushu",
      "Chiba",
      "Sakai",
      "Shizuoka",
      "Niigata",
      "Hamamatsu",
      "Okayama",
      "Kumamoto",
      "Sagamihara",
      "Kagoshima",
      "Matsuyama",
      "Hachioji",
      "Funabashi",
      "Higashiosaka",
      "Himeji",
      "Utsunomiya",
      "Mito",
      "Oita",
      "Kanazawa",
      "Miyazaki",
      "Nagasaki",
      "Hirakata",
      "Kawagoe",
      "Urawa",
      "Machida",
      "Gifu",
      "Fujisawa",
      "Toyohashi",
      "Kurashiki",
      "Toyama",
      "Ichikawa",
      "Otsu",
      "Wakayama",
      "Nara",
      "Koriyama",
      "Shimizu",
      "Fukuyama",
      "Kochi",
      "Nagano",
      "Toyohashi",
      "Toyota",
      "Takamatsu",
      "Morioka",
      "Kashiwa",
      "Tokushima",
      "Naha",
      "Nagareyama",
      "Matsue",
      "Aomori",
      "Miyakonojo",
      "Hakodate",
      "Kasugai",
      "Otsuka",
      "Yamagata",
      "Koshigaya",
      "Akita",
      "Iwaki",
      "Shimonoseki",
      "Kure",
      "Sasebo",
      "Machida",
      "Hiratsuka",
      "Yokkaichi",
      "Fuchu",
      "Oyama",
      "Goshogawara",
      "Fukushima",
      "Iwata",
      "Hitachi",
      "Takasaki",
      "Hirosaki",
      "Kofu",
      "Saitama",
      "Atsugi",
      "Takatsuki",
      "Ise",
      "Ube",
      "Amagasaki",
      "Yonago",
      "Kakogawa",
      "Hirado",
      "Choshi",
      "Ishinomaki"
    ]
  },
  {
    "country": "Jordan",
    "cities": [
      "Ajlun",
      "Al 'Aqabah",
      "Al Balqa'",
      "Al Karak",
      "Al Mafraq",
      "'Amman",
      "At Tafilah",
      "Az Zarqa'",
      "Irbid",
      "Jarash",
      "Ma'an",
      "Madaba"
    ]
  },
  {
    "country": "Kazakhstan",
    "cities": [
      "Aksay",
      "Aksoran",
      "Almaty",
      "Aqtas",
      "Aqtau",
      "Astana",
      "Atyrau",
      "Baikonur",
      "Dostyk",
      "Dzhezkazgan",
      "Ekibastuz",
      "Esil",
      "Karagandy",
      "Karagandy",
      "Kazakh",
      "Kentau",
      "Koktem",
      "Kostanay",
      "Kyzyl",
      "Kyzylorda",
      "Lomonosovka",
      "Oral",
      "Pavlodar",
      "Petropavl",
      "Qaraghandy",
      "Qaskeleng",
      "Ridder",
      "Rudnyy",
      "Semey",
      "Serebryansk",
      "Shymkent",
      "Sonaly",
      "Stepnogorsk",
      "Taldyk",
      "Taldykorgan",
      "Talghar",
      "Taraz",
      "Temirtau",
      "Turkestan",
      "Uritskiy",
      "Ust-Kamenogorsk",
      "Vostok",
      "Zyryanovsk"
    ]
  },
  {
    "country": "Kenya",
    "cities": [
      "Bondo",
      "Chuka",
      "Eldoret",
      "Kabete",
      "Kaiboi",
      "Karatina",
      "Kiambu",
      "Kikuyu",
      "Kisii",
      "Kisumu",
      "Kitale",
      "Kitui",
      "Machakos",
      "Maseno",
      "Mbita",
      "Mombasa",
      "Nairobi",
      "Nakuru",
      "Sawa Sawa",
      "Siaya",
      "Thika",
      "Turbo",
      "Wote"
    ]
  },
  {
    "country": "Kiribati",
    "cities": []
  },
  {
    "country": "Korea North",
    "cities": [
      "Chagang",
      "North Hamgyong",
      "South Hamgyong",
      "North Hwanghae",
      "South Hwanghae",
      "Kangwon",
      "North P'yongan",
      "South P'yongan",
      "Yanggang",
      "Kaesong",
      "Najin",
      "Namp'o",
      "Pyongyang"
    ]
  },
  {
    "country": "Korea South",
    "cities": [
      "Seoul",
      "Busan City",
      "Daegu City",
      "Incheon City",
      "Gwangju City",
      "Daejeon City",
      "Ulsan",
      "Gyeonggi Province",
      "Gangwon Province",
      "North Chungcheong Province",
      "South Chungcheong Province",
      "North Jeolla Province",
      "South Jeolla Province",
      "North Gyeongsang Province",
      "South Gyeongsang Province",
      "Jeju"
    ]
  },
  {
    "country": "Kosovo",
    "cities": [
      "Dunavo",
      "Ferizaj",
      "Mitrovica",
      "Pristina",
      "Prizren"
    ]
  },
  {
    "country": "Kuwait",
    "cities": [
      "Abraq Khaytan",
      "Ad Dasmah",
      "Ad Dawhah",
      "Al Ahmadi",
      "Al Farwaniyah",
      "Al Shamiya",
      "Ar Rawdah",
      "As Salimiyah",
      "Ash Shu`aybah",
      "Ash Shuwaykh",
      "Bayan",
      "Hawalli",
      "Janub as Surrah",
      "Kayfan",
      "Kuwait City",
      "Salwa"
    ]
  },
  {
    "country": "Kyrgyzstan",
    "cities": [
      "Batken Oblasty",
      "Bishkek Shaary",
      "Chuy Oblasty",
      "Jalal-Abad Oblasty",
      "Naryn Oblasty",
      "Osh Oblasty",
      "Talas Oblasty",
      "Ysyk-Kol Oblasty"
    ]
  },
  {
    "country": "Laos",
    "cities": [
      "Attapu",
      "Bokeo",
      "Bolikhamxai",
      "Champasak",
      "Houaphan",
      "Khammouan",
      "Louangnamtha",
      "Louangphrabang",
      "Oudomxai",
      "Phongsali",
      "Salavan",
      "Savannakhet",
      "Viangchan",
      "Viangchan",
      "Xaignabouli",
      "Xaisomboun",
      "Xekong",
      "Xiangkhoang"
    ]
  },
  {
    "country": "Latvia",
    "cities": [
      "Adazi",
      "Agenskalns",
      "Aizkraukle",
      "Aizpute",
      "Baldone",
      "Balvi",
      "Bauska",
      "Brankas",
      "Carnikava",
      "Centrs",
      "Daugavpils",
      "Dobele",
      "Durbe",
      "Gulbene",
      "Ilguciems",
      "Izvalta",
      "Jaunolaine",
      "Jelgava",
      "Kandava",
      "Kolka",
      "Lapmezciems",
      "Liepa",
      "Ludza",
      "Madona",
      "Malpils",
      "Malta",
      "Marupe",
      "Mazsalaca",
      "Ogre",
      "Olaine",
      "Ozolnieki",
      "Pilsrundale",
      "Priedkalne",
      "Pure",
      "Riga",
      "Salaspils",
      "Saldus",
      "Sigulda",
      "Smiltene",
      "Stalgene",
      "Talsi",
      "Tukums",
      "Valka",
      "Valmiera",
      "Varsava",
      "Ventspils",
      "Zasa",
      "Zilupe"
    ]
  },
  {
    "country": "Lebanon",
    "cities": [
      "Aaley",
      "Adma",
      "Ashrafiye",
      "Baabda",
      "Baalbek",
      "Beirut",
      "Broummana",
      "Bsalim",
      "Chekka",
      "Dbaiye",
      "Dik el Mehdi",
      "Halba",
      "Hboub",
      "Sarba",
      "Sidon",
      "Tripoli",
      "Yanar",
      "Zgharta"
    ]
  },
  {
    "country": "Lesotho",
    "cities": [
      "Berea",
      "Butha-Buthe",
      "Leribe",
      "Mafeteng",
      "Maseru",
      "Mohale's Hoek",
      "Mokhotlong",
      "Qacha's Nek",
      "Quthing",
      "Thaba-Tseka"
    ]
  },
  {
    "country": "Liberia",
    "cities": [
      "Bomi",
      "Bong",
      "Gbarpolu",
      "Grand Bassa",
      "Grand Cape Mount",
      "Grand Gedeh",
      "Grand Kru",
      "Lofa",
      "Margibi",
      "Maryland",
      "Montserrado",
      "Nimba",
      "River Cess",
      "River Gee",
      "Sinoe"
    ]
  },
  {
    "country": "Libya",
    "cities": [
      "Benghazi",
      "Misratah",
      "Sabha",
      "Tripoli",
      "Zliten"
    ]
  },
  {
    "country": "Liechtenstein",
    "cities": [
      "Balzers",
      "Bendern",
      "Eschen",
      "Gamprin",
      "Mauren",
      "Nendeln",
      "Planken",
      "Ruggell",
      "Schaan",
      "Schaanwald",
      "Schellenberg",
      "Triesen",
      "Triesenberg",
      "Vaduz"
    ]
  },
  {
    "country": "Luxembourg",
    "cities": [
      "Ahn",
      "Alzingen",
      "Bascharage",
      "Beaufort",
      "Beckerich",
      "Beggen",
      "Beidweiler",
      "Belvaux",
      "Berchem",
      "Bereldange",
      "Bergem",
      "Bertrange",
      "Bettembourg",
      "Betzdorf",
      "Bigonville",
      "Bissen",
      "Bivange",
      "Bofferdange",
      "Bonnevoie",
      "Bourglinster",
      "Bridel",
      "Burmerange",
      "Canach",
      "Cap",
      "Capellen",
      "Colmar",
      "Contern",
      "Crauthem",
      "Dalheim",
      "Differdange",
      "Dippach",
      "Dudelange",
      "Echternach",
      "Ehnen",
      "Ernster",
      "Erpeldange-lez-Bous",
      "Esch-sur-Alzette",
      "Esch-sur-Sure",
      "Ettelbruck",
      "Foetz",
      "Frisange",
      "Godbrange",
      "Goetzingen",
      "Gonderange",
      "Gosseldange",
      "Gostingen",
      "Grevenknapp",
      "Grevenmacher",
      "Grosbous",
      "Hagen",
      "Hautcharage",
      "Heffingen",
      "Heisdorf-sur-Alzette",
      "Helmdange",
      "Helmsange",
      "Hersberg",
      "Hesperange",
      "Hobscheid",
      "Hollerich",
      "Holzem",
      "Huncherange",
      "Hunsdorf",
      "Itzig",
      "Junglinster",
      "Kayl",
      "Kehlen",
      "Kleinbettingen",
      "Koetschette",
      "Lamadelaine",
      "Larochette",
      "Leudelange",
      "Limpertsberg",
      "Lintgen",
      "Lorentzweiler",
      "Luxembourg",
      "Luxembourg-Kirchberg",
      "Mamer",
      "Medernach",
      "Merl",
      "Mersch",
      "Mertert",
      "Mondercange",
      "Mondorf-les-Bains",
      "Moutfort",
      "Niedercorn",
      "Noerdange",
      "Nospelt",
      "Oberanven",
      "Oetrange",
      "Olm",
      "Pontpierre",
      "Remerschen",
      "Remich",
      "Rodange",
      "Roeser",
      "Roodt-sur-Syre",
      "Rumelange",
      "Sandweiler",
      "Sanem",
      "Schieren",
      "Schifflange",
      "Schoos",
      "Schouweiler",
      "Schuttrange",
      "Soleuvre",
      "Steinfort",
      "Steinsel",
      "Strassen",
      "Syren",
      "Tetange",
      "Tuntange",
      "Uebersyren",
      "Walferdange",
      "Wasserbillig",
      "Wolpert",
      "Wormeldange"
    ]
  },
  {
    "country": "Macedonia",
    "cities": [
      "Berovo",
      "Bitola",
      "Bogdanci",
      "Cair",
      "Debar",
      "Delcevo",
      "Demir Hisar",
      "Gevgelija",
      "Gostivar",
      "Ilinden",
      "Kadino",
      "Kavadarci",
      "Kicevo",
      "Kumanovo",
      "Labunista",
      "Lisice",
      "Makedonska Kamenica",
      "Negotino",
      "Novo Selo",
      "Ohrid",
      "Prilep",
      "Pripor",
      "Probistip",
      "Radovis",
      "Shtip",
      "Skopje",
      "Struga",
      "Strumica",
      "Tetovo",
      "Veles"
    ]
  },
  {
    "country": "Madagascar",
    "cities": [
      "Ambanja",
      "Antananarivo",
      "Antsirabe",
      "Antsiranana",
      "Fianarantsoa",
      "Toamasina",
      "Toliara"
    ]
  },
  {
    "country": "Malawi",
    "cities": [
      "Balaka",
      "Blantyre",
      "Chikwawa",
      "Chiradzulu",
      "Chitipa",
      "Dedza",
      "Dowa",
      "Karonga",
      "Kasungu",
      "Likoma",
      "Lilongwe",
      "Machinga",
      "Mangochi",
      "Mchinji",
      "Mulanje",
      "Mwanza",
      "Mzimba",
      "Ntcheu",
      "Nkhata Bay",
      "Nkhotakota",
      "Nsanje",
      "Ntchisi",
      "Phalombe",
      "Rumphi",
      "Salima",
      "Thyolo",
      "Zomba"
    ]
  },
  {
    "country": "Malaysia",
    "cities": [
      "Kuala Lumpur",
      "George Town",
      "Ipoh",
      "Shah Alam",
      "Petaling Jaya",
      "Johor Bahru",
      "Kuching",
      "Kota Kinabalu",
      "Alor Setar",
      "Malacca City",
      "Miri",
      "Kuantan",
      "Seremban",
      "Kuala Terengganu",
      "Kangar",
      "Kuala Langat",
      "Tawau",
      "Sandakan",
      "Sibu",
      "Kota Bharu",
      "Taiping",
      "Bukit Mertajam",
      "Muar",
      "Bintulu",
      "Kulim",
      "Kluang",
      "Skudai",
      "Butterworth",
      "Selayang",
      "Sungai Petani",
      "Port Dickson",
      "Tanjung Tokong",
      "Perai",
      "Batu Pahat",
      "Kampar",
      "Rawang",
      "Lahad Datu",
      "Teluk Intan",
      "Pekan",
      "Temerloh",
      "Keningau",
      "Simpang Empat",
      "Segamat",
      "Semenyih",
      "Kuala Selangor",
      "Bandar Maharani",
      "Marang",
      "Sungai Buloh",
      "Banting",
      "Jitra",
      "Tanjung Sepat",
      "Batu Gajah",
      "Mentakab",
      "Pontian Kechil",
      "Tampin",
      "Raub",
      "Pasir Mas",
      "Kampong Pangkal Kalong",
      "Kampung Bukit Baharu",
      "Putrajaya",
      "Serendah",
      "Jenjarom",
      "Batu Berendam",
      "Bidur",
      "Tanjung Malim",
      "Simpang Renggam",
      "Kuah",
      "Tapah Road",
      "Labis",
      "Jasin",
      "Sungai Udang",
      "Papar",
      "Kapit",
      "Jertih",
      "Kudat",
      "Sarikei",
      "Tangkak",
      "Port Klang",
      "Kampung Ayer Keroh",
      "Victoria",
      "Kangar",
      "Kampong Dungun",
      "Pekan Nenas",
      "Kuala Pilah",
      "Kampung Simpang Renggam",
      "Parit Raja",
      "Putatan",
      "Kampung Baharu Nilai",
      "Jerantut",
      "Tampin",
      "Bentong Town",
      "Bakri",
      "Lumut",
      "Pasir Puteh",
      "Permatang Kuching"
    ]
  },
  {
    "country": "Maldives",
    "cities": [
      "Alifu",
      "Baa",
      "Dhaalu",
      "Faafu",
      "Gaafu Alifu",
      "Gaafu Dhaalu",
      "Gnaviyani",
      "Haa Alifu",
      "Haa Dhaalu",
      "Kaafu",
      "Laamu",
      "Lhaviyani",
      "Maale",
      "Meemu",
      "Noonu",
      "Raa",
      "Seenu",
      "Shaviyani",
      "Thaa",
      "Vaavu"
    ]
  },
  {
    "country": "Mali",
    "cities": [
      "Bamako (Capital)",
      "Gao",
      "Kayes",
      "Kidal",
      "Koulikoro",
      "Mopti",
      "Segou",
      "Sikasso",
      "Tombouctou"
    ]
  },
  {
    "country": "Malta",
    "cities": [
      "Attard",
      "Balzan",
      "Bingemma",
      "Birgu",
      "Birkirkara",
      "Bugibba",
      "Cospicua",
      "Dingli",
      "Fgura",
      "Floriana",
      "Fontana",
      "Ghajnsielem",
      "Gharb",
      "Ghasri",
      "Ghaxaq",
      "Gudja",
      "Gzira",
      "Hal Gharghur",
      "Hamrun",
      "Haz-Zebbug",
      "Il-Madliena",
      "Il-Pergla",
      "Imsida",
      "Imtarfa",
      "Iz-Zebbiegh",
      "Kirkop",
      "L-Iklin",
      "L-Iskorvit",
      "Lija",
      "Luqa",
      "Manikata",
      "Marsa",
      "Marsalforn",
      "Marsaskala",
      "Marsaxlokk",
      "Mdina",
      "Mellieha",
      "Mosta",
      "Mqabba",
      "Munxar",
      "Nadur",
      "Naxxar",
      "Paola",
      "Pembroke",
      "Qormi",
      "Qrendi",
      "Rabat",
      "Safi",
      "Saint John",
      "Saint Lawrence",
      "Saint Lucia",
      "Saint Venera",
      "San Pawl il-Bahar",
      "Sannat",
      "Senglea",
      "Siggiewi",
      "Sliema",
      "Swieqi",
      "Tarxien",
      "Valletta",
      "Victoria",
      "Xaghra",
      "Xewkija",
      "Zabbar",
      "Zejtun",
      "Zurrieq"
    ]
  },
  {
    "country": "Martinique",
    "cities": [
      "Case-Pilote",
      "Ducos",
      "Fort-de-France",
      "Le Carbet",
      "Le Diamant",
      "Le Francois",
      "Le Gros-Morne",
      "Le Lamentin",
      "Le Morne-Rouge",
      "Le Robert",
      "Les Trois-Ilets",
      "Riviere-Salee",
      "Saint-Esprit",
      "Saint-Joseph",
      "Sainte-Anne",
      "Sainte-Luce",
      "Sainte-Marie",
      "Schoelcher"
    ]
  },
  {
    "country": "Mauritania",
    "cities": [
      "Adrar",
      "Assaba",
      "Brakna",
      "Dakhlet Nouadhibou",
      "Gorgol",
      "Guidimaka",
      "Hodh Ech Chargui",
      "Hodh El Gharbi",
      "Inchiri",
      "Nouakchott",
      "Tagant",
      "Tiris Zemmour",
      "Trarza"
    ]
  },
  {
    "country": "Mauritius",
    "cities": [
      "Beau Bassin",
      "Chemin Grenier",
      "Ebene CyberCity",
      "Floreal",
      "Goodlands",
      "Le Reduit",
      "Port Louis",
      "Port Mathurin",
      "Quatre Bornes",
      "Rose Hill",
      "Saint Jean",
      "Tamarin",
      "Vacoas"
    ]
  },
  {
    "country": "Mayotte",
    "cities": [
      "Bandaboa",
      "Chiconi",
      "Combani",
      "Dzaoudzi",
      "Dzoumonye",
      "Koungou",
      "Mamoudzou",
      "Ouangani",
      "Pamandzi",
      "Sada",
      "Tsingoni",
      "Tsoundzou 1"
    ]
  },
  {
    "country": "Mexico",
    "cities": [
      "Mexico City",
      "Ecatepec",
      "Guadalajara",
      "Puebla",
      "Juarez",
      "Tijuana",
      "Leon",
      "Zapopan",
      "Monterrey",
      "Nezahualcoyotl",
      "Culiacan",
      "Chihuahua",
      "Naucalpan",
      "Merida",
      "San Luis Potosi",
      "Aguascalientes",
      "Hermosillo",
      "Saltillo",
      "Mexicali",
      "Cancun",
      "Guadalupe",
      "Toluca",
      "Acapulco",
      "Tlalnepantla",
      "Coyoacan",
      "Victoria de Durango",
      "Playa del Carmen",
      "Santa Maria Chimalhuacan",
      "Torreon",
      "Durango",
      "Tlaquepaque",
      "Tuxtla Gutierrez",
      "Reynosa",
      "Tlaquepaque",
      "Cuautitlan Izcalli",
      "Ciudad Lopez Mateos",
      "San Nicolas de los Garza",
      "Tlajomulco de Zuniga",
      "Xalapa",
      "Tlalpan",
      "Celaya",
      "Cuernavaca",
      "Villahermosa",
      "La Paz",
      "Oaxaca",
      "Soledad de Graciano Sanchez",
      "Nuevo Laredo",
      "Irapuato",
      "Apodaca",
      "Nicolas Romero",
      "Ensenada",
      "Tecamac",
      "Heroica Matamoros",
      "Chalco",
      "Coacalco",
      "Colima",
      "Huixquilucan",
      "Pachuca",
      "Tonalá",
      "Mazatlan",
      "Puerto Vallarta",
      "Chimalhuacan",
      "Los Reyes",
      "La Paz",
      "Ixtapaluca",
      "El Marques",
      "Lazaro Cardenas",
      "Tampico",
      "Melchor Ocampo",
      "Benito Juarez",
      "Matamoros",
      "San Pedro Garza Garcia",
      "Xico",
      "Navojoa",
      "Minatitlan",
      "Cuautla",
      "Chetumal",
      "Huimanguillo",
      "Los Mochis",
      "Zamora",
      "Jiutepec",
      "Piedras Negras",
      "San Juan del Rio",
      "Teziutlan",
      "La Piedad",
      "Chalco de Diaz Covarrubias",
      "Gomez Palacio",
      "Nogales",
      "San Pablo de las Salinas",
      "San Cristobal de las Casas",
      "Comalcalco",
      "Zacatecas"
    ]
  },
  {
    "country": "Moldova",
    "cities": [
      "Anenii Noi",
      "Briceni",
      "Cahul",
      "Calarasi",
      "Ciorescu",
      "Cojusna",
      "Comrat",
      "Drochia",
      "Durlesti",
      "Ialoveni",
      "Magdacesti",
      "Nisporeni",
      "Orhei",
      "Soroca",
      "Straseni",
      "Tighina",
      "Tiraspol",
      "Voinescu",
      "Vulcanesti"
    ]
  },
  {
    "country": "Mongolia",
    "cities": [
      "Altai",
      "Arvayheer",
      "Baruun-Urt",
      "Bayangol",
      "Bayanhongor",
      "Cecerleg",
      "Chihertey",
      "Choyr",
      "Dalandzadgad",
      "Darhan",
      "Han-Uul",
      "Javhlant",
      "Khovd",
      "Mandalgovi",
      "Ovoot",
      "Saynshand",
      "Toyrim",
      "Ulaan-Uul",
      "Ulan Bator",
      "Uliastay",
      "Undurkhaan"
    ]
  },
  {
    "country": "Montenegro",
    "cities": [
      "Budva",
      "Crna Gora",
      "Herceg Novi",
      "Igalo",
      "Kotor",
      "Niksic",
      "Pljevlja",
      "Podgorica",
      "Stari Bar",
      "Ulcinj"
    ]
  },
  {
    "country": "Morocco",
    "cities": [
      "Afourer",
      "Agadir",
      "Ait Melloul",
      "Al Hoceima",
      "Assa",
      "Benguerir",
      "Beni Mellal",
      "Berrechid",
      "Casablanca",
      "Deroua",
      "El Gara",
      "El Hajeb",
      "El Jadida",
      "Erfoud",
      "Fes",
      "Fkih Ben Salah",
      "Kenitra",
      "Khemisset",
      "Khouribga",
      "Ksar el Kebir",
      "Larache",
      "Mansour",
      "Marrakesh",
      "Mehediyah",
      "Meknes",
      "Mohammedia",
      "Nador",
      "Ouazzane",
      "Oued Zem",
      "Oujda",
      "Oulad Teima",
      "Rabat",
      "Safi",
      "Sefrou",
      "Settat",
      "Sidi Bennour",
      "Sidi Slimane",
      "Skhirat",
      "Tahala",
      "Tan-Tan",
      "Tangier",
      "Tarfaya",
      "Taza",
      "Temara",
      "Tiflet",
      "Tiznit",
      "Touissite"
    ]
  },
  {
    "country": "Mozambique",
    "cities": [
      "Beira",
      "Maputo",
      "Matola",
      "Mozambique",
      "Nampula",
      "Pemba",
      "Quelimane",
      "Tete"
    ]
  },
  {
    "country": "Myanmar [Burma]",
    "cities": [
      "Hlaing",
      "Inya",
      "Kyauktada",
      "Mandalay",
      "Wagan",
      "Yangon"
    ]
  },
  {
    "country": "Namibia",
    "cities": [
      "Etunda",
      "Grootfontein",
      "Katima Mulilo",
      "Keetmanshoop",
      "Mpapuka",
      "Olympia",
      "Ondangwa",
      "Ongwediva",
      "Oranjemund",
      "Oshakati",
      "Otjiwarongo",
      "Outapi",
      "Swakopmund",
      "Tsumeb",
      "Walvis Bay",
      "Windhoek"
    ]
  },
  {
    "country": "Nepal",
    "cities": [
      "Bharatpur",
      "Jawlakhel",
      "Kathmandu",
      "Lumbini",
      "Palpa",
      "Patan"
    ]
  },
  {
    "country": "Netherlands",
    "cities": [
      "Amsterdam",
      "Rotterdam",
      "The Hague",
      "Utrecht",
      "Eindhoven",
      "Tilburg",
      "Groningen",
      "Almere Stad",
      "Breda",
      "Nijmegen",
      "Enschede",
      "Haarlem",
      "Arnhem",
      "Zaanstad",
      "Amersfoort",
      "Apeldoorn",
      "'s-Hertogenbosch",
      "Hoofddorp",
      "Maastricht",
      "Leiden",
      "Dordrecht",
      "Zoetermeer",
      "Zwolle",
      "Deventer",
      "Delft",
      "Alkmaar",
      "Heerlen",
      "Venlo",
      "Leeuwarden",
      "Amsterdam-Zuidoost",
      "Hilversum",
      "Hengelo",
      "Amstelveen",
      "Roosendaal",
      "Purmerend",
      "Oss",
      "Schiedam",
      "Spijkenisse",
      "Emmen",
      "Alphen aan den Rijn",
      "Gouda",
      "Zaandam",
      "Lelystad",
      "Almelo",
      "Harderwijk",
      "Venray",
      "Hoorn",
      "Velsen-Zuid",
      "Sittard",
      "Zeist",
      "Geleen",
      "Bergen op Zoom",
      "Capelle aan den IJssel",
      "Assen",
      "Nieuwegein",
      "Veenendaal",
      "Katwijk",
      "Leidschendam",
      "Middelburg",
      "Drachten",
      "Heerhugowaard",
      "Soest",
      "Roermond"
    ]
  },
  {
    "country": "New Caledonia",
    "cities": [
      "Dumbea",
      "Mont-Dore",
      "Noumea",
      "Nouville",
      "Paita"
    ]
  },
  {
    "country": "New Zealand",
    "cities": [
      "Auckland",
      "Wellington",
      "Christchurch",
      "Hamilton",
      "Tauranga",
      "Lower Hutt",
      "Dunedin",
      "Palmerston North",
      "Napier",
      "Hastings",
      "Nelson",
      "Upper Hutt",
      "Gisborne",
      "New Plymouth",
      "Rotorua",
      "Whangarei",
      "Invercargill",
      "Wanganui",
      "Kapiti",
      "Masterton",
      "Levin",
      "Tokoroa",
      "Ashburton",
      "Timaru",
      "Pukekohe",
      "Taupo",
      "Waiuku",
      "Carterton",
      "Dannevirke",
      "Waihi",
      "Motueka",
      "Thames",
      "Kawerau",
      "Waitara",
      "Whitianga",
      "Stratford",
      "Te Puke",
      "Temuka",
      "Wairoa",
      "Kaikohe",
      "Otorohanga",
      "Morrinsville",
      "Te Aroha",
      "Putaruru",
      "Marton",
      "Opotiki",
      "Warkworth",
      "Huntly",
      "Foxton",
      "Waipukurau",
      "Raglan",
      "Paihia",
      "Wairoa",
      "Kaitaia",
      "Cheviot",
      "Winton",
      "Feilding",
      "Blackball",
      "Te Anau",
      "Waverley",
      "Murupara",
      "Methven",
      "Greytown",
      "Rangiora",
      "Kumara",
      "Picton",
      "Snells Beach",
      "Darfield",
      "Winton",
      "Edgecumbe",
      "Port Chalmers",
      "Ranfurly",
      "Kaponga",
      "Dargaville",
      "Opunake",
      "Te Kuiti",
      "Dobson",
      "Runanga",
      "Kingston",
      "Waimate",
      "Kaikoura",
      "Whangamata",
      "Moerewa",
      "Oxford",
      "Kaiapoi",
      "Pleasant Point",
      "Whangamomona",
      "Woodend",
      "Marton",
      "Waiouru",
      "Ngunguru",
      "Hakataramea",
      "Maketu",
      "Hawera"
    ]
  },
  {
    "country": "Nicaragua",
    "cities": [
      "Bluefields",
      "Chinandega",
      "El Panama",
      "Esteli",
      "Granada",
      "Jinotega",
      "Los Arados",
      "Managua",
      "Masaya",
      "Matagalpa",
      "Ocotal",
      "Rivas",
      "San Juan del Sur"
    ]
  },
  {
    "country": "Nigeria",
    "cities": [
      "Aba",
      "Abakaliki",
      "Abeokuta",
      "Abraka",
      "Abraka",
      "Abuja",
      "Ado-Ekiti",
      "Adodo",
      "Aganga",
      "Agege",
      "Agidingbi",
      "Ajegunle",
      "Ajuwon",
      "Akure",
      "Alimosho",
      "Anambra",
      "Apapa",
      "Ayobo",
      "Benin City",
      "Birnin Kebbi",
      "Bonny",
      "Burutu",
      "Bwari",
      "Calabar",
      "Chafe",
      "Damaturu",
      "Egbeda",
      "Ekpoma",
      "Enugu",
      "Forum",
      "Funtua",
      "Ibadan",
      "Ido",
      "Ifako",
      "Igando",
      "Igueben",
      "Ikeja",
      "Ikorodu",
      "Ikotun",
      "Ile-Ife",
      "Ilesa",
      "Ilorin",
      "Ipaja",
      "Iseri-Oke",
      "Isolo",
      "Jalingo",
      "Jos",
      "Kaduna",
      "Kano",
      "Kebbi",
      "Lagos",
      "Lekki",
      "Lokoja",
      "Magodo",
      "Makurdi",
      "Maryland",
      "Minna",
      "Mogho",
      "Mowe",
      "Mushin",
      "Nsukka",
      "Obafemi",
      "Obudu",
      "Odau",
      "Ojo",
      "Ojota",
      "Ondo",
      "Onigbongbo",
      "Orile Oshodi",
      "Oshodi",
      "Osogbo",
      "Ota",
      "Owerri",
      "Oworonsoki",
      "Port Harcourt",
      "Shomolu",
      "Suleja",
      "Suru-Lere",
      "Tara",
      "Ughelli",
      "Ungwan Madaki",
      "Uyo",
      "Warri",
      "Warri",
      "Yaba",
      "Yola",
      "Zaria"
    ]
  },
  {
    "country": "Norway",
    "cities": [
      "Oslo",
      "Bergen",
      "Stavanger",
      "Trondheim",
      "Drammen",
      "Fredrikstad",
      "Skien",
      "Sandnes",
      "Kristiansand",
      "Ålesund",
      "Tønsberg",
      "Moss",
      "Haugesund",
      "Arendal",
      "Sandefjord",
      "Bodø",
      "Tromsø",
      "Hamar",
      "Halden",
      "Larvik",
      "Askøy",
      "Kongsberg",
      "Harstad",
      "Molde",
      "Narvik",
      "Leirvik",
      "Lillehammer",
      "Gjøvik",
      "Sarpsborg",
      "Lillestrøm",
      "Horten",
      "Grimstad",
      "Mandal",
      "Elverum",
      "Råholt",
      "Jessheim",
      "Nesoddtangen",
      "Mysen",
      "Mo i Rana",
      "Hønefoss",
      "Vennesla",
      "Lørenskog",
      "Bryne",
      "Vestby",
      "Ski",
      "Førde",
      "Kopervik",
      "Drøbak",
      "Verdal",
      "Flekkefjord",
      "Korsør",
      "Kragerø",
      "Steinkjer",
      "Porsgrunn",
      "Moss",
      "Halden",
      "Gjøvik",
      "Sarpsborg",
      "Lillestrøm",
      "Horten",
      "Grimstad",
      "Mandal",
      "Elverum",
      "Råholt",
      "Jessheim",
      "Nesoddtangen",
      "Mysen",
      "Mo i Rana",
      "Hønefoss",
      "Vennesla",
      "Lørenskog",
      "Bryne",
      "Vestby",
      "Ski",
      "Førde",
      "Kopervik",
      "Drøbak",
      "Verdal",
      "Flekkefjord",
      "Korsør",
      "Kragerø",
      "Steinkjer",
      "Porsgrunn",
      "Haugesund",
      "Arendal",
      "Sandefjord",
      "Bodø",
      "Tromsø",
      "Hamar",
      "Halden",
      "Larvik",
      "Askøy",
      "Kongsberg",
      "Harstad",
      "Molde",
      "Narvik",
      "Leirvik",
      "Lillehammer",
      "Gjøvik",
      "Sarpsborg",
      "Lillestrøm",
      "Horten",
      "Grimstad"
    ]
  },
  {
    "country": "Oman",
    "cities": [
      "Al Sohar",
      "Muscat",
      "Nizwa",
      "Ruwi",
      "Saham",
      "Salalah",
      "Samad"
    ]
  },
  {
    "country": "Pakistan",
    "cities": [
      "Al Mawasi",
      "Bethlehem",
      "Gaza",
      "Hebron",
      "Jenin",
      "Jericho",
      "Nablus",
      "Ramallah"
    ]
  },
  {
    "country": "Panama",
    "cities": [
      "Aguadulce",
      "Albrook",
      "Ancon",
      "Arosemena",
      "Arraijan",
      "Balboa",
      "Bella Vista",
      "Bocas del Toro",
      "Boquete",
      "Bugaba",
      "Calidonia",
      "Campo Alegre",
      "Cerro Viento",
      "Chigore",
      "Chiriqui",
      "Cocle",
      "Corozal",
      "Cristobal",
      "Curundame",
      "Curundu",
      "David",
      "El Arado",
      "El Cangrejo",
      "El Lago",
      "Elena",
      "Entre Rios",
      "Finca Blanco Numero Uno",
      "Fuerte Amador",
      "Guadalupe",
      "Jimenez",
      "Juan Diaz",
      "Juan Diaz",
      "Juan Franco",
      "Kuba",
      "La Chorrera",
      "La Exposicion",
      "La Loma",
      "Las Mercedes",
      "Las Sabanas",
      "Las Tablas",
      "Llano Tugri",
      "Los Angeles",
      "Muerto",
      "Mulatupo",
      "Paitilla",
      "Panama City",
      "Parque Lefevre",
      "Peru",
      "Playa Blanca",
      "Plaza",
      "Portobelo",
      "Pueblo Nuevo",
      "Quebrada de Camilo",
      "Rio Abajo",
      "Sabalo",
      "Sacramento",
      "San Cristobal",
      "San Felipe",
      "San Francisco",
      "San Miguelito",
      "Santa Ana",
      "Santa Catalina",
      "Santa Clara",
      "Santa Isabel",
      "Santiago",
      "Santo Domingo",
      "Tocumen",
      "Torre",
      "Torres Bluff",
      "Veraguas",
      "Victoria",
      "Vista Alegre",
      "Vista Hermosa"
    ]
  },
  {
    "country": "Papua New Guinea",
    "cities": [
      "Aitape",
      "Arawa",
      "Daru",
      "Goroka",
      "Kavieng",
      "Kerema",
      "Kikori",
      "Kimbe",
      "Kiunga",
      "Kokopo",
      "Kundiawa",
      "Kupano",
      "Lae",
      "Lorengau",
      "Madang",
      "Mendi",
      "Mount Hagen",
      "Namatanai",
      "Nambaga",
      "Paivara",
      "Pongani",
      "Popondetta",
      "Port Moresby",
      "Vanimo",
      "Wabag",
      "Waigani",
      "Wewak"
    ]
  },
  {
    "country": "Paraguay",
    "cities": [
      "Ayolas",
      "Boqueron",
      "Chore",
      "Ciudad del Este",
      "Colonia Mariano Roque Alonso",
      "Coronel Oviedo",
      "Fernando de la Mora",
      "Fuerte Olimpo",
      "Hernandarias",
      "Hohenau",
      "Independencia",
      "La Paz",
      "Limpio",
      "Loma Plata",
      "Luque",
      "Nemby",
      "Presidente Franco",
      "Salto del Guaira",
      "San Alberto",
      "San Juan Bautista",
      "San Lorenzo",
      "Santa Rita",
      "Santa Rosa",
      "Villa Elisa",
      "Villa Hayes",
      "Villarrica",
      "Yataity"
    ]
  },
  {
    "country": "Peru",
    "cities": [
      "Abancay",
      "Arequipa",
      "Ate",
      "Ayacucho",
      "Bagua",
      "Barranca",
      "Barranco",
      "Bellavista",
      "Bolivar",
      "Cajamarca",
      "Callao",
      "Calle",
      "Caras",
      "Cerro de Pasco",
      "Chachapoyas",
      "Chiclayo",
      "Chimbote",
      "Chincha",
      "Cusco",
      "Cuzco",
      "El Agustino",
      "Hacienda La Palma",
      "Huacho",
      "Huancavelica",
      "Huancayo",
      "Huaral",
      "Huaraz",
      "Huaytara",
      "Ica",
      "Ilo",
      "Imperial",
      "Iquitos",
      "Jaen",
      "Jesus Maria",
      "Jose Olaya",
      "Juliaca",
      "Junin",
      "La Molina",
      "La Perla",
      "La Punta",
      "Lambayeque",
      "Lima",
      "Lince",
      "Loreto",
      "Lurigancho",
      "Machu Picchu",
      "Maldonado",
      "Minas de Marcona",
      "Miraflores",
      "Moquegua",
      "Moyobamba",
      "Pasco",
      "Paucarpata",
      "Pimentel",
      "Pisco",
      "Piura",
      "Progreso",
      "Pucallpa",
      "Puerto Inca",
      "Puerto Maldonado",
      "Puno",
      "Rimac",
      "Rimac",
      "Rioja",
      "San Borja",
      "San Isidro",
      "San Isidro",
      "San Juan Bautista",
      "San Martin",
      "San Martin",
      "San Miguel",
      "Santa",
      "Santiago",
      "Santiago De Surco",
      "Sullana",
      "Surco",
      "Surquillo",
      "Tacna",
      "Talara",
      "Tarapoto",
      "Tingo Maria",
      "Trujillo",
      "Tumbes",
      "Ventanilla",
      "Victoria"
    ]
  },
  {
    "country": "Philippines",
    "cities": [
      "Quezon City",
      "Manila",
      "Caloocan",
      "Davao City",
      "Cebu City",
      "Zamboanga City",
      "Taguig",
      "Antipolo",
      "Pasig",
      "Cagayan de Oro",
      "Parañaque",
      "Tagaytay",
      "Pasay",
      "Makati",
      "Bacolod",
      "Mandaluyong",
      "General Santos",
      "Iloilo City",
      "Bacoor",
      "Muntinlupa",
      "Baguio",
      "San Juan",
      "Dasmariñas",
      "Bacoor",
      "Lipa",
      "Tacloban",
      "Cabanatuan",
      "Malolos",
      "Santa Rosa",
      "Imus",
      "Angeles",
      "Legazpi",
      "Cotabato City",
      "Olongapo",
      "Ormoc",
      "Puerto Princesa",
      "Valenzuela",
      "Butuan",
      "Santiago",
      "Iligan",
      "San Pablo",
      "Cotabato",
      "Lapu-Lapu",
      "Lucena",
      "Calamba",
      "Batangas City",
      "Tarlac City",
      "Laoag",
      "Kidapawan",
      "Alaminos",
      "Koronadal",
      "Tuguegarao",
      "Toledo",
      "Bago",
      "Mabalacat",
      "Tabaco",
      "Naga",
      "Marawi",
      "San Fernando",
      "Bislig",
      "Bayawan",
      "Dipolog",
      "Gingoog",
      "Batac",
      "Silay",
      "Talisay",
      "Canlaon",
      "Calbayog",
      "Malaybalay",
      "Sorsogon City",
      "Surigao City",
      "Oroquieta",
      "Cabadbaran",
      "Sarangani",
      "Bayugan",
      "Tandag",
      "Bais",
      "Surallah",
      "Cauayan",
      "Baler",
      "Libmanan",
      "Bongao",
      "Marawi",
      "Bongabon",
      "Solana",
      "Calapan",
      "Tabuk",
      "Bislig",
      "Taytay",
      "Borongan",
      "Sorsogon City",
      "La Carlota"
    ]
  },
  {
    "country": "Poland",
    "cities": [
      "Warsaw",
      "Krakow",
      "Lodz",
      "Wroclaw",
      "Poznan",
      "Gdansk",
      "Szczecin",
      "Bydgoszcz",
      "Lublin",
      "Katowice",
      "Bialystok",
      "Gdynia",
      "Czestochowa",
      "Sosnowiec",
      "Radom",
      "Kielce",
      "Gliwice",
      "Torun",
      "Bytom",
      "Zabrze",
      "Bielsko-Biala",
      "Olsztyn",
      "Rzeszow",
      "Ruda Slaska",
      "Rybnik",
      "Tychy",
      "Dabrowa Gornicza",
      "Plock",
      "Elblag",
      "Opole",
      "Gorzow Wielkopolski",
      "Wloclawek",
      "Chorzow",
      "Tarnow",
      "Koszalin",
      "Kalisz",
      "Legnica",
      "Grudziadz",
      "Slupsk",
      "Jaworzno",
      "Jastrzebie-Zdroj",
      "Nowy Sacz",
      "Jelenia Gora",
      "Siedlce",
      "Myslowice",
      "Konin",
      "Piotrkow Trybunalski",
      "Inowroclaw",
      "Lubin",
      "Ostrowiec Swietokrzyski",
      "Suwalki",
      "Pabianice",
      "Gniezno",
      "Stargard Szczecinski",
      "Siemianowice Slaskie",
      "Leszno",
      "Zamosc",
      "Mielec",
      "Tomaszow Mazowiecki",
      "Swidnica",
      "Zory",
      "Kedzierzyn-Kozle",
      "Lomza",
      "Tarnowskie Gory",
      "Wejherowo",
      "Starachowice",
      "Glogow",
      "Jaroslaw",
      "Ilawa",
      "Swietochlowice",
      "Ostroleka",
      "Krosno",
      "Ełk",
      "Przemysl",
      "Siemiatycze",
      "Nowa Sol",
      "Otwock",
      "Zlotow",
      "Swiebodzin",
      "Swinoujscie",
      "Tarnobrzeg",
      "Skierniewice",
      "Koscierzyna",
      "Leczna",
      "Zagan",
      "Sieradz",
      "Miedzyrzec Podlaski",
      "Gostynin",
      "Wodzislaw Slaski",
      "Ostrow Wielkopolski",
      "Bielawa",
      "Myszkow",
      "Zawiercie",
      "Ciechanow",
      "Skarzysko-Kamienna",
      "Wloclawek",
      "Krotoszyn",
      "Leczna"
    ]
  },
  {
    "country": "Portugal",
    "cities": [
      "Lisbon",
      "Porto",
      "Vila Nova de Gaia",
      "Amadora",
      "Braga",
      "Setubal",
      "Coimbra",
      "Queluz",
      "Funchal",
      "Cacem",
      "Vila Nova de Famalicao",
      "Aveiro",
      "Barreiro",
      "Rio de Mouro",
      "Odivelas",
      "Viseu",
      "Leiria",
      "Oeiras",
      "Guimaraes",
      "Santarem",
      "Evora",
      "Rio Tinto",
      "Matosinhos",
      "Cascais",
      "Maia",
      "Almada",
      "Castelo Branco",
      "Alcabideche",
      "Amora",
      "Ponta Delgada",
      "Portimao",
      "Faro",
      "Seixal",
      "Ermesinde",
      "Guarda",
      "Aguas Santas",
      "Povoa de Varzim",
      "Santa Maria da Feira",
      "Loulé",
      "Covilha",
      "Montijo",
      "Charneca",
      "Faro",
      "Custoias",
      "Arrentela",
      "Sao Joao da Madeira",
      "Sacavem",
      "Olhao",
      "Marinha Grande",
      "Ovar",
      "Vila do Conde",
      "Valongo",
      "Moncao",
      "Valbom",
      "Vilar de Andorinho",
      "Caparica",
      "Belas",
      "Linda-a-Velha",
      "Canidelo",
      "Gondomar",
      "Laranjeiro",
      "Parede",
      "Peniche",
      "Moita",
      "Caldas da Rainha",
      "Vila Franca de Xira",
      "Sao Domingos de Rana",
      "Albufeira",
      "Sequeira",
      "Esmoriz",
      "Carcavelos",
      "Figueira da Foz",
      "Vila Real",
      "Quarteira",
      "Tomar",
      "Torres Vedras",
      "Fafe",
      "Oliva",
      "Famoes",
      "Feira",
      "Baguim do Monte",
      "Santarém",
      "Povoa de Santa Iria",
      "Algueirao",
      "Portalegre",
      "Leca do Bailio",
      "Canico",
      "Leca da Palmeira"
    ]
  },
  {
    "country": "Puerto Rico",
    "cities": [
      "Adjuntas",
      "Aguada",
      "Aguadilla",
      "Aguas Buenas",
      "Aibonito",
      "Anasco",
      "Arecibo",
      "Arroyo",
      "Bajadero",
      "Barceloneta",
      "Barranquitas",
      "Boqueron",
      "Cabo Rojo",
      "Caguas",
      "Camuy",
      "Canovanas",
      "Carolina",
      "Catano",
      "Cayey",
      "Ceiba",
      "Ciales",
      "Cidra",
      "Coamo",
      "Corozal",
      "Coto Laurel",
      "Culebra",
      "Dorado",
      "Ensenada",
      "Fajardo",
      "Florida",
      "Garrochales",
      "Guanica",
      "Guayama",
      "Guayanilla",
      "Guaynabo",
      "Gurabo",
      "Hatillo",
      "Hormigueros",
      "Humacao",
      "Isabela",
      "Jayuya",
      "Juana Diaz",
      "Juncos",
      "Lajas",
      "Lares",
      "Las Piedras",
      "Loiza",
      "Luquillo",
      "Manati",
      "Maricao",
      "Maunabo",
      "Mayaguez",
      "Mercedita",
      "Moca",
      "Morovis",
      "Naguabo",
      "Naranjito",
      "Orocovis",
      "Palmer",
      "Patillas",
      "Penuelas",
      "Ponce",
      "Punta Santiago",
      "Quebradillas",
      "Residencial Puerto Real",
      "Rincon",
      "Rio Grande",
      "Sabana Grande",
      "Sabana Seca",
      "Saint Just",
      "Salinas",
      "San Antonio",
      "San German",
      "San Juan",
      "San Lorenzo",
      "San Sebastian",
      "Santa Isabel",
      "Toa Alta",
      "Toa Baja",
      "Trujillo Alto",
      "Utuado",
      "Vega Alta",
      "Vega Baja",
      "Vieques",
      "Villalba",
      "Yabucoa",
      "Yauco"
    ]
  },
  {
    "country": "Korea",
    "cities": [
      "Andong",
      "Ansan-si",
      "Anseong",
      "Anyang-si",
      "Asan",
      "Bucheon-si",
      "Busan",
      "Changwon",
      "Cheonan",
      "Cheongju-si",
      "Chilgok",
      "Chuncheon",
      "Chungju",
      "Chungnim",
      "Daegu",
      "Daejeon",
      "Deokjin",
      "Duchon",
      "Gangneung",
      "Gimhae",
      "Gongju",
      "Gumi",
      "Gunnae",
      "Gwangju",
      "Gwangmyeong",
      "Gyeongju",
      "Gyeongsan-si",
      "Hadong",
      "Haesan",
      "Haeundae",
      "Hanam",
      "Hansol",
      "Hyangyang",
      "Hyoja-dong",
      "Icheon-si",
      "Iksan",
      "Ilsan-dong",
      "Incheon",
      "Janghowon",
      "Jecheon",
      "Jeju-si",
      "Jeonju",
      "Jinju",
      "Ka-ri",
      "Kimso",
      "Kisa",
      "Koyang-dong",
      "Kwanghui-dong",
      "Mapo-dong",
      "Miryang",
      "Moppo",
      "Nae-ri",
      "Naju",
      "Namhyang-dong",
      "Namyang",
      "Namyangju",
      "Nonsan",
      "Okpo-dong",
      "Osan",
      "Paju",
      "Pohang",
      "Pon-dong",
      "Pyong-gol",
      "Samjung-ni",
      "Samsung",
      "Sangdo-dong",
      "Sasang",
      "Se-ri",
      "Seocho",
      "Seong-dong",
      "Seongnam-si",
      "Seosan City",
      "Seoul",
      "Songam",
      "Songang",
      "Songjeong",
      "Songnim",
      "Suncheon",
      "Suwon-si",
      "Taebuk",
      "Tang-ni",
      "Tongan",
      "Uiwang",
      "Ulchin",
      "Ulsan",
      "Unpo",
      "Wonju",
      "Wonmi-gu",
      "Yangsan",
      "Yeoju",
      "Yeosu",
      "Yongin",
      "Yongsan-dong"
    ]
  },
  {
    "country": "Lithuania",
    "cities": [
      "Alytus",
      "Anciskiai",
      "Antakalnis",
      "Garliava",
      "Ignalina",
      "Jonava",
      "Jurbarkas",
      "Juskonys",
      "Kaunas",
      "Kretinga",
      "Mastaiciai",
      "Palanga",
      "Panevezys",
      "Sakiai",
      "Salcininkai",
      "Trakai",
      "Ukmerge",
      "Uzliedziai",
      "Venta",
      "Vievis",
      "Vilniaus Apskritis",
      "Vilnius",
      "Visaginas"
    ]
  },
  {
    "country": "Qatar",
    "cities": [
      "Ad Dawhah",
      "Al Ghuwayriyah",
      "Al Jumayliyah",
      "Al Khawr",
      "Al Wakrah",
      "Ar Rayyan",
      "Jarayan al Batinah",
      "Madinat ash Shamal",
      "Umm Sa'id",
      "Umm Salal"
    ]
  },
  {
    "country": "Romania",
    "cities": [
      "Bucharest",
      "Cluj-Napoca",
      "Timisoara",
      "Iasi",
      "Constanta",
      "Craiova",
      "Brasov",
      "Galati",
      "Ploiesti",
      "Oradea",
      "Braila",
      "Arad",
      "Pitesti",
      "Sibiu",
      "Bacau",
      "Targu Mures",
      "Baia Mare",
      "Buzau",
      "Botosani",
      "Satu Mare",
      "Ramnicu Valcea",
      "Suceava",
      "Piatra Neamt",
      "Drobeta-Turnu Severin",
      "Targu Jiu",
      "Tulcea",
      "Targoviste",
      "Focsani",
      "Bistrita",
      "Resita",
      "Deva",
      "Slatina",
      "Alba Iulia",
      "Giurgiu",
      "Roman",
      "Barlad",
      "Sfântu Gheorghe",
      "Pascani",
      "Miercurea-Ciuc",
      "Medias",
      "Vaslui",
      "Rosiorii de Vede",
      "Turnu Magurele",
      "Lugoj",
      "Caracal",
      "Mangalia",
      "Dej",
      "Navodari",
      "Odorheiu Secuiesc",
      "Reghin",
      "Sighisoara",
      "Borsa",
      "Voluntari",
      "Fetesti",
      "Curtea de Arges",
      "Dorohoi",
      "Ludus",
      "Râmnicu Sărat",
      "Tecuci",
      "Zalau",
      "Gherla",
      "Aiud",
      "Vulcan",
      "Petrosani",
      "Moreni",
      "Băicoi",
      "Cugir",
      "Făgăraș",
      "Blaj",
      "Sighetu Marmatiei",
      "Mioveni",
      "Motru",
      "Campia Turzii",
      "Hunedoara",
      "Săcele",
      "Panciu",
      "Avrig",
      "Brezoi",
      "Câmpina",
      "Buftea",
      "Simeria",
      "Baia Sprie",
      "Rădăuți",
      "Zărnești",
      "Toplița",
      "Rasnov",
      "Victoria",
      "Cisnadie",
      "Hârlău",
      "Câmpulung Moldovenesc",
      "Târgu Neamț",
      "Petrila",
      "Uricani"
    ]
  },
  {
    "country": "Russia",
    "cities": [
      "Moscow",
      "Saint Petersburg",
      "Novosibirsk",
      "Yekaterinburg",
      "Nizhny Novgorod",
      "Kazan",
      "Chelyabinsk",
      "Omsk",
      "Samara",
      "Rostov-on-Don",
      "Ufa",
      "Krasnoyarsk",
      "Perm",
      "Voronezh",
      "Volgograd",
      "Saratov",
      "Krasnodar",
      "Tolyatti",
      "Izhevsk",
      "Barnaul",
      "Ulyanovsk",
      "Vladivostok",
      "Yaroslavl",
      "Irkutsk",
      "Tyumen",
      "Khabarovsk",
      "Makhachkala",
      "Orenburg",
      "Novokuznetsk",
      "Kemerovo",
      "Ryazan",
      "Tomsk",
      "Astrakhan",
      "Penza",
      "Kirov",
      "Lipetsk",
      "Cheboksary",
      "Tula",
      "Kaliningrad",
      "Bryansk",
      "Ivanovo",
      "Magnitogorsk",
      "Kursk",
      "Nizhny Tagil",
      "Stavropol",
      "Ulan-Ude",
      "Tver",
      "Sochi",
      "Izhevsk",
      "Tobolsk",
      "Vladikavkaz",
      "Kostroma",
      "Volzhsky",
      "Taganrog",
      "Novorossiysk",
      "Syktyvkar",
      "Nalchik",
      "Kurgan",
      "Khimki",
      "Shakhty",
      "Cherepovets",
      "Bratsk",
      "Miass",
      "Serpukhov",
      "Yuzhno-Sakhalinsk",
      "Engels",
      "Yoshkar-Ola",
      "Podolsk",
      "Tula",
      "Votkinsk",
      "Novocherkassk",
      "Balashikha",
      "Severodvinsk",
      "Zelenograd",
      "Korolev",
      "Armavir",
      "Lyubertsy",
      "Prokopyevsk",
      "Abakan",
      "Kolomna",
      "Noginsk",
      "Dzerzhinsk",
      "Orekhovo-Zuyevo",
      "Nizhnekamsk",
      "Oktyabrsky",
      "Arzamas",
      "Khimki",
      "Rubtsovsk",
      "Mytishchi",
      "Kamensk-Uralsky",
      "Novomoskovsk",
      "Zheleznogorsk"
    ]
  },
  {
    "country": "Rwanda",
    "cities": [
      "Butare",
      "Byumba",
      "Cyangugu",
      "Gikongoro",
      "Gisenyi",
      "Gitarama",
      "Kibungo",
      "Kibuye",
      "Kigali Rurale",
      "Kigali-ville",
      "Umutara",
      "Ruhengeri"
    ]
  },
  {
    "country": "Samoa",
    "cities": [
      "A'ana",
      "Aiga-i-le-Tai",
      "Atua",
      "Fa'asaleleaga",
      "Gaga'emauga",
      "Gagaifomauga",
      "Palauli",
      "Satupa'itea",
      "Tuamasaga",
      "Va'a-o-Fonoti",
      "Vaisigano"
    ]
  },
  {
    "country": "San Marino",
    "cities": [
      "Acquaviva",
      "Falciano",
      "Fiorentino",
      "San Marino",
      "Serravalle"
    ]
  },
  {
    "country": "Saudi Arabia",
    "cities": [
      "Abha",
      "Abqaiq",
      "Al Bahah",
      "Al Faruq",
      "Al Hufuf",
      "Al Qatif",
      "Al Yamamah",
      "At Tuwal",
      "Buraidah",
      "Dammam",
      "Dhahran",
      "Hayil",
      "Jazirah",
      "Jazirah",
      "Jeddah",
      "Jizan",
      "Jubail",
      "Khamis Mushait",
      "Khobar",
      "Khulays",
      "Linah",
      "Madinat Yanbu` as Sina`iyah",
      "Mecca",
      "Medina",
      "Mina",
      "Najran",
      "Rabigh",
      "Rahimah",
      "Rahman",
      "Ramdah",
      "Ras Tanura",
      "Riyadh",
      "Sabya",
      "Safwa",
      "Sakaka",
      "Sambah",
      "Sayhat",
      "Tabuk",
      "Yanbu` al Bahr"
    ]
  },
  {
    "country": "Senegal",
    "cities": [
      "Boussinki",
      "Camberene",
      "Dakar",
      "Dodji",
      "Guediawaye",
      "Kaolack",
      "Kedougou",
      "Louga",
      "Madina Kokoun",
      "Saint-Louis",
      "Sama",
      "Tanaf"
    ]
  },
  {
    "country": "Serbia and Montenegro",
    "cities": [
      "Ada",
      "Aleksinac",
      "Apatin",
      "Arilje",
      "Avala",
      "Backa Topola",
      "Backi Jarak",
      "Backi Petrovac",
      "Backo Gradiste",
      "Banatsko Novo Selo",
      "Barajevo",
      "Basaid",
      "Batajnica",
      "Becej",
      "Belgrade",
      "Bocar",
      "Bor",
      "Cantavir",
      "Coka",
      "Cukarica",
      "Cuprija",
      "Debeljaca",
      "Despotovac",
      "Dolovo",
      "Drenovac",
      "Futog",
      "Glozan",
      "Golubinci",
      "Gornji Milanovac",
      "Grocka",
      "Ingija",
      "Izvor",
      "Jagodina",
      "Kacarevo",
      "Kanjiza",
      "Kovin",
      "Kragujevac",
      "Kraljevo",
      "Leskovac",
      "Loznica",
      "Melenci",
      "Nikinci",
      "Nova Pazova",
      "Novi Banovci",
      "Novi Becej",
      "Novi Belgrade",
      "Novi Pazar",
      "Novi Sad",
      "Odzaci",
      "Palic",
      "Paracin",
      "Petrovac",
      "Petrovaradin",
      "Pirot",
      "Popovac",
      "Priboj",
      "Prokuplje",
      "Ratkovo",
      "Ruma",
      "Rumenka",
      "Savski Venac",
      "Selo Mladenovac",
      "Senta",
      "Sibac",
      "Simanovci",
      "Sirig",
      "Smederevo",
      "Sombor",
      "Srbobran",
      "Sremcica",
      "Sremska Kamenica",
      "Sremska Mitrovica",
      "Sremski Karlovci",
      "Stara Pazova",
      "Stari Banovci",
      "Subotica",
      "Surcin",
      "Svilajnac",
      "Svrljig",
      "Temerin",
      "Titel",
      "Tornjos",
      "Ugrinovci",
      "Umcari",
      "Umka",
      "Vajska",
      "Valjevo",
      "Veternik",
      "Vrbas",
      "Zajecar",
      "Zemun Polje",
      "Zlatibor",
      "Zrenjanin"
    ]
  },
  {
    "country": "Seychelles",
    "cities": [
      "Anse aux Pins",
      "Anse Boileau",
      "Anse Etoile",
      "Anse Louis",
      "Anse Royale",
      "Baie Lazare",
      "Baie Sainte Anne",
      "Beau Vallon",
      "Bel Air",
      "Bel Ombre",
      "Cascade",
      "Glacis",
      "Grand' Anse",
      "Grand' Anse",
      "La Digue",
      "La Riviere Anglaise",
      "Mont Buxton",
      "Mont Fleuri",
      "Plaisance",
      "Pointe La Rue",
      "Port Glaud",
      "Saint Louis",
      "Takamaka"
    ]
  },
  {
    "country": "Singapore",
    "cities": [
      "Ang Mo Kio New Town",
      "Ayer Raja New Town",
      "Bedok New Town",
      "Boon Lay",
      "Bukit Batok New Town",
      "Bukit Panjang New Town",
      "Bukit Timah",
      "Bukit Timah Estate",
      "Changi Village",
      "Choa Chu Kang New Town",
      "Clementi New Town",
      "Holland Village",
      "Hougang",
      "Jurong East New Town",
      "Jurong Town",
      "Jurong West New Town",
      "Kalang",
      "Kampong Pasir Ris",
      "Kembangan",
      "Pandan Valley",
      "Pasir Panjang",
      "Punggol",
      "Queenstown Estate",
      "Serangoon",
      "Simei New Town",
      "Singapore",
      "Tai Seng",
      "Tampines New Town",
      "Tanglin Halt",
      "Tanjong Pagar",
      "Toa Payoh New Town",
      "Woodlands New Town",
      "Yew Tee",
      "Yishun New Town"
    ]
  },
  {
    "country": "Slovakia",
    "cities": [
      "Bratislava",
      "Kosice",
      "Presov",
      "Nitra",
      "Zilina",
      "Banska Bystrica",
      "Trnava",
      "Martin",
      "Trencin",
      "Poprad",
      "Prievidza",
      "Zvolen",
      "Povazska Bystrica",
      "Nove Zamky",
      "Michalovce",
      "Spisska Nova Ves",
      "Levice",
      "Komarno",
      "Humenne",
      "Liptovsky Mikulas",
      "Ruzomberok",
      "Pezinok",
      "Dubnica nad Vahom",
      "Senica",
      "Partizanske",
      "Piestany",
      "Galanta",
      "Detva",
      "Hlohovec",
      "Topolcany",
      "Rimavska Sobota",
      "Ziar nad Hronom",
      "Cadca",
      "Dunajska Streda",
      "Sala",
      "Vranov nad Toplou",
      "Stara Lubovna",
      "Snina",
      "Liptovsky Hradok",
      "Lucenec",
      "Banovce nad Bebravou",
      "Bardejov",
      "Roznava",
      "Kezmarok",
      "Puchov",
      "Svidnik",
      "Turzovka",
      "Kysucke Nove Mesto",
      "Krupina",
      "Rajec",
      "Namestovo",
      "Dolny Kubin",
      "Gelnica",
      "Nove Mesto nad Vahom"
    ]
  },
  {
    "country": "Slovenia",
    "cities": [
      "Ankaran",
      "Begunje na Gorenjskem",
      "Beltinci",
      "Besnica",
      "Bevke",
      "Bistrica pri Rusah",
      "Bled",
      "Bohinjska Bela",
      "Borovnica",
      "Breginj",
      "Brestanica",
      "Breznica",
      "Cemsenik",
      "Cerklje ob Krki",
      "Cerknica",
      "Cersak",
      "Cirkovce",
      "Crensovci",
      "Dekani",
      "Dob",
      "Dobravlje",
      "Dobrova",
      "Dobrunje",
      "Dol pri Ljubljani",
      "Dolenjske Toplice",
      "Dornava",
      "Dravograd",
      "Globoko",
      "Gmajnica",
      "Gorenja Vas",
      "Gorisnica",
      "Gornja Radgona",
      "Grize",
      "Grosuplje",
      "Horjul",
      "Hotedrsica",
      "Hrastnik",
      "Hrusica",
      "Idrija",
      "Ig",
      "Ilirska Bistrica",
      "Izola",
      "Jesenice",
      "Kamnik",
      "Kidricevo",
      "Knezak",
      "Kobarid",
      "Kojsko",
      "Komenda",
      "Koper",
      "Krajna",
      "Kranj",
      "Kranjska Gora",
      "Lenart v Slov. Goricah",
      "Lendava",
      "Lesce",
      "Limbus",
      "Litija",
      "Ljubecna",
      "Ljubljana",
      "Log pri Brezovici",
      "Logatec",
      "Lokev",
      "Lovrenc na Dravskem Polju",
      "Lovrenc na Pohorju",
      "Maribor",
      "Markovci",
      "Medvode",
      "Menges",
      "Mezica",
      "Miklavz na Dravskem Polju",
      "Miren",
      "Mirna",
      "Mojstrana",
      "Moravce",
      "Mozirje",
      "Murska Sobota",
      "Naklo",
      "Notranje Gorice",
      "Nova Cerkev",
      "Nova Gorica",
      "Novo Mesto",
      "Pernica",
      "Pesnica pri Mariboru",
      "Petrovce",
      "Piran",
      "Pobegi",
      "Podbocje",
      "Polhov Gradec",
      "Poljcane",
      "Polzela",
      "Postojna",
      "Prebold",
      "Preserje",
      "Prestranek",
      "Prevalje",
      "Ptuj",
      "Puconci",
      "Radlje ob Dravi",
      "Radomlje",
      "Radovljica",
      "Rakek",
      "Ravne",
      "Ravne na Koroskem",
      "Rocinj",
      "Secovlje",
      "Selnica ob Dravi",
      "Sempeter pri Gorici",
      "Sencur",
      "Sentjanz",
      "Sentvid pri Sticni",
      "Sevnica",
      "Skofljica",
      "Slovenj Gradec",
      "Slovenska Bistrica",
      "Slovenske Konjice",
      "Smarje",
      "Smarje pri Jelsah",
      "Smarje-Sap",
      "Smartno ob Paki",
      "Smlednik",
      "Sostanj",
      "Spodnje Hoce",
      "Spodnji Brnik",
      "Spodnji Duplek",
      "Spodnji Ivanjci",
      "Sredisce ob Dravi",
      "Stahovica",
      "Stara Cerkev",
      "Stari Trg pri Lozu",
      "Starse",
      "Tisina",
      "Tolmin",
      "Trbovlje",
      "Trzic",
      "Trzin",
      "Velenje",
      "Velika Loka",
      "Verzej",
      "Videm",
      "Vipava",
      "Vodice",
      "Vojnik",
      "Vrhnika",
      "Zagorje ob Savi",
      "Zelezniki",
      "Zgornja Polskava",
      "Zgornje Gorje",
      "Zgornje Hoce",
      "Zgornje Skofije",
      "Zidani Most",
      "Ziri"
    ]
  },
  {
    "country": "Solomon Islands",
    "cities": [
      "Central",
      "Choiseul",
      "Guadalcanal",
      "Honiara",
      "Isabel",
      "Makira",
      "Malaita",
      "Rennell and Bellona",
      "Temotu",
      "Western"
    ]
  },
  {
    "country": "Somalia",
    "cities": [
      "Awdal",
      "Bakool",
      "Banaadir",
      "Bari",
      "Bay",
      "Galguduud",
      "Gedo",
      "Hiiraan",
      "Jubbada Dhexe",
      "Jubbada Hoose",
      "Mudug",
      "Nugaal",
      "Sanaag",
      "Shabeellaha Dhexe",
      "Shabeellaha Hoose",
      "Sool",
      "Togdheer",
      "Woqooyi Galbeed"
    ]
  },
  {
    "country": "South Africa",
    "cities": [
      "Johannesburg",
      "Cape Town",
      "Durban",
      "Pretoria",
      "Port Elizabeth",
      "Bloemfontein",
      "East London",
      "Pietermaritzburg",
      "Soweto",
      "Alberton",
      "Benoni",
      "Randburg",
      "Umlazi",
      "Polokwane",
      "Germiston",
      "Khayelitsha",
      "Nelspruit",
      "Witbank",
      "Roodepoort",
      "Vanderbijlpark",
      "Middelburg",
      "Richards Bay",
      "Centurion",
      "Klerksdorp",
      "George",
      "Midrand",
      "Westonaria",
      "Vryheid",
      "Orkney",
      "Kimberley",
      "Ermelo",
      "Krugersdorp",
      "Mabopane",
      "Bloemhof",
      "KwaMashu",
      "Stellenbosch",
      "Louis Trichardt",
      "Potchefstroom",
      "Grahamstown",
      "Oudtshoorn",
      "Port Shepstone",
      "Vereeniging",
      "Nigel",
      "Middelburg",
      "Mthatha",
      "Upington",
      "Aliwal North",
      "Queenstown",
      "Mossel Bay",
      "Potgietersrus",
      "Virginia",
      "Brits",
      "Malmesbury",
      "Kroonstad",
      "Bethal",
      "Sasolburg",
      "Lichtenburg",
      "Fort Beaufort",
      "Heidelberg",
      "Kokstad",
      "Empangeni",
      "Thohoyandou",
      "Senekal",
      "Volksrust",
      "Worcester",
      "Graaff-Reinet",
      "Parys",
      "Tzaneen",
      "Komatipoort",
      "Bultfontein",
      "Aliwal North",
      "Volksrust",
      "Klerksdorp",
      "Mabopane",
      "Carletonville",
      "Nylstroom",
      "De Aar",
      "Modimolle",
      "Cradock",
      "De Doorns",
      "Port Alfred",
      "Giyani",
      "Klerksdorp",
      "Volksrust",
      "Stanger",
      "KwaDukuza",
      "Margate",
      "Kroonstad",
      "Mossel Bay",
      "Richards Bay",
      "Nelspruit"
    ]
  },
  {
    "country": "Spain",
    "cities": [
      "Madrid",
      "Barcelona",
      "Valencia",
      "Seville",
      "Zaragoza",
      "Málaga",
      "Murcia",
      "Palma",
      "Las Palmas de Gran Canaria",
      "Bilbao",
      "Alicante",
      "Córdoba",
      "Valladolid",
      "Vigo",
      "Gijón",
      "Hospitalet de Llobregat",
      "A Coruña",
      "Vitoria-Gasteiz",
      "Granada",
      "Elche",
      "Oviedo",
      "Badalona",
      "Cartagena",
      "Terrassa",
      "Jerez de la Frontera",
      "Sabadell",
      "Alcalá de Henares",
      "Pamplona",
      "Fuenlabrada",
      "Almería",
      "San Sebastián",
      "Leganés",
      "Santander",
      "Burgos",
      "Castellón de la Plana",
      "Albacete",
      "Alcorcón",
      "Getafe",
      "Salamanca",
      "Logroño",
      "San Cristóbal de La Laguna",
      "Huelva",
      "Badajoz",
      "Tarragona",
      "León",
      "Cádiz",
      "Jaén",
      "Ourense",
      "Mataró",
      "Algeciras",
      "Marbella",
      "Dos Hermanas",
      "Torrejón de Ardoz",
      "Parla",
      "Alcobendas",
      "Santa Coloma de Gramenet",
      "Alcalá de Guadaíra",
      "Jaén",
      "Torrevieja",
      "Orihuela",
      "Gandía",
      "Manresa",
      "Torrent",
      "Vélez-Málaga",
      "Rubi",
      "Ávila",
      "Majadahonda",
      "Valdemoro",
      "Vilanova i la Geltrú",
      "El Puerto de Santa María",
      "Mollet del Vallès",
      "Paterna",
      "Sant Cugat del Vallès",
      "Ceuta",
      "Cuenca",
      "Toledo",
      "Las Rozas de Madrid",
      "Rubí",
      "Melilla",
      "Pontevedra",
      "Santiago de Compostela",
      "San Fernando",
      "Getxo",
      "Benidorm",
      "Inca",
      "Coslada",
      "Mijas",
      "Ibiza",
      "Granollers",
      "Cáceres"
    ]
  },
  {
    "country": "Sri Lanka",
    "cities": [
      "Badulla",
      "Battaramulla South",
      "Biyagama",
      "Boralesgamuwa South",
      "Colombo",
      "Dehiwala",
      "Dehiwala-Mount Lavinia",
      "Eppawala",
      "Gampaha",
      "Gangodawila North",
      "Hekitta",
      "Homagama",
      "Kaduwela",
      "Kandana",
      "Kandy",
      "Katubedda",
      "Katunayaka",
      "Kelaniya",
      "Kohuwala",
      "Maharagama",
      "Makola South",
      "Matale",
      "Moratuwa",
      "Nattandiya Town",
      "Negombo",
      "Nugegoda",
      "Orugodawatta",
      "Padukka",
      "Pannipitiya",
      "Peradeniya",
      "Piliyandala",
      "Ragama",
      "Rajagiriya",
      "Sri Jayewardenepura Kotte",
      "Talapathpitiya",
      "Wattala",
      "Wellampitiya"
    ]
  },
  {
    "country": "Sudan",
    "cities": [
      "Kassala",
      "Khartoum",
      "Nyala",
      "Shendi",
      "Thabit",
      "Umm Durman"
    ]
  },
  {
    "country": "South Sudan",
    "cities": [
      "Juba",
      "Malakal",
      "Wau",
      "Rumbek",
      "Bentiu",
      "Aweil",
      "Yei",
      "Bor",
      "Torit",
      "Yambio",
    ]
  },
  {
    "country": "Suriname",
    "cities": [
      "Botopasi",
      "Brownsweg",
      "Friendship",
      "Groningen",
      "Moengo",
      "Nieuw Amsterdam",
      "Onverwacht",
      "Paramaribo",
      "Totness"
    ]
  },
  {
    "country": "Swaziland",
    "cities": [
      "Kwaluseni",
      "Lobamba",
      "Manzini",
      "Mbabane",
      "Piggs Peak"
    ]
  },
  {
    "country": "Sweden",
    "cities": [
      "Stockholm",
      "Gothenburg",
      "Malmö",
      "Uppsala",
      "Västerås",
      "Örebro",
      "Linköping",
      "Helsingborg",
      "Jönköping",
      "Norrköping",
      "Lund",
      "Umeå",
      "Gävle",
      "Borås",
      "Södertälje",
      "Eskilstuna",
      "Halmstad",
      "Växjö",
      "Karlstad",
      "Täby",
      "Västerås",
      "Huddinge",
      "Nacka",
      "Järfälla",
      "Haninge",
      "Bromma",
      "Sollentuna",
      "Umeå",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad",
      "Täby",
      "Södertälje",
      "Helsingborg",
      "Norrköping",
      "Luleå",
      "Märsta",
      "Växjö",
      "Karlstad"
    ]
  },
  {
    "country": "Switzerland",
    "cities": [
      "Zurich",
      "Geneva",
      "Basel",
      "Bern",
      "Lausanne",
      "Winterthur",
      "Lucerne",
      "St. Gallen",
      "Lugano",
      "Biel/Bienne",
      "Thun",
      "Köniz",
      "La Chaux-de-Fonds",
      "Schaffhausen",
      "Fribourg",
      "Chur",
      "Neuchâtel",
      "Vernier",
      "Uster",
      "Sion",
      "Emmen",
      "Zug",
      "Yverdon-les-Bains",
      "Dübendorf",
      "Cham",
      "Montreux",
      "Frauenfeld",
      "Wetzikon",
      "Rapperswil-Jona",
      "Wil",
      "Muttenz",
      "Wädenswil",
      "Horgen",
      "Vevey",
      "Kriens",
      "Allschwil",
      "Bellinzona",
      "Oberwil",
      "Renens",
      "Aarau",
      "Wettingen",
      "Dietikon",
      "Carouge",
      "Pully",
      "Thalwil",
      "Herdern",
      "Monthey",
      "Onex",
      "Schwyz",
      "Steffisburg",
      "Morges",
      "Weinfelden",
      "Martigny",
      "Locarno",
      "Neuhausen am Rheinfall",
      "Bulle",
      "Freienbach",
      "Meyrin",
      "Binningen",
      "Riehen",
      "Pratteln",
      "Zofingen",
      "Sierre",
      "Val-de-Ruz",
      "Regensdorf",
      "Zollikon",
      "Wohlen",
      "Baden",
      "Arbon",
      "Worb",
      "Münsingen",
      "Thônex",
      "Versoix",
      "Burgdorf",
      "Wil",
      "Grenchen",
      "Opfikon",
      "Ostermundigen",
      "Bellinzona",
      "Birsfelden",
      "Wallisellen",
      "Liestal",
      "Altstätten",
      "Küsnacht",
      "Solothurn",
      "Brugg",
      "Steffisburg",
      "Kloten",
      "Veyrier",
      "Amriswil",
      "Plan-les-Ouates",
      "Prilly",
      "Gland",
      "Bassersdorf",
      "Chêne-Bougeries"
    ]
  },
  {
    "country": "Syria",
    "cities": [
      "Al Hasakah",
      "Al Ladhiqiyah",
      "Al Qunaytirah",
      "Ar Raqqah",
      "As Suwayda'",
      "Dar'a",
      "Dayr az Zawr",
      "Dimashq",
      "Halab",
      "Hamah",
      "Hims",
      "Idlib",
      "Rif Dimashq",
      "Tartus"
    ]
  },
  {
    "country": "Tajikistan",
    "cities": [
      "Dushanbe",
      "Khujand",
      "Kulob",
      "Qurghonteppa",
      "Istaravshan",
      "Vahdat",
      "Kofarnihon",
      "Tursunzoda",
      "Konibodom",
      "Isfara",
    ]
  },
  {
    "country": "Taiwan",
    "cities": [
      "Budai",
      "Caogang",
      "Chang-hua",
      "Checheng",
      "Chiayi",
      "Dahu",
      "Douliu",
      "Erlin",
      "Fanlu",
      "Fengshan",
      "Gangshan",
      "Gaozhongyicun",
      "Hemei",
      "Hengchun",
      "Hsinchu",
      "Hualian",
      "Jincheng",
      "Kao-sung",
      "Kaohsiung City",
      "Keelung",
      "Linkou",
      "Lugang",
      "Ma-kung",
      "Maru",
      "Matsu",
      "Meilun",
      "Miaoli",
      "Nanhua",
      "Nankan",
      "Nantou",
      "Neihu",
      "Pingtung",
      "Pozi",
      "Puli",
      "Shalun",
      "Shetou",
      "Shoufeng",
      "Shuilin",
      "Shuiliu",
      "Shuishang",
      "Shulin",
      "Shuyi",
      "Suqin",
      "Taichang",
      "Taichung",
      "Tainan",
      "Taipei",
      "Taipingding",
      "Taitung",
      "Taoyuan",
      "Toucheng",
      "Tuchang",
      "Wan-hua",
      "Wangtongwen",
      "Wanluan",
      "Wutai",
      "Xinji",
      "Xinzhuang",
      "Yangmei",
      "Yanping",
      "Yilan",
      "Yuli",
      "Yunlin County",
      "Zhubei",
      "Zhunan",
      "Zhuqi"
    ]
  },
  {
    "country": "Tanzania",
    "cities": [
      "Arusha",
      "Bukoba",
      "Dar es Salaam",
      "Dodoma",
      "Morogoro",
      "Mwanza",
      "Njombe",
      "Raha",
      "Tanga",
      "Zanzibar"
    ]
  },
  {
    "country": "Thailand",
    "cities": [
      "Amnat Charoen",
      "Amphawa",
      "Amphoe Aranyaprathet",
      "Ang Thong",
      "Ayutthaya",
      "Ban Ang Thong",
      "Ban Bang Phli Nakhon",
      "Ban Bang Plong",
      "Ban Bueng",
      "Ban Don Sak",
      "Ban Hua Thale",
      "Ban Khlong Ngae",
      "Ban Khlong Prasong",
      "Ban Khlong Prawet",
      "Ban Khlong Takhian",
      "Ban Ko Pao",
      "Ban Na",
      "Ban Nam Hak",
      "Ban Pat Mon",
      "Ban Phichit",
      "Ban Phlam",
      "Ban Pong Lang",
      "Ban Pratunam Tha Khai",
      "Ban Talat Rangsit",
      "Ban Tha Duea",
      "Ban Tha Pai",
      "Ban Tha Ruea",
      "Ban Tham",
      "Ban Wang Yai",
      "Bang Kapi",
      "Bang Khae",
      "Bang Khun Thian",
      "Bang Kruai",
      "Bang Lamung",
      "Bang Na",
      "Bang Phlat",
      "Bang Sao Thong",
      "Bang Sue",
      "Bangkok",
      "Bangkok",
      "Buriram",
      "Cha-am",
      "Chachoengsao",
      "Chaiyaphum",
      "Chanthaburi",
      "Chatuchak",
      "Chiang Mai",
      "Chiang Muan",
      "Chiang Rai",
      "Chon Buri",
      "Chulabhorn",
      "Chumphon",
      "Din Daeng",
      "Don Mueang",
      "Dusit",
      "Hat Yai",
      "Hua Hin",
      "Huai Khwang",
      "Ka Bang",
      "Kalasin",
      "Kamphaeng Phet",
      "Kanchanaburi",
      "Kathu",
      "Khan Na Yao",
      "Khlong Luang",
      "Khlong San",
      "Khlong Toei",
      "Khlung",
      "Khon Kaen",
      "Klaeng",
      "Kosum Phisai",
      "Krabi",
      "Krathum Baen",
      "Kumphawapi",
      "Lampang",
      "Lamphun",
      "Lat Krabang",
      "Loei",
      "Long",
      "Lopburi",
      "Mae Hong Son",
      "Mae Sot",
      "Maha Sarakham",
      "Makkasan",
      "Manorom",
      "Min Buri",
      "Muak Lek",
      "Mueang Nonthaburi",
      "Mueang Samut Prakan",
      "Mukdahan",
      "Nakhon Pathom",
      "Nakhon Ratchasima",
      "Nakhon Sawan",
      "Nakhon Si Thammarat",
      "Nan",
      "Narathiwat",
      "New Sukhothai",
      "Non Thai",
      "Nong Bua",
      "Nong Bua Lamphu",
      "Nong Khai",
      "Nong Prue",
      "Pak Kret",
      "Pathum Thani",
      "Pathum Wan",
      "Pattani",
      "Pattaya",
      "Phachi",
      "Phan",
      "Phang Khon",
      "Phang Nga",
      "Phanna Nikhom",
      "Phatthalung",
      "Phaya Thai",
      "Phayao",
      "Phetchabun",
      "Phetchaburi",
      "Phichit",
      "Phitsanulok",
      "Photharam",
      "Phrae",
      "Phuket",
      "Prachin Buri",
      "Prachuap Khiri Khan",
      "Prakanong",
      "Ranong",
      "Ratchaburi",
      "Rawai",
      "Rayong",
      "Roi Et",
      "Sa Kaeo",
      "Sakon Nakhon",
      "Salaya",
      "Sam Khok",
      "Sam Sen",
      "Samphanthawong",
      "Samut Sakhon",
      "Samut Songkhram",
      "San Sai",
      "Sathon",
      "Sattahip",
      "Satun",
      "Sena",
      "Si Racha",
      "Si Sa Ket",
      "Sing Buri",
      "Songkhla",
      "Suan Luang",
      "Sung Noen",
      "Suphan Buri",
      "Surin",
      "Tak",
      "Thalang",
      "Thanyaburi",
      "Thon Buri",
      "Trang",
      "Trat",
      "Ubon Ratchathani",
      "Udon Thani",
      "Uthai Thani",
      "Uttaradit",
      "Wang Thonglang",
      "Watthana",
      "Wiphawadi",
      "Yala",
      "Yasothon"
    ]
  },
  {
    "country": "Togo",
    "cities": [
      "Kara",
      "Plateaux",
      "Savanes",
      "Centrale",
      "Maritime"
    ]
  },
  {
    "country": "Tonga",
    "cities": []
  },
  {
    "country": "Trinidad and Tobago",
    "cities": [
      "Arima",
      "Arouca",
      "Barataria",
      "California",
      "Carapichaima",
      "Carenage",
      "Caroni",
      "Chaguanas",
      "Claxton Bay",
      "Couva",
      "Cumuto",
      "Cunupia",
      "Curepe",
      "Debe",
      "Diego Martin",
      "El Dorado",
      "Freeport",
      "Fyzabad",
      "Gasparillo",
      "Golden Lane",
      "Laventille",
      "Lopinot",
      "Marabella",
      "Maraval",
      "Moriah",
      "Morvant",
      "Penal",
      "Petit Valley",
      "Phoenix Park",
      "Piarco",
      "Port of Spain",
      "Princes Town",
      "Rio Claro",
      "Roxborough",
      "Saint Augustine",
      "Saint Clair",
      "Saint James",
      "Saint Joseph",
      "San Fernando",
      "San Juan",
      "Sangre Grande",
      "Santa Cruz",
      "Scarborough",
      "Siparia",
      "Tunapuna",
      "Valsayn",
      "Victoria",
      "Ward of Tacarigua",
      "Woodbrook"
    ]
  },
  {
    "country": "Tunisia",
    "cities": [
      "Ariana",
      "Beja",
      "Gafsa",
      "Hammamet",
      "Le Bardo",
      "Manouba",
      "Monastir",
      "Rades",
      "Sfax",
      "Sidi Bouzid",
      "Sousse",
      "Tunis"
    ]
  },
  {
    "country": "Turkey",
    "cities": [
      "Istanbul",
      "Ankara",
      "Izmir",
      "Bursa",
      "Adana",
      "Gaziantep",
      "Konya",
      "Antalya",
      "Eskisehir",
      "Diyarbakir",
      "Kayseri",
      "Mersin",
      "Samsun",
      "Denizli",
      "Sanliurfa",
      "Adapazari",
      "Malatya",
      "Kahramanmaras",
      "Erzurum",
      "Van",
      "Batman",
      "Elazig",
      "Sivas",
      "Manisa",
      "Gebze",
      "Balikesir",
      "Tarsus",
      "Kütahya",
      "Trabzon",
      "Çorum",
      "Sakarya",
      "Erzincan",
      "Iskenderun",
      "Osmaniye",
      "Isparta",
      "Aydin",
      "Antakya",
      "Aksaray",
      "Afyonkarahisar",
      "Kiziltepe",
      "Nigde",
      "Kastamonu",
      "Ereğli",
      "Agri",
      "Ercis",
      "Giresun",
      "Kars",
      "Karaman",
      "Arnavutköy",
      "Polatli",
      "Bandirma",
      "Nazilli",
      "Tekirdag",
      "Alanya",
      "Ordu",
      "Kilis",
      "Yozgat",
      "Aydincik",
      "Osmaniye",
      "Mus",
      "Turgutlu",
      "Silifke",
      "Bilecik",
      "Tatvan",
      "Kozan",
      "Düzce",
      "Bafra",
      "Karasu",
      "Erbaa",
      "Suruc",
      "Silvan",
      "Soma",
      "Sisli",
      "Torbalı",
      "Ceyhan",
      "Bismil",
      "Silvan",
      "Tire",
      "Kadirli",
      "Turhal",
      "Kestel",
      "Cizre",
      "Sungurlu",
      "Erzin",
      "Beypazari",
      "Gölcük",
      "Çaycuma",
      "Gölbaşı",
      "Nizip"
    ]
  },
  {
    "country": "Turkmenistan",
    "cities": [
      "Ahal Welayaty (Ashgabat)",
      "Balkan Welayaty (Balkanabat)",
      "Dashoguz Welayaty",
      "Lebap Welayaty (Turkmenabat)",
      "Mary Welayaty"
    ]
  },
  {
    "country": "Uganda",
    "cities": [
      "Adjumani",
      "Apac",
      "Arua",
      "Bugiri",
      "Bundibugyo",
      "Bushenyi",
      "Busia",
      "Gulu",
      "Hoima",
      "Iganga",
      "Jinja",
      "Kabale",
      "Kabarole",
      "Kaberamaido",
      "Kalangala",
      "Kampala",
      "Kamuli",
      "Kamwenge",
      "Kanungu",
      "Kapchorwa",
      "Kasese",
      "Katakwi",
      "Kayunga",
      "Kibale",
      "Kiboga",
      "Kisoro",
      "Kitgum",
      "Kotido",
      "Kumi",
      "Kyenjojo",
      "Lira",
      "Luwero",
      "Masaka",
      "Masindi",
      "Mayuge",
      "Mbale",
      "Mbarara",
      "Moroto",
      "Moyo",
      "Mpigi",
      "Mubende",
      "Mukono",
      "Nakapiripirit",
      "Nakasongola",
      "Nebbi",
      "Ntungamo",
      "Pader",
      "Pallisa",
      "Rakai",
      "Rukungiri",
      "Sembabule",
      "Sironko",
      "Soroti",
      "Tororo",
      "Wakiso",
      "Yumbe"
    ]
  },
  {
    "country": "Ukraine",
    "cities": [
      "Aleksandriya",
      "Aleksandrovka",
      "Alekseyevo-Druzhkovka",
      "Alupka",
      "Alushta",
      "Babin",
      "Barashivka",
      "Baryshivka",
      "Belaya",
      "Berdychiv",
      "Berehove",
      "Bila Hora",
      "Bila Tserkva",
      "Bolekhiv",
      "Borodyanka",
      "Bortnychi",
      "Boryslav",
      "Boryspil",
      "Boryspil Raion",
      "Boyarka",
      "Bozhenka",
      "Brody",
      "Brovary",
      "Bryanka",
      "Bucha",
      "Buchach",
      "Bukova",
      "Bushtyno",
      "Cherkasy",
      "Chernev",
      "Chernihiv",
      "Chernivtsi",
      "Chervona Hirka",
      "Chervonohrad",
      "Chop",
      "Darakhov",
      "Davydov",
      "Desna",
      "Dimitrov",
      "Dmitrovichi",
      "Dnipropetrovsk",
      "Donetsk",
      "Drohobych",
      "Druzhkovka",
      "Dubno",
      "Dzhankoy",
      "Energodar",
      "Fastiv",
      "Feodosiya",
      "Fontanka",
      "Frunze",
      "German",
      "Goncharov",
      "Gorlovka",
      "Grebenyuk",
      "Haysyn",
      "Hlukhiv",
      "Horlivka",
      "Horodenka",
      "Hoshiv",
      "Illintsi",
      "Irpin",
      "Ivankov",
      "Ivanov",
      "Izmail",
      "Kakhovka",
      "Kalush",
      "Kalynivka",
      "Kalynivka",
      "Kaniv",
      "Kerch",
      "Kharkiv",
      "Kherson",
      "Khust",
      "Khyzha",
      "Kiev",
      "Kirovohrad",
      "Kirovsk",
      "Knyazhychi",
      "Kolomyia",
      "Komsomolsk",
      "Konotop",
      "Korosten",
      "Korostyshiv",
      "Kovel",
      "Kozyatyn",
      "Kramatorsk",
      "Krasnodon",
      "Krasnohrad",
      "Kremenchuk",
      "Kremenets",
      "Kreminna",
      "Kryvyy Rih",
      "Kuna",
      "Kurakhovo",
      "Kurylivka",
      "Kuznetsovsk",
      "Kvasyliv",
      "Kyseliv",
      "Kyyiv",
      "Lanovka",
      "Lenina",
      "Letychiv",
      "Lisichansk",
      "Lityn",
      "Lohvynove",
      "Lubny",
      "Lugansk",
      "Luganskoye",
      "Luhansk",
      "Lutsk",
      "Lutugino",
      "Lviv",
      "Makariv",
      "Makiyivka",
      "Maksim",
      "Mala Tokmachka",
      "Malyn",
      "Mariupol",
      "Markova",
      "Mega",
      "Melitopol",
      "Merefa",
      "Monastyryshche",
      "Mukacheve",
      "Mykolayiv",
      "Mykolayiv",
      "Mylove",
      "Nadezhda",
      "Netishyn",
      "Nizhyn",
      "Nosivka",
      "Novi Sanzhary",
      "Novomoskovsk",
      "Novyy Buh",
      "Obukhov",
      "Odesa",
      "Oleh",
      "Oreanda",
      "Osnova",
      "Osypenko",
      "Pavlograd",
      "Pavlohrad",
      "Petranka",
      "Petrenky",
      "Pobeda",
      "Podol",
      "Poltava",
      "Poplavy",
      "Pyrohiv",
      "Rayhorodka",
      "Rivne",
      "Rivne",
      "Rodinskoye",
      "Romaniv",
      "Romny",
      "Rovenki",
      "Rubizhne",
      "Saky",
      "Selidovo",
      "Semen",
      "Sencha",
      "Serhiyi",
      "Sevastopol",
      "Shepetivka",
      "Shevchenka",
      "Shevchenko",
      "Shostka",
      "Shpola",
      "Simferopol",
      "Slavuta",
      "Sloviansk",
      "Slynkivshchyna",
      "Smila",
      "Sokol",
      "Sokoliv",
      "Soroka",
      "Soroki",
      "Sorotskoye",
      "Spas",
      "Stakhanov",
      "Stanislav",
      "Stat",
      "Stryi",
      "Sudak",
      "Sumy",
      "Svalyava",
      "Svessa",
      "Teplodar",
      "Teremky",
      "Ternopil",
      "Ternovka",
      "Tkachenka",
      "Tokmak",
      "Toporov",
      "Torez",
      "Trypillia",
      "Tsvetkov",
      "Tsyurupinsk",
      "Turka",
      "Tyachiv",
      "Ugledar",
      "Ukrayinka",
      "Uzhhorod",
      "Vasylkiv",
      "Vinnytsya",
      "Volkov",
      "Volnovakha",
      "Voronovo",
      "Vorovskiy",
      "Voznesenka",
      "Vyshhorod",
      "Vyshneve",
      "Yalta",
      "Yasinovataya",
      "Yenakiyeve",
      "Zabolotiv",
      "Zaporizhia",
      "Zaporozhe",
      "Zastava",
      "Zavod",
      "Zdolbuniv",
      "Zhashkiv",
      "Zhdanovka",
      "Zhmerynka",
      "Zhytomyr",
      "Zlazne",
      "Zmiyiv",
      "Zolochiv",
      "Zolotonosha"
    ]
  },
  {
    "country": "United Arab Emirates",
    "cities": [
      "Abu Dhabi",
      "Al Ain",
      "Al Khan",
      "Ar Ruways",
      "As Satwah",
      "Dayrah",
      "Dubai",
      "Fujairah",
      "Ras al-Khaimah",
      "Sharjah"
    ]
  },
  {
    "country": "United Kingdom",
    "cities": [
      "London",
      "Birmingham",
      "Manchester",
      "Glasgow",
      "Liverpool",
      "Newcastle upon Tyne",
      "Sheffield",
      "Leeds",
      "Bristol",
      "Edinburgh",
      "Cardiff",
      "Leicester",
      "Belfast",
      "Nottingham",
      "Southampton",
      "Brighton",
      "Portsmouth",
      "Leeds",
      "Stoke-on-Trent",
      "Wolverhampton",
      "Plymouth",
      "Reading",
      "Hull",
      "Peterborough",
      "Bradford",
      "Southend-on-Sea",
      "Swansea",
      "Derby",
      "Sunderland",
      "Warrington",
      "Bournemouth",
      "Swindon",
      "Oxford",
      "Middlesbrough",
      "Blackpool",
      "Bolton",
      "Stockport",
      "Luton",
      "Oldham",
      "Woking",
      "Wigan",
      "Lincoln",
      "Huddersfield",
      "Dundee",
      "Rotherham",
      "Rochdale",
      "Cambridge",
      "Maidstone",
      "Eastbourne",
      "Basingstoke",
      "Sutton Coldfield",
      "Birkenhead",
      "Mansfield",
      "Telford",
      "Darlington",
      "Hartlepool",
      "Basildon",
      "Chesterfield",
      "Exeter",
      "Cheltenham",
      "Stevenage",
      "Harlow",
      "Burnley",
      "Gillingham",
      "Grimsby",
      "Chester",
      "Scunthorpe",
      "Bedford",
      "Sutton",
      "Guildford",
      "Preston",
      "St Albans",
      "Salford",
      "West Bromwich",
      "Worcester",
      "High Wycombe",
      "Wakefield",
      "Gateshead",
      "Bury",
      "Solihull",
      "Grays",
      "Stafford",
      "East Kilbride",
      "Welwyn Garden City",
      "Halifax",
      "York",
      "Chatham",
      "Esher",
      "Walsall",
      "Barnsley",
      "Londonderry",
      "Fareham",
      "Carlisle",
      "Kettering",
      "Hemel Hempstead",
      "Hove",
      "Bath",
      "Weston-super-Mare"
    ]
  },
  {
    "country": "United States",
    "cities": [
      "New York",
      "Los Angeles",
      "Chicago",
      "Houston",
      "Phoenix",
      "Philadelphia",
      "San Antonio",
      "San Diego",
      "Dallas",
      "San Jose",
      "Austin",
      "Jacksonville",
      "Fort Worth",
      "Columbus",
      "San Francisco",
      "Charlotte",
      "Indianapolis",
      "Seattle",
      "Denver",
      "Washington",
      "Boston",
      "El Paso",
      "Detroit",
      "Nashville",
      "Memphis",
      "Portland",
      "Oklahoma City",
      "Las Vegas",
      "Louisville",
      "Baltimore",
      "Milwaukee",
      "Albuquerque",
      "Tucson",
      "Fresno",
      "Sacramento",
      "Kansas City",
      "Long Beach",
      "Mesa",
      "Atlanta",
      "Colorado Springs",
      "Virginia Beach",
      "Raleigh",
      "Omaha",
      "Miami",
      "Oakland",
      "Minneapolis",
      "Tulsa",
      "Wichita",
      "New Orleans",
      "Arlington",
      "Cleveland",
      "Bakersfield",
      "Tampa",
      "Aurora",
      "Honolulu",
      "Anaheim",
      "Santa Ana",
      "Corpus Christi",
      "Riverside",
      "Lexington",
      "St. Louis",
      "Stockton",
      "Pittsburgh",
      "Saint Paul",
      "Cincinnati",
      "Anchorage",
      "Henderson",
      "Greensboro",
      "Plano",
      "Newark",
      "Toledo",
      "Lincoln",
      "Orlando",
      "Chula Vista",
      "Jersey City",
      "Chandler",
      "Fort Wayne",
      "Buffalo",
      "Durham",
      "St. Petersburg",
      "Irvine",
      "Laredo",
      "Lubbock",
      "Madison",
      "Gilbert",
      "Norfolk",
      "Reno",
      "Winston-Salem",
      "Glendale",
      "Hialeah",
      "Garland",
      "Scottsdale",
      "Irving",
      "Chesapeake",
      "North Las Vegas",
      "Fremont",
      "Baton Rouge",
      "Richmond",
      "Boise",
      "San Bernardino",
      "Spokane",
      "Tacoma",
      "Santa Clarita",
      "Fort Lauderdale",
      "Garden Grove",
      "Oceanside",
      "Rancho Cucamonga",
      "Santa Rosa",
      "Ontario",
      "Elk Grove",
      "Corona",
      "Salem",
      "Springfield",
      "Pasadena",
      "Rockford",
      "Pomona",
      "Joliet",
      "Paterson",
      "Torrance",
      "Bridgeport",
      "Alexandria",
      "Sunnyvale",
      "Cary",
      "Lakewood",
      "Hollywood",
      "Clarksville",
      "Lancaster",
      "Salinas",
      "Palmdale",
      "Maumee",
      "Corvallis",
      "Bowie",
      "Bowling Green",
      "Burbank",
      "Murfreesboro",
      "Frisco",
      "Elgin",
      "Lakewood",
      "Carlsbad",
      "Westminster",
      "Rochester",
      "Odessa",
      "Manchester",
      "Cambridge",
      "Rialto",
      "Temecula",
      "Green Bay",
      "Davenport",
      "Richardson",
      "Boulder",
      "Gresham",
      "Salem",
      "Fairfield",
      "West Covina",
      "Wichita Falls",
      "Round Rock",
      "High Point",
      "Billings",
      "Clearwater",
      "Pembroke Pines",
      "West Jordan",
      "Everett",
      "Downey",
      "College Station",
      "Ventura",
      "Inglewood",
      "Waterbury",
      "Richmond",
      "Lafayette",
      "Charleston",
      "Beaumont",
      "Independence",
      "Ann Arbor",
      "Fayetteville",
      "Provo",
      "Peoria",
      "Norman",
      "Berkeley",
      "San Mateo",
      "Santa Clara",
      "Miami Gardens",
      "El Cajon",
      "Tempe",
      "Bloomington",
      "Columbia",
      "El Monte",
      "Simi Valley",
      "Norwalk",
      "Lansing"
    ]
  },
  {
    "country": "Uruguay",
    "cities": [
      "Barra de Carrasco",
      "Canelones",
      "Colonia del Sacramento",
      "Durazno",
      "Florida",
      "La Floresta",
      "La Paz",
      "Las Piedras",
      "Maldonado",
      "Mercedes",
      "Montevideo",
      "Punta del Este",
      "Salto",
      "San Carlos",
      "Toledo",
      "Union"
    ]
  },
  {
    "country": "Uzbekistan",
    "cities": [
      "Andijon Viloyati",
      "Buxoro Viloyati",
      "Farg'ona Viloyati",
      "Jizzax Viloyati",
      "Namangan Viloyati",
      "Navoiy Viloyati",
      "Qashqadaryo Viloyati",
      "Qaraqalpog'iston Respublikasi",
      "Samarqand Viloyati",
      "Sirdaryo Viloyati",
      "Surxondaryo Viloyati",
      "Toshkent Shahri",
      "Toshkent Viloyati",
      "Xorazm Viloyati"
    ]
  },
  {
    "country": "Vanuatu",
    "cities": [
      "Malampa",
      "Penama",
      "Sanma",
      "Shefa",
      "Tafea",
      "Torba"
    ]
  },
  {
    "country": "Venezuela",
    "cities": [
      "Acarigua",
      "Anaco",
      "Araure",
      "Bachaquero",
      "Barcelona",
      "Barinas",
      "Barquisimeto",
      "Bejuma",
      "Bolivar",
      "Cabimas",
      "Cabudare",
      "Cagua",
      "Caja de Agua",
      "Calabozo",
      "Campo de Carabobo",
      "Caraballeda",
      "Caracas",
      "Caripe",
      "Carrizal",
      "Catia La Mar",
      "Charallave",
      "Chivacoa",
      "Ciudad Guayana",
      "Ciudad Ojeda",
      "Ciudad Piar",
      "Colonia Tovar",
      "Coro",
      "Cua",
      "Curazaito",
      "El Chorro",
      "El Recreo",
      "El Roque",
      "El Rosal",
      "El Tigre",
      "El Vigia",
      "Guachara",
      "Guanare",
      "Guarenas",
      "Guatire",
      "Guayana",
      "Gueiria",
      "La Castellana",
      "La Cruz",
      "La Guaira",
      "La Urbina",
      "La Verdad",
      "La Victoria",
      "La Villa del Rosario",
      "Londres",
      "Los Chorros",
      "Los Teques",
      "Macuto",
      "Maiquetia",
      "Maracaibo",
      "Maracay",
      "Maria",
      "Mariguitar",
      "Matanzas",
      "Miranda",
      "Monte Cristo",
      "Naguanagua",
      "Ocumare",
      "Pampatar",
      "Porlamar",
      "Puerto Cabello",
      "Puerto La Cruz",
      "Puerto Ordaz and San Felix",
      "Punto Fijo",
      "Sabaneta",
      "San Antonio",
      "San Antonio de Los Altos",
      "San Diego",
      "San Felipe",
      "San Felix",
      "San Francisco",
      "San Joaquin",
      "San Jose de Guanipa",
      "Santa Cruz del Zulia",
      "Santa Fe",
      "Santa Teresa",
      "Solano",
      "Tariba",
      "Torre",
      "Trujillo",
      "Tucupita",
      "Turmero",
      "Valencia",
      "Valera",
      "Valle",
      "Vargas"
    ]
  },
  {
    "country": "Vietnam",
    "cities": [
      "An Dinh",
      "An Giang",
      "An Nhon",
      "An Tam",
      "An Thanh",
      "Ap Sai Gon",
      "Ap Trung",
      "Bac Giang",
      "Bac Kan",
      "Bac Ninh",
      "Bach Ma",
      "Bang",
      "Ben Nghe",
      "Ben Than",
      "Bien Hoa",
      "Binh Phuoc",
      "Binh Thanh",
      "Binh Thoai",
      "Buon Ma Thuot",
      "Ca Mau",
      "Cam Pha",
      "Can Tho",
      "Cho Ha",
      "Chuong Duong",
      "Co Giang",
      "Da Lat",
      "Da Nang",
      "Dac Loc",
      "Di An",
      "Dien Bien Phu",
      "Dinh Long",
      "Dong",
      "Dong Nai",
      "Dong Thap",
      "Duc Thang",
      "Giong Ngai",
      "Giong Truong",
      "Go Vap",
      "Ha Dong",
      "Ha Long",
      "Ha Nam",
      "Ha Tinh",
      "Haiphong",
      "Hanoi",
      "Ho Chi Minh City",
      "Hoang Mai",
      "Hung Yen",
      "Huong Tinh",
      "Khanh Hoa",
      "Kon Tum",
      "Lang Dung",
      "Lang Hong",
      "Lang Son",
      "Lao Cai",
      "Le Loi",
      "Long An",
      "Long Tan",
      "Long Van",
      "Luong Hoi",
      "Minh Khai",
      "Nghi Loc",
      "Nha Be",
      "Nha Trang",
      "Phan Thon",
      "Phu Nhuan",
      "Phu Tho",
      "Phu Xuyen",
      "Phu Yen",
      "Phuong Tan",
      "Pleiku",
      "Quan Ngoc",
      "Quan Trang",
      "Quang Nam",
      "Quang Tri",
      "Quang Trung",
      "Qui Nhon",
      "Soc Trang",
      "Tam Ky",
      "Tan Binh",
      "Tan Thuan Dong",
      "Tang",
      "Tay Ninh",
      "Thach That",
      "Thanh Nguyen",
      "Thanh Pho Ha Giang",
      "Thanh Pho Hai Duong",
      "Thanh Pho Hoa Binh",
      "Thanh Pho Ninh Binh",
      "Thanh Pho Thai Binh",
      "Thanh Pho Thai Nguyen",
      "Thanh Pho Tuyen Quang",
      "Thon Bao An",
      "Thong Nhat",
      "Thu Dau Mot",
      "Thu Duc",
      "Thuan An",
      "Thuan Cong",
      "Tra Vinh",
      "Trao",
      "Truong Lam",
      "Truong Son",
      "Van Hai",
      "Van Tan",
      "Vi Thanh",
      "Vinh",
      "Vinh Long",
      "Vinh Yen",
      "Xom Phuoc My",
      "Xom Thang",
      "Xom Truong Dinh",
      "Yen Bai",
      "Yen Thanh"
    ]
  },
  {
    "country": "Yemen",
    "cities": [
      "Abyan",
      "'Adan",
      "Ad Dali'",
      "Al Bayda'",
      "Al Hudaydah",
      "Al Jawf",
      "Al Mahrah",
      "Al Mahwit",
      "'Amran",
      "Dhamar",
      "Hadramawt",
      "Hajjah",
      "Ibb",
      "Lahij",
      "Ma'rib",
      "Sa'dah",
      "San'a'",
      "Shabwah",
      "Ta'izz"
    ]
  },
  {
    "country": "Zambia",
    "cities": [
      "Kalomo",
      "Kitwe",
      "Livingstone",
      "Lusaka",
      "Macha",
      "Mumbwa",
      "Ndola",
      "Siavonga",
      "Solwezi"
    ]
  },
  {
    "country": "Zimbabwe",
    "cities": [
      "Bulawayo",
      "Chinhoyi",
      "Greendale",
      "Gwanda",
      "Harare",
      "Kwekwe",
      "Mufakose",
      "Mutare",
      "Victoria Falls"
    ]
  },
  {
    "country": "Fiji",
    "cities": [
      "Suva",
      "Lautoka",
      "Nadi",
      "Levuka",
      "Ba",
      "Lami",
      "Savusavu",
      "Rakiraki",
      "Tavua",
      "Sigatoka",
      "Navua",
      "Nausori",
      "Korovou",
      "Labasa",
      "Vunisea",
      "Laukota",
      "Vilivilin",
      "Somosomo",
      "Kadavu",
      "Vailoku",
      "Savuna",
      "Malha",
      "Vaiwai"
    ]
  },
  {
    "country": "Hawaii",
    "cities": [
      "Honolulu",
      "Hilo",
      "Kailua",
      "Kaneohe",
      "Waipahu",
      "Pearl City",
      "Waimalu",
      "Mililani Town",
      "Kahului",
      "Kihei",
      "Wahiawa",
      "Ewa Gentry",
      "Makakilo",
      "Schofield Barracks",
      "Wailuku",
      "Kaneohe Station",
      "Halawa",
      "Waianae",
      "Nanakuli",
      "Kailua",
      "Lahaina",
      "Waimea",
      "Kahului",
      "Aiea",
      "Ewa Beach",
      "Wailua",
      "Waimanalo",
      "Heeia",
      "Makaha",
      "Hawaiian Paradise Park",
      "Hilo",
      "Kapaa",
      "Kalaoa",
      "Kaneohe",
      "Kalaheo",
      "Lihue",
      "Waikoloa Village",
      "Kahaluu",
      "Kealakekua",
      "Makawao",
      "Wailea",
      "Pukalani",
      "Waihee-Waiehu",
      "Laie",
      "Ewa Villages",
      "Kapolei",
      "Napili-Honokowai",
      "Pupukea",
      "Waimanalo Beach",
      "Haiku-Pauwela",
      "Wailea",
      "Makaha Valley",
      "Waimea",
      "Haleiwa",
      "Waikele",
      "Kalaheo",
      "Lanai City",
      "Holualoa",
      "Lanai City",
      "Kahuku",
      "Maunawili",
      "Waikapu",
      "Waialua",
      "Kilauea",
      "Mountain View",
      "Hawaiian Ocean View",
      "Hawaiian Beaches",
      "Wainaku",
      "Kahaluu-Keauhou",
      "Ewa Beach",
      "Pahala",
      "Kahaluu",
      "Honaunau-Napoopoo",
      "Volcano",
      "Princeville",
      "Hawaiian Acres",
      "Punaluu",
      "Kaanapali",
      "Papaikou",
      "Paia",
      "Keaau",
      "Hawi",
      "Captain Cook",
      "Kapaau",
      "Kurtistown",
      "Kekaha",
      "Anahola",
      "Honomu",
      "Kaunakakai",
      "Mokuleia",
      "Waimanalo Beach",
      "Honalo",
      "Paukaa",
      "Papaikou",
      "Waihee-Waikapu",
      "Keokea",
      "Pepeekeo"
    ]
  },
  {
    "country": "New Caledonia",
    "cities": [
      "Nouméa",
      "Mont-Dore",
      "Dumbéa",
      "Païta",
      "Wé",
      "Koné",
      "Bourail",
      "Koumac",
      "Poum",
      "Pouébo",
      "Poindimié",
      "Houaïlou",
      "Ouégoa",
      "Kaala-Gomen",
      "Pouembout",
      "Touho",
      "Poya",
      "Canala",
      "Voh",
      "Thio",
      "Kouaoua",
      "La Foa",
      "Sarraméa",
      "Farino",
      "Moindou",
      "Bouloupari",
      "Yaté",
      "Île des Pins",
      "Tadine",
      "Vao",
      "Bélep"
    ]
  },
  {
    "country": "New Zealand",
    "cities": [
      "Auckland",
      "Wellington",
      "Christchurch",
      "Hamilton",
      "Tauranga",
      "Napier-Hastings",
      "Dunedin",
      "Palmerston North",
      "Nelson",
      "Rotorua",
      "Whangarei",
      "New Plymouth",
      "Invercargill",
      "Whanganui",
      "Gisborne",
      "Masterton",
      "Levin",
      "Tokoroa",
      "Ashburton",
      "Timaru",
      "Taupo",
      "Pukekohe East",
      "Morrinsville",
      "Thames",
      "Kawerau",
      "Waitara",
      "Ōtaki",
      "Kerikeri",
      "Foxton",
      "Dargaville",
      "Waiuku",
      "Wairoa",
      "Raglan",
      "Taihape",
      "Eltham",
      "Te Aroha",
      "Roxburgh",
      "Waimate North",
      "Paihia",
      "Brightwater",
      "Te Kauwhata",
      "Opotiki",
      "Hāwera",
      "Winton",
      "Balclutha",
      "Waihi",
      "Marton",
      "Wairoa",
      "Warkworth",
      "Whitianga",
      "Turangi",
      "Waihi Beach",
      "Otorohanga",
      "Paeroa",
      "Westport",
      "Wellsford",
      "Tīrau",
      "Putaruru",
      "Picton",
      "Matakana",
      "Kerikeri",
      "Temuka",
      "Snells Beach",
      "Te Anau",
      "Katikati",
      "Tūrangi",
      "Dannevirke",
      "Arrowtown",
      "Te Kuiti",
      "Temuka",
      "Hāwera",
      "Leeston",
      "Warkworth",
      "Methven",
      "Waihi",
      "Murchison",
      "Te Kauwhata",
      "Waipukurau",
      "Paraparaumu Beach",
      "Ōpōtiki",
      "Waiuku",
      "Winton",
      "Balclutha",
      "Waipawa",
      "Hāwera",
      "Kerikeri",
      "Dargaville",
      "Waipawa",
      "Taihape",
      "Foxton",
      "Levin",
      "Dannevirke",
      "Te Anau",
      "Wairoa",
      "Brightwater",
      "Methven",
      "Ōtaki",
      "Waimate",
      "Kawerau",
      "Warkworth",
      "Picton",
      "Turangi",
      "Whitianga"
    ]
  },
]