import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

export const InputText = styled((props: TextFieldProps) => (
  <TextField InputLabelProps={{ shrink: true }}
    variant="outlined"
    {...props}
  />
))(({ theme }) => ({
  '&': {
    borderRadius: 10,
    paddingTop: '4px',
    paddingBottom: '4px',
    position: 'relative',
    marginBottom: '8px'
  },
  '&. .Mui-disabled': {
    backgroundColor: 'grey'
  },
  '& .MuiFormLabel-root': {
    position: 'static',
    transform: 'none',
    marginBottom: '5px',
    color: '#333333',
    fontSize: '10px',
    paddingLeft: '12px'
  },
  '& .MuiOutlinedInput-root': {
    overflow: 'hidden',
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#CCCCCC',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error': {
      borderColor: '#EB5757',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '12px',
      paddingTop: '6px',
      paddingBottom: '6px'
    },
    '& .Mui-disabled': {
      backgroundColor: 'grey'
    }
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  '.MuiFormHelperText-contained': {
    // position: 'absolute',
    bottom: '-12px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    display: 'none',
  }
}));