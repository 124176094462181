import { Stack, Box, Tab, Tabs, Typography, SelectChangeEvent, Button } from '@mui/material';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import SelectControl from '../elements/SelectControl';
import { useTheme } from '@mui/material/styles';
import { useState, createContext, useContext } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import AnalyticsMapFilters from '../blocks/AnalyticsMapFilters';
import FilterDrawer from '../blocks/FilterDrawer';
import { vibeColors } from '../../data/vibesColors';
import { BaseDatePicker } from '../elements/BaseDatePicker';

interface AnalyticsContextType {
  dateValue: string;
  selectedDays: number;
  mapTabData: {
    mapData: Array<string[]>;
    selectedMapView: string;
  };
  setMapTabData: any;
  selectedDate: {
    startDay: any;
    endDay: any;
  };
  currentVibeFilter: any;
}

const AnalyticsContext = createContext<AnalyticsContextType>(null!);

const Analytics = () => {
  const routeMatch = useRouteMatch([
    '/dashboard/analytics/map',
    '/dashboard/analytics/sessions',
    '/dashboard/analytics/usage',
    '/dashboard/analytics/users'
  ]);
  const currentTab = routeMatch?.pattern?.path;
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<any>();
  const [currentVibeFilter, setCurrentVibeFilter] = useState<string>('');
  const [dateValue, setDateValue] = useState('week');
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedDays, setSelectedDays] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>({
    startDay: new Date(),
    endDay: null
  });
  const [mapTabData, setMapTabData] = useState<any>({});
  const [mapSidebarFiltersOpened, setMapSidebarFiltersOpened] = useState(false);
  const theme = useTheme();

  const onChangeDate = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setSelectedDate({
      startDay: start,
      endDay: end
    })
  };

  const handleOpenDate = (e: any) => {
    if (dateValue === 'date range') {
      setShowDatePicker(true)
    }
  }
  const handleChangeDate = (event: SelectChangeEvent) => {
    if (event.target.value === 'date range') {
      setShowDatePicker(true)
    } else {
      setShowDatePicker(false)
    }
    setDateValue(event.target.value as string);
  };

  const hanleSortByFilters = (filters: any) => {
    const { ...rest } = filters;
    const newFilters = { ...rest };
    if (newFilters && Object.keys(newFilters).length !== 0) {
      setCurrentVibeFilter(newFilters['Vibe'][0]);
    } else {
      setCurrentVibeFilter('');
    }
  }

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }

  function getDaysDifference(date1: Date, date2: Date): number {
    const oneDayMs = 1000 * 60 * 60 * 24;
    const time1 = date1.getTime();
    const time2 = date2.getTime();
    const differenceMs = Math.abs(time1 - time2);
    return Math.floor(differenceMs / oneDayMs);
  }

  return (
    <Box sx={[{
      px: '35px',
      py: '20px',
      width: '100%',
      maxWidth: '100%',
    }]}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        mb: '40px'
      }}>
        <Typography
          variant="h1"
          component="h3"
        >
          Analytics
        </Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifySelf: 'flex-end',
          ml: 'auto',
          transition: 'margin-right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          ...(mapSidebarFiltersOpened && window.innerWidth > 890 || filtersOpen && window.innerWidth > 890 ? { mr: '325px' } : {})
        }}>
          <Stack direction="row" spacing={2}>
            {
              currentTab === '/dashboard/analytics/map' ?
                <>
                  <AnalyticsMapFilters
                    dateValue={dateValue}
                    startDate={startDate}
                    selectedDays={selectedDays}
                    endDate={endDate}
                    mapTabData={mapTabData}
                    setMapTabData={setMapTabData}
                    setMapSidebarFiltersOpened={setMapSidebarFiltersOpened}
                    currentVibeFilter={currentVibeFilter}
                  />
                  <FilterDrawer
                    open={filtersOpen}
                    setOpen={setFiltersOpen}
                    filters={{
                      Vibe: vibeColors.map((item: any) => item.vibe)
                    }}
                    mainTitle="Filters"
                    hanleSortByFilters={hanleSortByFilters}
                    type={'radio'}
                  />
                </> : null
            }
            <Box sx={{
              width: '210px',
              ml: 'auto',
              position: 'relative'
            }}>
              <SelectControl
                items={
                  [
                    { value: 'today', label: 'Today' },
                    { value: 'week', label: 'Week' },
                    { value: '2 weeks', label: '2 Weeks' },
                    { value: 'month', label: 'Month' },
                    { value: '3 month', label: '3 Months' },
                    { value: 'year', label: 'Year' },
                    { value: 'date range', label: 'Date range' },
                  ]
                }
                onChange={handleChangeDate}
                onOpen={handleOpenDate}
                value={dateValue}
              />
              {showDatePicker && <Box sx={{
                position: 'absolute',
                left: '-50%',
                top: '55px',
                zIndex: 1,
                backgroundColor: '#fff',
                padding: '20px 40px',
                border: '1px solid #E2E2E2',
                '& .react-datepicker': {
                  border: 'none',
                },
              }}>
                <BaseDatePicker
                  formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 1)}
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  selectsRange
                  inline
                />
                <Box sx={{
                  margin: '0 auto',
                  maxWidth: '225px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <Button
                    fullWidth
                    sx={{
                      mr: '10px',
                      color: '#000',
                      borderColor: '#C9C9C9',
                      '&:hover': {
                        borderColor: '#C9C9C9',
                        boxShadow: 'none',
                        opacity: '0.7'
                      },
                      '&:active': {
                        boxShadow: 'none',
                        borderColor: '#C9C9C9',
                      },
                    }}
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                    }}
                    variant="outlined"
                  >
                    Reset
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      if (startDate) {
                        const endDateValue = endDate ? endDate : startDate;
                        const days = getDaysDifference(startDate, endDateValue);
                        setSelectedDays(days === 0 ? 1 : days + 1);
                      }
                      setShowDatePicker(false);
                    }}>
                    Confirm
                  </Button>
                </Box>
              </Box>
              }
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box>
        <Tabs
          value={currentTab}
          aria-label="Analytics tabs"
          sx={{ minHeight: '0', height: '33px', mb: '35px' }}
        >
          <Tab label="Map" value="/dashboard/analytics/map" to="/dashboard/analytics/map" component={Link} />
          <Tab label="Sessions" value="/dashboard/analytics/sessions" to="/dashboard/analytics/sessions" component={Link} />
          <Tab label="Usage" value="/dashboard/analytics/usage" to="/dashboard/analytics/usage" component={Link} />
          <Tab label="Users" value="/dashboard/analytics/users" to="/dashboard/analytics/users" component={Link} />
        </Tabs>
      </Box>
      <AnalyticsContext.Provider value={{ dateValue, selectedDays, mapTabData, setMapTabData, selectedDate, currentVibeFilter }}>
        <Outlet />
      </AnalyticsContext.Provider>
    </Box>
  )
}

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export default Analytics