import { Box, Typography, Button, Pagination } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import SessionsTable from '../../blocks/sessionsTable';
import { Order, OrderBySessions } from '../../../types/types';
import { useResponseError } from '../../../hooks/useResponseError';
import { mockSessions } from '../../../data/data';
import { generateMockSessions, getComparator, searchSort, stableSort } from '../../../utils';
import { useAnalytics } from '../Analytics';
import { useDebounce } from '../../../hooks/useDebounce';
import SearchBox from '../../blocks/SearchBox';

const AnalyticsSessions = () => {
  const [sort, setSort] = useState('ALL');
  const [totalSesions, setTotalSessions] = useState('1256');
  const [totalPages, setTotalPages] = useState(0);
  const [averageSessionLength, setAverageSessionLength] = useState('2 min 30 sec');
  const [cumulativeSessiontime, setCumulativeSessiontime] = useState('16h 30min');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof OrderBySessions>('id');
  const [sessions, setSessions] = useState<any>(generateMockSessions(7));
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { responseError, setResponseError } = useResponseError(50000);
  const [isLoading, setIsLoading] = useState(true);
  const { dateValue, selectedDays } = useAnalytics();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 1000, '');


  useEffect(() => {
    if (selectedDays) {
      let sessionsMultiplier = selectedDays / 5;

      const totalSessions = 256 * sessionsMultiplier;
      const averageSessionLengthMinutes = Math.min(Math.max(2, Math.random() * 9 + 1), 10);
      const averageSessionLengthSeconds = Math.floor(Math.random() * 60);

      const cumulativeSessiontimeSeconds = totalSessions * averageSessionLengthMinutes * 60;
      const days = Math.floor(cumulativeSessiontimeSeconds / (3600 * 24));
      const hours = Math.floor((cumulativeSessiontimeSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((cumulativeSessiontimeSeconds % 3600) / 60);
      const seconds = Math.floor(cumulativeSessiontimeSeconds % 60);
      const formatDaysLabel = days > 1 ? `${days} days` : `${days} day`;
      const formatHoursLabel = hours > 1 ? `${hours} hours` : `${hours} hour`;

      const cumulativeSessiontimeValue = `${days > 0 ? formatDaysLabel : ''} ${hours > 0 ? formatHoursLabel : ''} ${minutes} min ${seconds} sec`;
      const averageSessionLengthValue = `${Math.floor(averageSessionLengthMinutes)} min ${averageSessionLengthSeconds.toFixed(0)} sec`;

      setTotalSessions(totalSessions.toFixed(0));
      setAverageSessionLength(averageSessionLengthValue);
      setCumulativeSessiontime(cumulativeSessiontimeValue);
    }
  }, [selectedDays]);

  useEffect(() => {
    if (dateValue) {
      setSessions(generateMockSessions(7));
      updateSessionsValues(dateValue);
    }
  }, [dateValue]);

  useEffect(() => {
    if (pageNumber) {
      sortTable(searchValue, sort, order, orderBy, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    sortTable(debouncedSearchValue, sort, order, orderBy, 1);

    if (searchValue || debouncedSearchValue) {
      setHasMore(true);
    }
  }, [debouncedSearchValue]);


  const updateSessionsValues = (selectedOption: string) => {
    let sessionsMultiplier = 1;
    switch (selectedOption) {
      case 'week':
        sessionsMultiplier = 3;
        break;
      case '2 weeks':
        sessionsMultiplier = 7;
        break;
      case 'month':
        sessionsMultiplier = 15;
        break;
      case '3 month':
        sessionsMultiplier = 25;
        break;
      case 'year':
        sessionsMultiplier = 50;
        break;
      default:
        sessionsMultiplier = 0.2;
    }

    const totalSessions = 256 * sessionsMultiplier;
    const averageSessionLengthMinutes = Math.min(Math.max(2, Math.random() * 9 + 1), 10);
    const averageSessionLengthSeconds = Math.floor(Math.random() * 60);

    const cumulativeSessiontimeSeconds = totalSessions * averageSessionLengthMinutes * 60;
    const days = Math.floor(cumulativeSessiontimeSeconds / (3600 * 24));
    const hours = Math.floor((cumulativeSessiontimeSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((cumulativeSessiontimeSeconds % 3600) / 60);
    const seconds = Math.floor(cumulativeSessiontimeSeconds % 60);
    const formatDaysLabel = days > 1 ? `${days} days` : `${days} day`;
    const formatHoursLabel = hours > 1 ? `${hours} hours` : `${hours} hour`;

    const cumulativeSessiontimeValue = `${days > 0 ? formatDaysLabel : ''} ${hours > 0 ? formatHoursLabel : ''} ${minutes} min ${seconds} sec`;
    const averageSessionLengthValue = `${Math.floor(averageSessionLengthMinutes)} min ${averageSessionLengthSeconds.toFixed(0)} sec`;

    setTotalSessions(totalSessions.toFixed(0));
    setAverageSessionLength(averageSessionLengthValue);
    setCumulativeSessiontime(cumulativeSessiontimeValue);
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleNextPage = (pgNumber: number) => {
    if (hasMore) {
      setPageNumber(pgNumber + 1)
    } else {
      setPageNumber(pgNumber);
    }
  }

  const handlePrevPage = (pgNumber: number) => {
    if (pgNumber > 0) {
      setPageNumber(pgNumber - 1)
    } else {
      setPageNumber(0);
    }
  }

  const handleResetSearch = () => {
    setPageNumber(1);
    sortTable(searchValue, sort, order, orderBy, 1);
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBySessions,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    setPageNumber(1);
    sortTable(searchValue, sort, orderValue, property, 1);
  };

  const sortTable = useCallback(async (
    searchField: string,
    sortField: string,
    orderField: Order,
    orderByField: string,
    pgNumber: number
  ) => {
    if (!hasMore) return;

    setIsLoading(true);
    let data: {
      take: number,
      page?: number,
      search?: string,
      roles?: string,
      order: string,
      orderBy: string
    } = {
      page: pgNumber,
      take: 7,
      order: orderField,
      orderBy: orderByField
    }

    if (searchField !== '') {
      data.search = searchField;
    }

    try {
      const searchedSessions = data.search ? searchSort(sessions, data.search) : sessions;
      const formatData = stableSort(searchedSessions, getComparator(orderField, orderByField));
      setSessions(formatData);
      setHasMore(false);
    } catch (error: any) {
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);

  const renderStatusBlock = (title: string, text: string) => {
    return (
      <Box sx={{
        mr: '10px',
        mb: '10px',
        border: '1px solid #E2E2E2',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '20px 30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'baseline',
      }}>
        <Typography
          sx={{
            mb: '15px',
            fontSize: '13px',
            fontWeight: '400',
            textTransform: 'uppercase',
            letterSpacing: '.04em;',
            color: '#333333'
          }}
          variant='body1'
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '1',
            color: '#422662'
          }}
          variant='body1'
        >
          {text}
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <SearchBox
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleResetSearch={handleResetSearch}
      />
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mb: '60px',
      }}>
        {renderStatusBlock('Total Sessions', totalSesions)}
        {renderStatusBlock('Average Session Length', averageSessionLength)}
        {renderStatusBlock('Cumulative session time', cumulativeSessiontime)}
      </Box>
      <Box sx={{
        position: 'relative',
        width: '100%',
        minHeight: '400px',
        mb: '60px',
      }}>
        <Box sx={[{
          maxWidth: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          left: '-2px',
        }]}>
          <Box sx={[{
            'display': 'flex',
            flexWrap: 'wrap',
            pb: '25px',
            maxWidth: '100%',
            width: '100%',
            overflow: 'auto',
          }]}>
            <SessionsTable
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              data={sessions}
              page={pageNumber}
            />
          </Box>
          <Box sx={{
            mt: '50px',
            mb: '110px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            {pageNumber > 1 && <Button
              sx={{
                mr: '15px',
                color: '#000',
                borderColor: '#C9C9C9',
                '&:hover': {
                  borderColor: '#C9C9C9',
                  boxShadow: 'none',
                  opacity: '0.7'
                },
                '&:active': {
                  boxShadow: 'none',
                  borderColor: '#C9C9C9',
                },
              }}
              onClick={() => handlePrevPage(pageNumber)}
              variant="outlined"
              disabled={pageNumber === 1}
            >
              Previous Page
            </Button>}
            {totalPages > 1 && <Pagination
              sx={{
                mr: '15px',
              }}
              onChange={handleChangePage}
              page={pageNumber}
              hideNextButton={true}
              hidePrevButton={true}
              count={totalPages}
              variant="outlined"
              color="primary"
            />}
            <Button
              sx={{
                color: '#000',
                borderColor: '#C9C9C9',
                '&:hover': {
                  borderColor: '#C9C9C9',
                  boxShadow: 'none',
                  opacity: '0.7'
                },
                '&:active': {
                  boxShadow: 'none',
                  borderColor: '#C9C9C9',
                },
              }}
              onClick={() => handleNextPage(pageNumber)}
              variant="outlined"
              disabled={!hasMore}
            >
              Next Page
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AnalyticsSessions;