import { useState } from 'react';
import {
  styled,
  SvgIcon,
  useTheme,
  Button,
  Box,
  Drawer,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Alert,
} from '@mui/material';
import BasicCheckbox from '../elements/BasicCheckbox';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { addHours } from '../../utils';
import { BaseDatePicker } from '../elements/BaseDatePicker';

interface Filters {
  [key: string]: string[];
}

export default function FilterDrawer({ open, setOpen, filters, mainTitle = 'Filter', hanleSortByFilters, type = 'checkbox', showDateFilters = false }: any) {
  const theme = useTheme();
  const [filtersState, setFiltersState] = useState<Filters>({});
  const [startDateCreationDate, setStartDateCreationDate] = useState(null);
  const [endDateCreationDate, setEndDateCreationDate] = useState(null);
  const [startDateCreatedAt, setStartDateCreatedAt] = useState(null);
  const [endDateCreatedAt, setEndDateCreatedAt] = useState(null);
  const [startDateUpdatedAt, setStartDateUpdatedAt] = useState(null);
  const [endDateUpdatedAt, setEndDateUpdatedAt] = useState(null);

  const onChangeCreationDate = (dates: any) => {

    const [start, end] = dates;
    setStartDateCreationDate(start);
    setEndDateCreationDate(end);
    updDateFilters('creationDate', start, end);
  };

  const onChangeCreatedAt = (dates: any) => {
    const [start, end] = dates;
    setStartDateCreatedAt(start);
    setEndDateCreatedAt(end);
    updDateFilters('createdAt', start, end);
  };

  const onChangeUpdatedAt = (dates: any) => {
    const [start, end] = dates;
    setStartDateUpdatedAt(start);
    setEndDateUpdatedAt(end);
    updDateFilters('updatedAt', start, end);
  };

  const resetFiltersState = () => {
    setFiltersState({});
    setStartDateCreationDate(null);
    setEndDateCreationDate(null);
    setStartDateCreatedAt(null);
    setEndDateCreatedAt(null);
    setStartDateUpdatedAt(null);
    setEndDateUpdatedAt(null);
    hanleSortByFilters({});
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    hanleSortByFilters(filtersState);
    handleDrawerClose();
  }

  const updDateFilters = (key: string, startDay: Date, endDay: Date) => {
    const newFilters: Filters = { ...filtersState };

    if (!newFilters[key]) {
      newFilters[key] = [];
    }

    if (startDay) {
      newFilters[key][0] = addHours(startDay, 4).toISOString().split('T')[0];
    } else {
      newFilters[key].shift();
    }
    if (endDay) {
      newFilters[key][1] = addHours(endDay, 4).toISOString().split('T')[0];
    } else {
      newFilters[key] = newFilters[key].slice(0, 1);
    }

    setFiltersState(newFilters);
  };

  const handleCheckboxChange = (key: string, value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters: Filters = { ...filtersState };

    if (!newFilters[key]) {
      newFilters[key] = [];
    }

    if (event.target.checked) {
      newFilters[key].push(value);
    } else {
      newFilters[key] = newFilters[key].filter((item) => item !== value);
    }

    setFiltersState(newFilters);
  };

  const handleRadioChange = (key: string, value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters: Filters = { ...filtersState };

    if (!newFilters[key]) {
      newFilters[key] = [];
    }

    if (event.target.checked) {
      newFilters[key] = [value];
    } else {
      newFilters[key] = [];
    }

    setFiltersState(newFilters);
  };

  const renderRadioItems = (key: string) => {
    return (<RadioGroup
      name="radio-buttons-group"
    >
      {filters[key] && filters[key].map((item: any, id: any) => (
        <FormControlLabel
          key={id}
          sx={{
            color: filtersState[key]?.includes(item) ? '#000000' : '#666666',
            '.MuiFormControlLabel-label': {
              fontSize: '13px',
            }
          }}
          control={
            <Radio
              key={id}
              checked={filtersState[key] ? filtersState[key]?.includes(item) : false}
              onChange={handleRadioChange(key, item)}
              value={item}
              inputProps={{ 'aria-label': 'A' }}
            />
          }
          label={item}
        />
      ))}
    </RadioGroup>);
  }

  const renderCheckboxItem = (key: string) => {
    return (filters[key] && filters[key].map((item: any, id: any) => (
      <FormControlLabel
        key={id}
        sx={{
          color: filtersState[key]?.includes(item) ? '#000000' : '#666666',
          '.MuiFormControlLabel-label': {
            fontSize: '13px',
          }
        }}
        control={
          <BasicCheckbox
            checked={filtersState[key] ? filtersState[key]?.includes(item) : false}
            onChange={handleCheckboxChange(key, item)}
          />
        }
        label={item}
      />
    )));
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          p: 0,
        }}
      >
        <SvgIcon sx={{
          width: '40px',
          height: '40px',
        }}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.5262 15H15.4739C15.2965 14.9997 15.1339 15.0963 15.0529 15.2502C14.9707 15.4061 14.9852 15.5939 15.0901 15.7364L18.7729 20.7976C18.7741 20.7994 18.7754 20.801 18.7767 20.8027C18.9105 20.979 18.9829 21.1925 18.9833 21.4118V25.5382C18.9825 25.6604 19.0317 25.7778 19.1199 25.8646C19.2082 25.9511 19.3282 26 19.4534 26C19.517 25.9999 19.5801 25.9876 19.6389 25.9637L21.7083 25.194C21.8937 25.1387 22.0167 24.968 22.0167 24.7625V21.4118C22.0171 21.1925 22.0895 20.979 22.2232 20.8027C22.2244 20.801 22.2258 20.7994 22.227 20.7976L25.9099 15.7362C26.0148 15.5939 26.0293 15.4062 25.9471 15.2503C25.8662 15.0963 25.7035 14.9997 25.5262 15Z" fill={open || Object.keys(filtersState).length > 0 ? "#A460F6" : "#C9C9C9"} />
          </svg>
        </SvgIcon>
      </IconButton>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            maxWidth: '335px',
            width: '100%',
            borderLeftColor: '#D9D9D9',
            top: '61.6px',
            height: 'calc(100vh - 61.6px)',
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{
          width: '100%',
          flexGrow: '1',
          py: '20px',
          pl: '40px',
          pb: '0',
          boxSizing: 'border-box',
          backgroundColor: '#FBFBFB',
        }}>
          <Box sx={{
            mb: '40px',
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography
              variant="h1"
              component="h3"
            >
              {mainTitle}
            </Typography>
            <IconButton
              sx={{
                p: 0,
                position: 'absolute',
                right: '20px',
                top: '20px'
              }}
              onClick={handleDrawerClose}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
                <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
              </svg>
            </IconButton>
          </Box>
          <Box
            sx={{
              mr: '10px',
              position: 'relative',
              maxHeight: 'calc(100% - 65px)',
              height: '100%',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '2px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px #E2E2E2',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
              },
            }}>
            <Box sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '5px',
              right: 0,
            }}>
              {showDateFilters &&
                <Box sx={{
                  mb: '30px',
                  '& .MuiFormLabel-root': {
                    color: '#333333',
                    fontSize: '18px',
                    fontFamily: 'Poppins, Arial, sans-serif',
                  },
                  '.react-datepicker__input-container input': {
                    maxWidth: '240px',
                    width: '100%',
                    boxSizing: 'border-box',
                    paddingLeft: '12px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    paddingRight: '30px',
                    border: `1px solid #CCCCCC`,
                    fontSize: '1rem',
                    lineHeight: '23px',
                    borderRadius: '4px',
                  },
                  '.react-datepicker__input-container input:focus': {
                    backgroundColor: '#fff',
                    outline: 0,
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                  '.react-datepicker-popper': {
                    left: '0px !important',
                  },
                  '& .react-datepicker': {
                    border: '1px solid #E2E2E2',
                  },
                }}>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '13px',
                      color: '#000',
                      textTransform: 'capitalize',
                      mb: '11px'
                    }}
                    variant="h5"
                  >
                    Date
                  </Typography>
                  <Box sx={{ mb: '11px' }}>
                    <InputLabel shrink htmlFor="creationDate-picker">
                      Creation Date
                    </InputLabel>
                    <BaseDatePicker
                      formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 1)}
                      id="creationDate-picker"
                      selected={endDateCreationDate}
                      onChange={onChangeCreationDate}
                      startDate={startDateCreationDate}
                      endDate={endDateCreationDate}
                      minDate={null}
                      maxDate={new Date()}
                      selectsRange
                      isClearable
                      placeholderText="Click to select a date"
                    />
                  </Box>
                  <Box sx={{ mb: '11px' }}>
                    <InputLabel shrink htmlFor="createdAt-picker">
                      Created At
                    </InputLabel>
                    <BaseDatePicker
                      formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 1)}
                      id="createdAt-picker"
                      selected={endDateCreatedAt}
                      onChange={onChangeCreatedAt}
                      startDate={startDateCreatedAt}
                      endDate={endDateCreatedAt}
                      minDate={null}
                      maxDate={new Date()}
                      selectsRange
                      isClearable
                      placeholderText="Click to select a date"
                    />
                  </Box>
                  <Box sx={{ mb: '14px', width: '100%' }}>
                    <InputLabel shrink htmlFor="updatedAt-picker">
                      Updated At
                    </InputLabel>
                    <BaseDatePicker
                      formatWeekDay={(nameOfDay: any) => nameOfDay.substr(0, 1)}
                      id="updatedAt-picker"
                      selected={endDateUpdatedAt}
                      onChange={onChangeUpdatedAt}
                      startDate={startDateUpdatedAt}
                      endDate={endDateUpdatedAt}
                      minDate={null}
                      maxDate={new Date()}
                      selectsRange
                      isClearable
                      placeholderText="Click to select a date"
                    />
                  </Box>
                </Box>
              }
              {filters && Object.keys(filters).map((key) => (
                <div key={key}>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '13px',
                      color: '#000',
                      textTransform: 'capitalize',
                      mb: '11px'
                    }}
                    variant="h5"
                  >
                    {key}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: '30px'
                    }}>
                    {type === 'radio' ?
                      renderRadioItems(key)
                      : renderCheckboxItem(key)
                    }
                  </Box>
                </div>
              ))}
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            mt: 'auto'
          }}
        />
        <Box sx={{
          display: 'flex',
          p: '20px',
          backgroundColor: '#fff',
          borderTop: '1px solid #D9D9D9'
        }}>
          <Button
            fullWidth
            sx={{
              mr: '10px',
              color: '#000',
              borderColor: '#C9C9C9',
              '&:hover': {
                borderColor: '#C9C9C9',
                boxShadow: 'none',
                opacity: '0.7'
              },
              '&:active': {
                boxShadow: 'none',
                borderColor: '#C9C9C9',
              },
            }}
            onClick={resetFiltersState}
            variant="outlined"
          >
            Reset
          </Button>
          <Button
            onClick={onSubmit}
            fullWidth
            variant="contained"
          >
            Confirm
          </Button>
        </Box>
      </Drawer >
    </Box >
  );
}