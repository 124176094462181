import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputText } from '../elements/InputText';
import { emailPattern } from '../../utils';
import SelectControl from '../elements/SelectControl';

type RegisterFormFields = {
  email: string;
  firstName: string;
  lastName: string;
  roles: 'ADMIN' | 'SUPER ADMIN';
};

const EditAdminDialog = ({ onSubmit, isOpen, handleOpen, handleClose, onDeleteSubmit, user, responseError }: any) => {
  const [showDelete, setShowDelete] = useState(false);
  const theme = useTheme();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields },
    setValue,
    getValues
  } = useForm<RegisterFormFields>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      roles: 'ADMIN',
    },
    mode: "onChange",
  });

  useEffect(() => {

    getUserObj();

    return () => { };
  }, [user]);

  const getUserObj = async () => {
    setShowDelete(false);
    if (user) {
      try {
        const userObj = user;
        setValue('email', userObj?.email, { shouldTouch: true })
        setValue('firstName', userObj?.firstName, { shouldTouch: true })
        setValue('lastName', userObj?.lastName, { shouldTouch: true })
        setValue('roles', userObj?.roles[0]?.replace('_', ' '), { shouldTouch: true })
      } catch (error: any) {
        console.error('error', error)
      }
    }
  };

  return (<Dialog
    fullScreen
    open={isOpen}
    onClose={handleClose}
    sx={{
      top: '2%',
      left: '2%',
      right: '2%',
      bottom: '2%',
      '.MuiDialog-paper': {
        padding: '40px',
        boxSizing: 'border-box',
      }
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{
        justifyContent: 'flex-end',
        maxWidth: '35px',
        width: '100%',
        ml: 'auto'
      }}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
        <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
      </svg>
    </IconButton>
    <Box sx={{
      maxWidth: '335px',
      width: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <DialogTitle>{!showDelete ? 'Edit admin' : 'Are you sure?'}</DialogTitle>
      {!showDelete && <DialogContent sx={{
        px: 0,
        pb: '0',
        width: '100%'
      }}>
        <Box
          component="form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            '& > :not(style)': { width: '100%' },
            'display': 'flex',
            'flexDirection': 'column',
          }}
          id="hook-form-admin"
        >
          <InputText
            autoFocus
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            placeholder="Email"
            inputProps={{ ...register("email", { required: true, pattern: emailPattern.value }) }}
            error={!!errors['email']}
            helperText={errors['email'] ? emailPattern.message : ''}
          />
          <InputText
            autoFocus
            required
            id="firstname"
            name="firstName"
            label="First name"
            type="text"
            fullWidth
            placeholder="First name"
            inputProps={{ ...register("firstName", { required: true }) }}
            error={!!errors['firstName']}
            helperText={errors['firstName'] ? 'This is required' : ''}
          />
          <InputText
            autoFocus
            required
            id="lastname"
            name="lastname"
            label="Last name"
            type="text"
            fullWidth
            variant="outlined"
            placeholder="Last name"
            inputProps={{ ...register("lastName", { required: true }) }}
            error={!!errors['lastName']}
            helperText={errors['lastName'] ? 'This is required' : ''}
          />
          <Controller
            control={control}
            name='roles'
            render={({ field: { onChange, onBlur, value } }) => (
              <SelectControl
                label='Role'
                items={
                  [
                    { value: 'ADMIN', label: 'Admin' },
                    { value: 'SUPER ADMIN', label: 'Super Admin' },
                  ]
                }
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Box>
        <Box sx={{
					textAlign: 'left',
					color: theme.palette.error.main,
				}}>{responseError}</Box>
      </DialogContent>}
      <DialogActions sx={{
        width: '100%',
        px: 0,
        py: 0,
        mt: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {!showDelete ? <Button form="hook-form-admin" fullWidth variant="contained" type="submit">
          Save
        </Button> :
        <Button onClick={onDeleteSubmit} fullWidth variant="contained">Yes, delete admin</Button>}
        <Button
          onClick={() => setShowDelete(!showDelete)}
          sx={{
            mt: '20px',
            py: '1px',
            textDecoration: 'underline',
            color: '#000',
            fontWeight: '400',
            fontSize: '13px',
            '&:hover': {
              textDecoration: 'underline',
            }
          }}>
          {!showDelete ? 'Delete admin' : 'Back'}
        </Button>
      </DialogActions>
    </Box>
  </Dialog>);
}

export default EditAdminDialog;