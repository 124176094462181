import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { OrderBy, Order } from '../../types/types';
import { visuallyHidden } from '@mui/utils';

interface Data {
  roles: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  action?: any;
}

interface Column {
  id: 'roles' | 'firstName' | 'lastName' | 'email' | 'verified' | 'action';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const getColumns = (isSuperAdmin: boolean) => {

  const columns: Column[] = [
    {
      id: 'email',
      label: 'Email',
      minWidth: 70,
    },
    {
      id: 'firstName',
      label: 'First name',
      minWidth: 70,
    },
    {
      id: 'lastName',
      label: 'Last name',
      minWidth: 70,
    },
    {
      id: 'roles',
      label: 'Role',
      minWidth: 90,
    },
    {
      id: 'verified',
      label: 'Active',
      minWidth: 30,
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 30,
    },
  ];

  if(!isSuperAdmin) {
    columns.pop();
  }

  return columns
}



interface UsersTableProps {
  data: any;
  handleRequestSort: any,
  order: Order,
  orderBy: keyof OrderBy,
  page: number,
  handleEditUser: any,
  handleResendUser: any,
  isSuperAdmin: boolean,
}

const UsersTable: React.FC<UsersTableProps> = (
  { data, handleRequestSort, order, orderBy, page, handleEditUser, handleResendUser, isSuperAdmin }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const columns = getColumns(isSuperAdmin);

  useEffect(() => {
    if (data) {
      setRows(formatUserData(data));
    }
  }, [data]);

  const formatUserData = (arr: any) => {
    return arr.map((obj: any) => {
      const { zip, city, state, ...rest } = obj;
      const location = [state, city, zip].filter(Boolean).join(', ');
      const roles = obj.roles.map((item: any) => item.toLowerCase()).join(', ').replace('_', ' ');;

      if (isSuperAdmin) {
        const verified = !obj.verified ?
          <Button onClick={() => handleResendUser(obj)} sx={{
            textTransform: 'capitalize',
            textDecoration: 'underline',
            fontWeight: '400',
            padding: 0,
            minWidth: 0,
          }}>Resend</Button> :
          <Typography
            variant='body1'
          >Yes</Typography>;
        const action = <Button onClick={() => handleEditUser(obj)} sx={{
          textTransform: 'capitalize',
          textDecoration: 'underline',
          fontWeight: '400',
          padding: 0,
          minWidth: 0,
        }}>Edit</Button>

        return {
          ...rest,
          roles,
          location,
          verified,
          action,
        };
      } else {
        const verified = 
        <Typography
          variant='body1'
        >{!obj.verified ? 'No' : 'Yes'}</Typography>;

        return {
          ...rest,
          roles,
          location,
          verified,
        };
      }

    });
  }

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <TableRow
          sx={{
            cursor: 'pointer',
          }}
          hover role="checkbox" tabIndex={-1} key={row.id + rowId}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            return (
              <TableCell
                sx={{
                  color: '#333333',
                  fontSize: '16px',
                  fontWeight: '400',
                  border: '1px solid #E2E2E2',
                  textTransform: column.id === 'roles' ? 'capitalize' : 'none',
                  backgroundColor: '#FFFFFF',
                  py: '10px',
                  px: '20px',
                }}
                key={row.id + id + rowId}
                align={column.align}
              >
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    })
  }

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      if(property === 'action') {
        return
      }
      handleRequestSort(event, property);
    };

  return (
    <div>
      <Paper sx={{
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}>
        <TableContainer>
          <Table sx={{
            backgroundColor: 'transparent',
          }}>
            <TableHead>
              <TableRow>
                {columns.map((column, id) => (
                  <TableCell
                    sx={[
                      {
                        color: '#333333',
                        fontSize: '16px',
                        fontWeight: '500',
                        py: '10px',
                        px: '20px',
                        border: '1px solid #E2E2E2',
                        textTransform: 'capitalize',
                      },
                      (theme) => ({
                        [theme.breakpoints.up('md')]: {
                          minWidth: column.minWidth
                        }
                      }),
                    ]}
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                      hideSortIcon={true}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(rows)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>);
};

export default UsersTable;