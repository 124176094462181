import { handleApiError } from "./handleApiError";
import instance from './index';

type TGetSoundById = {
  id: string,
};

type TSoundData = {
  originalName: string,
  fileName: string,
  path: string
}

type TRemoveSound = {
  id: string,
}

type TPublishSound = {
  id: string,
  status: boolean,
}

type TGetSounds = {
  page?: number,
  take?: number,
  searchText?: string,
  orderBy?: string,
  order?: string,
  filters?: {
    intention?: string[];
    keys?: string[];
    tempo?: string[];
    layer?: string[];
    tone?: string[];
    voice?: string[];
    voiceSupport?: string[];
    types?: string[];
    category?: string[];
    status?: 'DRAFT' | 'PUBLISHED';
  },
  search?: string
}

type TFilterKeys = keyof TGetSounds['filters'];

export const getSounds = async ({
  page = 1,
  take = 12,
  orderBy = 'createdAt',
  order = 'desc',
  filters,
  search
}: TGetSounds) => {
  try {
    let params = `?page=${page}&take=${take}&sorting[orderBy]=${orderBy}&sorting[order]=${order}${search ? `&search=${search}` : ''}`;

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value) && key !== 'status' && value?.length > 0) {
          value.forEach((item: string) => {
            params += `&filters[${key}][]=${encodeURIComponent(item)}`;
          });
        } else {
          if (key === 'status' && value?.length > 0) {
            console.log('status', key, value);
            params += `&filters[${key}]=${value}`;
          } else if (value && !Array.isArray(value)) {
            params += `&filters[${key}]=${value}`;
          }
        }
      });

      if (params.includes('&filters[status]=DRAFT,PUBLISHED')) {
        params = params.replace('&filters[status]=DRAFT,PUBLISHED', '');
      }
    }


    const response = await instance.get(`/admin/sound-experience-packages${params}`);

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getSoundById = async ({ id }: TGetSoundById) => {
  try {
    const response = await instance.get(`/admin/sound-experience-packages/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const removeSound = async ({ id }: TRemoveSound) => {
  try {
    const response = await instance.delete(`/admin/sound-experience-packages/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const publishSound = async ({ id, status }: TPublishSound) => {
  try {
    const data = {
      status: status ? 'PUBLISHED' : 'DRAFT',
    }
    const response = await instance.patch(`/admin/sound-experience-packages/${id}`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const addSound = async (data: any) => {
  try {
    const response = await instance.post(`/admin/sound-experience-packages`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getFilters = async () => {
  try {
    const response = await instance.get(`/admin/sound-experience-packages/available-filter-values`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};
