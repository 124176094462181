import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Link } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useTheme } from '@mui/material/styles';
import { InputText } from '../../elements/InputText';
import { emailPattern } from '../../../utils';
import { useResponseError } from '../../../hooks/useResponseError';
import { requestPasswordResetAuth } from '../../../service/authService';

type RegisterFormFields = {
	email: string;
};

const AccountRequestReset = () => {
	const navigate = useNavigate();
	const { responseError, setResponseError } = useResponseError();
	const theme = useTheme();
	const [isSentShow, setIsSentShow] = useState<boolean>(false);

	const {
		control,
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors, dirtyFields },
	} = useForm<RegisterFormFields>({
		defaultValues: {
			email: '',
		},
		mode: "onChange",
	});

	const onSubmit = async (data: any) => {
		try {
			const res = await requestPasswordResetAuth({
				data
			});
			setIsSentShow(true);
		} catch (error: any) {
			setResponseError(error.message)
		}
	}

	return <>
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				width: '100%',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<Typography variant="h4" component="h1" style={{
				fontWeight: '500',
				fontSize: '21px',
				color: '#333',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				{isSentShow ? 'Email sent' : 'Reset password'}
			</Typography>

			<Box
				sx={{
					'& > :not(style)': { width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant="body1" style={{
					fontSize: '13px',
					color: '#666',
					textAlign: 'center',
					marginBottom: isSentShow ? '10px' : '20px',
				}}>
					{isSentShow
						? 'If a user with this email exists in our system, we will send a link to reset your password.'
						: 'Enter your email address to receive a link to reset your password.'}
				</Typography>
				{!isSentShow && <Box
					component="form"
					autoComplete="off"
					onSubmit={handleSubmit(onSubmit)}
					sx={{
						'& > :not(style)': { my: 0.5, width: '100%' },
						'display': 'flex',
						'flexDirection': 'column',
						'allignItems': 'center',
						'width': '100%',
					}}
				>
					<InputText
						label="Email"
						placeholder="Email"
						inputProps={{ ...register("email", { required: true, pattern: emailPattern.value }) }}
						error={!!errors['email']}
						helperText={errors['email'] ? emailPattern.message : ''}
					/>
					<Box sx={{ textAlign: 'left', color: theme.palette.error.main }}>{responseError}</Box>
					<Box sx={{ textAlign: 'center', mt: '12px' }}>
						<Button type="submit" sx={{ mt: '2px', py: '8px', borderRadius: '20px', width: '100%' }} variant="contained">Send email</Button>
					</Box>
				</Box>}
				<Box sx={{ textAlign: 'center', mt: '6px' }}>
					<Link
						sx={{
							p: '10px',
							display: 'inline-flex',
							textDecoration: 'underline',
							color: '#000',
							fontWeight: '400',
							fontSize: '13px',
						}} href="/signin">Login</Link>
				</Box>
			</Box>
		</Box>
	</>;
};

export default AccountRequestReset;
