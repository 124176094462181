import { useState, useEffect, createContext, useContext } from 'react';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  SvgIcon,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  IconButton,
} from '@mui/material';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: '#422662',
  position: 'relative',
  top: 0,
  bottom: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#422662',
  position: 'relative',
  top: 0,
  bottom: 0,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface LaoyutContextType {
  value?: any
}

const LaoyutContext = createContext<LaoyutContextType>(null!);

const Layout = () => {
  const [openSide, setOpenSide] = useState(true);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <LaoyutContext.Provider value={{
        value: 'test'
      }}>
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <Box
            component="main"
            sx={{
              position: 'fixed',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              flexGrow: 1,
              width: '100%',
              display: 'flex',
              backgroundColor: '#422662',
            }}>
            <Box sx={{
              margin: '20px',
              backgroundColor: '#fff',
              flexGrow: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Outlet />
            </Box>
          </Box>
        </Box>
      </LaoyutContext.Provider>
    </Box >
  )
};

export const useLayout = () => {
  return useContext(LaoyutContext);
};

export default Layout;