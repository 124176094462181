import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Link, Typography } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useTheme } from '@mui/material/styles';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { useResponseError } from '../../../hooks/useResponseError';
import { InputText } from '../../elements/InputText';
import { passwordResetAuth } from '../../../service/authService';

type RegisterFormFields = {
	password: string;
	passwordConfirm: string;
};

const AccountReset = () => {
	const navigate = useNavigate();
	const theme = useTheme();
	const { responseError, setResponseError } = useResponseError();
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const userId = searchParams.get("userId");

	const {
		control,
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors, dirtyFields },
	} = useForm<RegisterFormFields>({
		defaultValues: {
			password: '',
			passwordConfirm: '',
		},
		mode: "onChange",
	});

	const onSubmit = async (data: any) => {
		const formatData = {
			token,
			...data
		};
		if (token) {
			try {
				const formatData = {
					userId: userId,
					token,
					...data
				};

				const resPassword = await passwordResetAuth({
					data: formatData
				});
				navigate(`/reset-step2`, {
					replace: true
				});
			} catch (error: any) {
				setResponseError(error.message)
			}
		}
	}

	return <>
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				width: '100%',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<Typography variant="h4" component="h1" style={{
				fontWeight: '400',
				fontSize: '21px',
				color: '#333',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				Create new password
			</Typography>

			<Box
				sx={{
					'& > :not(style)': { width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
					alignItems: 'center',
				}}
			>
				<Box
					component="form"
					autoComplete="off"
					onSubmit={handleSubmit(onSubmit)}
					sx={{
						'& > :not(style)': { my: 0.5, width: '100%' },
						'display': 'flex',
						'flexDirection': 'column',
						'allignItems': 'center',
						'width': '100%',
					}}
				>
					<InputText
						label="New Password"
						type="password"
						placeholder="Password"
						inputProps={{
							...register("password", {
								required: true,
								validate: {
									isValid: (value) => {
										return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
									}
								}
							})
						}}
						error={!!errors['password']}
						helperText={errors['password'] ? errors['password'].message : ''}
					/>
					<InputText
						label="Confirm new password"
						placeholder="Password"
						type="password"
						inputProps={{
							...register("passwordConfirm", {
								required: true,
								validate: {
									isValid: (value: any) => {
										return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
									},
									isMatch: (value: any) => {
										return watch('password') === value || "The passwords do not match";
									}
								}
							})
						}}
						error={!!errors['passwordConfirm']}
						helperText={errors['passwordConfirm'] ? errors['passwordConfirm'].message : ''}
					/>
					<Box sx={{ textAlign: 'left', color: theme.palette.error.main }}>{responseError}</Box>
					<Box sx={{ textAlign: 'center' }}>
						<Button type="submit" sx={{ mt: '2px', py: '8px', borderRadius: '20px', width: '100%' }} onClick={() => console.log('send')} variant="contained">Save new password</Button>
					</Box>
				</Box>
				<Box sx={{ textAlign: 'center', mt: '6px' }}>
					<Link
						sx={{
							p: '10px',
							display: 'inline-flex',
							textDecoration: 'underline',
							color: '#000',
							fontWeight: '400',
							fontSize: '13px',
						}} href="/signin">Login</Link>
				</Box>
			</Box>
		</Box>
	</>;
};

export default AccountReset;
