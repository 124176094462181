interface Constraint {
  [key: string]: string;
}

interface ErrorMessage {
  constraints: Constraint;
  error: string;
  statusCode: number;
}

function extractMessages(array: ErrorMessage[]): string {
  let messagesString: string = "";
  array.forEach(item => {
    if (item.hasOwnProperty("constraints")) {
      for (const constraint in item.constraints) {
        messagesString += `${item.constraints[constraint]}, `;
      }
    }
  }
  );
  return messagesString.trim();
}

function collectErrors(obj: any, errors: any[] = [], parentProperties: any[] = []) {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      obj.forEach(item => collectErrors(item, errors, parentProperties));
    } else {
      const { property, children, constraints } = obj;
      
      if (!parentProperties.includes(property)) {
        parentProperties.push(property);
      }
      
      if (constraints) {
        Object.entries(constraints).forEach(([constraint, message]) => {
          errors.push({ properties: [...parentProperties], message });
        });
      }
      
      if (children) {
        Object.values(children).forEach(child => collectErrors(child, errors, [...parentProperties]));
      }
    }
  }
  return errors;
}

export const handleApiError = (error: any): void => {
  console.error('Handle Api Error', error)
  const errorMessage = error.response?.data?.errors;

  if (error.response?.status === 401) {

    if (!window.location.pathname.includes('/dashboard')) {
      throw new Error('Incorrect credentials or account not verified')
    } else {
      window.location.replace("/signin");
    }
  }

  if (error.response?.data?.type === "DB-002") {
    throw new Error(errorMessage + '. Please login.')
  }

  if (error.response?.data?.type === "AUTH-0005") {
    throw new Error('Old Password is incorrect')
  }

  if (Array.isArray(errorMessage) && errorMessage.length) {
    if (errorMessage[0]?.constraints) {
      const errorMsg = extractMessages(errorMessage);
      console.log(errorMsg);
      throw new Error(errorMsg);
    }
    throw new Error(errorMessage[0]);
  }
  if (errorMessage) {
    if(errorMessage && typeof errorMessage === 'object' && errorMessage.constructor === Object) {
      const arrErr = [];
      for (var prop in errorMessage) {
        if( errorMessage[prop] === 'cantUploadFileType') {
          arrErr.push(`${prop}: incorrect format, please try zip file`);
        } else {
          arrErr.push(`${prop}: ${errorMessage[prop]}`);
        }
      }
      const errorStr = arrErr.join(', ');
      throw new Error(errorStr);
    } else {
      throw new Error(errorMessage);
    }
  }
  if(error.response.data.message) {
    if(Array.isArray(error.response.data.message)) {
      const arr = collectErrors(error.response.data.message[0].children);
      const formatErrors = arr.map((item) => `${item.properties.slice(0, item.properties.length - 1).join(' ')}: ${item.message}`).join(',');

      throw new Error(formatErrors);
    } else {
      throw new Error(error.response.data.message);
    }
  }

  if (error.message) {
    throw new Error(error.message);
  }

};
