export const mapDropdownOptions: { [key: string]: string } = {
  "World": "001",
  "North America": "021",
  "Africa": "002",
  "Asia": "142",
  "Europe": "150",
  "Oceania": "009",
  "South America": "005",
};

export const mapDataSessions = [
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bujumbura",
    "country": "BI",
    "countryName": "Burundi",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ottawa",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Vancouver",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Winnipeg",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mississauga",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brampton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jaboatão dos Guararapes",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Osasco",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lubango",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Huambo",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mar del Plata",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gold Coast",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rājshāhi",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cochabamba",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-17"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-13"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-30"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-09"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-01-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-20"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-23"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-29"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-28"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-22"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-21"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-01-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-01-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-03"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-10"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-24"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-02"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kolwezi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Likasi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tshikapa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bukavu",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Leshan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cúcuta",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucaramanga",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Djibouti",
    "country": "DJ",
    "countryName": "Djibouti",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Oeste",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo Este",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brussels",
    "country": "BE",
    "countryName": "Belgium",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cotonou",
    "country": "BJ",
    "countryName": "Benin",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sarajevo",
    "country": "BA",
    "countryName": "Bosnia and Herzegovina",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Oran",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kabul",
    "country": "AF",
    "countryName": "Afghanistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yerevan",
    "country": "AM",
    "countryName": "Armenia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Vienna",
    "country": "AT",
    "countryName": "Austria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Baku",
    "country": "AZ",
    "countryName": "Azerbaijan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sofia",
    "country": "BG",
    "countryName": "Bulgaria",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Belém",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Minsk",
    "country": "BY",
    "countryName": "Belarus",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Xinyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Weinan",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shijiazhuang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Laiwu",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hengyang",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Changchun",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Linyi",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hohhot",
    "country": "CN",
    "countryName": "China",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cali",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Barranquilla",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bogotá",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Medellín",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cartagena",
    "country": "CO",
    "countryName": "Colombia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Luanda",
    "country": "AO",
    "countryName": "Angola",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rosario",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Córdoba",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Buenos Aires",
    "country": "AR",
    "countryName": "Argentina",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Adelaide",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sydney",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Melbourne",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Perth",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Brisbane",
    "country": "AU",
    "countryName": "Australia",
    "continent": "Oceania",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dhaka",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Comilla",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Khulna",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rangpur",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Chattogram",
    "country": "BD",
    "countryName": "Bangladesh",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "La Paz",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santa Cruz de la Sierra",
    "country": "BO",
    "countryName": "Bolivia",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Maceió",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Nova Iguaçu",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Goiânia",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guarulhos",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manaus",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Natal",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Duque de Caxias",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "São Luís",
    "country": "BR",
    "countryName": "Brazil",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bobo-Dioulasso",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ouagadougou",
    "country": "BF",
    "countryName": "Burkina Faso",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Toronto",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Calgary",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montréal",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Edmonton",
    "country": "CA",
    "countryName": "Canada",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kisangani",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kinshasa",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lubumbashi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mbuji-Mayi",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kananga",
    "country": "CD",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Santiago de los Caballeros",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Havana",
    "country": "CU",
    "countryName": "Cuba",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Frankfurt am Main",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Köln",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Munich",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Großzschocher",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Essen",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Copenhagen",
    "country": "DK",
    "countryName": "Denmark",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santo Domingo",
    "country": "DO",
    "countryName": "Dominican Republic",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Algiers",
    "country": "DZ",
    "countryName": "Algeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hamburg",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Berlin",
    "country": "DE",
    "countryName": "Germany",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coimbatore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mysore",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kolkata",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Faridabad",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kota",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kanayannur",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Agra",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bhopāl",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puducherry",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Varanasi",
    "country": "IN",
    "countryName": "India",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Medan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Malang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pekanbaru",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Balikpapan",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Batam",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Denpasar",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jakarta",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Padang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Situbondo",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jepara",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tangerang",
    "country": "ID",
    "countryName": "Indonesia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karaj",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kerman",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pasragad Branch",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Orūmīyeh",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rasht",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Qom",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Erbil",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Abū Ghurayb",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Başrah al Qadīmah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baghdad",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "As Sulaymānīyah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karbala",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Basrah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mosul",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Mawşil al Jadīdah",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kirkuk",
    "country": "IQ",
    "countryName": "Iraq",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Isfahan",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tehran",
    "country": "IR",
    "countryName": "Iran",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Reynosa",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Naucalpan de Juárez",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Saltillo",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tijuana",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Luis Potosí",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Culiacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mérida",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Monterrey",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Santa María Chimalhuacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Coyoacán",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Puebla",
    "country": "MX",
    "countryName": "Mexico",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ipoh",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Johor Bahru",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kuala Lumpur",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Klang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Petaling Jaya",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Shah Alam",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kampung Baru Subang",
    "country": "MY",
    "countryName": "Malaysia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mandalay",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nay Pyi Taw",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Yangon",
    "country": "MM",
    "countryName": "Myanmar",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kathmandu",
    "country": "NP",
    "countryName": "Nepal",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Benin City",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kaduna",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ilorin",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lagos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ibadan",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ile-Ife",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jos",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maiduguri",
    "country": "NG",
    "countryName": "Nigeria",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cebu City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taguig",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pasig City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "General Santos",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Paranaque City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Piñas",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Quezon City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Caloocan City",
    "country": "PH",
    "countryName": "Philippines",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Yaroslavl",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Irkutsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Khabarovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ulyanovsk",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Volgograd",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Moscow",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ufa",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Samara",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rostov-na-Donu",
    "country": "RU",
    "countryName": "Russian Federation",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tabuk",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Medina",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Buraydah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dammam",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ta’if",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sulţānah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ha'il",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Riyadh",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Jeddah",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Mecca",
    "country": "SA",
    "countryName": "Saudi Arabia",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Touba",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pikine",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Dakar",
    "country": "SN",
    "countryName": "Senegal",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-23"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phnom Penh",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Takeo",
    "country": "KH",
    "countryName": "Cambodia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bangui",
    "country": "CF",
    "countryName": "Central African Republic",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abidjan",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abobo",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bouaké",
    "country": "CI",
    "countryName": "Côte d'Ivoire",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Brazzaville",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pointe-Noire",
    "country": "CG",
    "countryName": "Congo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Prague",
    "country": "CZ",
    "countryName": "Czech Republic",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cuenca",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Quito",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guayaquil",
    "country": "EC",
    "countryName": "Ecuador",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Giza",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Suez",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Maḩallah al Kubrá",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cairo",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shubrā al Khaymah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tanta",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Mansurah",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port Said",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Alexandria",
    "country": "EG",
    "countryName": "Egypt",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Asmara",
    "country": "ER",
    "countryName": "Eritrea",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Helsinki",
    "country": "FI",
    "countryName": "Finland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tbilisi",
    "country": "GE",
    "countryName": "Georgia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zagreb",
    "country": "HR",
    "countryName": "Croatia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Addis Ababa",
    "country": "ET",
    "countryName": "Ethiopia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Marseille",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Paris",
    "country": "FR",
    "countryName": "France",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kumasi",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Accra",
    "country": "GH",
    "countryName": "Ghana",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Camayenne",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Conakry",
    "country": "GN",
    "countryName": "Guinea",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Guatemala City",
    "country": "GT",
    "countryName": "Guatemala",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Port-au-Prince",
    "country": "HT",
    "countryName": "Haiti",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Budapest",
    "country": "HU",
    "countryName": "Hungary",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dublin",
    "country": "IE",
    "countryName": "Ireland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kingston",
    "country": "JM",
    "countryName": "Jamaica",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Amman",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zarqa",
    "country": "JO",
    "countryName": "Jordan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Shymkent",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Almaty",
    "country": "KZ",
    "countryName": "Kazakhstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Pyongyang",
    "country": "KP",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Aḩmadī",
    "country": "KW",
    "countryName": "Kuwait",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bishkek",
    "country": "KG",
    "countryName": "Kyrgyzstan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Colombo",
    "country": "LK",
    "countryName": "Sri Lanka",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nouakchott",
    "country": "MR",
    "countryName": "Mauritania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chisinau",
    "country": "MD",
    "countryName": "Moldova",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Niamey",
    "country": "NE",
    "countryName": "Niger",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Oslo",
    "country": "NO",
    "countryName": "Norway",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Asunción",
    "country": "PY",
    "countryName": "Paraguay",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Trujillo",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Callao",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lima",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Arequipa",
    "country": "PE",
    "countryName": "Peru",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaragoza",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sevilla",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Madrid",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Valencia",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "ES",
    "countryName": "Spain",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Łódź",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Warsaw",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Wrocław",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kraków",
    "country": "PL",
    "countryName": "Poland",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucharest",
    "country": "RO",
    "countryName": "Romania",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Singapore",
    "country": "SG",
    "countryName": "Singapore",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mogadishu",
    "country": "SO",
    "countryName": "Somalia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Stockholm",
    "country": "SE",
    "countryName": "Sweden",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Belgrade",
    "country": "RS",
    "countryName": "Serbia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dushanbe",
    "country": "TJ",
    "countryName": "Tajikistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dubai",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Abu Dhabi",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Ain City",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sharjah",
    "country": "AE",
    "countryName": "United Arab Emirates",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Santiago",
    "country": "CL",
    "countryName": "Chile",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Yaoundé",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Douala",
    "country": "CM",
    "countryName": "Cameroon",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Libreville",
    "country": "GA",
    "countryName": "Gabon",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jerusalem",
    "country": "IL",
    "countryName": "Israel",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Athens",
    "country": "GR",
    "countryName": "Greece",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Victoria",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tegucigalpa",
    "country": "HN",
    "countryName": "Honduras",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Palermo",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Genoa",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Naples",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Turin",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Milan",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kawaguchi",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hiroshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Saitama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Honchō",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Chiba",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Okayama",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kagoshima",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Hamamatsu",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Seongnam-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bucheon-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cheonan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ansan-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Cheongju-si",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ra’s Bayrūt",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Beirut",
    "country": "LB",
    "countryName": "Lebanon",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tripoli",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Benghazi",
    "country": "LY",
    "countryName": "Libyan Arab Jamahiriya",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Monrovia",
    "country": "LR",
    "countryName": "Liberia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Macau",
    "country": "MO",
    "countryName": "Macau",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bamako",
    "country": "ML",
    "countryName": "Mali",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Antananarivo",
    "country": "MG",
    "countryName": "Madagascar",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ulan Bator",
    "country": "MN",
    "countryName": "Mongolia",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Meknes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Salé",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Fes",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Marrakesh",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Agadir",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tangier",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Nampula",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maputo",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Beira",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Matola",
    "country": "MZ",
    "countryName": "Mozambique",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Managua",
    "country": "NI",
    "countryName": "Nicaragua",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Amsterdam",
    "country": "NL",
    "countryName": "Netherlands",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Muscat",
    "country": "OM",
    "countryName": "Oman",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gujranwala",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Battagram",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Peshawar",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Muzaffarābād",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kotli",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Multan",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Birmingham",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Liverpool",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Glasgow",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mombasa",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Riga",
    "country": "LV",
    "countryName": "Latvia",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Blantyre",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lilongwe",
    "country": "MW",
    "countryName": "Malawi",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kigali",
    "country": "RW",
    "countryName": "Rwanda",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Omdurman",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Freetown",
    "country": "SL",
    "countryName": "Sierra Leone",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taipei",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taichung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tainan",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kaohsiung",
    "country": "TW",
    "countryName": "Taiwan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Mwanza",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Arusha",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dar es Salaam",
    "country": "TZ",
    "countryName": "Tanzania",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lomé",
    "country": "TG",
    "countryName": "Togo",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tunis",
    "country": "TN",
    "countryName": "Tunisia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "London",
    "country": "GB",
    "countryName": "United Kingdom",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hong Kong",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kowloon",
    "country": "HK",
    "countryName": "Hong Kong",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rome",
    "country": "IT",
    "countryName": "Italy",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Osaka",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sapporo",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kobe",
    "country": "JP",
    "countryName": "Japan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Nairobi",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kakamega",
    "country": "KE",
    "countryName": "Kenya",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Busan",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Daejeon",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Gwangju",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Daegu",
    "country": "KR",
    "countryName": "Korea",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Casablanca",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rabat",
    "country": "MA",
    "countryName": "Morocco",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Rawalpindi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Karachi",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Faisalabad",
    "country": "PK",
    "countryName": "Pakistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Khartoum",
    "country": "SD",
    "countryName": "Sudan",
    "continent": "Africa",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Bangkok",
    "country": "TH",
    "countryName": "Thailand",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Ashgabat",
    "country": "TM",
    "countryName": "Turkmenistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Kampala",
    "country": "UG",
    "countryName": "Uganda",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Haiphong",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Biên Hòa",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cần Thơ",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Thuận An",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Hanoi",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Da Nang",
    "country": "VN",
    "countryName": "Viet Nam",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-16"
  },
  {
    "city": "Port Elizabeth",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Cape Town",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Durban",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Soweto",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Benoni",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pretoria",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Pietermaritzburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Johannesburg",
    "country": "ZA",
    "countryName": "South Africa",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Latakia",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Aleppo",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Homs",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Damascus",
    "country": "SY",
    "countryName": "Syrian Arab Republic",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "N'Djamena",
    "country": "TD",
    "countryName": "Chad",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Gaziantep",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Malatya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Antalya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Eskişehir",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Adana",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Diyarbakır",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Çankaya",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ankara",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kayseri",
    "country": "TR",
    "countryName": "Turkey",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kryvyy Rih",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Donetsk",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kharkiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Odesa",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Zaporizhzhya",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Kyiv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lviv",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Dnipro",
    "country": "UA",
    "countryName": "Ukraine",
    "continent": "Europe",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Baltimore",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "San Antonio",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Boston",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Queens",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Manhattan",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Charlotte",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Phoenix",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Las Vegas",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Jacksonville",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "El Paso",
    "country": "US",
    "countryName": "United States",
    "continent": "North America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Montevideo",
    "country": "UY",
    "countryName": "Uruguay",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Tashkent",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Namangan",
    "country": "UZ",
    "countryName": "Uzbekistan",
    "continent": "Asia",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maturín",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Barcelona",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Barquisimeto",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Maracay",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Maracaibo",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Valencia",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Ciudad Guayana",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Caracas",
    "country": "VE",
    "countryName": "Venezuela",
    "continent": "South America",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-30"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Al Ḩudaydah",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Aden",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Taiz",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Sanaa",
    "country": "YE",
    "countryName": "Yemen",
    "continent": "Asia",
    "sessionDate": "2024-03-09"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-01"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-02"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-04"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-05"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-07"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-08"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-09"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-11"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-12"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-14"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-15"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-16"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-18"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-19"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-21"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-22"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-23"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-25"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-26"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-28"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-02-29"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-01"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-03"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-04"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-05"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-06"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-07"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-08"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-10"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-11"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-12"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-13"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-14"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-15"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-17"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-18"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-19"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-20"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-21"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-22"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-24"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-25"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-26"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-27"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-28"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-29"
  },
  {
    "city": "Harare",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Bulawayo",
    "country": "ZW",
    "countryName": "Zimbabwe",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  },
  {
    "city": "Lusaka",
    "country": "ZM",
    "countryName": "Zambia",
    "continent": "Africa",
    "sessionDate": "2024-03-31"
  }
];