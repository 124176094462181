import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { OrderBySounds, Order } from '../../types/types';
import { visuallyHidden } from '@mui/utils';
import BasicCheckbox from '../elements/BasicCheckbox';
import { STORAGE_SOUNDS } from '../../constants/storage';

interface Data {
  originalPackageFileName: string;
  packageName: string;
  creators: string;
  creationDate: string;
  keys: string;
  tempo: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  publish: any;
}

interface Column {
  id: 'originalPackageFileName' | 'packageName' | 'creators' | 'creationDate' | 'keys' | 'tempo' | 'status' | 'createdAt' | 'updatedAt' | 'publish';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: 'packageName',
    label: 'Package Name',
    minWidth: 150,
  },
  {
    id: 'originalPackageFileName',
    label: 'File',
    minWidth: 30,
  },
  {
    id: 'creators',
    label: 'Creators',
    minWidth: 30,
  },
  {
    id: 'creationDate',
    label: 'Creation Date',
    minWidth: 140,
  },
  {
    id: 'keys',
    label: 'Keys',
    minWidth: 30,
  },
  {
    id: 'tempo',
    label: 'Tempo',
    minWidth: 30,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 30,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    minWidth: 120,
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    minWidth: 120,
  },
  {
    id: 'publish',
    label: 'Publish',
    minWidth: 30,
  },
];

interface SoundsTableProps {
  data: any;
  handleRequestSort: any,
  order: Order,
  orderBy: keyof OrderBySounds,
  page: number,
  handleSelect: any,
  publishSound: any,
}

const SoundsTable: React.FC<SoundsTableProps> = (
  { data, handleRequestSort, order, orderBy, page, handleSelect, publishSound }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      setRows(formatSoundData(data));
    }
  }, [data]);

  const formatSoundData = (arr: any) => {
    return arr.map((obj: any) => {
      const { ...rest } = obj;
      const active = obj.active ?
        <Button onClick={() => console.log('test')} sx={{
          textTransform: 'capitalize',
          textDecoration: 'underline',
          fontWeight: '400',
          padding: 0,
          minWidth: 0,
        }}>Resend</Button> :
        <Typography
          variant='body1'
        >Yes</Typography>;
      
      const publish = <BasicCheckbox
        checked={obj.status === "PUBLISHED" ? true : false}
        onClick={(e: any) => {
          e.stopPropagation();
          const status = obj.status === "PUBLISHED" ? false : true;
          publishSound(obj, status);
        }}
      />

      const creationDate = String(obj.creationDate)?.split('T')[0];
      const formatCreationDate = creationDate.split('-');
      const createdAt = String(obj.createdAt)?.split('T')[0];
      const formatCreatedAt = createdAt.split('-');
      const updatedAt = String(obj.updatedAt)?.split('T')[0];
      const formatUpdatedAt = updatedAt.split('-');
      
      return {
        ...rest,
        active,
        publish,
        createdAt: obj.createdAt ? `${formatCreatedAt[1]}-${formatCreatedAt[2]}-${formatCreatedAt[0]}` : null,
        updatedAt: obj.updatedAt ? `${formatUpdatedAt[1]}-${formatUpdatedAt[2]}-${formatUpdatedAt[0]}` : null,
        creationDate: obj.creationDate ? `${formatCreationDate[1]}-${formatCreationDate[2]}-${formatCreationDate[0]}` : null
      };
    });
  }

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <TableRow
          sx={{
            cursor: 'pointer',
          }}
          hover role="checkbox" tabIndex={-1} key={row.id + rowId}
          onClick={() => {
            handleSelect(row);
          }}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            return (
              <TableCell
                sx={{
                  color: '#333333',
                  fontSize: '16px',
                  fontWeight: '400',
                  border: '1px solid #E2E2E2',
                  textTransform: 'none',
                  backgroundColor: '#FFFFFF',
                  py: '10px',
                  px: '20px',
                }}
                key={row.id + id + rowId}
                align={column.align}
              >
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    })
  }

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  return (
    <div>
      <Paper sx={{
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}>
        <TableContainer>
          <Table sx={{
            backgroundColor: 'transparent',
          }}>
            <TableHead>
              <TableRow>
                {columns.map((column, id) => (
                  <TableCell
                    sx={[
                      {
                        color: '#333333',
                        fontSize: '16px',
                        fontWeight: '500',
                        py: '10px',
                        px: '20px',
                        border: '1px solid #E2E2E2',
                        textTransform: 'capitalize',
                      },
                      (theme) => ({
                        [theme.breakpoints.up('md')]: {
                          minWidth: column.minWidth
                        }
                      }),
                    ]}
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(rows)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>);
};

export default SoundsTable;