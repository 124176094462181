import { useState, useEffect, createContext, useContext } from 'react';
import { Outlet, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  SvgIcon,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  IconButton,
} from '@mui/material';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { STORAGE_USER } from '../constants/storage';

const drawerWidth = 70;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: '#422662',
  position: 'fixed',
  top: '61.6px',
  bottom: 0,
  height: 'calc(100vh - 61.6px)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#422662',
  position: 'fixed',
  top: '61.6px',
  bottom: 0,
  height: 'calc(100vh - 61.6px)',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    height: 'calc(100vh - 61.6px)',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface LaoyutContextType {
  value?: any
}

const LaoyutContext = createContext<LaoyutContextType>(null!);

const LayoutDashboard = () => {
  const location = useLocation();
  const params = useParams();
  const isNoMenuPage = params?.id ? true : false;
  const navigate = useNavigate();
  const theme = useTheme();
  const [openSide, setOpenSide] = useState(true);
  const [route, setRoute] = useState('');
  const userProfile = JSON.parse(localStorage.getItem(STORAGE_USER) || '');

  useEffect(() => {
    setRoute(location.pathname);

  }, [location.pathname]);

  const sideIcon: {
    [key: string]: any
  } = {
    'Home': <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5165 7.82913C17.5161 7.82872 17.5157 7.82831 17.5152 7.8279L10.1719 0.485596C9.85892 0.172485 9.44277 0 9.00011 0C8.55746 0 8.14131 0.172348 7.82816 0.485458L0.488685 7.82405C0.486213 7.82652 0.483741 7.82913 0.481269 7.8316C-0.161497 8.47801 -0.160398 9.52679 0.484428 10.1716C0.779029 10.4663 1.16812 10.637 1.58413 10.6548C1.60103 10.6565 1.61806 10.6573 1.63523 10.6573H1.9279V16.0608C1.9279 17.13 2.79797 18 3.8676 18H6.74054C7.03171 18 7.26794 17.7639 7.26794 17.4727V13.2363C7.26794 12.7484 7.66486 12.3515 8.15284 12.3515H9.84738C10.3354 12.3515 10.7323 12.7484 10.7323 13.2363V17.4727C10.7323 17.7639 10.9684 18 11.2597 18H14.1326C15.2023 18 16.0723 17.13 16.0723 16.0608V10.6573H16.3437C16.7862 10.6573 17.2024 10.4849 17.5157 10.1718C18.1612 9.52597 18.1614 8.4754 17.5165 7.82913Z" fill="white" />
    </svg>,
    'Map': <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 0C4.03737 0 0 3.89916 0 8.69185C0 14.6397 8.05412 23.3716 8.39703 23.7404C8.71912 24.0868 9.28146 24.0862 9.60297 23.7404C9.94588 23.3716 18 14.6397 18 8.69185C17.9999 3.89916 13.9626 0 9 0ZM9 13.065C6.50318 13.065 4.47192 11.1032 4.47192 8.69185C4.47192 6.28051 6.50323 4.31879 9 4.31879C11.4968 4.31879 13.528 6.28055 13.528 8.6919C13.528 11.1032 11.4968 13.065 9 13.065Z" fill="white" />
    </svg>,
    'Sounds': <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3853 14.9727C15.0691 13.8286 15.5227 12.3816 15.6107 10.9498C15.6665 10.0308 15.4617 9.06606 15.0183 8.15891C14.3227 6.73386 13.1278 5.93332 11.9736 5.15987C11.1121 4.58274 10.2981 4.03777 9.72262 3.25924L9.61938 3.12046C9.27919 2.6635 8.89501 2.1456 8.83408 1.71064C8.77315 1.2706 8.37373 0.957498 7.93708 0.982885C7.49365 1.01335 7.15008 1.38061 7.15008 1.82573V16.9055C6.44094 16.4756 5.56763 16.2116 4.61139 16.2116C2.27919 16.2116 0.380249 17.7298 0.380249 19.5965C0.380249 21.4633 2.27919 22.9815 4.61139 22.9815C6.9436 22.9815 8.84254 21.4633 8.84254 19.5965V9.73289C10.1136 10.2186 12.1936 11.4558 12.7657 14.3448C12.659 14.5022 12.5575 14.6715 12.439 14.8069C12.1293 15.1572 12.1631 15.692 12.5152 16.0001C12.8638 16.3115 13.3986 16.2742 13.7084 15.9239C13.9351 15.6667 14.1365 15.3654 14.3295 15.0506C14.3498 15.0269 14.3685 15.0015 14.3853 14.9727Z" fill="white" />
    </svg>,
    'Analytics': <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_717_5305)">
        <path d="M4.37766 8.41309H0.586969C0.262793 8.41309 0 8.67591 0 9.00005V16.5814C0 16.9055 0.262793 17.1684 0.586969 17.1684H4.37766C4.7018 17.1684 4.96463 16.9056 4.96463 16.5814V9.00005C4.96459 8.67591 4.7018 8.41309 4.37766 8.41309Z" fill="white" />
        <path d="M10.8952 0.831787H7.10455C6.78041 0.831787 6.51758 1.09458 6.51758 1.41876V16.5814C6.51758 16.9055 6.78037 17.1684 7.10455 17.1684H10.8952C11.2194 17.1684 11.4822 16.9056 11.4822 16.5814V1.41872C11.4822 1.09458 11.2194 0.831787 10.8952 0.831787Z" fill="white" />
        <path d="M17.4133 4.62231H13.6226C13.2985 4.62231 13.0356 4.88511 13.0356 5.20928V16.5813C13.0356 16.9054 13.2984 17.1682 13.6226 17.1682H17.4133C17.7374 17.1682 18.0003 16.9055 18.0003 16.5813V5.20928C18.0002 4.88514 17.7374 4.62231 17.4133 4.62231Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_717_5305">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>,
    'Users': <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.73343 0C4.35837 0 0 4.35751 0 9.733C0 15.1085 4.35794 19.466 9.73343 19.466C15.1093 19.466 19.4669 15.1085 19.4669 9.733C19.4669 4.35751 15.1093 0 9.73343 0ZM9.73343 2.91028C11.512 2.91028 12.9533 4.35195 12.9533 6.12968C12.9533 7.90782 11.512 9.34907 9.73343 9.34907C7.95571 9.34907 6.51446 7.90782 6.51446 6.12968C6.51446 4.35195 7.95571 2.91028 9.73343 2.91028ZM9.73129 16.9213C7.95742 16.9213 6.33276 16.2753 5.07963 15.206C4.77437 14.9456 4.59822 14.5638 4.59822 14.1632C4.59822 12.3603 6.05742 10.9173 7.86079 10.9173H11.6069C13.4107 10.9173 14.8644 12.3603 14.8644 14.1632C14.8644 14.5642 14.6891 14.9452 14.3834 15.2055C13.1307 16.2753 11.5056 16.9213 9.73129 16.9213Z" fill="white" />
    </svg>,
    'Settings': <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.5784 7.88517H17.5365C17.3428 7.13013 17.0406 6.42297 16.6464 5.7761L17.3954 5.02533C17.5976 4.82311 17.7088 4.55333 17.7088 4.26687C17.7088 3.98085 17.5968 3.71064 17.3954 3.50884L16.147 2.2617C15.7421 1.85639 15.0345 1.85682 14.6314 2.26127L13.8507 3.03726C13.2111 2.66444 12.5253 2.37714 11.7698 2.19244V1.07741C11.7698 0.486116 11.2974 0 10.7061 0H8.94119C8.3499 0 7.88474 0.486116 7.88474 1.07741V2.19201C7.1297 2.37628 6.43964 2.66402 5.79919 3.03726L5.01892 2.26127C4.61447 1.85596 3.90646 1.85639 3.50072 2.26084L2.25315 3.50799C2.05306 3.70765 1.9389 3.98384 1.9389 4.26602C1.9389 4.55204 2.04964 4.8214 2.25229 5.02362L3.00135 5.7761C2.60758 6.42297 2.30488 7.13013 2.11163 7.88517H1.06928C0.477565 7.88517 0 8.36059 0 8.9506V10.7142C0 11.3059 0.477565 11.7707 1.06928 11.7707H2.11163C2.30488 12.5257 2.60715 13.2371 3.00049 13.884L2.25144 14.6378C2.04878 14.84 1.93762 15.1106 1.93762 15.3971C1.93762 15.6835 2.04921 15.9542 2.25144 16.1564L3.49986 17.4044C3.70252 17.6066 3.97144 17.7178 4.25747 17.7178C4.54349 17.7178 4.81284 17.6062 5.0155 17.4044L5.79919 16.628C6.43964 17.0012 7.1297 17.2885 7.88474 17.4737V18.587C7.88474 19.1783 8.3499 19.655 8.94119 19.655H10.7061C11.2974 19.655 11.7698 19.1783 11.7698 18.587V17.4732C12.5253 17.2885 13.2111 17.0012 13.8515 16.628L14.6284 17.4027C14.831 17.6058 15.1004 17.7169 15.3873 17.7169C15.6737 17.7169 15.9435 17.6053 16.1457 17.4035L17.3941 16.1564C17.5964 15.9546 17.7071 15.6848 17.708 15.3988C17.708 15.1123 17.5959 14.8434 17.3941 14.6408L16.6459 13.884C17.0393 13.2367 17.3415 12.5249 17.5352 11.7707H18.5776C19.1689 11.7707 19.655 11.3059 19.655 10.7142V8.9506C19.6554 8.36059 19.1697 7.88517 18.5784 7.88517ZM9.82321 13.1897C7.95143 13.1897 6.43366 11.686 6.43366 9.83262C6.43366 7.97794 7.95143 6.47641 9.82321 6.47641C11.6954 6.47641 13.2141 7.97794 13.2141 9.83262C13.2136 11.6864 11.6954 13.1897 9.82321 13.1897Z" fill="white" />
    </svg>
  }

  const sideUrls: {
    [key: string]: any
  } = {
    'Home': '/dashboard/',
    'Calendar': '/dashboard/calendar',
    'Sounds': '/dashboard/sounds',
    'Analytics': '/dashboard/analytics/map',
    'Users': '/dashboard/users',
    'Settings': '/dashboard/settings'
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div className="App">
        <Box sx={{
          minHeight: '61.6px'
        }}>
          <header className="App-header">
            <Container maxWidth={false}>
              <Box className="App-header-inner">
                <div className="App-logo-box">
                  <Tooltip title="Home">
                    <Link to={'/dashboard'}>
                      <Typography variant="subtitle1" sx={{
                        mr: '15px',
                        color: 'black',
                        textDecoration: 'none',
                        fontSize: '20px',
                        lineHeight: '28px',
                        fontWeight: '600'
                      }}>
                        TheaMetrics
                      </Typography>
                    </Link>
                  </Tooltip>
                </div>
                <nav className="App-header-nav">
                  <Box sx={{
                    ml: 2,
                    'display': 'flex',
                    'alignItems': 'center',
                    '& > a': {
                      display: 'flex',
                      alignItems: "center",
                      textDecoration: 'none',
                    }
                  }}>
                    <Tooltip title="Settings">
                      <Link to={'/dashboard/settings'}>
                        <Typography variant="subtitle1" sx={{
                          mr: '15px',
                          color: 'black',
                          textDecoration: 'none'
                        }}>{userProfile?.firstName}</Typography>
                        <Box sx={{
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '10px',
                        }}>
                          <SvgIcon sx={{ fontSize: 20 }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                              <path d="M6.40418 15.8256L6.4041 15.8256C6.21013 15.6601 6.09822 15.4173 6.09822 15.1632C6.09822 13.6397 7.33026 12.4173 8.86079 12.4173H12.6069C14.1368 12.4173 15.3644 13.6386 15.3644 15.1632C15.3644 15.4182 15.253 15.6598 15.0592 15.8249L15.0587 15.8253C13.893 16.8207 12.382 17.4213 10.7313 17.4213C9.08107 17.4213 7.57043 16.8208 6.40418 15.8256ZM10.7334 0.5C5.08227 0.5 0.5 5.08133 0.5 10.733C0.5 16.3847 5.08183 20.966 10.7334 20.966C16.3855 20.966 20.9669 16.3847 20.9669 10.733C20.9669 5.08136 16.3855 0.5 10.7334 0.5ZM10.7334 4.41028C12.2359 4.41028 13.4533 5.6281 13.4533 7.12968C13.4533 8.63164 12.2359 9.84907 10.7334 9.84907C9.2319 9.84907 8.01446 8.63173 8.01446 7.12968C8.01446 5.62801 9.23194 4.41028 10.7334 4.41028Z" stroke="#C9C9C9" />
                            </svg>
                          </SvgIcon>
                        </Box>
                      </Link>
                    </Tooltip>
                  </Box>
                </nav>
              </Box>
            </Container>
          </header>
        </Box>
      </div >
      <LaoyutContext.Provider value={{
        value: 'test'
      }}>
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <Box sx={{
            minWidth: '70px',
            position: 'relative',
          }}>
            <Drawer
              variant="permanent"
              open={openSide}
            >
              {isNoMenuPage ?
                <Box sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <Tooltip title={"Go Back"} placement='right-start'>
                    <IconButton sx={{
                      '&:hover svg path': {
                        stroke: '#fff'
                      }
                    }} onClick={() => navigate(-1)} aria-label="Go Back">
                      <SvgIcon>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9 1L2.41421 7.58579C1.63316 8.36684 1.63317 9.63316 2.41421 10.4142L9 17" stroke="#8E7DA1" strokeWidth="2" stroke-linecap="round" />
                        </svg>
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
                :
                <>
                  <List sx={{
                    py: '26px'
                  }}>
                    {['Home', 'Sounds', 'Analytics'].map((text, index) => (
                      <ListItem key={text} disablePadding sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: '20px',
                      }}>
                        <Tooltip title={text} placement='right-start'>
                          <ListItemButton href={sideUrls[text]} selected={route === sideUrls[text]} sx={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            opacity: '0.5',
                            '&:hover, &.Mui-selected': {
                              opacity: '1',
                              backgroundColor: 'inherit',
                            }
                          }}>
                            <ListItemIcon
                              sx={{
                                color: '#fff',
                                minWidth: '20px',
                              }}
                            >
                              {sideIcon[text]}
                            </ListItemIcon>
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                  <List sx={{ mt: 'auto' }}>
                    {['Users', 'Settings'].map((text, index) => (
                      <ListItem key={text} disablePadding sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: '20px',
                      }}>
                        <Tooltip title={text} placement='right-start'>
                          <ListItemButton href={sideUrls[text]} selected={route === sideUrls[text]} sx={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            opacity: '0.5',
                            '&:hover, &.Mui-selected': {
                              opacity: '1',
                              backgroundColor: 'inherit',
                            }
                          }}>
                            <ListItemIcon
                              sx={{
                                color: '#fff',
                                minWidth: '20px',
                              }}
                            >
                              {sideIcon[text]}
                            </ListItemIcon>
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </>
              }
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              paddingLeft: '24px',
              paddingRight: '24px',
              flexGrow: 1,
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
            }}>
            <Outlet />
          </Box>
        </Box>
      </LaoyutContext.Provider>
    </Box >
  )
};

export const useLayout = () => {
  return useContext(LaoyutContext);
};

export default LayoutDashboard;