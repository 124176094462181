export const mockSessions = [
  {
    "id": 1,
    "date": "10.6",
    "time": "04:46:05",
    "length": "Long",
    "country": "Germany",
    "selected_intentions": "Harmony",
    "vokaturi": {
      "graceful": 80,
      "harmony": 12,
      "peaceful": 4,
      "positive": 3,
      "other": 1
    },
    "stress": 0,
    "light": "0.070",
    "resilience": 10,
    "hrv": 61
  },
  {
    "id": 2,
    "date": "16.8",
    "time": "14:27:35",
    "length": "Long",
    "country": "France",
    "selected_intentions": "Harmony",
    "vokaturi": {
      "graceful": 14,
      "harmony": 52,
      "peaceful": 24,
      "positive": 10,
      "other": 0
    },
    "stress": 19,
    "light": "0.827",
    "resilience": 8,
    "hrv": 42
  },
  {
    "id": 3,
    "date": "22.4",
    "time": "19:14:36",
    "length": "Long",
    "country": "Italy",
    "selected_intentions": "Graceful",
    "vokaturi": {
      "graceful": 51,
      "harmony": 40,
      "peaceful": 9,
      "positive": 0,
      "other": 0
    },
    "stress": 81,
    "light": "0.426",
    "resilience": 6,
    "hrv": 78
  },
  {
    "id": 4,
    "date": "16.9",
    "time": "10:34:37",
    "length": "Medium",
    "country": "France",
    "selected_intentions": "Graceful",
    "vokaturi": {
      "graceful": 2,
      "harmony": 27,
      "peaceful": 29,
      "positive": 3,
      "other": 39
    },
    "stress": 51,
    "light": "0.665",
    "resilience": 7,
    "hrv": 23
  },
  {
    "id": 5,
    "date": "3.5",
    "time": "14:43:44",
    "length": "Short",
    "country": "France",
    "selected_intentions": "Other",
    "vokaturi": {
      "graceful": 3,
      "harmony": 59,
      "peaceful": 37,
      "positive": 1,
      "other": 0
    },
    "stress": 86,
    "light": "0.981",
    "resilience": 4,
    "hrv": 41
  },
  {
    "id": 6,
    "date": "29.4",
    "time": "03:24:06",
    "length": "Medium",
    "country": "Germany",
    "selected_intentions": "Harmony",
    "vokaturi": {
      "graceful": 65,
      "harmony": 27,
      "peaceful": 1,
      "positive": 7,
      "other": 0
    },
    "stress": 13,
    "light": "0.053",
    "resilience": 8,
    "hrv": 49
  },
  {
    "id": 7,
    "date": "18.2",
    "time": "23:08:34",
    "length": "Medium",
    "country": "Germany",
    "selected_intentions": "Harmony",
    "vokaturi": {
      "graceful": 46,
      "harmony": 21,
      "peaceful": 31,
      "positive": 0,
      "other": 2
    },
    "stress": 31,
    "light": "0.445",
    "resilience": 7,
    "hrv": 27
  },
];


export const mockUsers = [
  {
    id: "2a7e1b41-9a14-4d23-bf61-8f78ec7e3e15",
    email: "j.doe@example.com",
    firstName: 'John',
    lastName: 'Doe',
    roles: ["SUPER ADMIN"],
    active: true,
  },
  {
    id: "45e39c2f-87f2-4924-ae9d-09a8e7d143a7",
    email: "m.smith@example.com",
    firstName: 'Mary',
    lastName: 'Smith',
    roles: ["SUPER ADMIN"],
    active: false,
  },
  {
    id: "6fd9a8e1-5a4b-4a88-b5a7-3c24d6f95f1b",
    email: "p.jones@example.com",
    firstName: 'Peter',
    lastName: 'Jones',
    roles: ["SUPER ADMIN"],
    active: false,
  },
  {
    id: "8b6c33bd-90a3-4fb1-8d1a-abcde1234567",
    email: "e.brown@example.com",
    firstName: 'Emily',
    lastName: 'Brown',
    roles: ["SUPER ADMIN"],
    active: false,
  },
  {
    id: "c7f2e9a5-2e75-4a7f-bd4c-4321dcba9876",
    email: "a.wilson@example.com",
    firstName: 'Alice',
    lastName: 'Wilson',
    roles: ["ADMIN"],
    active: false,
  },
  {
    id: "d45f8c72-6e6e-4c5d-9e87-7654321cba98",
    email: "t.robinson@example.com",
    firstName: 'Tom',
    lastName: 'Robinson',
    roles: ["SUPER ADMIN"],
    active: true,
  },
  {
    id: "e9a74b3f-1c7d-4eaa-813c-654321cba987",
    email: "l.miller@example.com",
    firstName: 'Laura',
    lastName: 'Miller',
    roles: ["SUPER ADMIN"],
    active: false,
  },
  {
    id: "f68c94e1-b301-4a72-83e9-87654321dcba",
    email: "g.smith@example.com",
    firstName: 'George',
    lastName: 'Smith',
    roles: ["SUPER ADMIN"],
    active: false,
  },
  {
    id: "23a0bc54-6a3a-49fb-84d9-0123456789ab",
    email: "r.harrison@example.com",
    firstName: 'Rachel',
    lastName: 'Harrison',
    roles: ["ADMIN"],
    active: false,
  }
];

export const mockSounds = [
  {
    id: '0',
    name: "B44",
    file: "b44_128.mp3",
    intention: "Focus",
    keys: "F",
    tempo: "Fast",
    type: "Opening",
    layer: "Birds",
    tone: "Gamma",
    isochronic: "Meditation",
    voiceover: "Meditation",
    publish: false
  },
  {
    id: '1',
    name: "A44",
    file: "A44_128.mp3",
    intention: "Sleep",
    keys: "C",
    tempo: "Fast",
    type: "Opening",
    layer: "Birds",
    tone: "Gamma",
    isochronic: "Meditation",
    voiceover: "Meditation",
    publish: false
  },
  {
    id: '2',
    name: "C44",
    file: "A44_128.mp3",
    intention: "Focus",
    keys: "C",
    tempo: "Fast",
    type: "Opening",
    layer: "Birds",
    tone: "Gamma",
    isochronic: "Meditation",
    voiceover: "Meditation",
    publish: false
  },
]

export const countryArr = [
  "Afghanistan",
  "Armenia",
  "Angola",
  "Argentina",
  "Australia",
  "Bangladesh",
  "Bolivia",
  "Burkina Faso",
  "Burundi",
  "Canada",
  "Congo",
  "Cuba",
  "Djibouti",
  "Dominican Republic",
  "Algeria",
  "Austria",
  "Azerbaijan",
  "Belgium",
  "Benin",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Brazil",
  "Belarus",
  "Central African Republic",
  "China",
  "Colombia",
  "Côte d'Ivoire",
  "Congo",
  "Czech Republic",
  "Germany",
  "Denmark",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Mexico",
  "Malaysia",
  "Myanmar",
  "Nepal",
  "Nigeria",
  "Philippines",
  "Russian Federation",
  "Saudi Arabia",
  "Senegal",
  "Tunisia",
  "Viet Nam",
  "South Africa",
  "Syrian Arab Republic",
  "Chad",
  "Egypt",
  "Ghana",
  "Guatemala",
  "Hong Kong",
  "Honduras",
  "Hungary",
  "Jordan",
  "Cambodia",
  "Ireland",
  "Ecuador",
  "Ethiopia",
  "France",
  "Georgia",
  "United Arab Emirates",
  "Chile",
  "Cameroon",
  "Gabon",
  "Guinea",
  "Greece",
  "Haiti",
  "Italy",
  "Japan",
  "Kenya",
  "Eritrea",
  "Finland",
  "Croatia",
  "Jamaica",
  "Kazakhstan",
  "Korea",
  "Kyrgyzstan",
  "Libyan Arab Jamahiriya",
  "Liberia",
  "Mali",
  "Madagascar",
  "Mongolia",
  "Morocco",
  "Mozambique",
  "Niger",
  "Nicaragua",
  "Oman",
  "Paraguay",
  "Singapore",
  "Somalia",
  "Spain",
  "Mauritania",
  "Poland",
  "Romania",
  "Sweden",
  "Serbia",
  "Israel",
  "Malawi",
  "Rwanda",
  "Sudan",
  "Sierra Leone",
  "Taiwan",
  "Tanzania",
  "United Kingdom",
  "Moldova",
  "Korea",
  "Lebanon",
  "Pakistan",
  "Peru",
  "Thailand",
  "Turkey",
  "Uganda",
  "Ukraine",
  "Uzbekistan",
  "Venezuela",
  "United States",
  "Uruguay",
  "Yemen",
  "Kuwait",
  "Sri Lanka",
  "Netherlands",
  "Norway",
  "Latvia",
  "Tajikistan",
  "Togo",
  "Turkmenistan",
  "Macau",
  "Zimbabwe",
  "Zambia"
];

export const countryCodesArr = [
  "AF",
  "AM",
  "AO",
  "AR",
  "AU",
  "BD",
  "BO",
  "BF",
  "BI",
  "CA",
  "CD",
  "CU",
  "DJ",
  "DO",
  "DZ",
  "AT",
  "AZ",
  "BE",
  "BJ",
  "BA",
  "BG",
  "BR",
  "BY",
  "CF",
  "CN",
  "CO",
  "CI",
  "CG",
  "CZ",
  "DE",
  "DK",
  "IN",
  "ID",
  "IR",
  "IQ",
  "MX",
  "MY",
  "MM",
  "NP",
  "NG",
  "PH",
  "RU",
  "SA",
  "SN",
  "TN",
  "VN",
  "ZA",
  "SY",
  "TD",
  "EG",
  "GH",
  "GT",
  "HK",
  "HN",
  "HU",
  "JO",
  "KH",
  "IE",
  "EC",
  "ET",
  "FR",
  "GE",
  "AE",
  "CL",
  "CM",
  "GA",
  "GN",
  "GR",
  "HT",
  "IT",
  "JP",
  "KE",
  "ER",
  "FI",
  "HR",
  "JM",
  "KZ",
  "KP",
  "KG",
  "LY",
  "LR",
  "ML",
  "MG",
  "MN",
  "MA",
  "MZ",
  "NE",
  "NI",
  "OM",
  "PY",
  "SG",
  "SO",
  "ES",
  "MR",
  "PL",
  "RO",
  "SE",
  "RS",
  "IL",
  "MW",
  "RW",
  "SD",
  "SL",
  "TW",
  "TZ",
  "GB",
  "MD",
  "KR",
  "LB",
  "PK",
  "PE",
  "TH",
  "TR",
  "UG",
  "UA",
  "UZ",
  "VE",
  "US",
  "UY",
  "YE",
  "KW",
  "LK",
  "NL",
  "NO",
  "LV",
  "TJ",
  "TG",
  "TM",
  "MO",
  "ZW",
  "ZM"
];


export const countryDictionary: any = {
  "Afghanistan": "AF",
  "Armenia": "AM",
  "Angola": "AO",
  "Argentina": "AR",
  "Australia": "AU",
  "Bangladesh": "BD",
  "Botswana": "BW",
  "Bolivia": "BO",
  "Burkina Faso": "BF",
  "Burundi": "BI",
  "Canada": "CA",
  "Congo": "CG",
  "Cuba": "CU",
  "Djibouti": "DJ",
  "Dominican Republic": "DO",
  "Algeria": "DZ",
  "Austria": "AT",
  "Azerbaijan": "AZ",
  "Belgium": "BE",
  "Benin": "BJ",
  "Bosnia and Herzegovina": "BA",
  "Bulgaria": "BG",
  "Brazil": "BR",
  "Belarus": "BY",
  "Central African Republic": "CF",
  "China": "CN",
  "Colombia": "CO",
  "Côte d'Ivoire": "CI",
  "Czech Republic": "CZ",
  "Germany": "DE",
  "Denmark": "DK",
  "India": "IN",
  "Indonesia": "ID",
  "Iran": "IR",
  "Iraq": "IQ",
  "Mexico": "MX",
  "Malaysia": "MY",
  "Myanmar": "MM",
  "Nepal": "NP",
  "Nigeria": "NG",
  "Philippines": "PH",
  "Russia": "RU",
  "Saudi Arabia": "SA",
  "Senegal": "SN",
  "Tunisia": "TN",
  "Viet Nam": "VN",
  "South Africa": "ZA",
  "Syrian Arab Republic": "SY",
  "Chad": "TD",
  "Egypt": "EG",
  "Ghana": "GH",
  "Guatemala": "GT",
  "Hong Kong": "HK",
  "Honduras": "HN",
  "Hungary": "HU",
  "Jordan": "JO",
  "Cambodia": "KH",
  "Ireland": "IE",
  "Ecuador": "EC",
  "Ethiopia": "ET",
  "France": "FR",
  "Fiji": "FJ",
  "Georgia": "GE",
  "United Arab Emirates": "AE",
  "Chile": "CL",
  "Cameroon": "CM",
  "Gabon": "GA",
  "Guinea": "GN",
  "Greece": "GR",
  "Haiti": "HT",
  "Italy": "IT",
  "Japan": "JP",
  "Kenya": "KE",
  "Eritrea": "ER",
  "Finland": "FI",
  "Croatia": "HR",
  "Jamaica": "JM",
  "Kazakhstan": "KZ",
  "Korea": "KR",
  "Kyrgyzstan": "KG",
  "Libyan Arab Jamahiriya": "LY",
  "Liberia": "LR",
  "Mali": "ML",
  "Madagascar": "MG",
  "Mongolia": "MN",
  "Morocco": "MA",
  "Mozambique": "MZ",
  "Niger": "NE",
  "Nicaragua": "NI",
  "Oman": "OM",
  "Paraguay": "PY",
  "Singapore": "SG",
  "Somalia": "SO",
  "Spain": "ES",
  "Mauritania": "MR",
  "Poland": "PL",
  "Romania": "RO",
  "Sweden": "SE",
  "Serbia": "RS",
  "Israel": "IL",
  "Malawi": "MW",
  "Rwanda": "RW",
  "Sudan": "SD",
  "Sierra Leone": "SL",
  "Taiwan": "TW",
  "Tanzania": "TZ",
  "United Kingdom": "GB",
  "Moldova": "MD",
  "Lebanon": "LB",
  "Pakistan": "PK",
  "Peru": "PE",
  "Thailand": "TH",
  "Turkey": "TR",
  "Uganda": "UG",
  "Ukraine": "UA",
  "Uzbekistan": "UZ",
  "Venezuela": "VE",
  "United States": "US",
  "Uruguay": "UY",
  "Yemen": "YE",
  "Kuwait": "KW",
  "Sri Lanka": "LK",
  "Netherlands": "NL",
  "Norway": "NO",
  "Latvia": "LV",
  "Tajikistan": "TJ",
  "Togo": "TG",
  "Turkmenistan": "TM",
  "Macau": "MO",
  "Zimbabwe": "ZW",
  "Greenland": "GL",
  "Zambia": "ZM",
  "Costa Rica": "CR",
  "New Zealand": "NZ",
  "Luxembourg": "LU",
  "Maldives": "MV",
  "Slovakia": "SK",
  "Switzerland": "CH",
  "Estonia": "EE",
  "Lithuania": "LT",
  "Namibia": "NA",
  "Panama": "PA",
  "Iceland": "IS",
  "New Caledonia": "NC",
  "New Zeland": "NZ",
  "Papua New Guinea": "PG",
  "Solomon Islands": "SB",
  "Vanuatu": "VU",
};

export const geoFilters: any = {
  "Asia": {
    "AF": {
      "countryName": "Afghanistan",
      "country": "AF",
      "continent": "Asia",
      "cities": [
        "Kabul"
      ]
    },
    "AM": {
      "countryName": "Armenia",
      "country": "AM",
      "continent": "Asia",
      "cities": [
        "Yerevan"
      ]
    },
    "BD": {
      "countryName": "Bangladesh",
      "country": "BD",
      "continent": "Asia",
      "cities": [
        "Dhaka",
        "Comilla",
        "Khulna",
        "Rājshāhi",
        "Rangpur",
        "Chattogram"
      ]
    },
    "AZ": {
      "countryName": "Azerbaijan",
      "country": "AZ",
      "continent": "Asia",
      "cities": [
        "Baku"
      ]
    },
    "CN": {
      "countryName": "China",
      "country": "CN",
      "continent": "Asia",
      "cities": [
        "Xinyang",
        "Weinan",
        "Shijiazhuang",
        "Laiwu",
        "Hengyang",
        "Changchun",
        "Linyi",
        "Hohhot",
        "Puyang",
        "Leshan"
      ]
    },
    "AE": {
      "countryName": "United Arab Emirates",
      "country": "AE",
      "continent": "Asia",
      "cities": [
        "Dubai",
        "Abu Dhabi",
        "Al Ain City",
        "Sharjah"
      ]
    },
    "GE": {
      "countryName": "Georgia",
      "country": "GE",
      "continent": "Asia",
      "cities": [
        "Tbilisi"
      ]
    },
    "IR": {
      "countryName": "Iran",
      "country": "IR",
      "continent": "Asia",
      "cities": [
        "Karaj",
        "Isfahan",
        "Tehran",
        "Qom",
        "Kerman",
        "Pasragad Branch",
        "Orūmīyeh",
        "Rasht"
      ]
    },
    "IN": {
      "countryName": "India",
      "country": "IN",
      "continent": "Asia",
      "cities": [
        "Coimbatore",
        "Mysore",
        "Kolkata",
        "Faridabad",
        "Kota",
        "Kanayannur",
        "Agra",
        "Bhopāl",
        "Puducherry",
        "Varanasi"
      ]
    },
    "ID": {
      "countryName": "Indonesia",
      "country": "ID",
      "continent": "Asia",
      "cities": [
        "Medan",
        "Malang",
        "Pekanbaru",
        "Batam",
        "Jakarta",
        "Jepara",
        "Tangerang",
        "Padang",
        "Balikpapan",
        "Denpasar",
        "Situbondo"
      ]
    },
    "IQ": {
      "countryName": "Iraq",
      "country": "IQ",
      "continent": "Asia",
      "cities": [
        "Erbil",
        "Abū Ghurayb",
        "Al Başrah al Qadīmah",
        "Baghdad",
        "As Sulaymānīyah",
        "Karbala",
        "Basrah",
        "Mosul",
        "Al Mawşil al Jadīdah",
        "Kirkuk"
      ]
    },
    "JO": {
      "countryName": "Jordan",
      "country": "JO",
      "continent": "Asia",
      "cities": [
        "Amman",
        "Zarqa"
      ]
    },
    "KH": {
      "countryName": "Cambodia",
      "country": "KH",
      "continent": "Asia",
      "cities": [
        "Phnom Penh",
        "Takeo"
      ]
    },
    "JP": {
      "countryName": "Japan",
      "country": "JP",
      "continent": "Asia",
      "cities": [
        "Hiroshima",
        "Saitama",
        "Osaka",
        "Chiba",
        "Sapporo",
        "Kobe",
        "Kawaguchi",
        "Honchō",
        "Okayama",
        "Kagoshima",
        "Hamamatsu"
      ]
    },
    "KZ": {
      "countryName": "Kazakhstan",
      "country": "KZ",
      "continent": "Asia",
      "cities": [
        "Shymkent",
        "Almaty"
      ]
    },
    "KP": {
      "countryName": "Korea",
      "country": "KP",
      "continent": "Asia",
      "cities": [
        "Pyongyang"
      ]
    },
    "KR": {
      "countryName": "Korea",
      "country": "KR",
      "continent": "Asia",
      "cities": [
        "Busan",
        "Seongnam-si",
        "Bucheon-si",
        "Daejeon",
        "Gwangju",
        "Cheongju-si",
        "Daegu",
        "Cheonan",
        "Ansan-si"
      ]
    },
    "KG": {
      "countryName": "Kyrgyzstan",
      "country": "KG",
      "continent": "Asia",
      "cities": [
        "Bishkek"
      ]
    },
    "LB": {
      "countryName": "Lebanon",
      "country": "LB",
      "continent": "Asia",
      "cities": [
        "Ra’s Bayrūt",
        "Beirut"
      ]
    },
    "MY": {
      "countryName": "Malaysia",
      "country": "MY",
      "continent": "Asia",
      "cities": [
        "Kuala Lumpur",
        "Ipoh",
        "Johor Bahru",
        "Klang",
        "Petaling Jaya",
        "Kampung Baru Subang",
        "Shah Alam"
      ]
    },
    "MM": {
      "countryName": "Myanmar",
      "country": "MM",
      "continent": "Asia",
      "cities": [
        "Yangon",
        "Mandalay",
        "Nay Pyi Taw"
      ]
    },
    "NP": {
      "countryName": "Nepal",
      "country": "NP",
      "continent": "Asia",
      "cities": [
        "Kathmandu"
      ]
    },
    "PH": {
      "countryName": "Philippines",
      "country": "PH",
      "continent": "Asia",
      "cities": [
        "Quezon City",
        "Caloocan City",
        "Cebu City",
        "Taguig",
        "Pasig City",
        "General Santos",
        "Paranaque City",
        "Las Piñas"
      ]
    },
    "PK": {
      "countryName": "Pakistan",
      "country": "PK",
      "continent": "Asia",
      "cities": [
        "Rawalpindi",
        "Karachi",
        "Faisalabad",
        "Gujranwala",
        "Peshawar",
        "Multan",
        "Rahim Yar Khan",
        "Battagram",
        "Muzaffarābād",
        "Kotli"
      ]
    },
    "SA": {
      "countryName": "Saudi Arabia",
      "country": "SA",
      "continent": "Asia",
      "cities": [
        "Riyadh",
        "Jeddah",
        "Mecca",
        "Medina",
        "Dammam",
        "Sulţānah",
        "Tabuk",
        "Buraydah",
        "Ta’if",
        "Ha'il"
      ]
    },
    "SG": {
      "countryName": "Singapore",
      "country": "SG",
      "continent": "Asia",
      "cities": [
        "Singapore"
      ]
    },
    "SY": {
      "countryName": "Syrian Arab Republic",
      "country": "SY",
      "continent": "Asia",
      "cities": [
        "Aleppo",
        "Damascus",
        "Latakia",
        "Homs"
      ]
    },
    "TH": {
      "countryName": "Thailand",
      "country": "TH",
      "continent": "Asia",
      "cities": [
        "Bangkok"
      ]
    },
    "TW": {
      "countryName": "Taiwan",
      "country": "TW",
      "continent": "Asia",
      "cities": [
        "Taipei",
        "Kaohsiung",
        "Taichung",
        "Tainan"
      ]
    },
    "HK": {
      "countryName": "Hong Kong",
      "country": "HK",
      "continent": "Asia",
      "cities": [
        "Hong Kong",
        "Kowloon",
        "Victoria"
      ]
    },
    "IL": {
      "countryName": "Israel",
      "country": "IL",
      "continent": "Asia",
      "cities": [
        "Jerusalem"
      ]
    },
    "TR": {
      "countryName": "Turkey",
      "country": "TR",
      "continent": "Asia",
      "cities": [
        "Gaziantep",
        "Antalya",
        "Eskişehir",
        "Adana",
        "Diyarbakır",
        "Ankara",
        "Kayseri",
        "Malatya",
        "Çankaya"
      ]
    },
    "MN": {
      "countryName": "Mongolia",
      "country": "MN",
      "continent": "Asia",
      "cities": [
        "Ulan Bator"
      ]
    },
    "OM": {
      "countryName": "Oman",
      "country": "OM",
      "continent": "Asia",
      "cities": [
        "Muscat"
      ]
    },
    "LK": {
      "countryName": "Sri Lanka",
      "country": "LK",
      "continent": "Asia",
      "cities": [
        "Colombo"
      ]
    },
    "KW": {
      "countryName": "Kuwait",
      "country": "KW",
      "continent": "Asia",
      "cities": [
        "Al Aḩmadī"
      ]
    },
    "TJ": {
      "countryName": "Tajikistan",
      "country": "TJ",
      "continent": "Asia",
      "cities": [
        "Dushanbe"
      ]
    },
    "TM": {
      "countryName": "Turkmenistan",
      "country": "TM",
      "continent": "Asia",
      "cities": [
        "Ashgabat"
      ]
    },
    "MO": {
      "countryName": "Macau",
      "country": "MO",
      "continent": "Asia",
      "cities": [
        "Macau"
      ]
    },
    "VN": {
      "countryName": "Viet Nam",
      "country": "VN",
      "continent": "Asia",
      "cities": [
        "Ho Chi Minh City",
        "Hanoi",
        "Haiphong",
        "Biên Hòa",
        "Cần Thơ",
        "Thuận An",
        "Da Nang"
      ]
    },
    "UZ": {
      "countryName": "Uzbekistan",
      "country": "UZ",
      "continent": "Asia",
      "cities": [
        "Tashkent",
        "Namangan"
      ]
    },
    "YE": {
      "countryName": "Yemen",
      "country": "YE",
      "continent": "Asia",
      "cities": [
        "Sanaa",
        "Al Ḩudaydah",
        "Aden",
        "Taiz"
      ]
    }
  },
  "Africa": {
    "AO": {
      "countryName": "Angola",
      "country": "AO",
      "continent": "Africa",
      "cities": [
        "Luanda",
        "Lubango",
        "Huambo"
      ]
    },
    "BF": {
      "countryName": "Burkina Faso",
      "country": "BF",
      "continent": "Africa",
      "cities": [
        "Bobo-Dioulasso",
        "Ouagadougou"
      ]
    },
    "BI": {
      "countryName": "Burundi",
      "country": "BI",
      "continent": "Africa",
      "cities": [
        "Bujumbura"
      ]
    },
    "BW": {
      "countryName": "Botswana",
      "country": "BW",
      "continent": "Africa",
      "cities": [
        "Francistown",
        "Gaborone",
        "Orapa",
        "Serowe",
        "Village"
      ]
    },
    "CD": {
      "countryName": "Congo",
      "country": "CD",
      "continent": "Africa",
      "cities": [
        "Kisangani",
        "Kolwezi",
        "Likasi",
        "Tshikapa",
        "Kinshasa",
        "Bukavu",
        "Lubumbashi",
        "Mbuji-Mayi",
        "Kananga"
      ]
    },
    "DJ": {
      "countryName": "Djibouti",
      "country": "DJ",
      "continent": "Africa",
      "cities": [
        "Djibouti"
      ]
    },
    "DZ": {
      "countryName": "Algeria",
      "country": "DZ",
      "continent": "Africa",
      "cities": [
        "Algiers",
        "Oran"
      ]
    },
    "BJ": {
      "countryName": "Benin",
      "country": "BJ",
      "continent": "Africa",
      "cities": [
        "Cotonou"
      ]
    },
    "CF": {
      "countryName": "Central African Republic",
      "country": "CF",
      "continent": "Africa",
      "cities": [
        "Bangui"
      ]
    },
    "CI": {
      "countryName": "Côte d'Ivoire",
      "country": "CI",
      "continent": "Africa",
      "cities": [
        "Abidjan",
        "Abobo",
        "Bouaké"
      ]
    },
    "CG": {
      "countryName": "Congo",
      "country": "CG",
      "continent": "Africa",
      "cities": [
        "Brazzaville",
        "Pointe-Noire"
      ]
    },
    "CM": {
      "countryName": "Cameroon",
      "country": "CM",
      "continent": "Africa",
      "cities": [
        "Yaoundé",
        "Douala"
      ]
    },
    "ER": {
      "countryName": "Eritrea",
      "country": "ER",
      "continent": "Africa",
      "cities": [
        "Asmara"
      ]
    },
    "EG": {
      "countryName": "Egypt",
      "country": "EG",
      "continent": "Africa",
      "cities": [
        "Giza",
        "Cairo",
        "Alexandria",
        "Shubrā al Khaymah",
        "Suez",
        "Al Maḩallah al Kubrá",
        "Tanta",
        "Al Mansurah",
        "Port Said"
      ]
    },
    "ET": {
      "countryName": "Ethiopia",
      "country": "ET",
      "continent": "Africa",
      "cities": [
        "Addis Ababa"
      ]
    },
    "GH": {
      "countryName": "Ghana",
      "country": "GH",
      "continent": "Africa",
      "cities": [
        "Kumasi",
        "Accra"
      ]
    },
    "GN": {
      "countryName": "Guinea",
      "country": "GN",
      "continent": "Africa",
      "cities": [
        "Camayenne",
        "Conakry"
      ]
    },
    "TD": {
      "countryName": "Chad",
      "country": "TD",
      "continent": "Africa",
      "cities": [
        "N'Djamena"
      ]
    },
    "GA": {
      "countryName": "Gabon",
      "country": "GA",
      "continent": "Africa",
      "cities": [
        "Libreville"
      ]
    },
    "LY": {
      "countryName": "Libyan Arab Jamahiriya",
      "country": "LY",
      "continent": "Africa",
      "cities": [
        "Tripoli",
        "Benghazi"
      ]
    },
    "LR": {
      "countryName": "Liberia",
      "country": "LR",
      "continent": "Africa",
      "cities": [
        "Monrovia"
      ]
    },
    "MA": {
      "countryName": "Morocco",
      "country": "MA",
      "continent": "Africa",
      "cities": [
        "Casablanca",
        "Rabat",
        "Salé",
        "Fes",
        "Marrakesh",
        "Tangier",
        "Meknes",
        "Agadir"
      ]
    },
    "ML": {
      "countryName": "Mali",
      "country": "ML",
      "continent": "Africa",
      "cities": [
        "Bamako"
      ]
    },
    "MG": {
      "countryName": "Madagascar",
      "country": "MG",
      "continent": "Africa",
      "cities": [
        "Antananarivo"
      ]
    },
    "NA": {
      "countryName": "Namibia",
      "country": "NA",
      "continent": "Africa",
      "cities": [
        "Etunda",
        "Grootfontein",
        "Katima Mulilo",
        "Keetmanshoop",
        "Mpapuka",
        "Olympia",
        "Ondangwa",
        "Ongwediva",
        "Oranjemund",
        "Oshakati",
        "Otjiwarongo",
        "Outapi",
        "Swakopmund",
        "Tsumeb",
        "Walvis Bay",
        "Windhoek"
      ]
    },
    "NG": {
      "countryName": "Nigeria",
      "country": "NG",
      "continent": "Africa",
      "cities": [
        "Benin City",
        "Kaduna",
        "Lagos",
        "Ibadan",
        "Warri",
        "Ilorin",
        "Jos",
        "Maiduguri",
        "Ile-Ife"
      ]
    },
    "SN": {
      "countryName": "Senegal",
      "country": "SN",
      "continent": "Africa",
      "cities": [
        "Dakar",
        "Pikine",
        "Touba"
      ]
    },
    "SO": {
      "countryName": "Somalia",
      "country": "SO",
      "continent": "Africa",
      "cities": [
        "Mogadishu"
      ]
    },
    "SD": {
      "countryName": "Sudan",
      "country": "SD",
      "continent": "Africa",
      "cities": [
        "Khartoum",
        "Omdurman"
      ]
    },
    "TZ": {
      "countryName": "Tanzania",
      "country": "TZ",
      "continent": "Africa",
      "cities": [
        "Dar es Salaam",
        "Mwanza",
        "Arusha"
      ]
    },
    "KE": {
      "countryName": "Kenya",
      "country": "KE",
      "continent": "Africa",
      "cities": [
        "Nairobi",
        "Kakamega",
        "Mombasa"
      ]
    },
    "MZ": {
      "countryName": "Mozambique",
      "country": "MZ",
      "continent": "Africa",
      "cities": [
        "Maputo",
        "Matola",
        "Nampula",
        "Beira"
      ]
    },
    "MW": {
      "countryName": "Malawi",
      "country": "MW",
      "continent": "Africa",
      "cities": [
        "Lilongwe",
        "Blantyre"
      ]
    },
    "NE": {
      "countryName": "Niger",
      "country": "NE",
      "continent": "Africa",
      "cities": [
        "Niamey"
      ]
    },
    "RW": {
      "countryName": "Rwanda",
      "country": "RW",
      "continent": "Africa",
      "cities": [
        "Kigali"
      ]
    },
    "SL": {
      "countryName": "Sierra Leone",
      "country": "SL",
      "continent": "Africa",
      "cities": [
        "Freetown"
      ]
    },
    "MR": {
      "countryName": "Mauritania",
      "country": "MR",
      "continent": "Africa",
      "cities": [
        "Nouakchott"
      ]
    },
    "UG": {
      "countryName": "Uganda",
      "country": "UG",
      "continent": "Africa",
      "cities": [
        "Kampala"
      ]
    },
    "TG": {
      "countryName": "Togo",
      "country": "TG",
      "continent": "Africa",
      "cities": [
        "Lomé"
      ]
    },
    "TN": {
      "countryName": "Tunisia",
      "country": "TN",
      "continent": "Africa",
      "cities": [
        "Tunis"
      ]
    },
    "ZA": {
      "countryName": "South Africa",
      "country": "ZA",
      "continent": "Africa",
      "cities": [
        "Cape Town",
        "Durban",
        "Soweto",
        "Pretoria",
        "Johannesburg",
        "Port Elizabeth",
        "Benoni",
        "Pietermaritzburg"
      ]
    },
    "ZW": {
      "countryName": "Zimbabwe",
      "country": "ZW",
      "continent": "Africa",
      "cities": [
        "Harare",
        "Bulawayo"
      ]
    },
    "ZM": {
      "countryName": "Zambia",
      "country": "ZM",
      "continent": "Africa",
      "cities": [
        "Lusaka"
      ]
    }
  },
  "South America": {
    "AR": {
      "countryName": "Argentina",
      "country": "AR",
      "continent": "South America",
      "cities": [
        "Rosario",
        "Córdoba",
        "Buenos Aires",
        "Mar del Plata"
      ]
    },
    "BO": {
      "countryName": "Bolivia",
      "country": "BO",
      "continent": "South America",
      "cities": [
        "La Paz",
        "Cochabamba",
        "Santa Cruz de la Sierra"
      ]
    },
    "BR": {
      "countryName": "Brazil",
      "country": "BR",
      "continent": "South America",
      "cities": [
        "Maceió",
        "Nova Iguaçu",
        "Goiânia",
        "Guarulhos",
        "Jaboatão dos Guararapes",
        "Manaus",
        "Natal",
        "Duque de Caxias",
        "São Luís",
        "Osasco",
        "Belém"
      ]
    },
    "CO": {
      "countryName": "Colombia",
      "country": "CO",
      "continent": "South America",
      "cities": [
        "Cali",
        "Barranquilla",
        "Bogotá",
        "Medellín",
        "Cartagena",
        "Cúcuta",
        "Bucaramanga"
      ]
    },
    "CL": {
      "countryName": "Chile",
      "country": "CL",
      "continent": "South America",
      "cities": [
        "Santiago"
      ]
    },
    "EC": {
      "countryName": "Ecuador",
      "country": "EC",
      "continent": "South America",
      "cities": [
        "Quito",
        "Guayaquil",
        "Cuenca"
      ]
    },
    "PE": {
      "countryName": "Peru",
      "country": "PE",
      "continent": "South America",
      "cities": [
        "Trujillo",
        "Callao",
        "Lima",
        "Arequipa"
      ]
    },
    "PY": {
      "countryName": "Paraguay",
      "country": "PY",
      "continent": "South America",
      "cities": [
        "Asunción"
      ]
    },
    "UY": {
      "countryName": "Uruguay",
      "country": "UY",
      "continent": "South America",
      "cities": [
        "Montevideo"
      ]
    },
    "VE": {
      "countryName": "Venezuela",
      "country": "VE",
      "continent": "South America",
      "cities": [
        "Barquisimeto",
        "Maracay",
        "Maracaibo",
        "Valencia",
        "Ciudad Guayana",
        "Caracas",
        "Maturín",
        "Barcelona"
      ]
    }
  },
  "Oceania": {
    "AU": {
      "countryName": "Australia",
      "country": "AU",
      "continent": "Oceania",
      "cities": [
        "Adelaide",
        "Sydney",
        "Melbourne",
        "Perth",
        "Brisbane",
        "Gold Coast"
      ]
    },
    "FJ": {
      "countryName": "Fiji",
      "country": "FJ",
      "continent": "Oceania",
      "cities": [
        "Suva",
        "Lautoka",
        "Nadi",
        "Levuka",
        "Ba",
        "Lami",
        "Savusavu",
        "Rakiraki",
        "Tavua",
        "Sigatoka",
        "Navua",
        "Nausori",
        "Korovou",
        "Labasa",
        "Vunisea",
        "Laukota",
        "Vilivilin",
        "Somosomo",
        "Kadavu",
        "Vailoku",
        "Savuna",
        "Malha",
        "Vaiwai"
      ]
    },
    "NC": {
      "countryName": "New Caledonia",
      "country": "NC",
      "continent": "Oceania",
      "cities": [
        "Nouméa",
        "Mont-Dore",
        "Dumbéa",
        "Païta",
        "Wé",
        "Koné",
        "Bourail",
        "Koumac",
        "Poum",
        "Pouébo",
        "Poindimié",
        "Houaïlou",
        "Ouégoa",
        "Kaala-Gomen",
        "Pouembout",
        "Touho",
        "Poya",
        "Canala",
        "Voh",
        "Thio",
        "Kouaoua",
        "La Foa",
        "Sarraméa",
        "Farino",
        "Moindou",
        "Bouloupari",
        "Yaté",
        "Île des Pins",
        "Tadine",
        "Vao",
        "Bélep"
      ]
    },
    "NZ": {
      "countryName": "New Zealand",
      "country": "NZ",
      "continent": "Oceania",
      "cities": [
        "Auckland",
        "Wellington",
        "Christchurch",
        "Hamilton",
        "Tauranga",
        "Napier-Hastings",
        "Dunedin",
        "Palmerston North",
        "Nelson",
        "Rotorua",
        "Whangarei",
        "New Plymouth",
        "Invercargill",
        "Whanganui",
        "Gisborne",
        "Masterton",
        "Levin",
        "Tokoroa",
        "Ashburton",
        "Timaru",
        "Taupo",
        "Pukekohe East",
        "Morrinsville",
        "Thames",
        "Kawerau",
        "Waitara",
        "Ōtaki",
        "Kerikeri",
        "Foxton",
        "Dargaville",
        "Waiuku",
        "Wairoa",
        "Raglan",
        "Taihape",
        "Eltham",
        "Te Aroha",
        "Roxburgh",
        "Waimate North",
        "Paihia",
        "Brightwater",
        "Te Kauwhata",
        "Opotiki",
        "Hāwera",
        "Winton",
        "Balclutha",
        "Waihi",
        "Marton",
        "Wairoa",
        "Warkworth",
        "Whitianga",
        "Turangi",
        "Waihi Beach",
        "Otorohanga",
        "Paeroa",
        "Westport",
        "Wellsford",
        "Tīrau",
        "Putaruru",
        "Picton",
        "Matakana",
        "Kerikeri",
        "Temuka",
        "Snells Beach",
        "Te Anau",
        "Katikati",
        "Tūrangi",
        "Dannevirke",
        "Arrowtown",
        "Te Kuiti",
        "Temuka",
        "Hāwera",
        "Leeston",
        "Warkworth",
        "Methven",
        "Waihi",
        "Murchison",
        "Te Kauwhata",
        "Waipukurau",
        "Paraparaumu Beach",
        "Ōpōtiki",
        "Waiuku",
        "Winton",
        "Balclutha",
        "Waipawa",
        "Hāwera",
        "Kerikeri",
        "Dargaville",
        "Waipawa",
        "Taihape",
        "Foxton",
        "Levin",
        "Dannevirke",
        "Te Anau",
        "Wairoa",
        "Brightwater",
        "Methven",
        "Ōtaki",
        "Waimate",
        "Kawerau",
        "Warkworth",
        "Picton",
        "Turangi",
        "Whitianga"
      ]
    },
    "PG": {
      "countryName": "Papua New Guinea",
      "country": "PG",
      "continent": "Oceania",
      "cities": [
        "Aitape",
        "Arawa",
        "Daru",
        "Goroka",
        "Kavieng",
        "Kerema",
        "Kikori",
        "Kimbe",
        "Kiunga",
        "Kokopo",
        "Kundiawa",
        "Kupano",
        "Lae",
        "Lorengau",
        "Madang",
        "Mendi",
        "Mount Hagen",
        "Namatanai",
        "Nambaga",
        "Paivara",
        "Pongani",
        "Popondetta",
        "Port Moresby",
        "Vanimo",
        "Wabag",
        "Waigani",
        "Wewak"
      ]
    },
    "SB": {
      "countryName": "Solomon Islands",
      "country": "SB",
      "continent": "Oceania",
      "cities": [
        "Central",
        "Choiseul",
        "Guadalcanal",
        "Honiara",
        "Isabel",
        "Makira",
        "Malaita",
        "Rennell and Bellona",
        "Temotu",
        "Western"
      ]
    },
    "VU": {
      "countryName": "Vanuatu",
      "country": "VU",
      "continent": "Oceania",
      "cities": [
        "Malampa",
        "Penama",
        "Sanma",
        "Shefa",
        "Tafea",
        "Torba"
      ]
    },
  },
  "North America": {
    "CA": {
      "countryName": "Canada",
      "country": "CA",
      "continent": "North America",
      "cities": [
        "Toronto",
        "Ottawa",
        "Calgary",
        "Vancouver",
        "Winnipeg",
        "Montréal",
        "Mississauga",
        "Brampton",
        "Edmonton"
      ]
    },
    "CU": {
      "countryName": "Cuba",
      "country": "CU",
      "continent": "North America",
      "cities": [
        "Havana"
      ]
    },
    "DO": {
      "countryName": "Dominican Republic",
      "country": "DO",
      "continent": "North America",
      "cities": [
        "Santo Domingo Oeste",
        "Santo Domingo Este",
        "Santo Domingo",
        "Santiago de los Caballeros"
      ]
    },
    "GT": {
      "countryName": "Guatemala",
      "country": "GT",
      "continent": "North America",
      "cities": [
        "Guatemala City"
      ]
    },
    "GL": {
      "countryName": "Greenland",
      "country": "GL",
      "continent": "North America",
      "cities": [
        "Aasiaat",
        "Ilulissat",
        "Kapisillit",
        "Maniitsoq",
        "Narsaq",
        "Narsarsuaq",
        "Nuuk",
        "Nuussuaq",
        "Paamiut",
        "Qaqortoq",
        "Qasigiannguit",
        "Qeqertarsuaq",
        "Qeqertat",
        "Sisimiut",
        "Tasiilaq",
        "Upernavik",
        "Uummannaq Kommune"
      ]
    },
    "HN": {
      "countryName": "Honduras",
      "country": "HN",
      "continent": "North America",
      "cities": [
        "Tegucigalpa"
      ]
    },
    "HT": {
      "countryName": "Haiti",
      "country": "HT",
      "continent": "North America",
      "cities": [
        "Port-au-Prince"
      ]
    },
    "MX": {
      "countryName": "Mexico",
      "country": "MX",
      "continent": "North America",
      "cities": [
        "Tijuana",
        "Puebla",
        "Mérida",
        "Monterrey",
        "Reynosa",
        "Naucalpan de Juárez",
        "Saltillo",
        "San Luis Potosí",
        "Culiacán",
        "Santa María Chimalhuacán",
        "Coyoacán"
      ]
    },
    "NI": {
      "countryName": "Nicaragua",
      "country": "NI",
      "continent": "North America",
      "cities": [
        "Managua"
      ]
    },
    "JM": {
      "countryName": "Jamaica",
      "country": "JM",
      "continent": "North America",
      "cities": [
        "Kingston"
      ]
    },
    "US": {
      "countryName": "United States",
      "country": "US",
      "continent": "North America",
      "cities": [
        "San Antonio",
        "Queens",
        "Manhattan",
        "Charlotte",
        "Phoenix",
        "Jacksonville",
        "Baltimore",
        "Boston",
        "Las Vegas",
        "El Paso"
      ]
    }
  },
  "Europe": {
    "AT": {
      "countryName": "Austria",
      "country": "AT",
      "continent": "Europe",
      "cities": [
        "Vienna"
      ]
    },
    "BE": {
      "countryName": "Belgium",
      "country": "BE",
      "continent": "Europe",
      "cities": [
        "Brussels"
      ]
    },
    "BA": {
      "countryName": "Bosnia and Herzegovina",
      "country": "BA",
      "continent": "Europe",
      "cities": [
        "Sarajevo"
      ]
    },
    "BG": {
      "countryName": "Bulgaria",
      "country": "BG",
      "continent": "Europe",
      "cities": [
        "Sofia"
      ]
    },
    "BY": {
      "countryName": "Belarus",
      "country": "BY",
      "continent": "Europe",
      "cities": [
        "Minsk"
      ]
    },
    "CZ": {
      "countryName": "Czech Republic",
      "country": "CZ",
      "continent": "Europe",
      "cities": [
        "Prague"
      ]
    },
    "DE": {
      "countryName": "Germany",
      "country": "DE",
      "continent": "Europe",
      "cities": [
        "Hamburg",
        "Frankfurt am Main",
        "Berlin",
        "Köln",
        "Munich",
        "Großzschocher",
        "Essen"
      ]
    },
    "DK": {
      "countryName": "Denmark",
      "country": "DK",
      "continent": "Europe",
      "cities": [
        "Copenhagen"
      ]
    },
    "ES": {
      "countryName": "Spain",
      "country": "ES",
      "continent": "Europe",
      "cities": [
        "Madrid",
        "Barcelona",
        "Zaragoza",
        "Sevilla",
        "Valencia"
      ]
    },
    "HU": {
      "countryName": "Hungary",
      "country": "HU",
      "continent": "Europe",
      "cities": [
        "Budapest"
      ]
    },
    "FI": {
      "countryName": "Finland",
      "country": "FI",
      "continent": "Europe",
      "cities": [
        "Helsinki"
      ]
    },
    "FR": {
      "countryName": "France",
      "country": "FR",
      "continent": "Europe",
      "cities": [
        "Paris",
        "Marseille"
      ]
    },
    "GR": {
      "countryName": "Greece",
      "country": "GR",
      "continent": "Europe",
      "cities": [
        "Athens"
      ]
    },
    "HR": {
      "countryName": "Croatia",
      "country": "HR",
      "continent": "Europe",
      "cities": [
        "Zagreb"
      ]
    },
    "IE": {
      "countryName": "Ireland",
      "country": "IE",
      "continent": "Europe",
      "cities": [
        "Dublin"
      ]
    },
    "IT": {
      "countryName": "Italy",
      "country": "IT",
      "continent": "Europe",
      "cities": [
        "Naples",
        "Rome",
        "Turin",
        "Milan",
        "Palermo",
        "Genoa"
      ]
    },
    "IS": {
      "countryName": "Iceland",
      "country": "IS",
      "continent": "Europe",
      "cities": [
        "Akranes",
        "Akureyri",
        "Borgarnes",
        "Dalvik",
        "Grindavik",
        "Hella",
        "Holmavik",
        "Husavik",
        "Hvammstangi",
        "Hveragerdi",
        "Hvolsvollur",
        "Kopavogur",
        "Reykjavik",
        "Selfoss",
        "Skagastrond",
        "Stokkseyri",
        "Vestmannaeyjar",
        "Vogar"
      ]
    },
    "PL": {
      "countryName": "Poland",
      "country": "PL",
      "continent": "Europe",
      "cities": [
        "Warsaw",
        "Łódź",
        "Wrocław",
        "Kraków"
      ]
    },
    "RO": {
      "countryName": "Romania",
      "country": "RO",
      "continent": "Europe",
      "cities": [
        "Bucharest"
      ]
    },
    "RU": {
      "countryName": "Russia",
      "country": "RU",
      "continent": "Europe",
      "cities": [
        "Moscow",
        "Saint Petersburg",
        "Novosibirsk",
        "Yekaterinburg",
        "Nizhny Novgorod",
        "Kazan",
        "Chelyabinsk",
        "Omsk",
        "Samara",
        "Rostov-on-Don",
        "Ufa",
        "Krasnoyarsk",
        "Perm",
        "Voronezh",
        "Volgograd",
        "Saratov",
        "Krasnodar",
        "Tolyatti",
        "Izhevsk",
        "Barnaul",
        "Ulyanovsk",
        "Vladivostok",
        "Yaroslavl",
        "Irkutsk",
        "Tyumen",
        "Khabarovsk",
        "Makhachkala",
        "Orenburg",
        "Novokuznetsk",
        "Kemerovo",
        "Ryazan",
        "Tomsk",
        "Astrakhan",
        "Penza",
        "Kirov",
        "Lipetsk",
        "Cheboksary",
        "Tula",
        "Kaliningrad",
        "Bryansk",
        "Ivanovo",
        "Magnitogorsk",
        "Kursk",
        "Nizhny Tagil",
        "Stavropol",
        "Ulan-Ude",
        "Tver",
        "Sochi",
        "Izhevsk",
        "Tobolsk",
        "Vladikavkaz",
        "Kostroma",
        "Volzhsky",
        "Taganrog",
        "Novorossiysk",
        "Syktyvkar",
        "Nalchik",
        "Kurgan",
        "Khimki",
        "Shakhty",
        "Cherepovets",
        "Bratsk",
        "Miass",
        "Serpukhov",
        "Yuzhno-Sakhalinsk",
        "Engels",
        "Yoshkar-Ola",
        "Podolsk",
        "Tula",
        "Votkinsk",
        "Novocherkassk",
        "Balashikha",
        "Severodvinsk",
        "Zelenograd",
        "Korolev",
        "Armavir",
        "Lyubertsy",
        "Prokopyevsk",
        "Abakan",
        "Kolomna",
        "Noginsk",
        "Dzerzhinsk",
        "Orekhovo-Zuyevo",
        "Nizhnekamsk",
        "Oktyabrsky",
        "Arzamas",
        "Khimki",
        "Rubtsovsk",
        "Mytishchi",
        "Kamensk-Uralsky",
        "Novomoskovsk",
        "Zheleznogorsk"
      ]
    },
    "GB": {
      "countryName": "United Kingdom",
      "country": "GB",
      "continent": "Europe",
      "cities": [
        "London",
        "Birmingham",
        "Liverpool",
        "Glasgow"
      ]
    },
    "RS": {
      "countryName": "Serbia",
      "country": "RS",
      "continent": "Europe",
      "cities": [
        "Belgrade"
      ]
    },
    "UA": {
      "countryName": "Ukraine",
      "country": "UA",
      "continent": "Europe",
      "cities": [
        "Donetsk",
        "Kharkiv",
        "Odesa",
        "Kyiv",
        "Dnipro",
        "Kryvyy Rih",
        "Zaporizhzhya",
        "Lviv"
      ]
    },
    "SE": {
      "countryName": "Sweden",
      "country": "SE",
      "continent": "Europe",
      "cities": [
        "Stockholm"
      ]
    },
    "MD": {
      "countryName": "Moldova",
      "country": "MD",
      "continent": "Europe",
      "cities": [
        "Chisinau"
      ]
    },
    "NO": {
      "countryName": "Norway",
      "country": "NO",
      "continent": "Europe",
      "cities": [
        "Oslo"
      ]
    },
    "NL": {
      "countryName": "Netherlands",
      "country": "NL",
      "continent": "Europe",
      "cities": [
        "Amsterdam"
      ]
    },
    "LV": {
      "countryName": "Latvia",
      "country": "LV",
      "continent": "Europe",
      "cities": [
        "Riga"
      ]
    }
  }
};