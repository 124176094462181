import {
  Box,
  Typography,
} from '@mui/material';



const NoPage = () => {
  return (
    <Box sx={{
      position: 'fixed',
      top: '45%',
      left: '45%'
    }}>
      <Typography variant="h1" component="h3">
        404
      </Typography>
    </Box>);
};

export default NoPage;