import { Box, useTheme } from "@mui/material"
import DatePicker from 'react-datepicker';

export const BaseDatePicker = ({ ...props }: any) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        '& .react-datepicker__header': {
          backgroundColor: '#fff',
          border: 'none'
        },
        '& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle': {
          fill: '#fff',
          color: '#f0f0f0',
          stroke: '#E2E2E2',
        },
        '& .react-datepicker__close-icon': {
          fontSize: '28px',
          ml: '3px',
          py: 0,
          pr: 0
        },
        '& .react-datepicker__close-icon::after': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.main,
          height: '30px',
          width: '30px',
          fontSize: '26px',
          p: 0
        },
        '& .react-datepicker__current-month': {
          mb: '20px',
        },
        '.react-datepicker__navigation-icon::before': {
          content: 'none',
        },
        '.react-datepicker__navigation--previous': {
          mt: '10px',
          left: '17px',
          backgroundImage: `url(/arrow.svg)`,
          backgroundRepeat: 'no-repeat',
          width: '15px',
          height: '15px',
          border: 'none'
        },
        '.react-datepicker__navigation--next': {
          mt: '5px',
          right: '15px',
          backgroundImage: `url(/arrow.svg)`,
          backgroundRepeat: 'no-repeat',
          width: '15px',
          height: '15px',
          border: 'none',
          transform: 'rotate(180deg)',
        },
        '.react-datepicker__navigation:hover': {
          opacity: '0.6'
        },
        '.react-datepicker__current-month, .react-datepicker__day-name': {
          color: '#333'
        },
        '.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range': {
          backgroundColor: theme.palette.primary.main
        },
        '.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover': {
          backgroundColor: theme.palette.primary.main,
          opacity: '0.7'
        },
        '.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected': {
          backgroundColor: theme.palette.primary.main,
          opacity: '0.7',
          color: '#fff'
        }
      }}>
      <DatePicker
        {...props}
      />
    </Box>
  )
} 