import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, SvgIcon } from '@mui/material';

const AccountResetStep2 = () => {
	const navigate = useNavigate();

	return <>
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
				textAlign: 'center',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<SvgIcon sx={
				[{
					width: '80px',
					height: '80px',
					mb: '12px',
				}]
			}>
				<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M24 38L36 50L58 28" stroke="#A460F6" strokeWidth="8" />
				</svg>
			</SvgIcon>
			<Typography variant="h4" component="h1" style={{
				fontWeight: '500',
				fontSize: '21px',
				color: '#333333',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				Your password has been changed.
			</Typography>

			<Box
				sx={{
					'& > :not(style)': { my: 0.5, width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
					alignItems: 'center',
				}}
			>
				<Box sx={{ textAlign: 'center' }}>
					<Button
						variant="outlined"
						sx={{ 
							mt: '2px', 
							py: '8px', 
							borderRadius: '20px', 
							width: '100%',
							color: '#000',
							borderColor: '#C9C9C9',
							'&:hover': {
								borderColor: '#C9C9C9',
								boxShadow: 'none',
								opacity: '0.7'
							},
							'&:active': {
								boxShadow: 'none',
								borderColor: '#C9C9C9',
							},
						}}
						onClick={() => navigate('../signin', { replace: true })}>Login
					</Button>
				</Box>
			</Box>
		</Box>
	</>;
};

export default AccountResetStep2;
