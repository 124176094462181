import { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, SvgIcon, alpha, styled } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';

type SelectItems = {
  value: string,
  label: string,
}

type SelectProps = {
  label?: string,
  name?: string,
  items: SelectItems[],
  onBlur?: any,
  onChange: any,
  value: any,
}

export default function SelectControl({ label, items, name, ...selectProps }: any) {
  const theme = useTheme();
  const iconElement = () => {
    return (
      <div className={'select-icon'}>
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5.29289 5.29289C5.68342 5.68342 6.31658 5.68342 6.70711 5.29289L11 1" stroke={theme.palette.primary.main} strokeWidth="2" />
        </svg>
      </div>
    )
  }

  return (
    <Box sx={{
      minWidth: 120,
      paddingTop: '4px',
      paddingBottom: '4px',
    }}>
      <FormControl variant="filled" fullWidth>
        {label && <InputLabel sx={{
          position: 'static',
          transform: 'none',
          marginBottom: '5px',
          color: '#333333',
          fontSize: '10px',
          paddingLeft: '12px',
        }} id={`${label}-select-label`}>{label}</InputLabel>}
        <Select
          sx={{
            '&': {
              paddingTop: '2px',
            },
            '& .MuiSvgIcon-root': {
              display: 'none',
            },
            '& .MuiBox-root': {
              border: 'none',
              padding: 0,
            }
          }}
          labelId={`${label}-select-label`}
          id={`${label}-item`}
          name={name}
          label={label}
          IconComponent={iconElement}
          defaultValue={items[0].value}
          MenuProps={{
            MenuListProps: {
              sx: {
                py: '2px',
              }
            }
          }}
          {...selectProps}
        >
          {items.map((item: SelectItems, i: number) => {
            return <MenuItem
              sx={{
                position: 'relative',
                px: '10px',
                py: '5px',
                color: '#666666',
                '& svg': {
                  width: '11px',
                  height: '8px',
                  display: 'none',
                },
                '&.Mui-selected': {
                  backgroundColor: '#fff',
                  color: '#333333',
                },
                '&.Mui-selected svg': {
                  display: 'block',
                  ml: 'auto'
                },
                '&.Mui-selected:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }
              }} key={item.value + i} value={item.value}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                pt: item.label === 'Date range' ? '5px' : '0px',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '0px',
                  left: '10px',
                  right: '10px',
                  borderTop: item.label === 'Date range' ? '1px solid #E2E2E2' : 'none',
                }
              }}>
                {item.label}
                <SvgIcon>
                  <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 3.5L4 6.5L9.5 1" stroke="black" strokeWidth="2" />
                  </svg>
                </SvgIcon>
              </Box>
            </MenuItem>
          })}
        </Select>
      </FormControl>
    </Box >
  );
}