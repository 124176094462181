import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  TextField,
  Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from '../../elements/InputText';
import { useResponseError } from '../../../hooks/useResponseError';
import { useState } from 'react';
import { loginAuth } from '../../../service/authService';
import { getUserData } from '../../../service/userService';
import { useAuth } from '../../../hooks/useAuth';
import { STORAGE_AUTH_TOKEN, STORAGE_USER } from '../../../constants/storage';

type LoginFormFields = {
  email: string;
  password: string;
};


const AccountSignIn = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { responseError, setResponseError } = useResponseError(50000);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResendBtn, setShowResendBtn] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    setShowResendBtn(false);
    try {
      const res = await loginAuth({
        data
      });
      console.log('res', res);
      login(res.accessToken);
      const resUserData = await getUserData({
        token: res?.accessToken
      });
      localStorage.setItem(STORAGE_AUTH_TOKEN, res?.accessToken);

      if (resUserData) {
        localStorage.setItem(STORAGE_USER, JSON.stringify(resUserData));
        if (resUserData?.roles.includes('ADMIN')) {
          navigate('/dashboard', {
            replace: true
          });
        } else {
          navigate('/dashboard', {
            replace: true
          });

        }
        window.location.reload();
      }
    } catch (error: any) {
      if (error.message === "please verify your email") {
        setShowResendBtn(true);
      } else {
        setShowResendBtn(false);
      }
      setResponseError(error.message);
    }
    setIsLoading(false);
  }

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<LoginFormFields>({
    defaultValues: {
      email: '',
    },
    mode: "onChange",
  });

  return (
    <Box sx={
      [{
        backgroundColor: 'white',
        py: '30px',
        px: '56px',
        maxWidth: '440px',
        width: '100%',
        boxSizing: 'border-box',
        margin: '0 auto',
        borderRadius: '10px',
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          px: '15px',
        }
      }),
      ]}>
      <Typography variant="h4" component="h1" style={{
        fontWeight: '500',
        fontSize: '21px',
        color: '#333',
        textAlign: 'center',
        marginBottom: '18px',
      }}>
        Sign in
      </Typography>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          '& > :not(style)': { width: '100%' },
          'display': 'flex',
          'flexDirection': 'column',
        }}
      >
        <InputText
          label="Email"
          placeholder="Email"
          inputProps={{ ...register("email", { required: true }) }}
          error={!!errors['email']}
          helperText={errors['email'] ? 'Please enter a valid value' : ''}
        />
        <InputText
          label="Password"
          placeholder="Password"
          type="password"
          inputProps={{
            ...register("password", {
              required: true,
            })
          }}
          error={!!errors['password']}
          helperText={errors['password'] ? 'Please enter a valid value' : ''}
        />
        <Box sx={{
          textAlign: 'left',
          color: theme.palette.error.main,
        }}>{responseError}</Box>
        <Box sx={{ backgroundColor: 'white', borderRadius: '20px', mt: '12px', mb: '20px' }}>
          <Button type="submit" sx={{ py: '8px', width: '100%' }} variant="contained">Login</Button>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Link
            sx={{
              py: '1px',
              textDecoration: 'underline',
              color: '#000',
              fontWeight: '400',
              fontSize: '13px',
            }} href="/request-reset">Forgot password?</Link>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountSignIn;