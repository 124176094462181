import { Box, Typography, SelectChangeEvent, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import SelectControl from '../../elements/SelectControl';
import { intentionsColors } from '../../../constants/storage';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { useAnalytics } from '../Analytics';
import { generateDateRangeString } from '../../../utils';


function generateCompareData(multiplier = 1): any {
  const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

  const buildSubData = (multiplier: number) => {
    const firstNumber = getRandomNumber(100 * multiplier, 500 * multiplier);
    const secondNumber = getRandomNumber(500 * multiplier, 1000 * multiplier);
    return {
      firstPercentage: Number((firstNumber / (firstNumber + secondNumber)) * 100).toFixed(0),
      firstNumber,
      secondPercentage: Number((secondNumber / (firstNumber + secondNumber)) * 100).toFixed(0),
      secondNumber,
    }
  }

  return {
    'Registered  / Non registered': {
      ...buildSubData(multiplier)
    },
    'Users w & w/o ring': {
      ...buildSubData(multiplier)
    },
    'Paid vs Free users': {
      ...buildSubData(multiplier)
    },
    'Sessions w & w/o ring': {
      ...buildSubData(multiplier)
    }
  };
}

function generateDatesArrayDays(startDay: Date, endDay: Date) {
  const datesArray = [];
  const oneDay = 24 * 60 * 60 * 1000;

  const daysCount = Math.round(Math.abs((endDay.getTime() - startDay.getTime()) / oneDay));

  for (let i = 0; i <= daysCount; i++) {
    const currentDate = new Date(startDay.getTime() + i * oneDay);
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    datesArray.push(formattedDate);
  }

  datesArray.sort((a, b) => {
    const [dayA, monthA, yearA] = a.split('.').map(Number);
    const [dayB, monthB, yearB] = b.split('.').map(Number);
    const dateA: any = new Date(yearA, monthA - 1, dayA);
    const dateB: any = new Date(yearB, monthB - 1, dayB);
    return dateA - dateB;
  });

  return datesArray;
}

function generateDatesArray(count: number, months: number, addYear: boolean) {
  const datesArray = [];

  for (let i = 0; i < count; i++) {
    const currentMonth = Math.floor(Math.random() * months) + 1;
    const currentDay = Math.floor(Math.random() * 31) + 1;
    const day = String(currentDay).padStart(2, '0');
    const month = String(currentMonth).padStart(2, '0');
    const formattedDate = `${day}.${month}${addYear ? '.2023' : ''}`;
    datesArray.push(formattedDate);
  }

  datesArray.sort((a, b) => {
    const [dayA, monthA] = a.split('.');
    const [dayB, monthB] = b.split('.');
    const dateA: any = new Date(0, parseInt(monthA, 10) - 1, parseInt(dayA, 10));
    const dateB: any = new Date(0, parseInt(monthB, 10) - 1, parseInt(dayB, 10));
    return dateA - dateB;
  });

  return datesArray;
}

const AnalyticsUsers = () => {
  const theme = useTheme();
  const [sessionsMultiplierState, setSessionsMultiplierState] = useState(1);
  const [newUsers, setNewUsers] = useState(250);
  const [repetitiveUsers, setRepetitiveUsers] = useState(170);
  const [newSubscribers, setNewSubscribers] = useState(150);
  const [firstGraphColorLabel, setFirstGraphColorLabel] = useState<string | null>('With ring');
  const [secondGraphColorLabel, setSecondGraphColorLabel] = useState<string | null>('Without ring');
  const [graphValue, setGraphValue] = useState('Users w & w/o ring');
  const [firstGraphTitle, setFirstGraphTitle] = useState<string | null>('with');
  const [firstGraphCount, setFirstGraphCount] = useState<string | null>('89');
  const [firstGraphPercentage, setFirstGraphPercentage] = useState<string | null>('34%');
  const [secondGraphTitle, setSecondGraphTitle] = useState<string | null>('without');
  const [secondGraphCount, setSecondGraphCount] = useState<string | null>('169');
  const [secondGraphPercentage, setSecondGraphPercentage] = useState<string | null>('66%');
  const [compareValuesState, setCompareValuesState] = useState({
    'Registered  / Non registered': {
      firstPercentage: 41,
      firstNumber: 450,
      secondPercentage: 59,
      secondNumber: 650
    },
    'Users w & w/o ring': {
      firstPercentage: 35,
      firstNumber: 350,
      secondPercentage: 65,
      secondNumber: 650
    },
    'Paid vs Free users': {
      firstPercentage: 30,
      firstNumber: 350,
      secondPercentage: 70,
      secondNumber: 800
    },
    'Sessions w & w/o ring': {
      firstNumber: 350,
      firstPercentage: 30,
      secondNumber: 800,
      secondPercentage: 70
    },
  })
  const { dateValue, selectedDays, selectedDate } = useAnalytics();
  const [chartData, setChartData] = useState([
    {
      name: '01',
      uv: 400,
      pv: 240,
    },
    {
      name: '02',
      uv: 300,
      pv: 39,
    },
    {
      name: '03',
      uv: 200,
      pv: 600,
    },
    {
      name: '04',
      uv: 278,
      pv: 390,
    },
    {
      name: '05',
      uv: 189,
      pv: 480,
    },
    {
      name: '06',
      uv: 239,
      pv: 380,
    },
    {
      name: '07',
      uv: 349,
      pv: 430,
    },
  ]);

  useEffect(() => {
    if (selectedDate?.startDay && selectedDate?.endDay) {
      setChartData(generateChartDataDateRange(Number(firstGraphCount), Number(secondGraphCount), selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (dateValue) {
      updateSessionsValues(dateValue);
    }
  }, [dateValue]);

  useEffect(() => {
    if (selectedDays) {
      let sessionsMultiplier = selectedDays;
      setSessionsMultiplierState(sessionsMultiplier)
      setNewUsers(250 * sessionsMultiplier);
      setRepetitiveUsers(170 * sessionsMultiplier);
      setNewSubscribers(150 * sessionsMultiplier);
      setCompareValuesState(generateCompareData(sessionsMultiplier));
      updGraphBlockValues(graphValue);
    }
  }, [selectedDays]);

  useEffect(() => {
    if (graphValue) {
      updGraphBlockValues(graphValue);
    }
  }, [graphValue, sessionsMultiplierState]);

  useEffect(() => {
    if (firstGraphCount) {
      setChartData(generateChartData(dateValue, Number(firstGraphCount), Number(secondGraphCount)));
    }
  }, [firstGraphCount, secondGraphCount]);


  const updGraphBlockValues = (graphValue: string) => {
    switch (graphValue) {
      case 'Repetitive users':
        setFirstGraphTitle('Repetitive users');
        setFirstGraphPercentage(null);
        setSecondGraphTitle(null);
        setSecondGraphCount(null);
        setSecondGraphPercentage(null);
        setFirstGraphColorLabel('Repetitive users');
        setSecondGraphColorLabel(null);
        setFirstGraphCount(String(repetitiveUsers));
        break;
      case 'Registered  / Non registered':
        setFirstGraphTitle('Registered');
        setSecondGraphTitle('Non registered');
        setFirstGraphCount(String(compareValuesState['Registered  / Non registered'].firstNumber));
        setFirstGraphPercentage(String(compareValuesState['Registered  / Non registered'].firstPercentage) + '%');
        setSecondGraphCount(String(compareValuesState['Registered  / Non registered'].secondNumber));
        setSecondGraphPercentage(String(compareValuesState['Registered  / Non registered'].secondPercentage) + '%');
        setFirstGraphColorLabel('Non registered');
        setSecondGraphColorLabel('Registered');
        break;
      case 'Users w & w/o ring':
        setFirstGraphTitle('With');
        setSecondGraphTitle('Without');
        setFirstGraphCount(String(compareValuesState['Users w & w/o ring'].firstNumber));
        setFirstGraphPercentage(String(compareValuesState['Users w & w/o ring'].firstPercentage) + '%');
        setSecondGraphCount(String(compareValuesState['Users w & w/o ring'].secondNumber));
        setSecondGraphPercentage(String(compareValuesState['Users w & w/o ring'].secondPercentage) + '%');
        setFirstGraphColorLabel('Without ring');
        setSecondGraphColorLabel('With ring');
        break;
      case 'Sessions w & w/o ring':
        setFirstGraphTitle('With');
        setSecondGraphTitle('Without');
        setFirstGraphCount(String(compareValuesState['Sessions w & w/o ring'].firstNumber));
        setFirstGraphPercentage(String(compareValuesState['Sessions w & w/o ring'].firstPercentage) + '%');
        setSecondGraphCount(String(compareValuesState['Sessions w & w/o ring'].secondNumber));
        setSecondGraphPercentage(String(compareValuesState['Sessions w & w/o ring'].secondPercentage) + '%');
        setFirstGraphColorLabel('Without ring');
        setSecondGraphColorLabel('With ring');
        break;
      case 'Paid vs Free users':
        setFirstGraphTitle('Paid');
        setSecondGraphTitle('Free');
        setFirstGraphCount(String(compareValuesState['Paid vs Free users'].firstNumber));
        setFirstGraphPercentage(String(compareValuesState['Paid vs Free users'].firstPercentage) + '%');
        setSecondGraphCount(String(compareValuesState['Paid vs Free users'].secondNumber));
        setSecondGraphPercentage(String(compareValuesState['Paid vs Free users'].secondPercentage) + '%');
        setFirstGraphColorLabel('Free');
        setSecondGraphColorLabel('Paid');
        break;
      case 'New Users':
        setFirstGraphTitle('New Users');
        setFirstGraphPercentage(null);
        setSecondGraphTitle(null);
        setSecondGraphCount(null);
        setSecondGraphPercentage(null);
        setFirstGraphColorLabel('New Users');
        setSecondGraphColorLabel(null);
        setFirstGraphCount(String(newUsers));
        break;
      case 'New subscribers':
        setFirstGraphTitle('New subscribers');
        setFirstGraphPercentage(null);
        setSecondGraphTitle(null);
        setSecondGraphCount(null);
        setSecondGraphPercentage(null);
        setFirstGraphColorLabel('New subscribers');
        setSecondGraphColorLabel(null);
        setFirstGraphCount(String(newSubscribers));
        break;
      default:
        setFirstGraphTitle(null);
        setFirstGraphCount(null);
        setFirstGraphPercentage(null);
        setSecondGraphTitle(null);
        setSecondGraphCount(null);
        setSecondGraphPercentage(null);
        setFirstGraphColorLabel(null);
        setSecondGraphColorLabel(null);
    }
  }


  const checkIsTwoLined = (graphValue: any) => {
    switch (graphValue) {
      case 'Repetitive users':
        return false
      case 'Registered  / Non registered':
        return true
      case 'Users w & w/o ring':
        return true
      case 'Sessions w & w/o ring':
        return true
      case 'Paid vs Free users':
        return true
      case 'New Users':
        return false
      case 'New subscribers':
        return false
      default:
        return false
    }

  }

  const generateChartDataDateRange = (uvTarget: number, pvTarget: number, selectedDate: any) => {
    const data = [];

    const names = generateDatesArrayDays(selectedDate.startDay, selectedDate.endDay);

    let uvSum = 0;
    let pvSum = 0;
    for (let i = 0; i < names.length; i++) {
      let uv = Math.floor(Math.random() * (uvTarget - uvSum + 1));
      let pv = Math.floor(Math.random() * (pvTarget - pvSum + 1));

      uvSum += uv;
      pvSum += pv;

      if (uvSum > uvTarget) {
        uv = 0;
      }
      if (pvSum > pvTarget) {
        pv = 0;
      }

      data.push({ name: names[i], uv, pv });
    }

    return data;
  }

  const generateChartData = (dateValue: string, uvTarget: number, pvTarget: number) => {
    const data = [];
    let names = ['01', '02', '03', '04', '05', '06', '07'];
    const today = new Date();
    const minusOneWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    switch (dateValue) {
      case 'today':
        names = [
          "00h",
          "01h",
          "02h",
          "03h",
          "04h",
          "05h",
          "06h",
          "07h",
          "08h",
          "09h",
          "10h",
          "11h",
          "12h",
          "13h",
          "14h",
          "15h",
          "16h",
          "17h",
          "18h",
          "19h",
          "20h",
          "21h",
          "22h",
          "23h"
        ];
        break;
      case 'week':
        names = generateDateRangeString(minusOneWeek, today).map((date) => {
          const [year, month, day] = date.split('-');

          return `${month}/${day}`;
        });
        break;
      case '2 weeks':
        const minusTwoWeeks = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
        names = generateDateRangeString(minusTwoWeeks, today).map((date) => {
          const [year, month, day] = date.split('-');

          return `${month}/${day}`;
        });
        break;
      case 'month':
        const minusOneMonth = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
        names = generateDateRangeString(minusOneMonth, today).map((date) => {
          const [year, month, day] = date.split('-');
          return `${month}/${day}`;
        });
        break;
      case '3 month':
        const minusThreeMonth = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000);
        names = generateDateRangeString(minusThreeMonth, today).map((date) => {
          const [year, month, day] = date.split('-');
          return `${month}/${day}`;
        });
        break;
      case 'year':
        const minusOneYear = new Date(today.getTime() - 365 * 24 * 60 * 60 * 1000);
        names = Object.keys(generateDateRangeString(minusOneYear, today).reduce(
          (acc: { [key: string]: boolean }, date, index) => {
            if (index % 30 === 0) {
              const [year, month, day] = date.split('-');
              acc[`${month}/${year}`] = true;
            }
            return acc;
          },
          {}
        ));
        break;
      case 'date range':
        const startDateTime = selectedDate.startDay ? selectedDate.startDay.getTime() : minusOneWeek.getTime();
        const endDateTime = selectedDate.endDay ? selectedDate.endDay.getTime() : new Date().getTime();
        names = generateDateRangeString(
          new Date(Math.min(startDateTime, endDateTime)),
          new Date(Math.max(startDateTime, endDateTime)),
        ).map((date) => {
          const [year, month, day] = date.split('-');
          return `${month}/${day}/${year}`;
        });
        break;
      default:
        names = ['01', '02', '03', '04', '05', '06', '07'];
    };

    let uvSum = 0;
    let pvSum = 0;
    for (let i = 0; i < names.length; i++) {
      let uv = Math.floor(Math.random() * (((uvTarget / names.length) - uvSum) + uvSum));
      let pv = Math.floor(Math.random() * (((pvTarget / names.length) - pvSum) + uvSum));

      uvSum += uv;
      pvSum += pv;

      if (uvSum > uvTarget) {
        uv = 0;
      }
      if (pvSum > pvTarget) {
        pv = 0;
      }

      data.push({ name: names[i], uv, pv });
    }

    return data;
  }


  const updateSessionsValues = (selectedOption: string) => {
    let sessionsMultiplier = 1;
    switch (selectedOption) {
      case 'week':
        sessionsMultiplier = 4;
        break;
      case '2 weeks':
        sessionsMultiplier = 6;
        break;
      case 'month':
        sessionsMultiplier = 15;
        break;
      case '3 month':
        sessionsMultiplier = 25;
        break;
      case 'year':
        sessionsMultiplier = 36;
        break;
      default:
        sessionsMultiplier = 1;
    }

    setSessionsMultiplierState(sessionsMultiplier);
    setNewUsers(250 * sessionsMultiplier);
    setRepetitiveUsers(170 * sessionsMultiplier);
    setNewSubscribers(150 * sessionsMultiplier);
    setCompareValuesState(generateCompareData(sessionsMultiplier / 5));
    updGraphBlockValues(graphValue);
  }

  const handleChangeFilterGraph = (event: SelectChangeEvent) => {
    setGraphValue(event.target.value as string);
  };

  const chartSizing = {
    margin: { right: 5 },
    width: 80,
    height: 80,
    legend: { hidden: true },
  };

  const generateBlock = (
    firstRegisteredCount: number,
    firstPercentage: number,
    secondRegisteredCount: number,
    secondPercentage: number,
    mainTitle: string,
    title: string,
    secondTitle: string
  ) => {
    return (
      <Box
        sx={[{
          mx: '5px',
          mb: '10px',
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          padding: '30px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'baseline',
          maxWidth: 'calc(325px - 10px)',
          width: '100%',
          boxSizing: 'border-box'
        },
        (theme) => ({
          [theme.breakpoints.down('lg')]: {
            maxWidth: 'calc(50% - 10px)',
          }
        })
        ]}
      >
        <PieChart
          series={[
            {
              data: [
                { id: 0, value: firstRegisteredCount, label: `${firstPercentage}% ${title}`, color: '#A460F6' },
                { id: 1, value: secondRegisteredCount, label: `${secondPercentage}% ${secondTitle}`, color: '#F6F0FF' },
              ],
            },
          ]}
          {...chartSizing}
        />
        <Typography
          sx={{
            mt: '15px',
            mb: '10px',
            fontSize: '13px',
            fontWeight: '400',
            textTransform: 'uppercase',
            letterSpacing: '.04em;',
            color: '#333333'
          }}
          variant='body1'
        >
          {mainTitle}
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Box sx={{ mr: '10px', display: 'flex', alignItems: 'baseline' }}>
            <Typography
              sx={{ mr: '10px', fontWeight: '500', fontSize: '34px', lineHeight: '1', color: '#422662' }}
              variant='body1'
            >
              {firstPercentage}%
            </Typography>
            <Typography
              sx={{ fontWeight: '500', fontSize: '13px', lineHeight: '1', color: '#422662' }}
              variant='body1'
            >
              {firstRegisteredCount}
            </Typography>
          </Box>
          <Box sx={{ mr: '10px', display: 'flex', alignItems: 'baseline' }}>
            <Typography
              sx={{ mr: '10px', fontWeight: '500', fontSize: '34px', lineHeight: '1', color: '#422662' }}
              variant='body1'
            >
              {secondPercentage}%
            </Typography>
            <Typography
              sx={{ fontWeight: '500', fontSize: '13px', lineHeight: '1', color: '#422662' }}
              variant='body1'
            >
              {secondRegisteredCount}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  const renderStatusBlock = (title: string, text: string, isFullSize: boolean = false) => {
    return (
      <Box sx={[{
        mx: '5px',
        mb: '10px',
        border: '1px solid #E2E2E2',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        padding: '20px 30px',
        paddingBottom: isFullSize ? '50px' : '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'baseline',
        maxWidth: isFullSize ? '100%' : 'calc(216px - 10px)',
        width: '100%',
        boxSizing: 'border-box'
      },
      (theme) => ({
        [theme.breakpoints.down('lg')]: {
          maxWidth: 'calc(33.333% - 10px)',
        }
      })
      ]}>
        <Typography
          sx={{
            mb: '15px',
            fontSize: '13px',
            fontWeight: '400',
            textTransform: 'uppercase',
            letterSpacing: '.04em;',
            color: '#333333'
          }}
          variant='body1'
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '34px',
            lineHeight: '1',
            color: '#422662'
          }}
          variant='body1'
        >
          {text}
        </Typography>
      </Box>
    )
  }


  return (
    <Box sx={[
      {
        display: 'flex',
        width: '100%'
      },
      (theme) => ({
        [theme.breakpoints.down('lg')]: {
          flexWrap: 'wrap',
        }
      })
    ]}>
      <Box sx={{
        width: '100%',
      }}>
        <Box sx={[
          {
            maxWidth: '675px',
            width: '100%',
          },
          (theme) => ({
            [theme.breakpoints.down('lg')]: {
              maxWidth: '100%',
            }
          })
        ]}>
          <Box sx={[{
            display: 'flex',
            flexWrap: 'wrap',
            mx: '-5px',
          }]}>
            {renderStatusBlock('New users', newUsers.toString())}
            {renderStatusBlock('Repetitive users', repetitiveUsers.toString())}
            {renderStatusBlock('New Subscribers', newSubscribers.toString())}
          </Box>
        </Box>
        <Box
          sx={[{
            width: '100%',
          },
          (theme) => ({
            [theme.breakpoints.down('lg')]: {
              maxWidth: '100%',
            }
          })
          ]}
        >
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mx: '-5px',
          }}>
            {generateBlock(
              compareValuesState['Registered  / Non registered'].firstNumber,
              compareValuesState['Registered  / Non registered'].firstPercentage,
              compareValuesState['Registered  / Non registered'].secondNumber,
              compareValuesState['Registered  / Non registered'].secondPercentage,
              'Registered  / Non registered',
              'Registered',
              'Non registered'
            )}
            {generateBlock(
              compareValuesState['Users w & w/o ring'].firstNumber,
              compareValuesState['Users w & w/o ring'].firstPercentage,
              compareValuesState['Users w & w/o ring'].secondNumber,
              compareValuesState['Users w & w/o ring'].secondPercentage,
              'Users w & w/o ring',
              'Users with ring',
              'Users without ring'
            )}
          </Box>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mx: '-5px',
          }}>
            {generateBlock(
              compareValuesState['Paid vs Free users'].firstNumber,
              compareValuesState['Paid vs Free users'].firstPercentage,
              compareValuesState['Paid vs Free users'].secondNumber,
              compareValuesState['Paid vs Free users'].secondPercentage,
              'Paid vs Free users',
              'Paid users',
              'Free users'
            )}
            {generateBlock(
              compareValuesState['Sessions w & w/o ring'].firstNumber,
              compareValuesState['Sessions w & w/o ring'].firstPercentage,
              compareValuesState['Sessions w & w/o ring'].secondNumber,
              compareValuesState['Sessions w & w/o ring'].secondPercentage,
              'Sessions w & w/o ring',
              'Sessions with ring',
              'Sessions without ring'
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={[
        {
          width: '100%',
          maxWidth: '600px',
        },
        (theme) => ({
          [theme.breakpoints.down('lg')]: {
            maxWidth: '100%',
          }
        })
      ]}>
        <Box sx={{
          mb: '10px',
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          padding: '20px 30px',
          paddingBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'baseline',
          maxWidth: '100%',
          width: '100%',
          boxSizing: 'border-box'
        }}>
          <Box
            sx={{
              width: '100%',
              mb: '28px',
              display: 'flex',
              justifyContent: 'flex-end',
              '& > .MuiBox-root': {
                maxWidth: '286px',
                width: '100%'
              }
            }}
          >
            <SelectControl
              items={
                [
                  { value: 'Repetitive users', label: 'Repetitive users' },
                  { value: 'Registered  / Non registered', label: 'Registered  / Non registered' },
                  { value: 'Users w & w/o ring', label: 'Users w & w/o ring' },
                  { value: 'Sessions w & w/o ring', label: 'Sessions w & w/o ring' },
                  { value: 'Paid vs Free users', label: 'Paid vs Free users' },
                  { value: 'New Users', label: 'New Users' },
                  { value: 'New subscribers', label: 'New subscribers' },
                ]
              }
              onChange={handleChangeFilterGraph}
              value={graphValue}
            />
          </Box>
          <Box sx={{
            minHeight: '45px',
            ml: 'auto',
            mb: '25px',
          }}>
            {secondGraphColorLabel && <Box
              sx={{
                mb: '10px',
                display: 'flex',
                alignItems: 'baseline',
                fontSize: '13px',
                '&::before': {
                  content: '""',
                  mr: '10px',
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: '#A460F6'
                }
              }}
            >
              {secondGraphColorLabel}
            </Box>}
            {firstGraphColorLabel && <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                fontSize: '13px',
                '&::before': {
                  content: '""',
                  mr: '10px',
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: '#DBBFFB'
                }
              }}
            >
              {firstGraphColorLabel}
            </Box>}
          </Box>
          <Box sx={{
            minHeight: '80px',
            margin: '0 auto',
            mb: '10px',
            display: 'flex',
            maxWidth: '265px',
            width: '100%',
            justifyContent: 'flex-start'
          }}>
            <Box sx={{
              mr: '40px',
            }}>
              <Typography
                sx={{
                  mt: '15px',
                  mb: '10px',
                  fontSize: '13px',
                  fontWeight: '400',
                  textTransform: 'uppercase',
                  letterSpacing: '.04em;',
                  color: '#333333'
                }}
                variant='body1'
              >
                {firstGraphTitle}
              </Typography>
              <Box sx={{ mr: '10px', display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  sx={{ mr: '10px', fontWeight: '500', fontSize: '34px', lineHeight: '1', color: '#422662' }}
                  variant='body1'
                >
                  {firstGraphCount}
                </Typography>
                <Typography
                  sx={{ fontWeight: '500', fontSize: '13px', lineHeight: '1', color: '#422662' }}
                  variant='body1'
                >
                  {firstGraphPercentage}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  mt: '15px',
                  mb: '10px',
                  fontSize: '13px',
                  fontWeight: '400',
                  textTransform: 'uppercase',
                  letterSpacing: '.04em;',
                  color: '#333333'
                }}
                variant='body1'
              >
                {secondGraphTitle}
              </Typography>
              <Box sx={{ mr: '10px', display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  sx={{ mr: '10px', fontWeight: '500', fontSize: '34px', lineHeight: '1', color: '#422662' }}
                  variant='body1'
                >
                  {secondGraphCount}
                </Typography>
                <Typography
                  sx={{ fontWeight: '500', fontSize: '13px', lineHeight: '1', color: '#422662' }}
                  variant='body1'
                >
                  {secondGraphPercentage}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{
            margin: '0 auto',
            maxWidth: '600px',
            width: '100%',
            '.recharts-cartesian-axis-line, .recharts-cartesian-axis-tick-line': {
              display: 'none'
            },
            '.recharts-xAxis': {
              transform: 'translateY(25px)',
              fontWeight: '400',
              fontSize: '10px'
            },
            '.recharts-yAxis': {
              transform: 'translateX(-25px)',
              fontWeight: '400',
              fontSize: '10px'
            },
            '.recharts-tooltip-item-list': {
              display: 'flex',
              flexDirection: 'column-reverse',
            }
          }}>
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 15,
                right: 30,
                left: 20,
                bottom: 30,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis hide={false} dataKey="name" />
              <YAxis />
              <Tooltip formatter={(value, name, props) => {
                if (name === 'pv') {
                  return [value, firstGraphColorLabel];
                } else if (secondGraphColorLabel) {
                  return [value, secondGraphColorLabel];
                }
                return [value, firstGraphColorLabel];
              }} />
              {
                checkIsTwoLined(graphValue) && <Line type="monotone" dataKey="pv" strokeWidth={2} stroke="#DBBFFB" dot={false} activeDot={false} />
              }
              <Line type="monotone" dataKey="uv" strokeWidth={2} stroke="#A460F6" dot={false} activeDot={false} />
            </LineChart>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AnalyticsUsers;