import {
  useTheme,
  Box,
  Typography,
  Table,
  TableCell,
  FormControlLabel,
  TableRow,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { STORAGE_SOUNDS } from '../../../constants/storage';
import { useEffect, useState } from 'react';
import BasicCheckbox from '../../elements/BasicCheckbox';
import { getSoundById, publishSound, removeSound } from '../../../service/soundsService';
import ErrorMsgDialog from '../../blocks/errorMsgDialog';
import { useResponseError } from '../../../hooks/useResponseError';
import { useNavigate, useParams } from 'react-router-dom';
import { mockSounds } from '../../../data/data';
import { CloseModal } from '../../elements/CloseModal';

const SoundsEdit = () => {
  const theme = useTheme();
  const { id } = useParams();
  const soundId = id;
  const navigate = useNavigate();
  const { responseError, setResponseError } = useResponseError(50000);
  const storageSounds = JSON.parse(localStorage.getItem(STORAGE_SOUNDS) || '[]');
  const currentStoreSound = storageSounds && storageSounds.length > 0 && storageSounds.find((el: any) => el.id === soundId);
  const [sound, setSound] = useState(currentStoreSound);
  const [openRemove, setOpenRemove] = useState(false);
  const [showStatusRemove, setShowStatusRemove] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // const isPublished = sound && storageSounds 
  // && storageSounds.length > 0 
  // && storageSounds.some((item: any) => {
  //   return item.id === sound?.id
  // });
  const [checked, setChecked] = useState(false);

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setOpenRemove(false);
    if (isSuccess) {
      navigate('/dashboard/sounds');
    }
  };

  useEffect(() => {

    getSound();

    return () => { };
  }, [id]);

  const getSound = async () => {
    if (id) {
      try {
        const res = await getSoundById({
          id: id
        });
        const soundRes = res;
        setChecked(soundRes?.status === "PUBLISHED" ? true : false);
        setSound((prevItem: any) => {
          const creationDate = String(soundRes?.creationDate)?.split('T')[0];
          const formatCreationDate = creationDate.split('-');
          const createdAt = String(soundRes?.createdAt)?.split('T')[0];
          const formatCreatedAt = createdAt.split('-');
          const updatedAt = String(soundRes?.updatedAt)?.split('T')[0];
          const formatUpdatedAt = updatedAt.split('-');

          return {
            ...prevItem,
            name: soundRes.packageFileName,
            file: soundRes.originalPackageFileName,
            ...soundRes,
            createdAt: soundRes?.createdAt ? `${formatCreatedAt[1]}-${formatCreatedAt[2]}-${formatCreatedAt[0]}` : null,
            updatedAt: soundRes?.updatedAt ? `${formatUpdatedAt[1]}-${formatUpdatedAt[2]}-${formatUpdatedAt[0]}` : null,
            creationDate: soundRes?.creationDate ? `${formatCreationDate[1]}-${formatCreationDate[2]}-${formatCreationDate[0]}` : null
          }
        });

      } catch (error: any) {
        console.error('error', error)
      }
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    try {
      const status = event.target.checked ? true : false;
      const res = await publishSound({ id: sound.id, status });
    } catch (error: any) {
      setResponseError(error.message)
    }
  };

  const onSubmitRemove = async () => {
    try {
      const res = await removeSound({
        id: sound.id,
      })
      setIsSuccess(true);
    } catch (error: any) {
      setResponseError(error.message);
    } finally {
      setShowStatusRemove(true)
    }
  }

  return (<Box sx={[{
    mb: '100px',
    px: '35px',
    py: '20px',
    width: '100%',
    maxWidth: '100%',
  }]}>
    <Dialog
      sx={[{
        '.MuiDialog-paper': {
          position: 'relative',
          px: '20px',
          paddingBottom: '20px',
          pt: '20px',
        }
      },
      (theme) => ({
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            px: '15px',
          }
        }
      }),
      ]}
      open={openRemove}
      onClose={handleCloseRemove}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseModal close={handleCloseRemove} />
      <DialogTitle sx={{
        px: '8px',
        pb: '20px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#000',
        maxWidth: '300px',
        textAlign: 'left'
      }}>
        {`Delete sound`}
      </DialogTitle>
      <DialogContent
        sx={{
          px: '8px',
          pb: '30px',
          maxWidth: '375px',
        }}>
        <DialogContentText sx={{
          px: '0',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          fontSize: '13px',
          fontWeight: '400',
          color: '#333'
        }}>
          {showStatusRemove
            ? <>
              {responseError ? "Something went wrong" : `Change has been submitted successfully`}
            </>
            : <>{`Are you sure you want to delete ${sound?.file}?`}</>
          }

        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          px: 0,
          maxWidth: '100%',
        }}
      >
        {showStatusRemove ? <Button
          onClick={handleCloseRemove}
          autoFocus
        >
          Okay
        </Button>
          : <>
            <Button
              sx={{
                mr: '20px',
              }}
              autoFocus
              onClick={handleCloseRemove}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={onSubmitRemove}
            >
              Delete sound
            </Button>
          </>
        }
      </DialogActions>
      <Box sx={{
        justifyContent: 'center',
        textAlign: 'center',
        color: theme.palette.error.main
      }}>
        {responseError}
      </Box>
    </Dialog>
    <ErrorMsgDialog
      title={"Error"}
      subTitle={responseError || ''}
      isOpen={responseError ? true : false}
    />
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: '55px',
      width: '100%',
    }}>
      <Typography variant="h1" component="h3">
        {sound?.name}
      </Typography>
    </Box>
    <Box sx={{
      maxWidth: '275px',
    }}>
      <Table>
        <TableRow>
          <TableCell variant="head">Package Name</TableCell>
          <TableCell>{sound?.packageName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Name</TableCell>
          <TableCell>{sound?.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">File</TableCell>
          <TableCell>{sound?.file}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Creators</TableCell>
          <TableCell>{sound?.creators}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Creation Date</TableCell>
          <TableCell>{sound?.creationDate}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Keys</TableCell>
          <TableCell>{sound?.keys}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Tempo</TableCell>
          <TableCell>{sound?.tempo}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Type</TableCell>
          <TableCell>{sound?.type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Status</TableCell>
          <TableCell>{sound?.status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Created At</TableCell>
          <TableCell>{sound?.createdAt}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head">Updated At</TableCell>
          <TableCell>{sound?.updatedAt}</TableCell>
        </TableRow>
      </Table>
    </Box>
    <Box sx={{
      mt: '30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }}>
      <FormControlLabel
        sx={{
          color: checked ? '#000000' : '#666666',
        }}
        control={
          <BasicCheckbox
            checked={checked}
            onChange={handleChange}
          />
        }
        label="Publish"
      />
      <Button
        variant="outlined"
        sx={{
          mt: '30px',
          py: '8px',
          borderRadius: '20px',
          color: '#000',
          borderColor: '#C9C9C9',
          '&:hover': {
            borderColor: '#C9C9C9',
            boxShadow: 'none',
            opacity: '0.7'
          },
          '&:active': {
            boxShadow: 'none',
            borderColor: '#C9C9C9',
          },
        }}
        onClick={handleClickOpenRemove}>
        Delete
      </Button>
    </Box>
  </Box>)
}

export default SoundsEdit;