// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #FBFBFB;
}

.App {
  text-align: center;
}

.App-logo-box {
  display: flex;
  align-items: center;
}

.App-logo-box a {
  text-decoration: none;
}

.App-logo {
  /* width: 103px !important;
  height: 60px !important;  */
  /* width: 50px; */
  pointer-events: none;
}

.App-footer {
  min-height: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  
  background-color: #FFFFFF;
  width: 100%;
}

.App-header {
  width: 100%;
  position: fixed;
  z-index: 5;
  background-color: #FFFFFF;
  padding: 16px 0;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom: 2px solid #E2E2E2;
}

.App-header-inner {
  display: flex;
}

.App-header-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.App-header-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.App-header-list_item {
  padding: 10px;
}

.App-link {
  color: #61dafb;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;6BAC2B;EAC3B,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,SAAS;EACT,OAAO;EACP,UAAU;;EAEV,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;EACf,UAAU;EACV,yBAAyB;EACzB,eAAe;EACf,6BAA6B;EAC7B,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap');\nbody {\n  background-color: #FBFBFB;\n}\n\n.App {\n  text-align: center;\n}\n\n.App-logo-box {\n  display: flex;\n  align-items: center;\n}\n\n.App-logo-box a {\n  text-decoration: none;\n}\n\n.App-logo {\n  /* width: 103px !important;\n  height: 60px !important;  */\n  /* width: 50px; */\n  pointer-events: none;\n}\n\n.App-footer {\n  min-height: 40px;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  z-index: 5;\n  \n  background-color: #FFFFFF;\n  width: 100%;\n}\n\n.App-header {\n  width: 100%;\n  position: fixed;\n  z-index: 5;\n  background-color: #FFFFFF;\n  padding: 16px 0;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  border-bottom: 2px solid #E2E2E2;\n}\n\n.App-header-inner {\n  display: flex;\n}\n\n.App-header-nav {\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n}\n\n.App-header-list {\n  display: flex;\n  align-items: center;\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.App-header-list_item {\n  padding: 10px;\n}\n\n.App-link {\n  color: #61dafb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
