import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';

type UploadProps = {
  btnTitle?: string,
  size?: string,
  getRootProps: any,
  getInputProps: any,
  acceptedFiles?: any,
}


export default function Dropzone(
  { 
    btnTitle, 
    size = 'small', 
    getRootProps, 
    getInputProps, 
  }: UploadProps
) {

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '.dropzone' : {
        px: size === 'small' ? '60px' : '105px',
        py: size === 'small' ? '40px' : '60px',
        maxWidth: '335px',
        width: '100%',
        backgroundColor: '#F6EFFE',
        border: '2px dashed #A460F6',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    }}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Button variant="contained">
          {btnTitle}
        </Button>
      </div>
    </Box>
  );
}