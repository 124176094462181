import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Pagination,
} from '@mui/material';
import SoundsTable from '../blocks/soundsTable';
import { Order, OrderBySounds } from '../../types/types';
import { useResponseError } from '../../hooks/useResponseError';
import { useResponseSuccessMsg } from '../../hooks/useResponseSuccessMsg';
import { mockSounds } from '../../data/data';
import { useNavigate } from 'react-router-dom';
import { STORAGE_SOUNDS } from '../../constants/storage';
import UploadFileDialog from '../blocks/UploadFileDialog';
import { addSound, getFilters, getSounds, publishSound as publishSoundReq } from '../../service/soundsService';
import FilterDrawer from '../blocks/FilterDrawer';
import { useDebounce } from '../../hooks/useDebounce';
import SearchBox from '../blocks/SearchBox';
import { hasNonEmptyArrays } from '../../utils';

type RegisterFormFields = {
  email: string;
  firstName: string;
  lastName: string;
  roles: 'ADMIN' | 'SUPER ADMIN';
};


const Sounds = () => {
  const navigate = useNavigate();
  const [openUpload, setOpenUpload] = useState(false);
  const [sort, setSort] = useState('ALL');
  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof OrderBySounds>('createdAt');
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filtersIconActive, setFiltersIconActive] = useState(false);
  const [sounds, setSounds] = useState<any>([]);
  const [filters, setFilters] = useState<any>();
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [selectedSound, setSelectedSound] = useState<any>();
  const { responseError, setResponseError } = useResponseError(50000);
  const { responseStatus, setResponseStatus } = useResponseSuccessMsg(3000);
  const debouncedSearchValue = useDebounce(searchValue, 1000, '');

  useEffect(() => {
    const getFiltersObj = async () => {
      try {
        const res = await getFilters();
        const { voiceSupport, ...rest } = res;
        const newFilters = { 'Voice Support': voiceSupport, ...rest, };
        setFilters(newFilters);
      } catch (error: any) {
        console.error('error', error)
      }
    };

    getFiltersObj();

    return () => { };
  }, []);

  useEffect(() => {
    if (pageNumber) {
      sortSounds(searchValue, sort, order, orderBy, pageNumber, currentFilters);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (hasNonEmptyArrays(currentFilters)) {
      setFiltersIconActive(true);
    } else {
      setFiltersIconActive(false);
    }
  }, [currentFilters]);

  useEffect(() => {
    sortSounds(debouncedSearchValue, sort, order, orderBy, 1, currentFilters);

    if (searchValue || debouncedSearchValue) {
      setHasMore(true);
    }
  }, [debouncedSearchValue]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const handleNextPage = (pgNumber: number) => {
    if (hasMore) {
      setPageNumber(pgNumber + 1)
    } else {
      setPageNumber(pgNumber);
    }
  }

  const handlePrevPage = (pgNumber: number) => {
    if (pgNumber > 0) {
      setPageNumber(pgNumber - 1)
    } else {
      setPageNumber(0);
    }
  }

  const handleClickOpen = () => {
    setOpenUpload(true);
  };

  const handleClickClose = () => {
    setOpenUpload(false);
    sortSounds(searchValue, sort, order, orderBy, pageNumber, currentFilters);
  };

  const handleResetSearch = () => {
    setPageNumber(1);
    sortSounds(searchValue, sort, order, orderBy, 1, currentFilters);
  }


  const hanleSortByFilters = async (filters: any) => {
    const { voiceSupport, ...rest } = filters;
    const newFilters = { voiceSupport: filters["Voice Support"], ...rest, };
    delete newFilters["Voice Support"];

    setCurrentFilters(newFilters);
    sortSounds(searchValue, sort, order, orderBy, pageNumber, newFilters);
  }

  const publishSound = async (item: any, status: boolean) => {
    try {
      const res = await publishSoundReq({ id: item.id, status })
      setResponseStatus(true);
      sortSounds(searchValue, sort, order, orderBy, pageNumber, currentFilters);
    } catch (error: any) {
      setResponseError(error.message)
    }
    sortSounds(searchValue, sort, order, orderBy, pageNumber, currentFilters);
  }

  const handleSelect = (item: any) => {
    setSelectedSound(item);
    navigate(`/dashboard/sounds/${item.id}`)
  }

  const handleSortBycreatedAt = () => {
    setOrder('desc');
    setOrderBy('createdAt');
    setPageNumber(1);
    sortSounds('', sort, 'desc', 'createdAt', 1, currentFilters);
  }


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBySounds,
  ) => {
    const propOrderBy = property === 'publish' ? 'status' : property;
    const isAsc = orderBy === propOrderBy && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(propOrderBy);
    setPageNumber(1);
    sortSounds(searchValue, sort, orderValue, propOrderBy, 1, currentFilters);
  };


  const sortSounds = useCallback(async (
    searchField: string,
    sortField: string,
    orderField: string,
    orderByField: string,
    pgNumber: number,
    filters?: any
  ) => {
    if (!hasMore) return;

    setIsLoading(true);
    let data: {
      take: number,
      page: number,
      search?: string,
      order?: string,
      orderBy?: string,
      filters?: any
    } = {
      page: pgNumber,
      take: 5,
      order: orderField,
      orderBy: orderByField,
      filters: filters
    }

    if (searchField !== '') {
      data.search = searchField;
    }

    try {
      const res = await getSounds(data);
      // const formatData = res.data;
      const formatData = res.data.map((item: any) => {

        return {
          packageFileName: item.packageFileName,
          originalPackageFileName: item.originalPackageFileName,
          ...item
        }
      })

      setSounds([...formatData]);
      setTotal(res?.meta?.total);
      if (res?.meta?.total) {
        const totalPages = Math.ceil(res?.meta?.total / data.take);
        setTotalPages(totalPages);
        setHasMore(totalPages > data.page ? true : false);
      } else {
        setTotalPages(0);
        setHasMore(false);
      }
    } catch (error: any) {
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);

  return (
    <Box sx={[{
      px: '35px',
      py: '20px',
      width: '100%',
      maxWidth: '100%',
    }]}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        mb: '20px',
      }}>
        <Typography
          variant="h1"
          component="h3"
        >
          Sounds
        </Typography>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          ml: 'auto'
        }}>
          <Button
            sx={[{
              mr: '40px',
              position: filtersOpen ? 'fixed' : 'static',
              right: '335px'
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                position: 'static',
              }
            }),
            ]}
            variant="contained"
            onClick={handleClickOpen}
          >
            Add Sound
          </Button>
          <FilterDrawer
            open={filtersOpen}
            setOpen={setFiltersOpen}
            filtersIconActive={filtersIconActive}
            filters={filters}
            mainTitle="Filters"
            hanleSortByFilters={hanleSortByFilters}
            showDateFilters={true}
          />
          <SearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleResetSearch={handleResetSearch}
          />
        </Box>
        <UploadFileDialog
          isOpen={openUpload}
          handleOpen={handleClickOpen}
          handleClose={handleClickClose}
          responseError={responseError}
          handleSortBycreatedAt={handleSortBycreatedAt}
        />
      </Box>
      <Box sx={{
        mb: '40px',
      }}>
        <Typography
          variant="h1"
          component="h3"
          sx={{
            fontSize: '34px',
            fontWeight: '500'
          }}
        >
          {total}
        </Typography>
      </Box>
      <Box sx={[
        {
          position: 'relative',
          maxWidth: filtersOpen ? 'calc(100% - 335px)' : '100%',
          width: '100%',
          minHeight: '550px',
          marginBottom: '30px',
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
          }
        }),
      ]}>
        <Box sx={[{
          'display': 'flex',
          flexWrap: 'wrap',
          pb: '15px',
          pr: '10px',
          maxWidth: '100%',
          width: '100%',
          overflow: 'auto',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: '-2px',
        }]}>
          <SoundsTable
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            handleSelect={handleSelect}
            publishSound={publishSound}
            data={sounds}
            page={pageNumber}
          />
        </Box>
      </Box>
      <Box sx={[{
        mb: '95px',
        display: 'flex',
        justifyContent: 'flex-end',
        position: filtersOpen ? 'fixed' : 'static',
        right: '375px'
      },
      (theme) => ({
        [theme.breakpoints.down('md')]: {
          position: 'static',
        }
      }),
      ]}>
        {pageNumber > 1 && <Button
          sx={{
            mr: '15px',
            color: '#000',
            borderColor: '#C9C9C9',
            '&:hover': {
              borderColor: '#C9C9C9',
              boxShadow: 'none',
              opacity: '0.7'
            },
            '&:active': {
              boxShadow: 'none',
              borderColor: '#C9C9C9',
            },
          }}
          onClick={() => handlePrevPage(pageNumber)}
          variant="outlined"
          disabled={pageNumber === 1}
        >
          Previous Page
        </Button>}
        <Pagination
          sx={{
            mr: '15px',
          }}
          onChange={handleChangePage}
          page={pageNumber}
          hideNextButton={true}
          hidePrevButton={true}
          count={totalPages}
          variant="outlined"
          color="primary"
        />
        <Button
          sx={{
            color: '#000',
            borderColor: '#C9C9C9',
            '&:hover': {
              borderColor: '#C9C9C9',
              boxShadow: 'none',
              opacity: '0.7'
            },
            '&:active': {
              boxShadow: 'none',
              borderColor: '#C9C9C9',
            },
          }}
          onClick={() => handleNextPage(pageNumber)}
          variant="outlined"
          disabled={!hasMore}
        >
          Next Page
        </Button>
      </Box>
    </Box>
  )
}

export default Sounds;