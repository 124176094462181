import { useState } from 'react';
import {
  styled,
  SvgIcon,
  useTheme,
  Button,
  Box,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';


export default function SearchBox({ searchValue, setSearchValue, handleResetSearch }: any) {
  const theme = useTheme();
  const [showClearIcon, setShowClearIcon] = useState(false);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowClearIcon(event.target.value === "" ? false : true);
    setSearchValue(event.target.value);
  };

  const handleClick = (): void => {
    setSearchValue('');
    setShowClearIcon(false);
    handleResetSearch();
  };

  return (
    <Box sx={{
      py: '20px',
      px: '60px',
      boxSizing: 'border-box',
      display: 'flex',
      height: '74.4px',
      backgroundColor: '#FFFFFF',
      position: 'fixed',
      left: '71px',
      right: 0,
      bottom: 0,
      zIndex: '1',
      borderTop: '1px solid #D9D9D9'
    }}>
      <Box sx={{
        display: 'flex',
        minHeight: '34px',
      }}>
        <FormControl sx={[
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              mb: '34px'
            }
          })
        ]}>
          <TextField
            InputLabelProps={{ shrink: true }}
            sx={[
              {
                backgroundColor: '#fff',
                borderRadius: '4px',
                maxWidth: '320px',
                ml: '20px',
                '.MuiInputBase-root': {
                  borderRadius: '10px',
                  pr: '10px',
                },
                '.MuiOutlinedInput-input': {
                  py: '4px',
                  borderRadius: '4px'
                },
                '&': {
                  borderRadius: 10,
                  position: 'relative',
                },
                '&. .Mui-disabled': {
                  backgroundColor: 'grey'
                },
                '& .MuiFormLabel-root': {
                  position: 'static',
                  transform: 'none',
                  marginBottom: '5px',
                  color: '#333333',
                  fontSize: '10px',
                  paddingLeft: '12px'
                },
                '& .MuiOutlinedInput-root': {
                  overflow: 'hidden',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                  border: '1px solid',
                  borderColor: '#CCCCCC',
                  transition: theme.transitions.create([
                    'border-color',
                    'background-color',
                    'box-shadow',
                  ]),
                  '&:hover': {
                    backgroundColor: '#fff',
                  },
                  '&.Mui-focused': {
                    backgroundColor: '#fff',
                    borderColor: theme.palette.primary.main,
                  },
                  '&.Mui-error': {
                    borderColor: '#EB5757',
                  },
                  '& .MuiInputBase-input': {
                    paddingLeft: '12px',
                    paddingTop: '6px',
                    paddingBottom: '6px'
                  },
                  '& .Mui-disabled': {
                    backgroundColor: 'grey'
                  }
                },
                '.MuiFormLabel-asterisk': {
                  display: 'none',
                },
                '.MuiFormHelperText-contained': {
                  // position: 'absolute',
                  bottom: '-12px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  display: 'none',
                }
              },

              (theme) => ({
                [theme.breakpoints.down('sm')]: {
                  m: 0,
                  width: '100%',
                  maxWidth: '100%'
                }
              }),
            ]}
            placeholder="Search"
            size="small"
            variant="outlined"
            onChange={handleChangeSearch}
            value={searchValue}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  sx={{
                    cursor: 'pointer'
                  }}
                  position="end"
                  onClick={handleClick}
                >
                  {showClearIcon ?
                    <SvgIcon>
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 11L19 19" stroke="#666666" />
                        <path d="M11 19L19 11" stroke="#666666" />
                      </svg>
                    </SvgIcon> :
                    <SvgIcon>
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="13.4853" cy="13.4854" r="5.5" transform="rotate(-45 13.4853 13.4854)" stroke="#666666" />
                        <path d="M17.7285 17.728L21.9712 21.9707" stroke="#666666" />
                      </svg>
                    </SvgIcon>
                  }
                </InputAdornment>
              )
            }}
          />
        </FormControl>
      </Box>
    </Box >
  );
}