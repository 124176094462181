import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  SvgIcon,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Dropzone from '../elements/Dropzone';
import { useDropzone } from 'react-dropzone';
import { addSound } from '../../service/soundsService';
import JSZip from 'jszip';
import { handleCancelRequest } from '../../service';

const UploadFileDialog = ({ isOpen, handleOpen, handleClose, responseError, handleSortBycreatedAt }: any) => {
  const [showResult, setShowResult] = useState(false);
  const [isSuccessResult, setSuccessResult] = useState(false);
  const [progress, setProgress] = useState(0);
  const [numberFile, setNumberFile] = useState(1);
  const [countFiles, setCountFiles] = useState(1);
  const [fileObj, setFileObj] = useState<any>();
  const [isLoading, setIsloading] = useState(false);
  const [errorsList, setErrorsList] = useState(['some'])
  const theme = useTheme();
  const onDrop = useCallback((acceptedFiles: any) => {
    setFileObj(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    setIsloading(false);
    setShowResult(false);
    setSuccessResult(false);
  }, [isOpen])

  useEffect(() => {
    if (responseError) {
      console.log('useEffect err', responseError, responseError.split(','));
      setErrorsList(responseError.split(','));
    }
  }, [responseError])

  useEffect(() => {
    const uploadFile = async () => {
      setIsloading(true);
      setProgress(0);
      setNumberFile(1);
      setShowResult(false);
      if (fileObj.type === 'application/zip') {
        const countFiles = await countFolders(fileObj);
        setCountFiles(countFiles)
      }
      const formData = new FormData();
      formData.append("soundExperiencePackageFile", fileObj);
      try {
        const response = await addSound(formData);
        console.log(response);
        setSuccessResult(true);
        handleSortBycreatedAt();
      } catch (error: any) {
        const errorsList = error.message.split(',');
        setErrorsList(errorsList)
        setSuccessResult(false);
      } finally {
        setIsloading(false);
        setShowResult(true);
        setProgress(0);
        setNumberFile(countFiles);
      }
    };

    if (fileObj) {
      uploadFile();
    }

    return () => { };
  }, [fileObj]);

  useEffect(() => {
    const intervalCallback = () => {
      setNumberFile((prevNumberFile) => {
        const nextNumberFile = Math.min(prevNumberFile + Math.floor(countFiles * 0.1), countFiles);
        const currentProgress = (nextNumberFile / countFiles) * 100;
        setProgress(Math.min(currentProgress, 100));
        return nextNumberFile;
      });
    };

    const timer = setInterval(intervalCallback, 500);

    return () => {
      setProgress(0);
      setNumberFile(1);
      clearInterval(timer);
    };
  }, [countFiles]);

  const cancelRequest = async () => {
    setShowResult(false);
    try {
      const res = await handleCancelRequest();
    } catch(err) { 
      console.log('err', err);
    }
  }

  async function countFolders(file: any) {

    if (!file) {
      console.error('No file selected');
      return 1;
    }

    const zip = new JSZip();
    const zipContent = await zip.loadAsync(file);
    let folderCount = 0;

    for (const [name, content] of Object.entries(zipContent.files)) {
      if (content.dir) {
        folderCount++;
      }
    }

    return folderCount - 1
  }

  const renderContent = () => {
    if (!showResult && !isLoading) {
      return (
        <>
          <Dropzone
            size={'big'}
            btnTitle={'Add ZIP file'}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
          />
          <Typography
            variant='body1'
            sx={{
              mt: '20px',
              color: '#666666',
              fontSize: '13px',
              textAlign: 'center'
            }}
          >
            Drag and drop the file here or select it on your device.
          </Typography>
        </>)
    }

    if (isLoading) {
      return (
        <>
          <Typography
            variant='subtitle1'
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '500'
            }}
          >
            {numberFile} of {countFiles}
          </Typography>
          <LinearProgress
            sx={{
              maxWidth: '200px',
              my: '20px',
              mx: 'auto',
              height: '2px',
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: '#E2E2E2',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: '#219653',
              },
            }}
            variant="determinate"
            value={progress}
          />
          <Typography
            variant='body1'
            sx={{
              mt: '18px',
              mb: '20px',
              fontWeight: '400',
              color: '#333',
              fontSize: '16px',
              textAlign: 'center'
            }}
          >
            {fileObj?.name.substring(0, 22) + '...'}
          </Typography>
          <Typography
            variant='body1'
            sx={{
              mt: '20px',
              color: '#666666',
              fontSize: '13px',
              textAlign: 'center'
            }}
          >
            Do not close this window while uploading
          </Typography>
        </>
      )
    }


    if (showResult) {
      return (
        <>
          {isSuccessResult ?
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: '10px',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#219653'
                }}
              >
                Sound successfully added
              </Typography>
              <SvgIcon sx={
                [{
                  width: '11px',
                  height: '8px',
                }]
              }>
                <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 3.5L4 6.5L9.5 1" stroke="#219653" strokeWidth="2" />
                </svg>
              </SvgIcon>
            </Box>
            :
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <Typography
                variant='subtitle1'
                sx={{
                  mb: '10px',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '500',
                  color: '#EB5757'
                }}
              >
                Uploading Error
              </Typography>
              <SvgIcon sx={
                [{
                  width: '20px',
                  height: '20px',
                }]
              }>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 6L14 14" stroke="#EB5757" strokeWidth="2" />
                  <path d="M6 14L14 6" stroke="#EB5757" strokeWidth="2" />
                </svg>
              </SvgIcon>
            </Box>
          }
          <Typography
            variant='body1'
            sx={{
              mt: '18px',
              mb: '20px',
              fontWeight: '400',
              color: '#333',
              fontSize: '16px',
              textAlign: 'center'
            }}
          >
            {fileObj?.name.substring(0, 22) + '...'}
          </Typography>
          {isSuccessResult ?
            <Typography
              variant='body1'
              sx={{
                mt: '20px',
                color: '#666666',
                fontSize: '13px',
                textAlign: 'center'
              }}
            >
              Successfully decompressed with {countFiles} of {countFiles} files
            </Typography>
            :
            <Box sx={{
              borderTop: '1px solid #E2E2E2',
              paddingTop: '20px',
            }}>
              <Typography
                variant='body1'
                sx={{
                  mb: '10px',
                  color: '#000',
                  fontSize: '13px',
                  textAlign: 'center'
                }}
              >
                Error list:
              </Typography>

              <Box
                sx={{
                  fontSize: '13px',
                  color: '#666',
                  my: 0,
                  pl: '15px'
                }}
                component={'ul'}>
                {errorsList.map((item, id) => {
                  return (
                    <li key={id}>{item.replace('Error:', '')}</li>
                  )
                })}
              </Box>
            </Box>
          }
        </>
      )
    }


  }


  return (<Dialog
    fullScreen
    open={isOpen}
    onClose={handleClose}
    sx={{
      top: '2%',
      left: '2%',
      right: '2%',
      bottom: '2%',
      '.MuiDialog-paper': {
        padding: '40px',
        boxSizing: 'border-box',
      }
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{
        justifyContent: 'flex-end',
        maxWidth: '35px',
        width: '100%',
        ml: 'auto'
      }}
    >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L14 14" stroke="#333333" strokeWidth="2" />
        <path d="M6 14L14 6" stroke="#333333" strokeWidth="2" />
      </svg>
    </IconButton>
    <Box sx={{
      maxWidth: '345px',
      width: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    }}>
      <DialogTitle sx={{
        mb: '14px'
      }}
      >
        Upload Sound
      </DialogTitle>
      <Box sx={{
        boxSizing: 'border-box',
        p: showResult || isLoading ? '40px !important' : '0',
        width: '100%',
        borderColor: isLoading ? '#A460F6' : '#E2E2E2',
        borderStyle: isLoading ? 'dashed' : 'solid',
        borderWidth: showResult || isLoading ? '2px' : '0',
        borderRadius: '20px',
      }}>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          {renderContent()}
          <Box sx={{
            textAlign: 'left',
            color: theme.palette.error.main,
          }}>{responseError}</Box>
        </DialogContent>
        <DialogActions sx={{
          width: '100%',
          px: 0,
          py: 0,
          mt: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box>
            {isLoading &&
              <Button
                onClick={cancelRequest}
                sx={{
                  maxWidth: '255px',
                  width: '100%',
                  color: '#000',
                  borderColor: '#C9C9C9',
                  '&:hover': {
                    borderColor: '#C9C9C9',
                    boxShadow: 'none',
                    opacity: '0.7'
                  },
                  '&:active': {
                    boxShadow: 'none',
                    borderColor: '#C9C9C9',
                  },
                }}
                variant="outlined"
              >
                Cancel
              </Button>}
            {showResult &&
              <>
                <Button
                  sx={{
                    maxWidth: '255px',
                    width: '100%',
                    color: '#000',
                    borderColor: '#C9C9C9',
                    '&:hover': {
                      borderColor: '#C9C9C9',
                      boxShadow: 'none',
                      opacity: '0.7'
                    },
                    '&:active': {
                      boxShadow: 'none',
                      borderColor: '#C9C9C9',
                    },
                  }}
                  onClick={() => handleClose()}
                  variant="outlined"
                >
                  To all files
                </Button>
                <Typography
                  variant='body1'
                  sx={{
                    my: '10px',
                    color: '#666666',
                    fontSize: '13px',
                    textAlign: 'center'
                  }}
                >
                  OR
                </Typography>
                <Dropzone
                  size={'small'}
                  btnTitle={'Add ZIP file'}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                />
              </>
            }
          </Box>
        </DialogActions>
      </Box>
    </Box>
  </Dialog>);
}

export default UploadFileDialog;