import { useState, useCallback } from "react";

export const useResponseSuccessMsg = (time?: number) => {
  const [responseStatus, setResponseStatus] = useState<boolean>(false);

  const mSeconds = time ? time : 10000;
  
  return {
    responseStatus,
    setResponseStatus: useCallback((status: boolean) => {
      setResponseStatus(status);
      setTimeout(() => setResponseStatus(false), mSeconds);
    }, []),
  };
};
