import { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';
import { InputText } from '../elements/InputText';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { useTheme } from '@mui/material/styles';
import { useResponseError } from '../../hooks/useResponseError';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import SuccessMsgDialog from '../blocks/SuccessMsgDialog';
import { useResponseSuccessMsg } from '../../hooks/useResponseSuccessMsg';
import { passwordPatch } from '../../service/authService';

type RegisterFormFields = {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
};

const Settings = () => {
  const theme = useTheme();
  const { logout } = useAuth();
  const [tabValue, setTabValue] = useState(0);
  const { responseStatus, setResponseStatus } = useResponseSuccessMsg(3000);
  const { responseError, setResponseError } = useResponseError(25000);

  const {
    control,
    register,
    handleSubmit,
    reset,
    resetField,
    watch,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<RegisterFormFields>({
    defaultValues: {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    },
    mode: "onChange",
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onSubmit = async (data: any) => {
    try {
      const res = await passwordPatch({
        data: data,
      })
      setResponseStatus(true);
      resetField("oldPassword");
      resetField("password");
      resetField("passwordConfirm");
    } catch (error: any) {
      setResponseError(error.message)
    }
  }

  return (<Box sx={[{
    px: '35px',
    py: '20px',
  }]}>
    <Typography sx={{ mb: '20px', color: '#333' }} variant="h1" component="h3">
      Settings
    </Typography>
    <Tabs
      value={tabValue}
      onChange={handleChange}
      aria-label="Settings tabs"
      sx={{ minHeight: '0', height: '33px', mb: '35px', borderRight: 1, borderColor: 'divider' }}
    >
      <Tab label="General" />
      <Tab label="Change password" />
    </Tabs>
    <TabPanel value={tabValue} index={0}>
      <Box sx={{ display: 'flex' }}>
        <Button
          onClick={() => logout()}
          variant="outlined"
          sx={{
            mr: '15px',
            py: '6px',
            borderRadius: '20px',
            color: '#000',
            borderColor: '#C9C9C9',
            '&:hover': {
              borderColor: '#C9C9C9',
              boxShadow: 'none',
              opacity: '0.7'
            },
            '&:active': {
              boxShadow: 'none',
              borderColor: '#C9C9C9',
            },
          }}>
          Logout
        </Button>
        <Button
          href="mailto:admin@lightbrite.net"
          variant="contained"
        >
          Contact administrator
        </Button>
      </Box>
    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <SuccessMsgDialog
        title={'Password changed'}
        subTitle={'Your password has been changed.'}
        isOpen={responseStatus}
      />
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          '& > .MuiFormControl-root': { my: 0.5, width: '100%' },
          'display': 'flex',
          'flexDirection': 'column',
          'allignItems': 'center',
          'width': '100%',
          'maxWidth': '340px',
        }}
      >
        <InputText
          label="Old Password"
          type="password"
          placeholder="Old password"
          inputProps={{
            ...register("oldPassword", {
              required: true,
              validate: {
                isValid: (value: any) => {
                  return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
                }
              }
            })
          }}
          error={!!errors['oldPassword']}
          helperText={errors['oldPassword'] ? errors['oldPassword'].message : ''}
        />
        <InputText
          label="New Password"
          type="password"
          placeholder="New password"
          inputProps={{
            ...register("password", {
              required: true,
              validate: {
                isValid: (value: any) => {
                  return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
                }
              }
            })
          }}
          error={!!errors['password']}
          helperText={errors['password'] ? errors['password'].message : ''}
        />
        <InputText
          label="Confirm new password"
          placeholder="Confirm new password"
          type="password"
          inputProps={{
            ...register("passwordConfirm", {
              required: true,
              validate: {
                isValid: (value: any) => {
                  return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
                },
                isMatch: (value: any) => {
                  return value === watch('password') || "The passwords do not match";
                }
              }
            })
          }}
          error={!!errors['passwordConfirm']}
          helperText={errors['passwordConfirm'] ? errors['passwordConfirm'].message : ''}
        />
        <Box sx={{
          textAlign: 'left',
          color: theme.palette.error.main,
        }}>{responseError}</Box>
        <Box sx={{ textAlign: 'center', mt: '10px' }}>
          <Button type="submit" sx={{ mt: '2px', py: '8px', borderRadius: '20px', width: '100%' }} variant="contained">Save new password</Button>
        </Box>
      </Box>
    </TabPanel>
  </Box>)
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Settings;